import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import View from './View';
import { selectors as appSelector } from '../../../../../../../../state/features/app';
import { operations as adminOps, selectors as adminSelector } from '../../../../../../../../state/features/admin';
import moment from 'moment';

const accordionData = [
  {
    milestoneOrder: 1,
    milestoneName: 'Initiate Integration',
  },
  {
    milestoneOrder: 2,
    milestoneName: 'Implementation',
  },
  {
    milestoneOrder: 3,
    milestoneName: 'Validation',
  },
  {
    milestoneOrder: 4,
    milestoneName: 'Certification',
  },
  {
    milestoneOrder: 5,
    milestoneName: 'Limited Production',
  },
  {
    milestoneOrder: 6,
    milestoneName: 'Full Production',
  },
];


const projectDocuments = [
  {
    title: 'Using the Swagger Editor',
  },
  {
    title: 'Trip Data Matrix',
  },
];


const ViewContainer = (props) => {
  const breadcrumbList = ['Projects'];
  const [openPanels, setOpenPanels] = useState([]);
  const { viewData } = props;
  const dispatch = useDispatch();
  const dispatchToggleAlertPopup = adminOps.dispatchToggleAlertPopup(dispatch);
  const dispatchIsProjectDataChanged = adminOps.dispatchIsProjectDataChanged(dispatch);

  const { userDetails } = useSelector((state) => ({
    userDetails: appSelector.userDetails(state),
  }));
  const { projectDataChanged } = useSelector((state) => ({
    projectDataChanged: adminSelector.projectDataChanged(state),
  }));

  const [dataItem, setDataItem] = useState([]);
  const [initData, setInitData] = useState([]);
  const [tempChanges, setTempChanges] = useState([]);

  useEffect(() => {
    let data = [
      {
        milestoneName: 'Initiate Integration',
        milestoneStatus:
          viewData?.milestones?.[0]?.milestoneStatus || 'In progress',
        expectedCompletionDate:
          viewData?.milestones?.[0]?.expectedCompletionDate || null,
        milestoneDescription:
          viewData?.milestones?.[0]?.milestoneDescription || '',
        completionDate: viewData?.milestones?.[0]?.completionDate || null,
        startDate: viewData?.milestones?.[0]?.startDate || null,
        ...viewData?.milestones?.[0],
        disabled: false,
      },
      {
        milestoneName: 'Implementation',
        milestoneStatus:
          viewData?.milestones?.[1]?.milestoneStatus || 'In progress',
        expectedCompletionDate:
          viewData?.milestones?.[1]?.expectedCompletionDate || null,
        milestoneDescription:
          viewData?.milestones?.[1]?.milestoneDescription || '',
        completionDate: viewData?.milestones?.[1]?.completionDate || null,
        startDate: viewData?.milestones?.[1]?.startDate || null,
        ...viewData?.milestones?.[1],
        disabled: viewData?.milestones?.[0]?.milestoneStatus !== 'Completed',
      },
      {
        milestoneName: 'Validation',
        milestoneStatus:
          viewData?.milestones?.[2]?.milestoneStatus || 'In progress',
        expectedCompletionDate:
          viewData?.milestones?.[2]?.expectedCompletionDate || null,
        milestoneDescription:
          viewData?.milestones?.[2]?.milestoneDescription || '',
        completionDate: viewData?.milestones?.[2]?.completionDate || null,
        startDate: viewData?.milestones?.[2]?.startDate || null,
        ...viewData?.milestones?.[2],
        disabled: viewData?.milestones?.[1]?.milestoneStatus !== 'Completed',
      },
      {
        milestoneName: 'Certification',
        milestoneStatus:
          viewData?.milestones?.[3]?.milestoneStatus || 'In progress',
        expectedCompletionDate:
          viewData?.milestones?.[3]?.expectedCompletionDate || null,
        milestoneDescription:
          viewData?.milestones?.[3]?.milestoneDescription || '',
        completionDate: viewData?.milestones?.[3]?.completionDate || null,
        startDate: viewData?.milestones?.[3]?.startDate || null,
        ...viewData?.milestones?.[3],
        disabled: viewData?.milestones?.[2]?.milestoneStatus !== 'Completed',
      },
      {
        milestoneName: 'Limited Production',
        milestoneStatus:
          viewData?.milestones?.[4]?.milestoneStatus || 'In progress',
        expectedCompletionDate:
          viewData?.milestones?.[4]?.expectedCompletionDate || null,
        milestoneDescription:
          viewData?.milestones?.[4]?.milestoneDescription || '',
        completionDate: viewData?.milestones?.[4]?.completionDate || null,
        startDate: viewData?.milestones?.[4]?.startDate || null,
        ...viewData?.milestones?.[4],
        disabled: viewData?.milestones?.[3]?.milestoneStatus !== 'Completed',
      },
      {
        milestoneName: 'Full Production',
        milestoneStatus:
          viewData?.milestones?.[5]?.milestoneStatus || 'In progress',
        expectedCompletionDate:
          viewData?.milestones?.[5]?.expectedCompletionDate || null,
        milestoneDescription:
          viewData?.milestones?.[5]?.milestoneDescription || '',
        completionDate: viewData?.milestones?.[5]?.completionDate || null,
        startDate: viewData?.milestones?.[5]?.startDate || null,
        ...viewData?.milestones?.[5],
        disabled: viewData?.milestones?.[4]?.milestoneStatus !== 'Completed',
      },
    ];
    let changes = (viewData?.milestones || []).map((item, idx) => ({ ...item, milestoneOrder: idx + 1 }));
    setTempChanges(changes);
    setDataItem(data);
    setInitData(data);
  }, [viewData]);

  useEffect(() => {
    return () => {
      if (projectDataChanged) {
        dispatchToggleAlertPopup(true, false);
      }
    };
  }, []);

  const specs = [
    {
      title: 'Product',
      text: 'PrevenTech',
    },
    {
      title: 'Service',
      text: 'External Response',
    },
    {
      title: 'API Endpoint',
      text: 'Endpoint 2',
    },
    {
      title: 'Project Manager',
      text: 'Jared Workman',
    },
  ];

  const onAccordianClick = (open) => {
    setOpenPanels(open);
  };


  const onTickClick = (event, index) => {
    event.stopPropagation();
    let data = JSON.parse(JSON.stringify(dataItem));
    const tempArr = [];
    data?.forEach((item, idx) => {
      const status = item?.milestoneStatus === 'Completed' ? 'In Progress' : 'Completed';
      if (idx === index) {
        tempArr.push({
          ...item,
          milestoneStatus: status,
          completionDate: status === 'Completed' ? moment().format('DD/MM/YYYY') : null,
          expectedCompletionDate: item?.expectedCompletionDate ? moment(item?.expectedCompletionDate).format('DD/MM/YYYY') : null
        });
      } else if (idx === index + 1) {
        const bfrItem = data[idx - 1];
        const brfStatus = bfrItem?.milestoneStatus === 'Completed' ? 'In Progress' : 'Completed';
        tempArr.push({
          ...item,
          milestoneStatus: 'In Progress',
          disabled: brfStatus !== 'Completed',
          startDate: moment().format('DD/MM/YYYY'),
          completionDate: null
        });

      } else {
        tempArr.push({
          ...item,
          milestoneStatus: idx > index ? 'In Progress' : 'Completed',
          disabled: idx > index,
          startDate: idx > index ? moment().format('DD/MM/YYYY') : item?.startDate,
          completionDate: idx > index ? null : item?.completionDate
        });
      }
    });
    dispatchIsProjectDataChanged(true);
    setDataItem(tempArr);
  };

  const onFieldChange = (value, field, index) => {
    let data = [...dataItem];
    const tempArr = [];
    data?.forEach((item, idx) => {
      if (idx === index) {
        let isAlreadyAvailable = tempChanges[idx];
        if (value !== initData[idx][field]) {
          if (isAlreadyAvailable) {
            let altData = [...tempChanges];
            altData[idx] = { ...item, [field]: value, completionDate: null };
            setTempChanges(altData);
          } else {
            setTempChanges((prevState) => [...prevState, { ...item, milestoneOrder: idx + 1 }]);
          }
        } else {
          let arr = tempChanges;
          arr.splice(idx);
          setTempChanges(arr);
        }
        tempArr.push({
          ...item,
          [field]: value,
          completionDate: null,
          milestoneStatus: 'In progress',
        });
      } else if (idx > index) {
        tempArr.push({
          ...viewData?.milestones[idx],
          milestoneName:
            idx === 0
              ? 'Initiate Integration'
              : idx === 1
                ? 'Implementation'
                : idx === 2
                  ? 'Validation'
                  : idx === 3
                    ? 'Certification'
                    : idx === 4
                      ? 'Limited Production'
                      : 'Full Production',
          milestoneStatus: 'In progress',
          milestoneDescription: item?.milestoneDescription,
          startDate: null,
          disabled: true,
        });
      } else {
        tempArr.push({ ...item });
      }
    });
    dispatchIsProjectDataChanged(true);
    setDataItem(tempArr);
  };

  return (
    <View
      breadcrumbList={breadcrumbList}
      breadcrumbOnClick={props.breadcrumbOnClick}
      accordionData={accordionData}
      openPanels={openPanels}
      onAccordianClick={onAccordianClick}
      projectDocuments={projectDocuments}
      contactOption={props?.contactOption}
      selectedContact={props?.selectedContact}
      contactList={props?.contactList}
      onContactSelectChange={props?.onContactSelectChange}
      setOpenPanels={setOpenPanels}
      dataItem={dataItem}
      setDataItem={setDataItem}
      onFileUpload={props?.onFileUpload}
      specs={specs}
      viewData={props?.viewData}
      otherData={props?.otherData}
      onChangeOtherData={props?.onChangeOtherData}
      onTickClick={onTickClick}
      userDetails={userDetails}
      onFieldChange={onFieldChange}
      onDeleteFile={props?.onDeleteFile}
      onClickSave={() => props?.onClickSave(dataItem)}
      onDownloadFile={props?.onDownloadFile}
      onManagerChange={props?.onManagerChange}
      managerList={props?.managerList}
      managers={props?.managers}
      selectedManagers={props?.selectedManagers}
      isAdmin={props?.isAdmin}
    />
  );
};

export default ViewContainer;
