import React from 'react';
import { Modal } from 'antd';
import './Modal.scss';

const ModalPopup = ({
  className='',
  width=1100,
  style={ top: 20 },
  closable=true,
  centered,
  ...props
}) => {
  return (
    <div>
      <Modal
        title={props.title}
        open={props.visible}
        width={width || 1000}
        closable={closable}
        onCancel={props.closeModalPopup}
        footer={props.footer}
        maskClosable={false}
        className={className}
        style={style}
        centered={centered}
      >
        {props.children}
      </Modal>
    </div>
  );
  
};

export default ModalPopup;