import React from 'react';
import Title from './Title';

const TitleContainer = (props) => {
  return (
    <Title
      breadcrumbList={props?.breadcrumbList}
      breadcrumbOnClick={props?.breadcrumbOnClick}
      viewData={props?.viewData}
    />
  );
};

export default TitleContainer;
