import React, { useMemo } from 'react';
import { Accordion, Datepicker } from '../../../../../../../../CommonComponents';
import TextAreaComponent from '../../../../../../../../CommonComponents/TextArea';
import RoundTick from '../../../../../../../../../assets/images/RoundTick.png';
import moment from 'moment';
import getImg from '../../getImg';

const MileStone = (props) => {
  const { viewData } = props;

  const AccordionItems = useMemo(() => {
    let array = [];
    if (props?.dataItem?.length > 0) {
      props.dataItem?.forEach((item, index) => {
        array.push({
          disabled: item?.disabled,
          headerText: (
            <p>
              {item?.milestoneStatus === 'Completed' ? (
                <img
                  src={RoundTick}
                  className={`tickIcon ${
                    item?.milestoneStatus === 'Completed' ? 'checkedItem' : ''
                  }`}
                  alt='tickIcon'
                  onClick={(e) =>
                    item?.disabled ? {} : props.onTickClick(e, index)
                  }
                />
              ) : (
                <span
                  className='roundButton'
                  onClick={(e) =>
                    item?.disabled ? {} : props.onTickClick(e, index)
                  }
                >
                  {index + 1}
                </span>
              )}
              {item.milestoneName}
            </p>
          ),
          content: (
            <div>
              <p className='labelText'>Step Description</p>
              <TextAreaComponent
                rows='6'
                value={item?.milestoneDescription || ''}
                onChangeEvent={(e) =>
                  props?.onFieldChange(
                    e?.target?.value,
                    'milestoneDescription',
                    index
                  )
                }
              />
              <div className='dateBlock'>
                <div className='dateList'>
                  <p className='labelText'>Step Start Date</p>
                  <p className='startDate'>{item?.startDate || '-'}</p>
                </div>
                <div className='dateList'>
                  <p className='labelText'>Expected Completion Date</p>
                  <Datepicker
                    value={item?.expectedCompletionDate && typeof item?.expectedCompletionDate === 'string' ? moment(item?.expectedCompletionDate, 'DD/MM/YYYY')  : item?.expectedCompletionDate && typeof item?.expectedCompletionDate === 'object' ? item?.expectedCompletionDate : null}
                    onChangeEvent={(date) =>
                      props?.onFieldChange(
                        date,
                        'expectedCompletionDate',
                        index
                      )
                    }
                    disabled={props?.isPartner}
                    className='expectedDate'
                  />
                </div>
                <div className='dateList'>
                  <p className='labelText'>Actual Completion Date</p>
                  <p className='startDate'>{item?.completionDate || '-'}</p>
                </div>
              </div>
            </div>
          ),
        });
      });
    }

    return array;
  }, [props.dataItem]);

  return (
    <div className='cardListBlock'>
      <div className='clearfix cardHeader'>
        <div className='float-left'>
          <span className='heading'>Project Milestones</span>
        </div>
        <div className='float-right'>
          <span className='status'>{viewData?.projectStatus}</span>
          <img src={getImg(viewData?.projectStatus)} alt='image' />
        </div>
      </div>
      <div className='accordionView'>
        <Accordion
          ghost={true}
          AccordionItems={AccordionItems}
          className='myaccordion'
          activeKey={props.openPanels}
          onChange={props.onAccordianClick}
        />
      </div>
    </div>
  );
};

export default MileStone;
