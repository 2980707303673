import React, { useEffect, useState } from 'react';
import { OutlineButton, Popover, Tag } from '../../../../../../../CommonComponents';
import { useDispatch, useSelector } from 'react-redux';
import PartnersView from './PartnersView';
import getImg from '../../ApiProject/getImg';
import sortData from '../../../../../../../../utils/sortData';
import { selectors as adminSelector, operations as adminOps } from '../../../../../../../../state/features/admin';
import {
  selectors as appSelector,
  operations as appOps,
} from '../../../../../../../../state/features/app';

const PartnersViewContainer = (props) => {
  const breadcrumbList = ['Partners'];
  const [deactivateSubscription, setDeactivateSubscription] = useState(false);
  const [status, setStatus] = useState('');
  const [prodOptions, setProdOptions] = useState([]);

  const dispatch = useDispatch();
  const dispatchSubscriptionSubmit = appOps.dispatchSubmitSubRequest(dispatch);
  const dispatchGetProducts = adminOps.dispatchGetProducts(dispatch);
  const dispatchSubscribeProducts = adminOps.dispatchSubscribeProducts(dispatch);

  const {
    activeSubscriptionData,
    inActiveSubscriptionData,
    partnerData,
    userDetails,
    products
  } = useSelector((state) => ({
    activeSubscriptionData: adminSelector.activeSubscription(state),
    inActiveSubscriptionData: adminSelector.inActiveSubscription(state),
    partnerData: adminSelector.partnerData(state),
    userDetails: appSelector?.userDetails(state),
    products: adminSelector?.products(state)
  }));

  const { businessContacts, supportContacts, technicalContacts } = partnerData;

  const [currentRow, setCurrentRow] = useState({});
  const [openSubscription, setOpenSubscription] = useState(false);
  const [product, setProduct] = useState(null);
  const [services, setServices] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    pageSizeOptions: [5, 10, 20, 50, 100],
    showSizeChanger: true,
  });
  const [inactivePagination, setInactivePagination] = useState({
    current: 1,
    pageSize: 5,
    pageSizeOptions: [5, 10, 20, 50, 100],
    showSizeChanger: true,
  });
  const { availableProducts } = products;

  useEffect(() => {
    dispatchGetProducts(props.partnerName);
  }, []);

  const showNotiPopup = (name, row) => {
    setDeactivateSubscription(true);
    setCurrentRow(row);
    setStatus(name);
  };

  const hideDeactivate = () => {
    setDeactivateSubscription(false);
    setStatus('');
    setCurrentRow('');
  };

  const activeSubscription = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (row) => {
        return (
          <div>
            <img src={getImg(row)} alt='statusb' className='statusIcon' />
            {row}
          </div>
        );
      },
      sorter: (a, b) => sortData(a.status, b.status),
      width: 150,
    },
    {
      title: 'Product',
      dataIndex: 'productName',
      key: 'productName',
      width: 150,
      sorter: (a, b) => sortData(a.productName, b.productName),
    },
    {
      title: 'Service',
      dataIndex: 'serviceName',
      key: 'serviceName',
      width: 150,
      sorter: (a, b) => sortData(a.serviceName, b.serviceName),
    },
    {
      title: 'Hardware Version',
      dataIndex: 'hardwareVersion',
      key: 'hardwareVersion',
      width: 150,
      sorter: (a, b) => sortData(a.hardwareVersion, b.hardwareVersion),
    },
    {
      title: 'Product Version',
      dataIndex: 'productVersion',
      key: 'productVersion',
      width: 140,
      sorter: (a, b) => sortData(a.productVersion, b.productVersion),
    },
    {
      title: 'Actions',
      dataIndex: 'Action',
      key: 'Action',
      width: 250,
      align: 'center',
      render: (_, row) => {
        return (
          <>
            {/* <OutlineButton className='normalButton viewButton'>
              View Project
            </OutlineButton> */}

            <OutlineButton
              className='normalButton viewButton rejectButton'
              onClick={() => {
                showNotiPopup('Deactivate', row);
              }}
            >
              Deactivate
            </OutlineButton>
          </>
        );
      },
    },
  ];

  const inactiveSubscription = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (row) => {
        return (
          <div>
            <img src={getImg(row)} alt='status' className='statusIcon' /> {row}
          </div>
        );
      },
      sorter: (a, b) => sortData(a.status, b.status),
      width: 120,
    },
    {
      title: 'Product',
      dataIndex: 'productName',
      key: 'product_name',
      width: 100,
      sorter: (a, b) => sortData(a.productName, b.productName),
    },
    {
      title: 'Service',
      dataIndex: 'serviceName',
      key: 'service_name',
      width: 110,
      sorter: (a, b) => sortData(a.serviceName, b.serviceName),
    },
    {
      title: 'Integration Manager',
      dataIndex: 'integrationManager',
      key: 'integrationManager',
      width: 200,
      sorter: (a, b) => sortData(a.integrationManager, b.integrationManager),
      render: (_, row) => {
        let remaining = 0;
        if (row?.integrationManager?.length > 1) {
          remaining = row?.integrationManager?.length - 1;
        }

        if (row?.integrationManager?.length > 1) {
          return <>{row?.integrationManager?.slice(0, 1)}
            <Popover content={row?.integrationManager?.slice(1, row?.integrationManager?.length).join(', ')}>
              <span className='leftMoreSpace'><Tag color={'#108ee9'}>+ {remaining} more</Tag></span>
            </Popover>
          </>;
        }
        return row?.integrationManager?.toString();
      },
    },
    {
      title: 'Inactive Date',
      dataIndex: 'rejectionDate',
      key: 'rejection_date',
      width: 170,
      align: 'right',
      sorter: (a, b) => sortData(a.rejectionDate, b.rejectionDate),
    },
    {
      title: 'Actions',
      dataIndex: 'Action',
      key: 'Action',
      width: 200,
      align: 'center',
      render: (_, row) => {
        return (
          <>
            <OutlineButton
              className='viewButton activateButton'
              onClick={() => {
                showNotiPopup('Activate', row);
              }}
            >
              Activate
            </OutlineButton>
          </>
        );
      },
    },
  ];

  const contactdetails = [
    {
      title: 'Technical Contact',
      name: 'Thomas Magnum',
      email: 'tmagnum@example.com',
      phone: 'tmagnum@example.com',
    },
    {
      title: 'Business Contact',
      name: 'Thomas Magnum',
      email: 'tmagnum@example.com',
      phone: 'tmagnum@example.com',
    },
    {
      title: 'Customer Support Contact',
      name: 'Thomas Magnum',
      email: 'tmagnum@example.com',
      phone: 'tmagnum@example.com',
    },
  ];

  const onSubmitBtn = (status, row) => {
    const body = {
      actionType: status === 'Deactivate' ? 'U' : 'A',
      partnerId: props?.partnerId,
      partnerName: props?.partnerName,
      product_id: row?.productId,
      emailId: userDetails?.unique_name,
      services: [row?.serviceId],
      productName: row?.ProductName,
      serviceName: row?.serviceName,
    };
    dispatchSubscriptionSubmit(body, {}, false, props?.partnerId);
    hideDeactivate();
  };

  const onAddSubscription = () => {
    setOpenSubscription(true);
  };

  const hideSubscription = () => {
    setServices([]);
    setProduct(null);
    setOpenSubscription(false);
  };


  useEffect(() => {
    let prods = [];
    if (availableProducts) {
      availableProducts?.forEach((item) => {
        prods.push({ text: item?.title, id: item?.title });
      });
      setProdOptions(prods);
    }
  }, [availableProducts]);

  const onChangeProducts = (value) => {
    setProduct(value);

    let service = [];
    if (availableProducts) {
      availableProducts?.forEach((item) => {
        if (item?.title === value) {
          service = item?.children?.map((child) => ({ ...child, subscriptionStatus: 'UnSubscribed' }));
        }
      });
    }
    setServices(service);
  };

  const onServiceCheck = (e, item) => {
    e?.preventDefault();
    const tempService = [...services];
    let newServiceArr = [];
    tempService.forEach(element => {
      if (element?.id === item?.id) {
        element.subscriptionStatus = element?.subscriptionStatus === 'UnSubscribed'
          ? 'Subscribed'
          : 'UnSubscribed';
      }
      newServiceArr.push(element);
    });
    setServices(newServiceArr);
  };

  const onSaveSubscription = () => {
    hideSubscription();
    const index = availableProducts?.findIndex((item) => item?.title === product);
    let serviceIds = [];
    services?.map((item) => {
      if (item?.subscriptionStatus === 'Subscribed') {
        serviceIds?.push(item?.id);
      }
    });
    const body = {
      partnerName: props?.partnerName,
      productId: availableProducts[index]?.id,
      serviceIds: serviceIds,
      userId: userDetails?.unique_name,
      partnerId: props?.partnerId
    };
    dispatchSubscribeProducts(body);

  };

  return (
    <PartnersView
      partnerName={props.partnerName}
      dataSource={activeSubscriptionData}
      inActiveSubscriptionData={inActiveSubscriptionData}
      activeSubscription={activeSubscription}
      inactiveSubscription={inactiveSubscription}
      breadcrumbList={breadcrumbList}
      breadcrumbOnClick={props.breadcrumbOnClick}
      contactdetails={contactdetails}
      deactivateSubscription={deactivateSubscription}
      loading={props?.loading}
      hideDeactivate={hideDeactivate}
      status={status}
      businessContacts={businessContacts?.[0] || {}}
      supportContacts={supportContacts?.[0] || {}}
      technicalContacts={technicalContacts?.[0] || {}}
      onSubmitBtn={(status) => onSubmitBtn(status, currentRow)}
      onAddSubscription={onAddSubscription}
      openSubscription={openSubscription}
      hideSubscription={hideSubscription}
      prodOptions={prodOptions}
      product={product}
      services={services}
      onChangeProducts={onChangeProducts}
      onServiceCheck={onServiceCheck}
      onSaveSubscription={onSaveSubscription}
      pagination={pagination}
      onPaginationChange={(value) => setPagination(value)}
      inactivePagination={inactivePagination}
      onInactivePaginationChange={(value) => {
        setInactivePagination(value);
      }}
    />
  );
};

export default PartnersViewContainer;
