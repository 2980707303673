import React from 'react';
import Title from './Title';

const TitleContainer = (props) => {
  return (
    <Title
      breadcrumbList={props.breadcrumbList}
      breadcrumbOnClick={props.breadcrumbOnClick}
      partnerName={props.partnerName}
      onAddSubscription={props?.onAddSubscription}
    />
  );
};

export default TitleContainer;