import React from 'react';
import './SimulateModal.scss';
import {
  // Radio,
  YMLEditor
} from '../../../../CommonComponents';
import { Radio } from 'antd';

const SimulateModal = (props) => {

  const { simulateData, onChangeSimulate, url, diagnosticsOption, onDiagnosticChange, disabledDiagnostics } = props;

  return (
    <div className='simulateExternalResp'>
      <div className='simulateWrapper'>
        <div className='labelView'>
          <label className='label'>
            External Response URL
          </label>
        </div>
        <div className='splitView'>
          :
        </div>
        <div className='valueView'>
          <div>{url}</div>
        </div>
      </div>
      <div className='simulateWrapper'>
        <div className='labelView'>
          <label className='label'>
            Simulation Type
          </label>
        </div>
        <div className='splitView'>
          :
        </div>
        <div className='valueView'>
          <div>
            <Radio.Group onChange={onDiagnosticChange} value={diagnosticsOption}>
              <Radio value={'Diagnostics'} disabled={disabledDiagnostics === 'Diagnostics'}>Diagnostics</Radio>
              <Radio value={'Prognostics'} disabled={disabledDiagnostics === 'Prognostics'}>Prognostics</Radio>
            </Radio.Group>
            {/* <Radio
              className='partnerNameText'
              options={['Diagnostics', 'Prognostics']}
              onChangeEvent={onDiagnosticChange}
              value={diagnosticsOption}
            /> */}
          </div>
        </div>
      </div>
      <div className="formControl">
        <label className='label'>
          Simulation Payload  <span style={{ color: 'red' }}>*</span>
        </label>
        <YMLEditor file={simulateData} onEdit={(value) => onChangeSimulate(value)} />
      </div>
    </div>
  );
};

export default SimulateModal;