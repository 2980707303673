import React from 'react';
import TabAndSearch from './TabAndSearch';

const TabAndSearchContainer = (props) => {
  return (
    <TabAndSearch
      tabs={props?.tabs}
      activeTabs={props?.activeTabs}
      onClickTabs={props?.onClickTabs}
    />
  );
};

export default TabAndSearchContainer;
