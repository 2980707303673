import React from 'react';
import {
  Button,
  Modal,
  OutlineButton,
  Select,
  Textarea,
  // Dropdowns,
  Table,
  Text,
  // Switch,
} from '../../../../../../../CommonComponents';

const APIChildren = (props) => {
  const {
    selectedRowKeys,
    onSelectChange,
    formData,
    onFormChange,
    statusMsg,
    currentRow,
  } = props;
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  return (
    <Modal
      visible={props.visible}
      hideModal={props.hideModal}
      closeModalPopup={props.closeModalPopup}
      title={props.title}
      width={props?.width}
      footer={
        <div className='customModalFooter viewBlock clearfix'>
          {props.title === 'Add API' ? (
            <div className='float-left'>
              <OutlineButton
                className='normalButton viewButton'
                onClick={props.onShowAddManually}
              >
                Add Custom API
              </OutlineButton>
            </div>
          ) : null}

          <div className='float-right'>
            <OutlineButton
              className='normalButton viewButton'
              onClick={props.onClickCancel}
            >
              Cancel
            </OutlineButton>
            <Button
              className='normalButton viewButton'
              onClick={props.onClickSave}
            >
              {currentRow?.isPublished === 'Yes' ? 'Publish' : 'Save'}
            </Button>
          </div>
        </div>
      }
      centered
    >
      <div className='editModal'>
        {props.title === 'Add API' ? (
          <div className='formItem'>
            <label className='label'>API Endpoint</label>
            <Select
              options={props.apiEndpointOption}
              onChange={(e) => props?.onEndpointChange(e)}
            />
          </div>
        ) : null}

        {props.title !== 'Add API' ? (
          <>
            <div className='formControl'>
              <label className='label'>
                API Name
                <span style={{ color: 'red' }}>*</span>
              </label>
              <Text
                className='productNameText'
                value={formData?.name}
                // disabled={true}
                onChange={(e) => onFormChange(e.target.value, 'name')}
                statusMsg={statusMsg?.name}
              />
            </div>
            <div className='formControl'>
              <label className='label'>
                API Description
                <span style={{ color: 'red' }}>*</span>
              </label>
              <Textarea
                className='descriptionText'
                rows={6}
                value={formData?.description}
                onChangeEvent={(e) =>
                  onFormChange(e.target.value, 'description')
                }
                statusMsg={statusMsg?.description}
              />
            </div>
            <p className='note'>
              Making a API active will allow Partners to request to subscribe to
              the API
            </p>
          </>
        ) : null}

        {props.title === 'Add API' ? (
          <Table
            dataSource={props.editAPIDataSource}
            columns={props.editAPIColumn}
            scroll={{ x: 'max-content' }}
            showSorterTooltip={false}
            pageSizeOptions={[5, 10, 20, 50, 100]}
            rowSelection={rowSelection}
            loading={props?.loading}
            pagination={{
              current: 1,
              pageSize: 5,
              pageSizeOptions: [5, 10, 20, 50, 100],
              showSizeChanger: true,
            }}
          />
        ) : null}
      </div>
    </Modal>
  );
};

export default APIChildren;
