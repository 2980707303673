import React, { /* useEffect,  useState */ } from 'react';
// import { Tabs } from '../../../CommonComponents';
import { CodeBlock } from '../../CommonWidget';
import './MethodTabs.scss';

const MethodTabs = (props) => {
  const content = props.content ?? {};
  const body = content.body ?? {};
  const methods = body?.methods ?? [];
  // const [tabContent, setTabContent] = useState();

  // console.log('methods', methods?.[0]?.data?.[0], props?.defaultTabKey);

  // useEffect(() => {
  //   const tabComponent = [
  //     {
  //       key: 0,
  //       label: methods[0].tab,
  //       children: (
  //         <div>
  //           {methods[0].data.map((item, index) => {
  //             console.log('itemitem', item);
  //             if (item?.body?.type !== 'yaml') {
  //               return (
  //                 <div key={index}>
  //                   <CodeBlock
  //                     content={item}
  //                     swaggerJson={props?.swaggerJson}
  //                     loading={props?.loading}
  //                   />
  //                 </div>
  //               );
  //             }
  //             return null;
  //           })}
  //         </div>
  //       ),
  //     },
  //   ];
  //   // const tabComponent = methods?.map((item, index) => {
  //   //   return {
  //   //     key: index,
  //   //     label: item.tab,
  //   //     children: (
  //   //       <div>
  //   //         {item.data.map((item, index) => {
  //   //           if(item?.body?.type !== 'yaml') {
  //   //             return <div key={index}>
  //   //               <CodeBlock
  //   //                 content={item}
  //   //                 swaggerJson={props?.swaggerJson}
  //   //                 loading={props?.loading}
  //   //               />
  //   //             </div>;
  //   //           }
  //   //           return null;
  //   //         })}
  //   //       </div>
  //   //     ),
  //   //   };
  //   // });
  //   setTabContent(tabComponent);
  // }, [methods, props?.swaggerJson]);

  return (
    <div className={`methodTabs ${content.className}`}>
      {/* <Tabs tabComponent={tabContent} defaultActiveKey={props.defaultTabKey} editable={props?.editable} /> */}
      <CodeBlock
        content={methods?.[0]?.data?.[0]}
        swaggerJson={props?.swaggerJson}
        loading={props?.loading}
      />
    </div>
  );
};

export default MethodTabs;
