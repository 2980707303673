import { all } from 'redux-saga/effects';

import {combinedSaga as app} from './app';
import {combinedSaga as admin} from './admin';

export default function* rootSaga() {
  yield all([
    app(),
    admin()
  ]);
}
