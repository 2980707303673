import React, { useState, useEffect, useMemo, useRef } from 'react';
import CommonContent from './CommonContent';
import { operations as appOps } from '../../../../../../../../state/features/app';
import { operations as adminOps } from '../../../../../../../../state/features/admin';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';

const CommonContentContainer = (props) => {
  const dispatch = useDispatch();
  const dispatchGetNavRouteContent =
    appOps.dispatchGetNavRouteContent(dispatch);
  const dispatchUpdateWebcontent = adminOps.dispatchUpdateWebcontent(dispatch);
  const dispatchUploadProjectDOc = adminOps.dispatchUploadProjectDoc(dispatch);
  const webContent = useSelector((state) => state?.app?.routeContent);
  const { products, services } = useSelector((state) => state?.admin || {});

  const [editField, setEditField] = useState('');
  const [fieldKey, setFieldKey] = useState('');
  const [showEditContent, setShowEditContent] = useState(false);
  const [editText, setEditText] = useState('');
  const [widgetName, setWidgetName] = useState('');
  const [editLink, setEditLink] = useState('');
  const [isLink, setIsLink] = useState(false);
  const [editFieldLink, setEditFieldLink] = useState('');
  const [isImageUpdate, setIsImageUpdated] = useState(false);
  const [isMethodImgUpdated, setIsMethodImgUpdated] = useState(false);
  const [isVideoUpdated, setIsVideoUpdated] = useState(false);
  const [previousClick, setPreviousClick] = useState(false);
  const [basePath, setBasePath] = useState('');
  const [closeEditModal, setCloseEditModal] = useState(false);


  const isContentChanged = useRef(false);

  const { productIds, visible, setVisible, currentScreen, onShowModal } = props;

  const onCloseModalClick = () => {
    if (isContentChanged?.current) {
      setCloseEditModal(true);
    } else {
      onCloseModal();
    }
  };

  const onClickModalClose = () => {
    setCloseEditModal(false);
  };

  const onCloseModal = () => {
    isContentChanged.current = false;
    setCloseEditModal(false);
    setVisible(false);
    setPreviousClick(false);
  };

  const productName = useMemo(() => {
    if (products?.length > 0) {
      return (
        products?.find((o) => o?.productId === productIds?.productId)
          ?.productName || ''
      );
    }
  }, [products, productIds]);

  const serviceName = useMemo(() => {
    if (Object.keys(services || {})?.length > 0) {
      return (
        services[productIds?.productId]?.find(
          (o) => o?.serviceId === productIds?.serviceId
        )?.serviceName || ''
      );
    }
  }, [services, productIds]);

  const currentContent = useMemo(() => {
    let tempData = {};
    if (currentScreen === '/Products') {
      tempData = R.pathOr({}, ['/Home'], webContent);
    } else if (
      productName !== '' &&
      serviceName !== '' &&
      `/${serviceName}` === currentScreen
    ) {
      tempData = webContent[`/${productName}/${serviceName}`];
    } else if (
      productName !== '' &&
      serviceName !== '' &&
      `/${serviceName}` !== currentScreen
    ) {
      tempData = webContent[`/${productName}/${serviceName}${currentScreen}`];
    } else if (productName !== '' && `/${productName}` === currentScreen) {
      tempData = webContent[`${currentScreen}`];
    } else {
      tempData = webContent[currentScreen];
    }
    return tempData;
  }, [currentScreen, webContent, productName, serviceName]);

  const [data, setData] = useState(currentContent?.currentVersion);

  useEffect(() => {
    setBasePath(currentContent?.basePath || '');
    if (previousClick) {

      const curData = currentScreen === '/Products' ? R.pathOr({}, ['defaultBody', '/Home'], currentContent?.previousVersion)
        : currentContent?.previousVersion;
      setData(curData);
    } else {
      setData(
        currentScreen === '/Products'
          ? R.pathOr(
            {},
            ['defaultBody', '/Home'],
            currentContent?.currentVersion
          )
          : currentContent?.currentVersion
      );
    }
  }, [currentContent, previousClick]);

  const onHideModal = () => {
    onCloseModalClick();
  };

  useEffect(() => {
    let schema = '';
    if (currentScreen === '/Products') {
      schema = '/Home';
    } else if (
      productName !== '' &&
      serviceName !== '' &&
      `/${serviceName}` === currentScreen
    ) {
      schema = `/${productName}/${serviceName}`;
    } else if (
      productName !== '' &&
      serviceName !== '' &&
      `/${serviceName}` !== currentScreen
    ) {
      schema = `/${productName}/${serviceName}${currentScreen}`;
    } else if (productName !== '' && `/${productName}` === currentScreen) {
      schema = currentScreen;
    } else if (`/${productName}` === currentScreen) {
      schema = currentScreen;
    } else {
      schema = currentScreen;
    }
    if (visible) {
      dispatchGetNavRouteContent(schema, 'Yes');
    }
  }, [visible, currentScreen, productName]);

  const onSaveWebContent = () => {
    onWebFieldChange(editText, editField, fieldKey, isLink);
    onHideEditContent();
    isContentChanged.current = true;
  };

  const onPublishClick = () => {
    let schema = '';
    if (currentScreen === '/Products') {
      schema = '/Home';
    } else if (
      productName !== '' &&
      serviceName !== '' &&
      `/${serviceName}` === currentScreen
    ) {
      schema = `/${productName}/${serviceName}`;
    } else if (
      productName !== '' &&
      serviceName !== '' &&
      `/${serviceName}` !== currentScreen
    ) {
      schema = `/${productName}/${serviceName}${currentScreen}`;
    } else if (productName !== '' && `/${productName}` === currentScreen) {
      schema = currentScreen;
    } else if (`/${productName}` === currentScreen) {
      schema = currentScreen;
    } else {
      schema = currentScreen;
    }
    dispatchUpdateWebcontent(
      data,
      {
        productId: productIds?.productId === 0 ? '' : productIds?.productId,
        serviceId: productIds?.serviceId === 0 ? '' : productIds?.serviceId,
        apiId: props?.apiId === 0 ? '' : props?.apiId || '',
      },
      schema,
      isImageUpdate,
      isVideoUpdated,
      basePath,
      isMethodImgUpdated
    );
    isContentChanged.current = false;
  };

  const onHideEditContent = () => {
    setShowEditContent(false);
  };

  const onClickField = (value, field, key, widgetName, isLink = false, hideModal = false) => {
    if (isLink) {
      setEditText(value[0]);
      setEditLink(value[1]);
      setEditField(field[0]);
      setEditFieldLink(field[1]);
    } else {
      setEditText(value);
      setEditField(field);
      setFieldKey(key);
    }
    if (!hideModal) {
      setShowEditContent(true);
    }
    setWidgetName(widgetName);
    setIsLink(isLink);
  };

  const onWebFieldChange = (value, field, key, isLink) => {
    let tempData = { ...data };
    let currentIdx = tempData?.sections?.findIndex(
      (o) => o?.widgetName === widgetName
    );
    let currentWidget = tempData?.sections?.find(
      (o) => o?.widgetName === widgetName
    );
    if (!isLink) {
      currentWidget[field][key] = value;
    } else {
      currentWidget['content1']['linkText'] = editText;
      currentWidget['content1']['link'] = editLink;
    }
    tempData.sections[currentIdx] = currentWidget;
    setData(tempData);
  };

  const onImageUpload = (file) => {
    let tempData = { ...data };
    tempData.sections[0].bannerMedia.imgSrc = file;
    setData(tempData);
    setIsImageUpdated(true);
    isContentChanged.current = true;
  };

  const onMethodImgUpload = (file) => {
    let tempData = { ...data };
    let widgetIndex = tempData?.sections?.findIndex((o) => o?.widgetName === widgetName);
    tempData.sections[widgetIndex][editField][fieldKey] = file;
    setData(tempData);
    setIsMethodImgUpdated(true);
    isContentChanged.current = true;

  };

  const onVideoUpload = (file) => {
    let tempData = { ...data };
    tempData.sections[0].bannerMedia.videoSrc = file;
    setData(tempData);
    setIsVideoUpdated(true);
    isContentChanged.current = true;
  };

  const onClickPrevious = () => {
    setPreviousClick(!previousClick);
  };

  const onFileUpload = (item) => {
    let schema = '';
    if (currentScreen === '/Products') {
      schema = '/Home';
    } else if (
      productName !== '' &&
      serviceName !== '' &&
      `/${serviceName}` === currentScreen
    ) {
      schema = `/${productName}/${serviceName}`;
    } else if (
      productName !== '' &&
      serviceName !== '' &&
      `/${serviceName}` !== currentScreen
    ) {
      schema = `/${productName}/${serviceName}${currentScreen}`;
    } else if (productName !== '' && `/${productName}` === currentScreen) {
      schema = currentScreen;
    } else if (`/${productName}` === currentScreen) {
      schema = currentScreen;
    } else {
      schema = currentScreen;
    }
    dispatchUploadProjectDOc(item, '', true, !previousClick, schema);
  };

  const onWebDocDelete = (key) => {
    let tempData = { ...data };
    tempData.sections[1].body.documents =
      tempData.sections[1].body.documents.filter((o) => o !== key);
    setData(tempData);
  };

  const onTileSave = async (title, description, point, index) => {
    let tempData = { ...data };
    tempData.sections[2].body.tiles[index].name = await title;
    tempData.sections[2].body.tiles[index].body.content = await description;
    tempData.sections[2].body.tiles[index].body.overview = await description;
    tempData.sections[2].body.tiles[index].points = await point;
    setData(tempData);
    isContentChanged.current = true;
  };


  return (
    <CommonContent
      tableKey={props.tableKey}
      dataSource={props.dataSource}
      columns={props.columns}
      editModal={props.editModal}
      hideEditModal={props.hideEditModal}
      modalTitle={props.modalTitle}
      addModal={props.addModal}
      onAddModal={props.onAddModal}
      content={props.content}
      screen={props.screen}
      loading={props.loading}
      onClickPublish={props?.onClickPublish}
      visible={visible}
      onShowModal={onShowModal}
      onHideModal={onHideModal}
      webContent={data}
      onWebFieldChange={onWebFieldChange}
      onHideEditContent={onHideEditContent}
      onSaveWebContent={onSaveWebContent}
      onClickField={onClickField}
      fieldKey={fieldKey}
      showEditContent={showEditContent}
      editText={editText}
      setEditText={setEditText}
      editLink={editLink}
      setEditLink={setEditLink}
      productIds={props?.productIds}
      onPublishClick={onPublishClick}
      onClickPrevious={onClickPrevious}
      isLink={isLink}
      editField={editField}
      editFieldLink={editFieldLink}
      onImageUpload={onImageUpload}
      onVideoUpload={onVideoUpload}
      onMethodImgUpload={onMethodImgUpload}
      previousClick={previousClick}
      onCloseModal={onCloseModal}
      closeEditModal={closeEditModal}
      setVisible={setVisible}
      onCloseModalClick={onCloseModalClick}
      onClickModalClose={onClickModalClose}
      enableDelete={true}
      onFileUpload={onFileUpload}
      onWebDocDelete={onWebDocDelete}
      onTileSave={onTileSave}
      apiId={props?.apiId}
      isAdmin={props?.isAdmin}
    />
  );
};

export default CommonContentContainer;
