import React from 'react';
import { Select } from 'antd';
import './Select.scss';

const SelectContainer = ({
  options = [],
  onChange = () => {},
  onSearch = () => {},
  value = undefined,
  placeholder = 'Select',
  showSearch = true,
  className = '',
  multiple,
  disabled=false,
  loading=false
}) => {
  return (
    <Select
      showSearch={showSearch}
      mode={multiple ? 'multiple' : 'default'}
      placeholder={placeholder}
      optionFilterProp='children'
      className={className}
      onChange={onChange}
      onSearch={showSearch ? onSearch : null}
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      value={value}
      options={options}
      disabled={disabled}
      loading={loading}
    />
  );
};

export default SelectContainer;
