import React from 'react';
import { useCookies } from 'react-cookie';

import Signin from './SigninModal';

const SigninModalContainer = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie] = useCookies();

  const redirectToExternalAD = (adName = '') => {
    // let baseURL = 'https://localhost:3001/login';
    let baseURL = '';
    if (window.location.hostname === 'localhost') {
      baseURL = `https://${window.location.hostname}:${window.location.port}`;
      if (adName === 'salesforce') {
        // Partner User
        const partnerUser = {
          'unique_name': 'testdevportal02@user.com.osmdev',
          'partnerName': 'Account Test 005',
          // 'unique_name': 'geotech.user@contact.com.csodev',
          // 'partnerName': 'geotech',
          // 'unique_name': 'testdevportal@user.com.osmdev',
          // // 'partnerName': 'Account Test 003',
          'isPortalUser': true,
          'name': 'Account Test 005',
          'groups': []
        };

        setCookie('userDetails', partnerUser);
      } else {
        // Admin User
        const adminUser = {
          // 'unique_name': 'xyz@cummins.com',
          'unique_name': 'vn441@cummins.com',
          // 'unique_name': 'va106@cummins.com',
          'name': 'Gowthaman Arumugam',
          'isPortalUser': true,
          'partnerName': '',
          // 'groups': [
          //   'g_Az_Dev_CdoCdpAdmin_Users',
          //   'g_Az_Dev_CdoCdpIntegrationManager_Users',
          //   'g_Az_Dev_CdoCdpViewOnly_Users'
          // ]
          'groups': [
            'dynamic.BU.Corporate',
            'Dynamic.India.Employees',
            'g_Az_Dev_CdoCdpIntegrationManager_Users',
            'US.IND.DBUHQ_G_Users',
            'IndiaEBUVelocity',
            'India Digital Engineering-1-571109450',
            'Dynamic.Indiana',
            'Dynamic_Everyone_PowerPlatform_Cummins_Supplier',
            'Dynamic_Everyone_PowerPlatform',
            'g_Az_Stg_CdoCdpIntegrationManager_Users',
            'Dynamic_ECM_E5Compliance_Users',
            'Dynamic.UsandCanada.Employees',
            'g_EmpType_CWK',
            'CBSIT Cost Billing',
            'Support-Developer-Portal-Dev',
            'g_Az_Dev_CdoCdpAdmin_Users'
          ]
        };

        setCookie('userDetails', adminUser);
      }
    } else {
      baseURL = `https://${window.location.hostname}/login`;

      if (adName === 'salesforce') {
        baseURL += '/sf';
      }
    }
    window.location.replace(baseURL);
  };
  return <Signin
    enableSignIn={props.enableSignIn}
    hideModalPopup={props.hideModalPopup}
    redirectToExternalAD={redirectToExternalAD}
  />;
};

// 'userAuth': {
//   'userId': 'U-00019',
//   'userName': 'Gowthaman',
//   'emailId': 'va106@cummins.com',
//   'partnerId': null,
//   'partnerName': 'Zonar',
//   'roles': [
//     'Admin',
//     'Integeration_Manager'
//   ]
// },

export default SigninModalContainer;