import React from 'react';
import { Switch } from 'antd';
import './Switch.scss';

const SwitchComponent = ({
  checked = false,
  className='',
  onClickEvent = () => {},
  onChangeEvent = () =>{},
  style = {},
  disabled = false,
  ...props
}) => {
  
  return (
    <Switch checked={checked} className={className} onChange={onChangeEvent} onClick={onClickEvent} style={style} disabled={disabled}>
      {props.children}
    </Switch>
  );

};

export default SwitchComponent;

