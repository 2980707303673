import React from 'react';
import { Input } from 'antd';
const { TextArea } = Input;

const TextAreaComponent = ({
  value = '',
  className = '',
  onClickEvent = () => {},
  onChangeEvent = () => {},
  style = {},
  disabled = false,
  rows = '4',
  // autoSize = {}
  statusMsg= () => {},
  placeholder=''
}) => {
  return (
    <>
      <TextArea
        rows={rows}
        value={value}
        className={className}
        onClick={onClickEvent}
        onChange={onChangeEvent}
        style={style}
        disabled={disabled}
        placeholder={placeholder}
        // autoSize={autoSize}
      />
      {statusMsg && <p className='mandatoryMsg'>{statusMsg}</p>}
    </>
  );
};

export default TextAreaComponent;
