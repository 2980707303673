import React from 'react';
import PartnerContact from './partnerContact';

const PartnerContactContainer = (props) => {
  return (
    <PartnerContact
      viewData={props?.viewData}
      contactOption={props?.contactOption}
      selectedContact={props?.selectedContact}
      contactList={props?.contactList}
      onContactSelectChange={props?.onContactSelectChange}
    />
  );
};

export default PartnerContactContainer;
