import React from 'react';
import { Select } from 'antd';
import './Dropdowns.scss';
const { Option } = Select;

const Dropdowns = ({
  allowClear=false,
  className='',
  value = undefined,
  style = {},
  optionFilterProp='children',
  showSearch = true,
  filterOption = (input, option) => option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0,
  filterSort = (optionA, optionB) => optionA?.children?.toLowerCase()?.localeCompare(optionB?.children?.toLowerCase()),
  options = [],
  disabledOptions = [],
  onChangeEvent = () => { },
  loading = false,
  disabled = false,
  showArrow=true,
  mode='',
  status='',
  statusMsg='',
  placeholder='',
  optionKeyName='',
  optionTextName=''
}) => {
  const cleanOption = options && options !== '' ? options : [];
  return (
    <>
      <Select
        allowClear={allowClear}
        disabled = { disabled }
        className = {className}
        placeholder={placeholder}
        optionFilterProp = {optionFilterProp}
        style = {style}
        value = {value}
        filterOption={filterOption}
        filterSort={filterSort}
        onChange={(item, option) => onChangeEvent(item, option)}
        loading={loading}
        showSearch={showSearch}
        showArrow={showArrow}
        mode={mode}
        status={status}
        getPopupContainer={trigger => trigger.parentNode}
      >
        {
          cleanOption.map((item, index) => {
            const key = item?.[optionKeyName] ?? index;
            return (
              <Option
                disabled={disabledOptions.includes(item[optionTextName])}
                // value={key}
                value={item[optionTextName]}
                key={key}
              >
                {item[optionTextName]}
              </Option>
            );
          })
        }
      </Select>
      {
        statusMsg && <p  className='mandatoryMsg'>{statusMsg }</p>
      }
    </>
  );
};

export default Dropdowns;