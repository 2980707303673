import React from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';
import './DatePicker.scss';

const dateFormat = 'DD/MM/YYYY';

const DatePickerComponent = ( { 
  allowClear = false,
  disabled=false,
  value = moment(new Date(), dateFormat),
  format= dateFormat,
  onChangeEvent = ()=> {},
  onOk = ()=> {},
  showNow  = false,
  placeholder = '',
  disabledDate,
  status='',
  statusMsg=''
} ) => {
  
  return (
    <>
      <DatePicker 
        allowClear={allowClear} 
        disabled={disabled} 
        format={format}
        value={value} 
        showNow = { showNow }
        onOk = { onOk }
        placeholder = { placeholder }
        status = {status}
        onChange= { onChangeEvent } 
        disabledDate={disabledDate}
        getPopupContainer={trigger => trigger.parentNode}
      />
      {
        statusMsg && <p  className='mandatoryMsg'>{statusMsg }</p>
      }
    </>
  );
};

export default DatePickerComponent;