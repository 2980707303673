import React from 'react';
import { BreadcrumbComponent } from '../../../../../../../../CommonComponents';

const Title = (props) => {
  const { viewData } = props;

  return (
    <>
      {props.breadcrumbList?.length ? (
        <BreadcrumbComponent
          enableClick={true}
          onClickEvent={props.breadcrumbOnClick}
          valueList={props.breadcrumbList}
        />
      ) : null}
      <div className='clearfix headerblock'>
        <div className='float-left leftText'>
          {viewData?.productName ? <span className='heading'> {`${viewData?.productName || ''}: ${viewData?.serviceName || ''}`}</span>: null}
        </div>
      </div>
    </>
  );
};

export default Title;
