import React from 'react';
import AcceptModal from './AcceptModal';

const AcceptModalContainer = (props) => {
  const { showModal, hideModalPopup, onFormChange, formData, onClickAccept, error } = props;
  return (
    <AcceptModal
      showModal={showModal}
      hideModalPopup={hideModalPopup}
      onFormChange={onFormChange}
      formData={formData}
      onClickAccept={onClickAccept}
      error={error}
    />
  );
};

export default AcceptModalContainer;
