import React from 'react';
import { ColComponent, Card } from '../../../../../../../../CommonComponents';

const Contact = (props) => {
  return (
    <ColComponent xs={24} md={12} lg={8} xl={8} className='tileblock'>
      <Card title={props?.item?.title || ''}>
        <div className='supportBlock'>
          <p className='labelText'>Name</p>
          <p>{props?.item?.name || '-'}</p>
        </div>
        <div className='supportBlock'>
          <p className='labelText'>Email</p>
          <p>{props?.item?.email || '-'}</p>
        </div>
        <div className='supportBlock'>
          <p className='labelText'>Phone</p>
          <p>{props?.item?.phone || '-'}</p>
        </div>
      </Card>
    </ColComponent>
  );
};

export default Contact;
