import React from 'react';
import MainContent from './MainContent';
import { Spinner, Swagger } from '../../CommonComponents';
import ApiProject from './PageLayout/AdminPortal/IntegrationManagement/ApiProject';
import ProductsServiceAPI from './PageLayout/AdminPortal/ContentManagement/ProductsServiceAPI';
import RolesAndPermission from './PageLayout/RolesAndPermission';
import Partners from './PageLayout/AdminPortal/IntegrationManagement/Partners';
import FAQ from './PageLayout/FAQ';
import Approval from './PageLayout/AdminPortal/IntegrationManagement/Approvals';
import RegisteredExternalResponse from './PageLayout/AdminPortal/IntegrationManagement/RegisteredExternalResponse';

const BodyLayout = (props) => {
  const layoutName = props.layoutName ?? '';
  const localBodyLoad = props.localBodyLoad === 'true';
  const pathname = props.pathname.replaceAll('%20', ' ') ?? '';
  const { swaggerJson, jsonLoading } = props;

  const loadBodyLayout = () => {
    if (localBodyLoad === true) {
      if (pathname === '/Products Services & API') {
        return <ProductsServiceAPI isAdmin={props?.isAdmin} />;
      } else if (pathname === '/Projects') {
        return <ApiProject isAdmin={props?.isAdmin} />;
      } else if (pathname === '/Approvals') {
        return <Approval isAdmin={props?.isAdmin} />;
      } else if (pathname === '/External Response') {
        return <RegisteredExternalResponse isAdmin={props?.isAdmin} />;
      } else if (pathname === '/Roles and Permissions') {
        return <RolesAndPermission />;
      } else if (pathname === '/swagger') {
        return (
          <Swagger
            swaggerJson={swaggerJson}
            loading={jsonLoading}
            supportedHTTPMethods={['get', 'head', 'post']}
            onNavClick={props?.onNavClick}
          />
        );
      } else if (pathname === '/Partners') {
        return <Partners />;
      } else if (pathname === '/FAQ') {
        return <FAQ />;
      }
      return '';
    } else if (layoutName === 'MainContent') {
      return (
        <MainContent
          bodyContent={props.bodyContent}
          swaggerJson={swaggerJson}
          loading={jsonLoading}
          onNavClick={props?.onNavClick}
          isAdmin={props?.isAdmin}
        />
      );
    }
  };

  return (
    <div className='spinnerBlock'>
      <Spinner spinning={props.contentFetchingState} tip='Loading'>
        {loadBodyLayout()}
      </Spinner>
    </div>
  );
};

export default BodyLayout;
