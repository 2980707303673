import React from 'react';

import {
  Banner,
  StageDescriptor,
  TileDescriptor,
  AvailableMethods,
  CardDescriptor,
  FooterContent,
  CodeBlock,
  Details,
  HeaderContent,
  Document,
  Collapsible,
  // BadgeDescriptor,
  MethodTabs,
  Description,
  Unsubscribed,
  APIDescriptor
} from '../../CommonWidget';

import './MainContent.scss';


const MainContent = (props) => {
  const constructMainPage = () => {
    const bodyContent = props.bodyContent ?? [];
    return bodyContent.length ? bodyContent?.map((section, index) => {
      const requireAuth = section?.action?.authentication === true ?? false;
      if (requireAuth === false || props.userDetails) {
        const widgetName = section?.widgetName ?? '';
        if (widgetName === 'Banner') {
          return (
            <Banner
              content={section}
              key={widgetName}
              mobile={props.mobile}
              onNavClick={props?.onNavClick}
              editable={props.editable}
              onHideEditContent={props?.onHideEditContent}
              onSaveWebContent={props?.onSaveWebContent}
              onClickField={(value, field, key) => props?.onClickField(value, field, key, widgetName, false, false)}
              fieldKey={props?.fieldKey}
              editField={props?.editField}
              showEditContent={props?.showEditContent}
              editText={props?.editText}
              setEditText={props?.setEditText}
              onImageUpload={props?.onImageUpload}
              onVideoUpload={props?.onVideoUpload}
              showLink={props?.showLink}
              editLink={props?.editLink}
              setEditLink={props?.setEditLink}
              isLink={props?.isLink}
              editFieldLink={props?.editFieldLink}
            />
          );
        } else if (widgetName === 'StageDescriptor') {
          return <StageDescriptor content={section} key={widgetName} />;
        } else if (widgetName === 'TileDescriptor') {
          return (
            <TileDescriptor
              content={section}
              key={index}
              onNavClick={props?.onNavClick}
              editable={props.editable}
              onClickField={(value, field, key) => props?.onClickField(value, field, key, widgetName)}
              fieldKey={props?.fieldKey}
              editField={props?.editField}
              onHideEditContent={props?.onHideEditContent}
              onSaveWebContent={props?.onSaveWebContent}
              showEditContent={props?.showEditContent}
              editText={props?.editText}
              setEditText={props?.setEditText}
              showLink={props?.showLink}
              editLink={props?.editLink}
              setEditLink={props?.setEditLink}
              isLink={props?.isLink}
              editFieldLink={props?.editFieldLink}
              enableDocDelete={props?.enableDelete}
              onFileUpload={props?.onFileUpload}
              onWebDocDelete={props?.onWebDocDelete}
              onTileSave={props?.onTileSave}
              apiId={props?.apiId}
              isAdmin={props?.isAdmin}
            />
          );
        } else if (widgetName === 'CardDescriptors') {
          return <CardDescriptor content={section} key={index} editable={props.editable} />;
        } else if (widgetName === 'FooterContent') {
          return (
            <FooterContent
              content={section}
              key={index}
              editable={props.editable}
              onClickField={(value, field, key, isLink) => props?.onClickField(value, field, key, widgetName, isLink)}
              onSaveWebContent={props?.onSaveWebContent}
              editField={props?.editField}
              fieldKey={props?.fieldKey}
              showEditContent={props?.showEditContent}
              editText={props?.editText}
              setEditText={props?.setEditText}
              showLink={props?.showLink}
              editLink={props?.editLink}
              setEditLink={props?.setEditLink}
              isLink={props.isLink}
              onHideEditContent={props?.onHideEditContent}
            />
          );
        } else if (widgetName === 'AvailableMethods') {
          return (
            <AvailableMethods
              editable={props?.editable}
              onClickField={(value, field, key, isLink, hideModal) => props?.onClickField(value, field, key, widgetName, isLink, hideModal)}
              onSaveWebContent={props?.onSaveWebContent}
              onHideEditContent={props?.onHideEditContent}
              content={section}
              key={index}
              editField={props?.editField}
              fieldKey={props?.fieldKey}
              showEditContent={props?.showEditContent}
              editText={props?.editText}
              setEditText={props?.setEditText}
              showLink={props?.showLink}
              editLink={props?.editLink}
              setEditLink={props?.setEditLink}
              isLink={props.isLink}
              onImageUpload={props?.onMethodImgUpload}
            />
          );
        } else if (widgetName === 'CodeBlock') {
          return (
            <CodeBlock
              content={section}
              key={index}
              swaggerJson={props?.swaggerJson}
              loading={props?.loading}
            />
          );
        } else if (widgetName === 'Details') {
          return <Details content={section} key={index} />;
        } else if (widgetName === 'HeaderContent') {
          return <HeaderContent content={section} key={index} />;
        } /* else if (widgetName === 'BadgeDescriptor') {
          return <BadgeDescriptor content={section} key={index} editable={props?.editable} />;
        } */ else if (widgetName === 'Description') {
          return <Description content={section} key={index} />;
        } else if (widgetName === 'Unsubscribed') {
          return <Unsubscribed content={section} key={index} />;
        } else if (widgetName === 'documents') {
          return (
            <div className='docs' key={index}>
              <p className='heading'>{section.heading}</p>
              <div className='clearfix'>
                {section?.docs?.map((item, index) => (
                  <Document item={item} key={index} />
                ))}
              </div>
            </div>
          );
        } else if (widgetName === 'Accordion') {
          return <Collapsible content={section} key={index} />;
        } else if (widgetName === 'MethodTabs') {
          return (
            <MethodTabs
              content={section}
              key={index}
              swaggerJson={props?.swaggerJson}
              loading={props?.loading}
              editable={props?.editable}
            />
          );
        }
        else if (widgetName === 'APIDescriptor') {
          return <APIDescriptor content={section} key={index} editable={props?.editable} />;
        }
        // return (
        //   <div key={index}>Invalid Widget Data in Main Content Layout</div>
        // );
      }
    }) : null;
  };
  return <div>{constructMainPage()}</div>;
};

export default MainContent;
