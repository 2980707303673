import React, { useEffect, useState } from 'react';

import ErrorBoundary from '../../../ErrorBoundary';
import BaseLayout from './BaseLayout';

const BaseLayoutContainer = (props) => {
  const [showMenu, setShowMenu] = useState(false);
  const [mobile, setMobile] = useState(false);
  const editable = false;

  const resizeFun = () => {
    if (window.innerWidth > 768) {
      setShowMenu(true);
      setMobile(false);
    } else {
      setShowMenu(false);
      setMobile(true);
    }
  };

  useEffect(() => {
    resizeFun();
    window.addEventListener('resize', () => {
      resizeFun();
    });
    return window.removeEventListener('resize', resizeFun);
  }, []);

  return (
    <ErrorBoundary>
      <BaseLayout
        setShowMenu={setShowMenu}
        showMenu={showMenu}
        navItems={props.navItems}
        onNavClick={props?.onNavClick}
        mobile={mobile}
        isAdmin={props?.isAdmin}
        editable={editable}
      />
    </ErrorBoundary>
  );
};

export default BaseLayoutContainer;
