import React, { useState } from 'react';
import AvailableMethods from './AvailableMethods';
const AvailableMethodsContainer = (props) => {
  const [methodSelected, setMethodSelected] = useState('method1');
  const methodClick = (method) => {
    setMethodSelected(method);
  };
  return (
    <AvailableMethods
      editable={props?.editable}
      onClickField={props?.onClickField}
      onSaveWebContent={props?.onSaveWebContent}
      onHideEditContent={props?.onHideEditContent}
      methodSelected={methodSelected}
      methodClick={methodClick}
      content={props.content}
      editField={props?.editField}
      onImageUpload={props?.onImageUpload}
    />
  );
};

export default AvailableMethodsContainer;
