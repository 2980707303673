import React from 'react';
import {Modal,OutlineButton,Button,YMLEditor} from '../../../../../../../CommonComponents';
import './ApproveModal.scss';
const ApproveModal = (props) => {
  let updatedArray = Array.isArray(props.updatedValue) ? props.updatedValue : [props.updatedValue];
  let joinedString = updatedArray.join(', ');
  return(
    <Modal
      visible={props.showApproveModal}
      hideModal={props.hideApproveModal}
      closeModalPopup={props.hideApproveModal}
      title={'Approve'}
      width={620}
      className={'approveModal'}
      footer={
        <div className='viewBlock'>
          <OutlineButton
            className='normalButton viewButton'
            onClick={props.hideApproveModal}
          >
          Cancel
          </OutlineButton>
          <Button
            className={'normalButton viewButton'}
            onClick={props.onClickModalApprove}
          >
           Approve
          </Button>
        </div>
      }
      centered
    >
      <div className='ApproveView'>
        <div className='ApprovalWrapper'>
          <div className='labelView'>
            <label className='label'>
              Partner Name
            </label>
          </div>
          <div className='splitView'>
            :
          </div>
          <div className='valueView'>
            <div>{props.rowValue?.partnerName}</div>
          </div>
        </div>
        <div className='ApprovalWrapper'>
          <div className='labelView'>
            <label className='label'>
              Product Name
            </label>
          </div>
          <div className='splitView'>
            :
          </div>
          <div className='valueView'>
            <div>{props.rowValue?.productName}</div>
          </div>
        </div>
        <div className='ApprovalWrapper'>
          <div className='labelView'>
            <label className='label'>
            Service Name
            </label>
          </div>
          <div className='splitView'>
            :
          </div>
          <div className='valueView'>
            <div>{props.rowValue?.serviceName}</div>
          </div>
        </div>
        <div className='ApprovalWrapper'>
          <div className='labelView'>
            <label className='label'>
            Updated Fields
            </label>
          </div>
          <div className='splitView'>
            :
          </div>
          <div className='valueView'>
            <div>{joinedString}</div>
          </div>
        </div>
        <div className="formControl">
          <label className='label'>
            Payload
          </label>
          <YMLEditor file={props.rowValue?.payload} />
        </div>
      </div>
    </Modal>
  );
};

export default ApproveModal;