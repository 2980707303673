const GET_LOGIN_USER_DETAILS = 'app/GET_LOGIN_USER_DETAILS';
const SET_LOGIN_USER_DETAILS = 'app/SET_LOGIN_USER_DETAILS';

const GET_NAV_ROUTES = 'app/GET_NAV_ROUTES';
const SET_NAV_ROUTES = 'app/SET_NAV_ROUTES';
const IS_FETCHING_NAV_ROUTES = 'app/IS_FETCHING_NAV_ROUTES';

const GET_NAV_CONTENT = 'app/GET_NAV_CONTENT';
const SET_NAV_CONTENT = 'app/SET_NAV_CONTENT';
const SET_LOCAL_BODY_CONTENT_FLG = 'app/SET_LOCAL_BODY_CONTENT_FLG';
const IS_FETCHING_NAV_CONTENT = 'app/IS_FETCHING_NAV_CONTENT';

const GET_CODE_FILE_CONTENT_JSON = 'app/GET_CODE_FILE_CONTENT_JSON';
const SET_CODE_FILE_CONTENT_JSON = 'app/SET_CODE_FILE_CONTENT_JSON';
const CODE_FILE_CONTENT_LOADING = 'app/CODE_FILE_CONTENT_LOADING';

const REDIRECT_EXTERNAL_LOGIN = 'app/REDIRECT_EXTERNAL_LOGIN';

const GET_GLOBAL_FAQ_CONTENT = 'app/GET_GLOBAL_FAQ_CONTENT';
const SET_GLOBAL_FAQ_CONTENT = 'app/SET_GLOBAL_FAQ_CONTENT';
const SET_GLOBAL_FAQ_CONTENT_LOADING = 'app/SET_GLOBAL_FAQ_CONTENT_LOADING';

const SET_PROD_SERVICE = 'app/SET_PROD_SERVICE';

const GET_SWAGGER_JSON = 'app/GET_SWAGGER_JSON';
const SET_SWAGGER_JSON = 'app/SET_SWAGGER_JSON';
const IS_FETCHING_SWAGGER_JSON = 'app/IS_FETCHING_SWAGGER_JSON';

const SUBMIT_SUBSCRIPTION_REQUEST = 'app/SUBMIT_SUBSCRIPTION_REQUEST';
const SUBMIT_SUBSCRIPTION_LOADING = 'app/SUBMIT_SUBSCRIPTION_LOADING';

const GET_USER_AUTH = 'app/GET_USER_AUTH';
const SET_USER_AUTH = 'app/SET_USER_AUTH';

const SET_CURRENT_ROUTE = 'app/SET_CURRENT_ROUTE';

const SET_ROUTE_KEY_MAP = 'app/SET_ROUTE_KEY_MAP';

const SUBMIT_MANAGE_SUBSCRIPTION = 'app/SUBMIT_MANAGE_SUBSCRIPTION';

const GET_API_DESC_JSON = 'app/GET_API_DESC_JSON';
const SET_API_DESC_JSON = 'app/SET_API_DESC_JSON';
const SET_DELETE_FILE = 'app/SET_DELETE_FILE';
const RESET_DOC_DATA = 'app/RESET_DOC_DATA';
const UPDATE_DOC_DATA = 'app/UPDATE_DOC_DATA';
const SET_SIMULATE = 'app/SET_SIMULATE';
const SEND_CONTACT_MESSAGE = 'app/SEND_CONTACT_MESSAGE';
const ADD_EDIT_EXT_RESPONSE = 'app/ADD_EDIT_EXT_RESPONSE';
const SET_EXTERNAL_RESPONSE = 'app/SET_EXTERNAL_RESPONSE';
const GET_EXTERNAL_RESPONSE = 'app/GET_EXTERNAL_RESPONSE';

export {
  GET_LOGIN_USER_DETAILS,
  SET_LOGIN_USER_DETAILS,
  GET_NAV_ROUTES,
  SET_NAV_ROUTES,
  IS_FETCHING_NAV_ROUTES,
  GET_NAV_CONTENT,
  SET_NAV_CONTENT,
  SET_LOCAL_BODY_CONTENT_FLG,
  IS_FETCHING_NAV_CONTENT,
  GET_CODE_FILE_CONTENT_JSON,
  SET_CODE_FILE_CONTENT_JSON,
  CODE_FILE_CONTENT_LOADING,
  REDIRECT_EXTERNAL_LOGIN,
  GET_GLOBAL_FAQ_CONTENT,
  SET_GLOBAL_FAQ_CONTENT,
  SET_GLOBAL_FAQ_CONTENT_LOADING,
  GET_SWAGGER_JSON,
  SET_SWAGGER_JSON,
  IS_FETCHING_SWAGGER_JSON,
  SUBMIT_SUBSCRIPTION_REQUEST,
  SUBMIT_SUBSCRIPTION_LOADING,
  SET_PROD_SERVICE,
  GET_USER_AUTH,
  SET_USER_AUTH,
  SET_CURRENT_ROUTE,
  SET_ROUTE_KEY_MAP,
  SUBMIT_MANAGE_SUBSCRIPTION,
  GET_API_DESC_JSON,
  SET_API_DESC_JSON,
  SET_DELETE_FILE,
  RESET_DOC_DATA,
  UPDATE_DOC_DATA,
  SET_SIMULATE,
  SEND_CONTACT_MESSAGE,
  ADD_EDIT_EXT_RESPONSE,
  SET_EXTERNAL_RESPONSE,
  GET_EXTERNAL_RESPONSE
};
