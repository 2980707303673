import React, { useState, useEffect, useMemo } from 'react';
import Method from './Method';

const MethodContainer = (props) => {
  const methodContent = props.methodContent ?? {};
  const overview = methodContent?.overview ?? '';
  const img = useMemo(() => {
    return methodContent?.img ?? '';
  }, [methodContent]);
  const docs = methodContent?.docs ?? [];
  const title = methodContent?.title ?? '';
  const specifications = methodContent?.specifications ?? {};
  const imageName = typeof img === 'string' ? img?.split('/')?.splice(-1)[0] : '';
  // const imageName = img?.split('/')?.splice(-1)[0];
  const [previewImg, setPreviewImage] = useState('https://d1lgeay9zr70r7.cloudfront.net/HomeScreen/noImage.png');
  const [editText, setEditText] = useState('');
  // const [editField,setEditField] = useState('');
  const { editField, onSaveWebContent, showEditContent } = props;
  // const [showEditContent,setShowEditContent] = useState(false);
  const [editRowLength, setEditRowLength] = useState(1);
  const [badge, setBadge] = useState('Text');
  const [showEditBanner, setShowEditBanner] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [fileName, setFileName] = useState('');
  useEffect(() => {
    if (img) {
      setPreviewImage(img);
    }
  }, [img]);

  useEffect(() => {
    if (imageName) {
      setFileName(imageName);
    }
  }, [imageName]);

  const onMouseOver = (field) => {
    if (field === 'overview') {
      setBadge('Paragraph');
    }
    else {
      setBadge('Text');
    }
  };


  const onClickField1 = (value, field, key) => {
    props?.onClickField(value, field, key, false);
    // setEditField(field);
    // setShowEditContent(true);

  };

  // const onHideEditContent =() => {
  //   setShowEditContent(false);

  // };

  const onEditBannerImage = () => {
    setShowEditBanner(true);
  };

  const onHideEditBannerImage = () => {
    setShowEditBanner(false);
  };

  const onEditData = (data) => {
    editText(data);
  };

  const uploadProps = {
    name: 'file',
    headers: {
      authorization: 'authorization-text',
    },
    maxCount: 1,
    beforeUpload: () => {
      /* update state here */
      return false;
    },
    onChange(info) {
      props?.onClickField(info?.file, 'method1', 'img', false, true);
      setFileList(info?.file);
      setPreviewImage(info?.file);
      setFileName(info?.fileList[0]?.name);
    },
  };


  const loadImage = async () => {
    if (fileList?.length) {
      let name = fileList[0].name;
      setFileName(name);
      let src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(fileList[fileList?.length - 1]?.originFileObj);
        reader.onload = () => {
          resolve(reader?.result);
        };
      });
      setPreviewImage(src);
    }
  };
  useEffect(() => {
    loadImage();
  }, [fileList]);

  useEffect(() => {
    if ((title || overview) && editField) {
      if (editField === 'title') {
        setEditText(title);
        setEditRowLength(1);
      }
      else {
        setEditText(overview);
        setEditRowLength(4);
      }
    }
  }, [editField, title, overview]);

  const onSaveBannerImg = () => {
    props?.onImageUpload(previewImg);
    onHideEditBannerImage();
  };

  return <Method
    methodContent={props.methodContent}
    editable={props?.editable}
    showEditContent={showEditContent}
    editRowLength={editRowLength}
    onClickField={onClickField1}
    onEditBannerImage={onEditBannerImage}
    onHideEditBannerImage={onHideEditBannerImage}
    onEditData={onEditData}
    badge={badge}
    onMouseOver={onMouseOver}
    img={img}
    docs={docs}
    specifications={specifications}
    uploadProps={uploadProps}
    showEditBanner={showEditBanner}
    title={title}
    overview={overview}
    editText={editText}
    fileName={fileName}
    previewImg={previewImg}
    onSaveWebContent={onSaveWebContent}
    onHideEditContent={props?.onHideEditContent}
    editField={props?.editField}
    onSaveBannerImg={onSaveBannerImg}
  />;
};

export default MethodContainer;