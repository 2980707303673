import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Subscription from './Subscription';
import {
  operations as appOps,
  selectors as appSelector,
} from '../../../../../state/features/app';

const SubscriptionContainer = (props) => {
  const [productId, setProductId] = useState(null);
  const { userDetails } = useSelector((state) => ({
    userDetails: appSelector.userDetails(state),
  }));
  // appSelector
  const { unique_name, partnerName } = userDetails;
  const dispatch = useDispatch();
  const submitManageSubscription =
    appOps.dispatchSubmitManageSubscription(dispatch);
  const { services } = props;
  const [manageServices, setManageServices] = useState([]);

  useEffect(() => {
    setManageServices(services);
    setProductId(props?.localProdId);
  }, [services]);

  const onSubscribeCheckChange = (e, item) => {
    e?.preventDefault();

    const tempService = JSON.parse(JSON.stringify(manageServices?.length > 0 ? manageServices : services)); //[...services];

    let newServiceArr = [];
    tempService.forEach(element => {
      if(element?.id === item?.id) {
        element.subscriptionStatus = element?.subscriptionStatus === 'UnSubscribed'
          ? 'Subscribed'
          : 'UnSubscribed';
      }

      newServiceArr.push(element);
    });
    setManageServices(newServiceArr);
    

    // let tempService = [...services];
    // let idx = services.findIndex((o) => o?.id === item?.id);
    // tempService[idx].subscriptionStatus =
    //   tempService[idx].subscriptionStatus === 'UnSubscribed'
    //     ? 'Subscribed'
    //     : 'UnSubscribed';
    // setManageServices(tempService);
  };

  const onClickSave = () => {
    let tempSubscribe = [];
    let tempUnsubscribe = [];
    manageServices?.map((serv) => {
      if (serv?.subscriptionStatus === 'UnSubscribed') {
        tempUnsubscribe.push(serv?.id);
      } else if (serv?.subscriptionStatus === 'Subscribed') {
        tempSubscribe.push(serv?.id);
      }
    });

    const body = {
      partnerName: partnerName || '',
      productId: productId,
      userId: unique_name,
      serviceIds: tempSubscribe,
      actionType: 'P',
      unSubscribedIds: tempUnsubscribe,
    };

    submitManageSubscription(body);
    onClickCancel();
  };

  const onClickCancel = () => {
    setManageServices(services);
    props.hideModalPopup();
  };

  return (
    <Subscription
      availableServices={manageServices}
      hideModalPopup={onClickCancel}
      onSubscribeCheckChange={onSubscribeCheckChange}
      onClickSave={onClickSave}
    />
  );
};

export default SubscriptionContainer;
