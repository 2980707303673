import React, { useEffect, useState } from 'react';
import Approvals from './Approvals';
import Zonar from '../../../../../../../assets/images/zonar.png';
import { OutlineButton } from '../../../../../../CommonComponents';
import { useDispatch, useSelector } from 'react-redux';
import { operations as adminOps, selectors as adminSelector } from '../../../../../../../state/features/admin';

const ApprovalContainer = () => {
  const heading = 'Approvals';
  const subHeading = 'Integrations Management';
  const [showPayloadModal, setShowPayloadModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [payloadValue, setPayloadValue] = useState('');
  const [updatedValue, setUpdatedValue] = useState('');
  const [rowValue, setRowValue] = useState('');
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState('');
  const [rejectErr, setRejectErr] = useState();
  const [historyPayload, setHistoryPayload] = useState(false);
  const [historyPayloadValue, setHistoryPayloadValue] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const dispatch = useDispatch();
  // const dispatchOnclickReject = adminOps.dispatchClickReject(dispatch);
  const dispatchOnClickApproval = adminOps.dispatchOnClickApproval(dispatch);
  const dispatchGetApprovalsList = adminOps.dispatchGetApprovalsList(dispatch);
  const dispatchGetHistoryList = adminOps.dispatchGetHistoryList(dispatch);

  const {
    loading,
    approvals,
    history
  } = useSelector((state) => ({
    loading: adminSelector.loading(state),
    approvals: adminSelector.approvals(state),
    history: adminSelector.history(state),
  }));

  useEffect(() => {
    if (activeTab === 0) {
      dispatchGetApprovalsList();
    } else {
      dispatchGetHistoryList();
    }
  }, [activeTab]);

  const hidePayloadModal = () => {
    setShowPayloadModal(false);
  };
  const hideApproveModal = () => {
    setShowApproveModal(false);
  };
  const hideRejectModal = () => {
    setShowRejectModal(false);
    setRejectErr();
    setTextAreaValue('');
  };

  const onTabChange = (value) => {
    setActiveTab(value);
  };

  const showHistoryPayloadModal = () => {
    setHistoryPayload(true);
  };
  const hideHistorypayloadModal = () => {
    setHistoryPayload(false);
  };

  const onClickApprove = (row) => {
    setRowValue(row);
    setShowApproveModal(true);
    setUpdatedValue(row.updatedFields);
  };
  const onClickReject = (row) => {
    setRowValue(row);
    setShowRejectModal(true);
  };
  const handleTextAreaChange = (e) => {
    setRejectErr(e.target.value === '' ? 'Please enter rejection reason.' : undefined);
    setTextAreaValue(e.target.value);
  };
  const onClickModalApprove = () => {
    const body = {
      partnerName: rowValue?.partnerName,
      productName: rowValue?.productName,
      serviceName: rowValue?.serviceName,
      action: 'Approve'

    };
    dispatchOnClickApproval(body);
    hideApproveModal();
  };

  const onClickModalReject = () => {
    if (textAreaValue === '') {
      setRejectErr('Please enter rejection reason.');
    } else {
      const body = {
        partnerName: rowValue?.partnerName,
        productName: rowValue?.productName,
        serviceName: rowValue?.serviceName,
        action: 'Reject',
        rejectedReason: textAreaValue
      };
      dispatchOnClickApproval(body);
      hideRejectModal();
    }
  };

  const columns = [
    {
      title: 'Partner Name',
      dataIndex: 'partnerName',
      key: 'partnerName',
      render: (row) => {
        return (
          <div>
            <img src={Zonar} className='logoIcon' /> {row}
          </div>
        );
      },
      width: 200,
    },
    {
      title: 'Product Name',
      dataIndex: 'productName',
      key: 'productName',
      width: 180,
      align: 'left',
    },
    {
      title: 'Service Name',
      dataIndex: 'serviceName',
      key: 'serviceName',
      width: 180,
      align: 'left',
    },
    {
      title: 'Payload',
      dataIndex: 'payload',
      key: 'payload',
      align: 'left',
      width: 350,
      render(text) {
        let val = JSON.stringify(text);
        return {
          children: <div className='payload'>{`${val.slice(0, 40)}...`}</div>
        };
      },
      onCell: (row) => {
        return {
          onClick: () => {
            setShowPayloadModal(true);
            // setPayloadValue(JSON.stringify(row.payload, undefined, 4));
            setPayloadValue(row.payload);
            setUpdatedValue(row.updatedFields);
          },
        };
      },
    },
    {
      title: 'Actions',
      dataIndex: 'Action',
      key: 'Action',
      width: 250,
      align: 'center',
      render: (text, record) => {
        return (
          <>
            <OutlineButton
              className='normalButton viewButton approveBtn'
              onClick={() => {
                onClickApprove(record);
              }}
            >
              Approve
            </OutlineButton>
            <OutlineButton
              className='normalButton viewButton'
              onClick={() => onClickReject(record)}
            >
              Reject
            </OutlineButton>
          </>
        );
      },
    },
  ];

  const historycolumns = [
    {
      title: 'Partner Name',
      dataIndex: 'partnerName',
      key: 'partnerName',
      render: (row) => {
        return (
          <div>
            <img src={Zonar} className='logoIcon' /> {row}
          </div>
        );
      },
      width: 180,
    },
    {
      title: 'Product Name',
      dataIndex: 'productName',
      key: 'productName',
      width: 180,
      align: 'left',
    },
    {
      title: 'Service Name',
      dataIndex: 'serviceName',
      key: 'serviceName',
      width: 180,
      align: 'left',
    },
    {
      title: 'Payload',
      dataIndex: 'payload',
      key: 'payload',
      align: 'left',
      width: 300,
      render(text) {
        let val = text;
        return {
          children: <div className='payload'>{`${val.slice(0, 40)}${val?.length > 40 ? '...' : ''}`}</div>
        };
      },
      onCell: (row) => {
        return {
          onClick: () => {
            setHistoryPayload(true);
            // setHistoryPayloadValue(JSON.stringify(row.payload, undefined, 4));
            setHistoryPayloadValue(JSON.parse(row?.payload));
          },
        };
      },
    },
    {
      title: 'Action Method',
      dataIndex: 'action',
      key: 'action',
      width: 200,
      align: 'center',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 250,
      align: 'center',
    },
    {
      title: 'Created Date',
      dataIndex: 'createdDate',
      key: 'createdDate',
      width: 250,
      align: 'center',
    },
    {
      title: 'Created User',
      dataIndex: 'createdUser',
      key: 'createdUser',
      width: 250,
      align: 'center',
    },
    {
      title: 'Comments',
      dataIndex: 'comments',
      key: 'comments',
      width: 250,
      align: 'center',
    }
  ];

  return (
    <Approvals
      heading={heading}
      subHeading={subHeading}
      // activePagination={activePagination}
      columns={columns}
      showPayloadModal={showPayloadModal}
      hidePayloadModal={hidePayloadModal}
      payloadValue={payloadValue}
      showApproveModal={showApproveModal}
      hideApproveModal={hideApproveModal}
      rowValue={rowValue}
      showRejectModal={showRejectModal}
      hideRejectModal={hideRejectModal}
      rejectErr={rejectErr}
      onClickReject={onClickReject}
      textAreaValue={textAreaValue}
      handleTextAreaChange={handleTextAreaChange}
      onClickModalApprove={onClickModalApprove}
      onClickModalReject={onClickModalReject}
      loading={loading}
      data={approvals}
      historycolumns={historycolumns}
      history={history}
      // historyaActivePagination={historyaActivePagination}
      hideHistorypayloadModal={hideHistorypayloadModal}
      showHistoryPayloadModal={showHistoryPayloadModal}
      historyPayload={historyPayload}
      historyPayloadValue={historyPayloadValue}
      onTabChange={onTabChange}
      activeTab={activeTab}
      updatedValue={updatedValue}
    />
  );
};

export default ApprovalContainer;