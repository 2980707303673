import React from 'react';
import { Radio, Space } from 'antd';
import './RadioGroup.scss';


const RadioComponent = ({
  className='',
  value = '',
  style = {},
  options = [],
  onChangeEvent = () => { },
  optionHtml=false,
  direction='vertical'
}) => {

  return (
    <Radio.Group
      className = {className}
      style = {style}
      value = {value}
      onChange={(item, option) => onChangeEvent(item, option)}
    >
      {
        optionHtml? 
          <Space direction={direction}>
            {
              optionHtml.map((item) => {
                return (
                  <Radio key={item.text} value={item.text}>
                    {item.content}
                  </Radio>
                );
              })
            }
          </Space>
          :
          options.map((item) => {
            return (
              <Radio key={item} value={item}>
                {item}
              </Radio>
            );
          })
      }
    </Radio.Group>
  );

};

export default RadioComponent;

