import React,{useState} from 'react';
import ApproveModal from '../ApproveModal';
import RejectModal from '../RejectModal';
import { Modal, Table } from '../../../../../../../CommonComponents';
import YMLEditor from '../../../../../../../CommonComponents/JSONCodeReader';
const IntegrationManagementTab = (props) => {
  const {columns, showPayloadModal, hidePayloadModal, payloadValue, rejectErr, loading, data, showApproveModal,updatedValue } = props;
  const [activePagination, setActivePagination] = useState({
    current: 1,
    pageSize: 5,
    pageSizeOptions: [5, 10, 20, 50, 100],
    showSizeChanger: true,
  });
  let updatedArray = Array.isArray(props.updatedValue) ? props.updatedValue : [props.updatedValue];
  let joinedString = updatedArray.join(', ');
  return (
    <div className='rolesAndPermissions p5'>
      <div>
        <Table
          dataSource={data}
          columns={columns}
          scroll={{ x: 'max-content' }}
          pagination={activePagination}
          onChangeEvent={(pageData) => setActivePagination(pageData)}
          rowKey='partner_id'
          loading={loading}
          rowSelection={false}
        />
      </div>
      <Modal
        visible={showPayloadModal}
        hideModal={hidePayloadModal}
        closeModalPopup={hidePayloadModal}
        title={'Payload'}
        width={620}
        className={'Payload'}
        footer={false}
        centered
      >
        <div>
          <div className='ApprovalWrapper'>
            <div className='labelView'>
              <label className='label'>
              Updated Fields
              </label>
            </div>
            <div className='splitView'>
              :
            </div>
            <div className='valueView'>
              <div>{joinedString}</div>
            </div>
          </div>
          <div className='breakView'></div>
          <div className="formControl">
            <YMLEditor file={payloadValue}/>
          </div>
        </div>
      </Modal>
      <ApproveModal
        showApproveModal={showApproveModal}
        hideApproveModal={props.hideApproveModal}
        rowValue={props.rowValue}
        onClickModalApprove={props.onClickModalApprove}
        updatedValue={updatedValue}
      />
      <RejectModal
        showRejectModal={props.showRejectModal}
        hideRejectModal={props.hideRejectModal}
        onClickReject={props.onClickReject}
        textAreaValue={props.textAreaValue}
        handleTextAreaChange={props.handleTextAreaChange}
        onClickModalReject={props.onClickModalReject}
        rejectErr={rejectErr}
      />
    </div>
  );
};

export default IntegrationManagementTab;