import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import BaseLayout from './BaseLayout';
import BodyLayout from '../Body/BodyLayout';
// import LoginRedirect from './login';
import './Home.scss';

const Home = (props) => {
  const constructNavRoutes = () => {
    const navRouteList = props.navRouteList?.length > 0 ? [...props.navRouteList, '/swagger'] : [];
    let navRouterList = [];
    navRouteList.forEach((route, index) => {
      if (index === 0) {
        navRouterList.push(
          <Route path='/' key={'/'} element={<Navigate to={route} />} />
        );
      }
      navRouterList.push(
        <Route
          path={route}
          element={<BodyLayout routeDetails={route} isAdmin={props.isAdmin} onNavClick={props?.onNavClick} />}
          key={route}
        />
      );
    });
    return navRouterList;
  };

  return (
    <Routes>
      <Route element={<BaseLayout isAdmin={props?.isAdmin} navItems={props.navItems} onNavClick={props?.onNavClick} />}>
        {constructNavRoutes()}
      </Route>
      <Route
        path='*'
        element={
          <div className='page404'>
            <h2>You don't have access for this Application.</h2>
            <h5>Please contact administrator.</h5>
          </div>
        }
      />
    </Routes>
  );
};

export default Home;
