import React from 'react';
import { Row } from 'antd';


const RowComponent = ({ className ='',style={},justify={},gutter=[24,24],...props}) => {

  return(
    <Row gutter={gutter}  className={className} style={style} justify={justify}>
      {props.children}
    </Row>
  );
};

export default RowComponent;