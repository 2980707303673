import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  selectors as appSelector,
  operations as appOps,
} from '../../../../state/features/app';
import { operations as adminOps } from '../../../../state/features/admin';

import TileDescriptor from './TileDescriptor';

const TileDescriptorContainer = (props) => {
  const { userDetails, prodServiceMap, resetDoc } = useSelector(
    (state) => ({
      userDetails: appSelector.userDetails(state),
      prodServiceMap: appSelector.prodServiceMap(state),
      resetDoc: appSelector.resetDoc(state),
    })
  );

  // onWebFieldChange
  const emailId = userDetails?.unique_name || '';
  const partnerName = userDetails?.partnerName || '';
  const [showModal, setShowModal] = useState(false);
  const [enableSignIn, setEnableSignIn] = useState(false);
  const location = useLocation();
  const prodService = prodServiceMap;
  const [services, setServices] = useState([]);
  const [productId, setProductId] = useState(null);
  const userName = userDetails?.name ?? '';
  const content = props.content ?? {};
  const heading = content?.heading ?? {};
  const body = content?.body ?? {};

  const mainPage = content?.mainPage ?? false;
  const { editField, editText, setEditText, setEditLink } = props;
  const [editRowLength, setEditRowLength] = useState(1);
  const [badge, setBadge] = useState('Text');
  const [docs, setDocs] = useState(body?.documents || []);
  const [basePath, setBasePath] = useState(body?.basePath || '');
  const [manageService, setManageService] = useState([]);
  const dispatch = useDispatch();
  const dispatchSubscriptionSubmit = appOps.dispatchSubmitSubRequest(dispatch);
  const dispatchDeleteProjectDoc = adminOps.dispatchDeleteProjectDoc(dispatch);
  const onMouseOver = (field) => {
    if (field === 'content') {
      setBadge('Paragraph');
    } else {
      setBadge('Text');
    }
  };

  useEffect(() => {
    if (
      (Object.keys(heading)?.length || Object.keys(body)?.length) &&
      editField
    ) {
      if (editField == 'heading') {
        setEditRowLength(1);
      } else if (editField === 'subHeading') {
        setEditRowLength(1);
      } else {
        setEditRowLength(4);
      }
    }
  }, [editField, heading, body]);

  const pathname = location?.pathname
    .replaceAll('%20', ' ')
    .replace('?portal=false', '')
    .replace('/', '');


  const currentRoute = useMemo(() => {
    return location?.search.replaceAll('%20', ' ').split('nav=')[1]?.split('/');
  }, [location]);

  const getServices = (prodService, sectionName, productName, serviceName) => {
    let allChildren = [];
    let manageServices = [];
    const productId = prodServiceMap[sectionName]?.find(
      (o) => o?.title === productName
    )?.id;
    setProductId(productId);
    if (serviceName === '') {
      const availableChildren = prodServiceMap['Available Products']?.find(
        (o) => o?.title === productName
      );
      const subscribedChildren = prodServiceMap['My Subscriptions']?.find(
        (o) => o?.title === productName
      );
      if (sectionName === 'Available Products') {
        manageServices = availableChildren;
      } else {
        manageServices = subscribedChildren;
      }
      setManageService(manageServices?.children || []);


      allChildren = [
        ...(availableChildren?.children || []),
        ...(subscribedChildren?.children || []),
      ];
    } else {
      const apiList = prodServiceMap[sectionName]
        .find((o) => o?.title === productName)
        ?.children?.find((o) => o?.title.trim() === serviceName)?.children;
      allChildren = apiList || [];
      setManageService(apiList || []);
    }
    return allChildren;
  };

  useEffect(() => {
    const newServices = getServices(
      prodService,
      currentRoute?.[0] || '',
      currentRoute?.[1] || '',
      currentRoute?.[2] || ''
    );
    setServices(newServices);
    let tempData = [];
    if (currentRoute?.length === 3) {
      prodService[currentRoute[0]].some((ser) => {
        if (ser?.title === currentRoute[1]) {
          if (ser?.children?.length > 0) {
            ser?.children?.some((child) => {
              if (child?.documents?.length > 0) {
                setBasePath(child?.basePath);
                tempData = child?.documents || [];
              }
            });
          }
          if (tempData?.length > 0) {
            setDocs((prevState) => [...prevState, ...tempData]);
          }
        }
      });
    }
    return () => {
      setDocs([]);
    };
  }, [currentRoute, prodService, resetDoc]);

  const navigateToSubService = useCallback(
    (e, status, link, tile) => {
      e.stopPropagation();
      const servicePath = link
        .replaceAll('%20', ' ')
        .replace('?portal=false', '')
        .replace('/', '');
      const path = servicePath.split('/');
      const product = prodServiceMap[currentRoute[0]].find(
        (o) => o?.title === path[0]
      );

      const productId = product?.id;
      const service = product?.children.find((o) => o?.title === path[1]);
      const serviceId = service?.id || tile?.id;
      const body = {
        actionType: status,
        // partnerId: partnerId,
        partnerName: partnerName,
        product_id: productId,
        emailId: emailId,
        services: [serviceId],
        productName: product?.title,
        serviceName: service?.title,
      };
      if (serviceId && productId !== 0) {
        dispatchSubscriptionSubmit(body);
      } else {
        console.error('Service Id not found in submit subscribe');
      }
    },
    [currentRoute]
  );

  // useEffect(() => {
  //   if (body?.basePath) {
  //     setDocs(body?.documents);
  //   }
  // }, [body?.documents]);

  const onShowSignin = () => {
    setEnableSignIn(true);
  };

  const onHideSignin = () => {
    setEnableSignIn(false);
  };

  const hideModalPopup = () => {
    const newServices = getServices(
      prodService,
      currentRoute?.[0] || '',
      currentRoute?.[1] || '',
      currentRoute?.[2] || ''
    );
    setServices(newServices);
    setShowModal(false);
  };

  const showPopup = () => {
    setShowModal(true);
  };

  const onEditData = (e) => {
    setEditText(e?.target?.value);
  };

  const onDocClick = (key) => {
    window.open(`${basePath}${key}`, '_blank');
  };

  const onDeleteFile = (key) => {
    if (body?.basePath) {
      props?.onWebDocDelete(key);
      // setDocs((prevState) => prevState?.filter((o) => o !== key));
    } else {
      dispatchDeleteProjectDoc(key, true, currentRoute, resetDoc);
    }
  };

  return (
    <TileDescriptor
      content={content}
      showModal={showModal}
      showPopup={showPopup}
      hideModalPopup={hideModalPopup}
      userDetails={userDetails}
      enableSignIn={enableSignIn}
      onShowSignin={onShowSignin}
      onHideSignin={onHideSignin}
      navigateToSubService={navigateToSubService}
      pathname={pathname}
      onNavClick={props?.onNavClick}
      onClickManage={showPopup}
      services={services}
      localProdId={productId}
      currentLocation={currentRoute}
      userName={userName}
      heading={heading}
      body={body}
      mainPage={mainPage}
      editable={props.editable}
      editText={editText}
      showEditContent={props?.showEditContent}
      editRowLength={editRowLength}
      badge={badge}
      onMouseOver={onMouseOver}
      onClickField={props?.onClickField}
      onHideEditContent={props?.onHideEditContent}
      onSaveWebContent={props?.onSaveWebContent}
      docs={docs}
      onEditData={onEditData}
      onWebFieldChange={props?.onWebFieldChange}
      showLink={props?.showLink}
      editLink={props?.editLink}
      setEditLink={setEditLink}
      isLink={props?.isLink}
      editFieldLink={props?.editFieldLink}
      onDocClick={onDocClick}
      onFileUpload={props?.onFileUpload}
      onDeleteFile={onDeleteFile}
      onDownloadFile={props?.onDownloadFile}
      enableDocDelete={props?.enableDocDelete}
      onTileSave={props?.onTileSave}
      apiId={props?.apiId}
      isAdmin={props?.isAdmin}
      prodService={prodService}
      manageService={manageService}
    />
  );
};

export default TileDescriptorContainer;
