import React from 'react';
import Account_box from '../../../../../../../../../assets/images/account_box.png';
import mail from '../../../../../../../../../assets/images/mail.png';
import { Select } from '../../../../../../../../CommonComponents';

const IntegrationManager = (props) => {
  const { options, onManagerChange, managersValue, selectedManagers } = props;

  return (
    <div className='cardList'>
      <p className='spec-heading'>Integration Manager</p>
      <Select
        options={options || []}
        onChange={onManagerChange}
        value={managersValue}
        // multiple={true}
        placeholder={'Integration Managers'}
        className='contactInfo'
      />
      {selectedManagers?.length > 0 && (
        <>
          {selectedManagers?.map((contact, idx) => (
            <div key={idx}>
              <div>
                <img src={Account_box} alt='userAccount' className='account' />
                {contact?.name}
              </div>
              <div className='mailList'>
                <img src={mail} alt='userAccount' className='mail' />
                {contact?.email}
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default IntegrationManager;
