import React from 'react';
import APIChildren from './APIChildren';

const ApiChildrenContainer = (props) => {

  return (
    <APIChildren
      onSelectChange={props?.onSelectChange}
      onEndpointChange={props?.onEndpointChange}
      selectedRowKeys={props?.selectedRowKeys}
      editAPIDataSource={props?.editAPIDataSource}
      editAPIColumn={props?.editAPIColumn}
      loading={props?.loading}
      visible={props.visible}
      hideModal={props.hideModal}
      closeModalPopup={props.closeModalPopup}
      title={props.title}
      width={props?.width}
      footer={props?.footer}
      onShowAddManually={props?.onShowAddManually}
      hideEditAPIModal={props?.hideEditAPIModal}
      apiEndpointOption={props?.apiEndpointOption}
      liveScreenName={props.liveScreenName}
      statusOption={props?.statusOption}
      formData={props?.formData}
      onFormChange={props?.onFormChange}
      onClickCancel={props?.onClickCancel}
      onClickSave={props?.onClickSave}
      statusMsg={props?.statusMsg}
      currentRow={props?.currentRow}
    />
  );
};

export default ApiChildrenContainer;
