import {
  GET_PRODUCT_SERVICE_API,
  SET_PRODUCT_SERVICE_API,
  SET_PRODUCT_SERVICE_API_FLG,
  SET_LOADER_STATUS,
  GET_CREATE_PRODUCT,
  SET_CREATE_PRODUCT,
  GET_CREATE_SERVICE,
  SET_CREATE_SERVICE,
  GET_API_ENDPOINTS,
  SET_API_ENDPOINTS,
  SHOW_ADMIN_POPUP,
  GET_CREATE_API,
  SET_CREATE_API,
  GET_API_PUBLISH,
  SET_API_PUBLISH,
  GET_API_PROJECT,
  SET_API_PROJECT,
  GET_PROJECT_PARTNERS,
  SET_PROJECT_PARTNERS,
  GET_PROJECT_PARTNERS_SUBSCRIPTIONS,
  GET_ROLES_USERS,
  SET_ROLES_USERS,
  SET_PARTNER_ACTIVE_SUBSCRIPTION,
  SET_PARTNER_INACTIVE_SUBSCRIPTION,
  SET_PARTNERS_DATA,
  UPLOAD_PROJECT_DOC,
  SET_PUBLISH_CHANGES,
  GET_PROJECT_VIEW,
  SET_PROJECT_VIEW,
  SET_PROJECT_MILESTONE,
  SET_PROJECT_UPLOAD,
  GET_DELETE_FILE,
  SET_DELETED_FILE,
  UPDATE_EDIT_WEBPAGE,
  SAVE_PERMISSIONS,
  TOGGLE_ALERT_POPUP,
  PROJECT_DATA_CHANGED,
  ADD_EDIT_PARTNER,
  ON_CLICK_REJECT,
  ON_CLICK_APPROVAL,
  GET_APPROVALS_LIST,
  SET_APPROVALS_LIST,
  GET_HISTORY,
  SET_HISTORY,
  GET_REg_EXT_RESP,
  SET_REg_EXT_RESP,
  UPDATE_REG_EXT_RESP,
  GET_CERTIFIED_PRODUCTS,
  SET_CERTIFIED_PRODUCTS,
  GET_PRODUCTS,
  SET_PRODUCTS,
  SUBSCRIBE_PRODUCTS,
  SET_DROPDOWN_LOADER,
} from './types';

const getProductServiceAPI = (entityDetails) => ({
  type: GET_PRODUCT_SERVICE_API,
  entityDetails,
});
const setLoaderStatus = (status) => ({
  type: SET_LOADER_STATUS,
  status,
});

const showAdminPopup = (data) => ({
  type: SHOW_ADMIN_POPUP,
  data,
});

const setProductServiceAPI = (productServiceAPIResponse) => ({
  type: SET_PRODUCT_SERVICE_API,
  productServiceAPIResponse,
});

const setProductServiceAPIFlag = (productServiceAPIStatus) => ({
  type: SET_PRODUCT_SERVICE_API_FLG,
  productServiceAPIStatus,
});

const getCreateProduct = (body, isEditMode, file) => ({
  type: GET_CREATE_PRODUCT,
  body,
  isEditMode,
  file,
});

const setCreateProduct = (data, isEditMode) => ({
  type: SET_CREATE_PRODUCT,
  data,
  isEditMode,
});
const getCreateService = (body, isEditMode) => ({
  type: GET_CREATE_SERVICE,
  body,
  isEditMode,
});

const setCreateService = (data, prodId, isEditMode) => ({
  type: SET_CREATE_SERVICE,
  data,
  prodId,
  isEditMode,
});

const getCreateApi = (body, productId, isEditMode) => ({
  type: GET_CREATE_API,
  body,
  productId,
  isEditMode,
});

const setCreateApi = (data, productId, serviceId, isEditMode) => ({
  type: SET_CREATE_API,
  data,
  productId,
  serviceId,
  isEditMode,
});

const getApiEndPoints = () => ({
  type: GET_API_ENDPOINTS,
});

const setApiEndPoints = (list) => ({
  type: SET_API_ENDPOINTS,
  list,
});

const getApiPublish = (body) => ({
  type: GET_API_PUBLISH,
  body,
});
const setApiPublish = (serviceId, apiId, isPublished, apiDescription) => ({
  type: SET_API_PUBLISH,
  serviceId,
  apiId,
  isPublished,
  apiDescription,
});

const getApiProject = (partnerId, filter, status, email, partnerName) => ({
  type: GET_API_PROJECT,
  partnerId,
  filter,
  status,
  email,
  partnerName
});

const setApiProject = (data) => ({
  type: SET_API_PROJECT,
  data,
});

const getProjectPartners = (searchValue, dropdown) => ({
  type: GET_PROJECT_PARTNERS,
  value: searchValue,
  dropdown
});

const setProjectPartners = (data) => ({
  type: SET_PROJECT_PARTNERS,
  data,
});

const getProjectPartnersSubscriptions = (partnerId) => ({
  type: GET_PROJECT_PARTNERS_SUBSCRIPTIONS,
  partnerId,
});

const getRoleUsers = () => ({
  type: GET_ROLES_USERS,
});

const setRolesUsers = (data) => ({
  type: SET_ROLES_USERS,
  data,
});

const setPartnerActiveSubscription = (data) => ({
  type: SET_PARTNER_ACTIVE_SUBSCRIPTION,
  data,
});

const setPartnerInActiveSubscription = (data) => ({
  type: SET_PARTNER_INACTIVE_SUBSCRIPTION,
  data,
});

const setPartnersData = (data) => ({
  type: SET_PARTNERS_DATA,
  data,
});

const uploadProjectDoc = (data, id, fromWebContent, isCurrent, schema) => ({
  type: UPLOAD_PROJECT_DOC,
  data,
  id,
  fromWebContent,
  isCurrent,
  schema,
});

const deleteProjectDoc = (key, fromContent, route, resetDoc) => ({
  type: GET_DELETE_FILE,
  key,
  fromContent,
  route,
  resetDoc,
});

const setDeletedFile = (key) => ({
  type: SET_DELETED_FILE,
  key,
});

const setPublishChanges = (data, productIds) => ({
  type: SET_PUBLISH_CHANGES,
  data,
  productIds,
});

const getProjectView = (id) => ({
  type: GET_PROJECT_VIEW,
  id,
});
const setProjectView = (data) => ({
  type: SET_PROJECT_VIEW,
  data,
});
const setProjectMileStone = (data) => ({
  type: SET_PROJECT_MILESTONE,
  data,
});
const setProjectUpload = (data) => ({
  type: SET_PROJECT_UPLOAD,
  data,
});

const updateWebcontent = (
  data,
  ids,
  schema,
  isImageUpdated,
  isVideoUpdated,
  basePath,
  isMethodImgUpdated
) => {
  return {
    type: UPDATE_EDIT_WEBPAGE,
    data,
    ids,
    schema,
    isImageUpdated,
    isVideoUpdated,
    basePath,
    isMethodImgUpdated,
  };
};

const savePermissions = (data) => ({
  type: SAVE_PERMISSIONS,
  data,
});

const toggleAlertPopup = (status, fromNav, callback) => {
  return {
    type: TOGGLE_ALERT_POPUP,
    status,
    fromNav,
    callback
  };
};

const isProjectDataChanged = (status) => ({
  type: PROJECT_DATA_CHANGED,
  status,
});

const addEditPartner = (body) => ({
  type: ADD_EDIT_PARTNER,
  body
});
const onClickReject = (comment) => ({
  type: ON_CLICK_REJECT,
  comment
});
const onClickApproval = (body) => ({
  type: ON_CLICK_APPROVAL,
  body
});

const getApprovalsList = () => ({
  type: GET_APPROVALS_LIST,
});
const getHistoryList = () => ({
  type: GET_HISTORY,
});
const getRegHistoryResp = (partnerName) => ({
  type: GET_REg_EXT_RESP,
  partnerName
});
const setRegHistoryResp = (data) => ({
  type: SET_REg_EXT_RESP,
  data
});
const updateRegExtResp = (body) => ({
  type: UPDATE_REG_EXT_RESP,
  body
});
const setApprovalsList = (data) => ({
  type: SET_APPROVALS_LIST,
  data
});
const setHistoryList = (data) => ({
  type: SET_HISTORY,
  data
});
const getCertifiedProducts = () => ({
  type: GET_CERTIFIED_PRODUCTS,
});
const setCertifiedProducts = (data) => ({
  type: SET_CERTIFIED_PRODUCTS,
  data
});
const getProducts = (id, dropdown) => ({
  type: GET_PRODUCTS,
  id, 
  dropdown
});
const setProducts = (data) => ({
  type: SET_PRODUCTS,
  data
});
const subscribeProducts = (body) => ({
  type: SUBSCRIBE_PRODUCTS,
  body
});

const setDropdownStatus = (status) => ({
  type: SET_DROPDOWN_LOADER,
  status
});

export {
  getProductServiceAPI,
  setProductServiceAPI,
  setProductServiceAPIFlag,
  setLoaderStatus,
  getCreateProduct,
  setCreateProduct,
  getCreateService,
  setCreateService,
  getApiEndPoints,
  setApiEndPoints,
  showAdminPopup,
  getCreateApi,
  setCreateApi,
  getApiPublish,
  setApiPublish,
  getApiProject,
  setApiProject,
  getProjectPartners,
  setProjectPartners,
  getProjectPartnersSubscriptions,
  getRoleUsers,
  setRolesUsers,
  setPartnerActiveSubscription,
  setPartnerInActiveSubscription,
  setPartnersData,
  uploadProjectDoc,
  setPublishChanges,
  getProjectView,
  setProjectView,
  setProjectMileStone,
  setProjectUpload,
  deleteProjectDoc,
  setDeletedFile,
  updateWebcontent,
  savePermissions,
  toggleAlertPopup,
  isProjectDataChanged,
  addEditPartner,
  onClickReject,
  onClickApproval,
  getApprovalsList,
  setApprovalsList,
  getHistoryList,
  setHistoryList,
  getRegHistoryResp,
  setRegHistoryResp,
  updateRegExtResp,
  getCertifiedProducts,
  setCertifiedProducts,
  getProducts,
  setProducts,
  subscribeProducts,
  setDropdownStatus
};
