import React from 'react';
import './Description.scss';

const Description = (props) => {
  const content = props?.content ?? {};
  const body = content?.body ?? {};
  const heading = body?.heading ?? '';
  const description = body?.description ?? '';
  const list = body?.list ?? [];

  return <div className='Description'>
    <p className='heading'>{heading}</p>
    <p className='description'>{description}</p>
    <div className='listView'>
      <ol className='borderedList'>
        {
          list.map((item,index) => {
            return <li key={index}>{item}</li>;
          })
        }
      </ol>
    </div>
  </div>;
};

export default Description;