import {notification} from 'antd';
import './Notification.scss';
export const Notification = ({type,message,description,placement,onClick,closeIcon=true}) => {
  const key = `open${Date.now()}`;
  notification.open({
    key:key,
    type,
    message,
    description,
    placement,
    onClick,
    duration: 2,
    className: type === 'success' ? 'successText' : 'errorText',
    closeIcon:closeIcon,
  });
};
