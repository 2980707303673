import React from 'react';
import {
  BreadcrumbComponent,
  Button
} from '../../../../../../../../CommonComponents';

const Title = (props) => {
  return (
    <>
      {props.breadcrumbList?.length ? (
        <BreadcrumbComponent
          enableClick={true}
          onClickEvent={props.breadcrumbOnClick}
          valueList={props.breadcrumbList}
        />
      ) : null}
      <div className='clearfix headerblock'>
        <div className='float-left leftText'>
          <span className='titleText'>{props.partnerName || 'Partner Name'}</span>
        </div>
        <div className="float-right">
          <Button className='normalButton viewButton' onClick={props.onAddSubscription}>
            Add Subscription
          </Button>
        </div>
      </div>
    </>
  );
};

export default Title;

