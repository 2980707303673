import React from 'react';
import { Collapse } from 'antd';
import './Accordion.scss';
const { Panel } = Collapse;
const Accordion = ({
  AccordionItems = [],
  defaultActiveKey = ['0'],
  activeKey = ['0'],
  onChange = () => {},
  ghost = true,
}) => {
  return (
    <div className='customAccordion'>
      <Collapse
        ghost={ghost}
        defaultActiveKey={defaultActiveKey}
        activeKey={activeKey}
        onChange={onChange}
        accordion
      >
        {AccordionItems.map((AccordionItems, index) => {
          return (
            <Panel
              header={AccordionItems.headerText}
              key={index}
              collapsible={AccordionItems?.disabled && 'disabled'}
            >
              {AccordionItems.content}
            </Panel>
          );
        })}
      </Collapse>
    </div>
  );
};

export default Accordion;
