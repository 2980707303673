import React from 'react';
import OtherData from './OtherData';

const OtherDataContainer = (props) => {
  const registrationStatusOption = [
    {
      text: 'Registered',
      label: 'Registered',
    },
    {
      text: 'Not Registered',
      label: 'Not Registered',
    },
  ];

  return (
    <OtherData
      registrationStatusOption={registrationStatusOption}
      otherData={props?.otherData}
      onChangeOtherData={props?.onChangeOtherData}
      isPartner={props?.isPartner}
    />
  );
};

export default OtherDataContainer;
