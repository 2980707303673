import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';

import { Spinner } from '../../components/CommonComponents';

import {
  operations as appOps,
  selectors as appSelector,
} from '../../state/features/app';
import {
  operations as adminOps,
} from '../../state/features/admin';

import ErrorBoundary from '../../ErrorBoundary';
import Home from './Home';
import { useLocation, useNavigate } from 'react-router-dom';

const HomeContainer = () => {
  const [cookies] = useCookies();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const dispatchGetNavRoutes = appOps.dispatchGetNavRoutes(dispatch);
  const dispatchSetNavRouteContent =
    appOps.dispatchSetNavRouteContent(dispatch);
  const dispatchSetUserDetails = appOps.dispatchSetUserDetails(dispatch);
  const dispatchSetProServiceMap = appOps.dispatchSetProServiceMap(dispatch);
  const dispatchSetCurrentRoute = appOps.dispatchSetCurrentRoute(dispatch);
  const dispatchRouteKeyMap = appOps.dispatchRouteKeyMap(dispatch);
  const dispatchToggleAlertPopup = adminOps.dispatchToggleAlertPopup(dispatch);

  const location = useLocation();
  const { navRoutes, navRouteFetchingState, userDetails } = useSelector(
    (state) => ({
      navRoutes: appSelector.navRoutes(state),
      navRouteFetchingState: appSelector.navRouteFetchingState(state),
      userDetails: appSelector.userDetails(state),
    })
  );

  const [navItems, setNavItems] = useState([]);
  const [navRouteList, setNavRouteList] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [enableHome, setEnableHome] = useState(false);
  const [userRoles, setUserRoles] = useState({});
  

  useEffect(() => {
    let userDetails = cookies?.userDetails ?? '';
    let userId = userDetails?.unique_name ?? '';

    // userId = 'abhishekv@softura.com';
    // userId = 'mariaj@softura.com';
    // userId = 'dhilipane@softura.com';

    if (navRoutes === '' && navRouteFetchingState === false) {
      dispatchGetNavRoutes(userId);
    }

    if (userDetails !== '') {
      dispatchSetUserDetails(userDetails);
    }
  }, [cookies?.userDetails]);


  const getUserRoles = () => {
    let isStg = window.location.href.includes('-stg');
    let isSandbox = window.location.href.includes('-sandbox');
    if (isStg) {
      setUserRoles({
        admin: 'g_Az_Stg_CdoCdpAdmin_Users',
        integrationManager: 'g_Az_Stg_CdoCdpIntegrationManager_Users',
        user: 'g_Az_Stg_CdoCdpViewOnly_Users'
      });
    } else if (isSandbox) {
      setUserRoles({
        admin: 'g_Az_Sandbox_CdoCdpAdmin_Users',
        integrationManager: 'g_Az_Sandbox_CdoCdpIntegrationManager_Users',
        user: 'g_Az_Sandbox_CdoCdpViewOnly_Users'
      });
    }else {
      setUserRoles({
        admin: 'g_Az_Dev_CdoCdpAdmin_Users',
        integrationManager: 'g_Az_Dev_CdoCdpIntegrationManager_Users',
        user: 'g_Az_Dev_CdoCdpViewOnly_Users'
      });
    }
  };

  useEffect(() => {
    getUserRoles();
  }, []);

  // Praveen's comment for checking purpose => Don't Remove
  // useEffect(() => {
  //   console.log('userRoles, isadmin, isDev, cookies?.userDetails?.groups ==>', userRoles?.admin, isAdmin, window.location.href, window.location.href.includes('-dev'), cookies?.userDetails?.groups);
  // },[userRoles?.admin, isAdmin]);

  useEffect(() => {
    if (userDetails?.groups?.some(group => group === userRoles?.admin)) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [userDetails?.groups, userRoles]);

  const constructRouteList = (routeArr = [], prefixRoute = '', heading) => {
    let routeList = [];
    let routeKeyMap = {};
    (routeArr?.nav || routeArr)?.forEach((item) => {
      let itemName = item?.title || item?.name || '';
      let keyName = item?.title || item?.name || '';
      if (prefixRoute !== '') {
        itemName = `${prefixRoute}/${itemName}`;
        keyName = `${heading}/${prefixRoute}/${keyName}`;
      }else{
        keyName = `${routeArr?.heading}/${keyName}`;
      }
      const routeItemName = `/${itemName}`;
      routeList.push(routeItemName);
      routeKeyMap[keyName] = item?.key;
      if (item?.children?.length > 0) {
        let childrenRouteList = constructRouteList(item.children, itemName, heading);
        routeList = [...routeList, ...childrenRouteList.routeList];
        routeKeyMap = { ...routeKeyMap, ...childrenRouteList.routeKeyMap };
      }
    });
    return {
      routeList,
      routeKeyMap,
    };
  };

  const formatData = (
    data,
    keyName,
    emptyChildren = false,
    isAvailableProduct = false
  ) => {
    return data?.map((nav) => ({
      ...nav,
      tree: [keyName, nav?.title],
      children: emptyChildren
        ? []
        : nav?.children?.map((serv) => ({
          ...serv,
          tree: [keyName, nav?.title, serv?.title],
          children:
            isAvailableProduct && keyName === 'Available Products'
              ? []
              : serv?.children?.map((api) => ({
                ...api,
                tree: [keyName, nav?.title, serv?.title, api?.title],
              })),
        })),
    }));
  };

  const constructProdList = (navData) => {
    let tempObj = {};
    navData.some((data) => {
      let keyName = data?.heading;
      if (keyName === 'Available Products') {
        tempObj[keyName] = formatData(data?.nav, 'Available Products');
      } else if (keyName === 'My Subscriptions') {
        tempObj[keyName] = formatData(data?.nav, 'My Subscriptions');
      }
    });
    dispatchSetProServiceMap(tempObj);
  };

 
  const onNavClick = (tree, routeName, localBodyLoad = false, projectDataChanged = false) => {
    let link = [...tree];

    if (projectDataChanged) {
      dispatchToggleAlertPopup(true, true, () => navigate({ pathname: routeName, search: `?portal=${localBodyLoad}&nav=${link?.length > 0 ? link.join('/') : ''}`}));
    } else {
      dispatchToggleAlertPopup(false, false, null);
      if (routeName !== '') {
        navigate({
          pathname: routeName,
          search: `?portal=${localBodyLoad}&nav=${
            link?.length > 0 ? link.join('/') : ''
          }`,
        });
      }
    }
  };

  useEffect(() => {
    let currentRoute = [];
    if (location?.search) {
      currentRoute = location?.search
        ?.replaceAll('%20', ' ')
        ?.split('nav=')[1]
        ?.split('/');
    }

    dispatchSetCurrentRoute(currentRoute);
  }, [location?.search]);

  useEffect(() => {
    if (navRoutes) {
      let userDetails = cookies?.userDetails ?? '';
      let userId = userDetails?.unique_name ?? '';
      const navItems = navRoutes?.navBar?.sections ?? [];
      let navRoutesList = [];
      let navData = [];
      let routeKeyMap = {};
      constructProdList(navItems);
      let pathName = '';
      let search = '';
      navItems.forEach((navArr, index) => {
        if (index === 0) {
          const heading = navArr?.heading ?? '';
          const subRoute = navArr?.nav?.[0]?.title ?? '';
          const renderFrom = navArr?.nav?.[0]?.renderFrom === 'portal';
          pathName = `/${subRoute}`;
          search = `?portal=${renderFrom}&nav=${heading}/${subRoute}`;
        }
        const newNavRoutes = constructRouteList(navArr, '', navArr?.heading);
        navRoutesList = [...navRoutesList, ...newNavRoutes.routeList];
        routeKeyMap = { ...routeKeyMap, ...newNavRoutes.routeKeyMap };
      });

      let tempArr = [...navItems];
      if (!userId) {
        navData = tempArr.map((arr) => ({
          ...arr,
          nav: formatData(arr?.nav, arr.heading, true),
        }));
      } else {
        navData = tempArr.map((arr) => ({
          ...arr,
          nav: formatData(arr?.nav, arr.heading, false, true),
        }));
      }
      navRoutes?.defaultBody &&
        dispatchSetNavRouteContent(navRoutes.defaultBody);
      dispatchRouteKeyMap(routeKeyMap);
      setNavRouteList(navRoutesList);
      setNavItems(navData);
      setEnableHome(true);

      navigate({
        pathname: pathName,
        search: search,
      });
    }
  }, [navRoutes, isAdmin, cookies.userDetails]);

  return enableHome === true ? (
    <ErrorBoundary>
      <Home
        navItems={navItems}
        navRouteList={navRouteList}
        onNavClick={onNavClick}
        isAdmin={isAdmin}
      />
    </ErrorBoundary>
  ) : (
    <div className='spinnerBlock'>
      <Spinner spinning={true} tip='Loading' />
    </div>
  );
};

export default HomeContainer;
