
import React from 'react';
import { Input } from 'antd';
import './TextField.scss';

const TextField = ({
  className = '',
  placeholder = '',
  onChange = () => { /** This is intentional Function */ },
  onBlur = () => { /** This is intentional Function */ },
  onFocus = () => { /** This is intentional Function */ },
  label = '',
  value = '',
  status = '',
  statusMsg = '',
  disabled = false,
  bordered = true,
  row,
  type = 'text'
}) => {

  const { TextArea } = Input;
  return (
    <>
      {
        !row ?
          <Input
            placeholder={placeholder}
            className={className}
            onChange={onChange}
            label={label}
            value={value}
            status={status}
            onBlur={onBlur}
            onFocus={onFocus}
            disabled={disabled}
            bordered={bordered}
            type={type}
          /> :
          <TextArea
            placeholder={placeholder}
            className={className}
            onChange={onChange}
            label={label}
            value={value}
            status={status}
            onBlur={onBlur}
            onFocus={onFocus}
            disabled={disabled}
            bordered={bordered}
            autoSize={{ minRows: row, maxRows: 6 }}
          />
      }
      {
        statusMsg && <p className='mandatoryMsg'>{statusMsg}</p>
      }
    </>
  );
};

export default TextField;