import React from 'react';
import ProductCard from './ProductCard';
import {Button,Modal,OutlineButton,Text} from '../../../CommonComponents';
import Edit from '../../../../assets/images/edit.png';

import './Products.scss';

const CardDescriptor = (props) => {
  // const userName = props.userDetails ?? '';

  const { availableServices } = props;
  const editUI = (field) => {
    return props.editable ? <div className='editableTools'><div className='titleBadge'>{props.badge}</div>
      <Button className='normalButton viewButton' onClick={() =>props.onClickField(field)}>
        <img src={Edit} alt='editImg' className='editImage'/>Edit</Button></div>
      : null;
  };

  return (
    <>
      {
        Object.keys(availableServices ? availableServices : []).length > 0 && 
    <div className='products'>
      <p onMouseOver={()=> props.onMouseOver('heading')} className='topic'>
        {editUI('heading')}
        <span>{props.heading.name}</span>
      </p>
      <p onMouseOver={()=> props.onMouseOver('subHeading')} className='productContentBlock'>
        {editUI('subHeading')}
        <span>{props.heading.content}</span></p>
      <div className='productsTile'>
        {availableServices
          ? Object.keys(availableServices).map((item, index) => {
            return <ProductCard item={availableServices[item]} content={props.content} key={index}  />;
          })
          : props.tileContent.map((item, index) => {
            return <ProductCard item={item} content={props.content} key={index} />;
          })}
      </div>
    </div>
      }
      <Modal
        visible={props.showEditContent}
        hideModal={props.onHideEditContent}
        closeModalPopup={props.onHideEditContent}
        title={''}
        width={400}
        footer={
          <div className='editBannerFooter'>
            <OutlineButton
              className='normalButton viewButton'
              onClick={props?.onHideEditContent}
            >
              Cancel
            </OutlineButton>
            <Button
              className={'normalButton viewButton'}
            >
              Save
            </Button>
          </div>
        }
        centered  
        className='editModalPopup'
        closable={false}
      >
        <div className='editableModal'>
          <div className='clearfix'>
            <label className='float-left labelText'>{props.badge === 'Text'? 'Section Title': 'Section Description'}</label>
          </div>
          <Text value={props.editText}  onChange={props.onEditData}/>
        </div>
      </Modal>
    </>
  );
};

export default CardDescriptor;
