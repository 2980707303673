import React from 'react';
import { Tabs } from '../../../../../../CommonComponents';
import './Approvals.scss';
import IntegrationManagementTab from './IntegrationManagementTab';
import HistoryTab from './HistoryTab';

const Approvals = (props) => {
  const {
    subHeading,
    heading,
    activePagination,
    columns,
    showPayloadModal,
    hidePayloadModal,
    payloadValue,
    rejectErr,
    loading,
    data,
    historycolumns,
    history,
    historyaActivePagination,
    hideHistorypayloadModal,
    showHistoryPayloadModal,
    historyPayload,
    historyPayloadValue,
    showApproveModal,
    rowValue,
    hideApproveModal,
    onClickModalApprove,
    showRejectModal,
    textAreaValue,
    handleTextAreaChange,
    hideRejectModal,
    onClickReject,
    onClickModalReject,
    onTabChange,
    activeTab,
    updatedValue
  } = props;

  const tabComponent = [
    {
      key: 0,
      label: 'Approval',
      children: (
        <IntegrationManagementTab
          subHeading={subHeading}
          heading={heading}
          activePagination={activePagination}
          columns={columns}
          showPayloadModal={showPayloadModal}
          hidePayloadModal={hidePayloadModal}
          payloadValue={payloadValue}
          rejectErr={rejectErr}
          loading={loading}
          data={data}
          showApproveModal={showApproveModal}
          rowValue={rowValue}
          hideApproveModal={hideApproveModal}
          onClickModalApprove={onClickModalApprove}
          showRejectModal={showRejectModal}
          textAreaValue={textAreaValue}
          handleTextAreaChange={handleTextAreaChange}
          hideRejectModal={hideRejectModal}
          onClickReject={onClickReject}
          onClickModalReject={onClickModalReject}
          updatedValue={updatedValue}
        />)
    }, {
      key: 1,
      label: 'History',
      children: (
        <HistoryTab
          historycolumns={historycolumns}
          history={history}
          historyaActivePagination={historyaActivePagination}
          hideHistorypayloadModal={hideHistorypayloadModal}
          showHistoryPayloadModal={showHistoryPayloadModal}
          historyPayload={historyPayload}
          historyPayloadValue={historyPayloadValue}
          loading={loading}
        />
      )
    }
  ];

  return (
    <div className='rolesAndPermissions'>
      <div className='approvableTable'>
        <div className={`clearfix ${subHeading ? 'headerblock' : ''}`}>
          <div className='float-left'>
            <p className={subHeading ? 'heading' : 'subHeading'}>{heading}</p>
            {props.subHeading ? <p className='subHeading'>{subHeading}</p> : null}
          </div>
        </div>
      </div>
      <Tabs tabComponent={tabComponent} onTabClick={onTabChange} activeTab={activeTab} />
    </div>
  );
};

export default Approvals;