import React from 'react';
import { Dropdowns, Text } from '../../../../../../../../CommonComponents';
import Copy from '../../../../../../../../../assets/images/content_copy.png';

const OtherData = (props) => {
  const { otherData, onChangeOtherData } = props;
  const {
    accessKey,
    ecmCode,
    hardwareVersion,
    registrationStatus,
    subscription,
  } = otherData;
  return (
    <>
      <div className='cardList'>
        <p className='spec-heading'>API Access Key</p>
        <div className='clearfix'>
          <div className='float-left'>{accessKey}</div>
          <div className='float-right'>
            {
              accessKey ?
                <img src={Copy} alt='copyIcon' className='copyIcon' />
                : null
            }
          </div>
        </div>
      </div>
      <div className='cardList'>
        <p className='spec-heading'>Hardware Version</p>
        <Text
          value={hardwareVersion}
          onChange={(e) =>
            onChangeOtherData(e?.target?.value, 'hardwareVersion')
          }
        />
      </div>
      <div className='cardList'>
        <p className='spec-heading'>CD_ECM Code Reporting</p>
        <Text
          value={ecmCode}
          onChange={(e) => onChangeOtherData(e?.target?.value, 'ecmCode')}
        />
      </div>
      <div className='cardList'>
        <p className='spec-heading'>Registration Status</p>
        <Dropdowns
          placeholder='Registeration Status'
          optionKeyName={'id'}
          optionTextName={'text'}
          options={props.registrationStatusOption}
          value={registrationStatus}
          disabled={props?.isPartner}
          allowClear
          onChangeEvent={(value) =>
            onChangeOtherData(value, 'registrationStatus')
          }
          className='registrationStatus'
        />
      </div>
      <div className='cardList'>
        <p className='spec-heading'>Subscription</p>
        <div>{subscription}</div>
      </div>
    </>
  );
};

export default OtherData;
