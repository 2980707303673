import React from 'react';
import ProductCard from './ProductCard';
import {useSelector} from 'react-redux';
import {
  selectors as appSelector
} from '../../../../../state/features/app';

const ProductCardContainer = (props) => {
  const {
    userDetails,
    prodServiceMap
  } = useSelector(
    state => ({
      userDetails: appSelector.userDetails(state),
      prodServiceMap: appSelector.prodServiceMap(state)
    })
  );

  return <ProductCard item={props.item} userDetails={userDetails} prodServiceMap={prodServiceMap} />;
};

export default ProductCardContainer;