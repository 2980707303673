import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectors as appSelector } from '../../../../../state/features/app';
import Card from './Card';

const CardContainer = (props) => {
  const { userDetails, currentRoute } = useSelector((state) => ({
    userDetails: appSelector.userDetails(state),
    currentRoute: appSelector.currentRoute(state),
  }));
  const [height, setHeight] = useState(false);
  const [showEditContent, setShowEditContent] = useState(false);
  const [editText, setEditText] = useState('');
  const [editDescription, setEditDescription] = useState('');
  const [editPoints, setEditPoints] = useState([]);
  const [addPoints, setAddPoints] = useState('');

  const deleteList = (index) => {
    let list = [...editPoints];
    list.splice(index, 1);
    setEditPoints(list);
  };

  const onClickField = (data) => {
    setShowEditContent(true);
    setEditText(data.name || data.title);
    setEditDescription(data.content || data.body?.content);
    setEditPoints(data.points);
  };

  const onAddPoints = () => {
    setEditPoints([addPoints, ...editPoints]);
    setAddPoints('');
  };

  const onEditData = (e, field) => {
    let val = e.target.value;
    if (field === 'editText') {
      setEditText(val);
    } else if (field === 'editDescription') {
      setEditDescription(val);
    } else if (field === 'addPoints') {
      setAddPoints(val);
    }
  };

  const onHideEditContent = () => {
    setShowEditContent(false);
  };
  useEffect(() => {
    if (props?.tileContent?.content || props?.tileContent?.body?.content) {
      setHeight(true);
    }

    return () => {
      setHeight(false);
    };
  }, [props?.tileContent?.content, props?.tileContent?.body?.content]);

  const onSaveTile = () => {
    props?.onTileSave(editText, editDescription, editPoints);
    onHideEditContent();
  };

  return (
    <Card
      tileContent={props?.tileContent}
      userDetails={userDetails}
      navigateToSubService={props?.navigateToSubService}
      clickLink={props?.clickLink}
      pathname={props?.pathname}
      link={props?.link}
      services={props?.services}
      height={height}
      editable={props.editable}
      onClickField={onClickField}
      onHideEditContent={onHideEditContent}
      showEditContent={showEditContent}
      editText={editText}
      editDescription={editDescription}
      editPoints={editPoints}
      addPoints={addPoints}
      onEditData={onEditData}
      deleteList={deleteList}
      onAddPoints={onAddPoints}
      currentRoute={currentRoute}
      onTileSave={onSaveTile}
    />
  );
};

export default CardContainer;
