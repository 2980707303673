import React, { useState, useEffect } from 'react';
import VideoPlayer from './VideoPlayer';

const VideoPlayerContainer = (props) => {

  const [showModal,setShowModal] = useState();

  useEffect(() => {
    setShowModal(props.videoPlayerInitState);
  }, [props.videoPlayerInitState]);

  const hideModalPopup = () => {
    props.enableVideo();
    setShowModal(false);
  };

  const showModalPopup = () => {
    setShowModal(true);
  };
  return (
    <VideoPlayer
      title={props.title}
      videoContent={props.videoContent}
      hideModalPopup={hideModalPopup}
      showModalPopup={showModalPopup}
      showModal={showModal}
      footer={null}
    />
  );
};

export default VideoPlayerContainer;