import React from 'react';
import { Modal, Textarea, OutlineButton, Button } from '../../../../../../../CommonComponents';


const RejectModal = (props) => {
  return (
    <Modal
      visible={props.showRejectModal}
      hideModal={props.hideRejectModal}
      closeModalPopup={props.hideRejectModal}
      title={'Reject'}
      width={620}
      className={'RejectModal'}
      footer={
        <div className='viewBlock'>
          <OutlineButton
            className='normalButton viewButton'
            onClick={props.hideRejectModal}
          >
            Cancel
          </OutlineButton>
          <Button
            className={'normalButton viewButton'}
            onClick={props.onClickModalReject}
          >
            Reject
          </Button>
        </div>
      }
      centered
    >
      <div className='RejectView'>
        <div className="formControl">
          <label className='label'>
            Please leave a reason for rejecting this Approval. <span style={{color : 'red'}}>*</span>
          </label>
          <Textarea
            className='rejectView'
            value={props.textAreaValue}
            onChangeEvent={props.handleTextAreaChange}
            rows={4}
            placeholder={''}
            statusMsg={props?.rejectErr && props?.rejectErr}
          />
        </div>
      </div>
    </Modal>
  );
};

export default RejectModal;