import React from 'react';
import ExternalResponseBadge from './ExternalResponseBadge';


const ExternalResponseBadgeContainer = (props) => {
  const { onFormChange, formData, onClickCancel, showModal, hideModalPopup, onManangeClick, onClickSave, externalResponse,
    hideModalSimulatePopup, showSimulateModal, onClickSimulateSave, onSimulateClick,
    onClickSimulateCancel, error, onEditClick, simulateData, onChangeSimulate, simulateErr, process, onDiagnosticChange, diagnosticsOption, 
    disabledDiagnostics, prognosticErr } = props;
  return (
    <ExternalResponseBadge
      onFormChange={onFormChange}
      formData={formData}
      onClickCancel={onClickCancel}
      showModal={showModal}
      hideModalPopup={hideModalPopup}
      onManangeClick={onManangeClick}
      onClickSave={onClickSave}
      externalResponse={externalResponse}
      hideModalSimulatePopup={hideModalSimulatePopup}
      showSimulateModal={showSimulateModal}
      onClickSimulateCancel={onClickSimulateCancel}
      onClickSimulateSave={onClickSimulateSave}
      onSimulateClick={onSimulateClick}
      error={error}
      onEditClick={onEditClick}
      simulateData={simulateData}
      onChangeSimulate={onChangeSimulate}
      simulateErr={simulateErr}
      process={process}
      onDiagnosticChange={onDiagnosticChange}
      diagnosticsOption={diagnosticsOption}
      disabledDiagnostics={disabledDiagnostics}
      prognosticErr={prognosticErr}
    />
  );
};

export default ExternalResponseBadgeContainer;