/* eslint-disable no-else-return */
import React from 'react';

import {
  BreadcrumbComponent,
  Button,
  OutlineButton,
  Modal,
  Upload,
  Text,
  Textarea,
} from '../../../CommonComponents';
import { SigninModal } from '../../CommonWidget';
import VideoPlayer from '../VideoPlayer';
import Play from '../../../../assets/images/play.png';
import Image from '../../../../assets/images/image.png';
import Edit from '../../../../assets/images/edit.png';

import './Banner.scss';

const Banner = (props) => {
  const editUI = (value, field, key) => {
    return props.editable ? (
      <div className='editableTools'>
        <div className='titleBadge'>{props.badge}</div>
        <Button
          className='normalButton viewButton'
          onClick={() => props.onClickField(value, field, key)}
        >
          <img src={Edit} alt='editImg' className='editImage' />
          Edit
        </Button>
      </div>
    ) : null;
  };

  const loadBannerHeading = () => {
    const icon = props.heading?.icon ?? false;
    if (Object.keys(props.heading)?.length > 0) {
      if (props.heading?.type === 'image') {
        const imageSrc = props.heading?.imgSrc ?? '';

        return (
          <img src={imageSrc} alt='Banner Heading' className='bannerImage' />
        );
      }
      return (
        <>
          <p className={`headline ${props.heading.className}`}>
            {icon ? (
              <img
                src={icon}
                alt='icon'
                className={`headingIcon ${props.editable ? 'editable' : ''}`}
              />
            ) : null}
            {editUI(props.heading?.name, 'heading', 'name')}
            <span
              onMouseOver={() => props.onMouseOver('heading')}
              className='headingText'
            >
              {props.heading?.name ?? '<Banner Heading>'}
            </span>
          </p>
        </>
      );
    }
  };

  const loadBannerBody = () => {
    if (Object.keys(props.body)?.length > 0) {
      if (props.body?.type === 'image') {
        const imageSrc = props.body?.imgSrc ?? '';
        return <img src={imageSrc} alt='Banner Body' className='bannerImage' />;
      } else {
        return (
          <>
            <p className='headline'>
              {editUI(props.body?.heading, 'body', 'heading')}
              <span onMouseOver={() => props.onMouseOver('heading')}>
                {props.body?.heading}
              </span>
            </p>
            <p className='subText'>
              {editUI(props.body?.content, 'body', 'content')}
              <span onMouseOver={() => props.onMouseOver('content')}>
                {props.body?.content}
              </span>
            </p>

            {!props.userName && props.pathLink == 'Home' ? (
              <div className='btnGrp'>
                <Button className='normalButton'>{'Become a Partner'}</Button>
                <OutlineButton onClick={props.onshowSignIn}>
                  {'Sign In'}
                </OutlineButton>
              </div>
            ) : null}
            {/* {!props.userName && props.pathLink !== 'Home' ? (
              <Button>Become a Partner</Button>
            ) : null} */}
          </>
        );
      }
    }
  };

  const loadBannerMedia = () => {
    if (Object.keys(props.bannerMedia)?.length > 0) {
      if (
        props.bannerMedia?.type === 'image' ||
        props.bannerMedia?.type === 'video'
      ) {
        return (
          <div onClick={props.enableVideo} className='watch'>
            {props.bannerMedia?.type === 'video' ? (
              <img src={Play} alt='watchVideo' />
            ) : null}
            <span className='watchText'>{props.bannerMedia?.imgText}</span>
          </div>
        );
      }
    }
  };

  const loadImg = () => {
    let src = '';
    if (props.mobile) {
      src = props.bannerMedia?.mobileImgSrc;
    } else {
      src =
        typeof props.bannerMedia?.imgSrc === 'string'
          ? props.bannerMedia?.imgSrc || ''
          : window?.URL?.createObjectURL(props.bannerMedia?.imgSrc || '');
    }
    return src;
  };

  return (
    <div className='bannerBlock'>
      <div className='contentBlock'>
        {props.breadcrumbList.length ? (
          <BreadcrumbComponent
            onClickEvent={props.breadcrumbOnClick}
            valueList={props.breadcrumbList}
          />
        ) : null}

        {loadBannerHeading()}
        {loadBannerBody()}
      </div>
      <div
        className={`videoContainer  ${props.editable ? 'editableContainer' : ''
        } `}
      >
        <div
          className={`videoBlock ${props.editable ? 'editableContent' : ''}`}
        >
          <img
            className='imageBlock'
            alt='bannerImg'
            src={loadImg()}
            onError={(e) => props.onError(e.target)}
          />
          {!props.imgError ? (
            <div className='playImg'>{loadBannerMedia()}</div>
          ) : null}
        </div>
        {props.editable ? (
          <div className='editableTools'>
            <div className='titleBadge'>Image</div>
            <Button
              className='normalButton viewButton'
              onClick={props.onEditBannerImage}
            >
              <img src={Edit} alt='editImg' className='editImage' />
              Edit
            </Button>
          </div>
        ) : null}
      </div>
      {props.bannerMedia?.type === 'video' && props.bannerMedia?.videoSrc && (
        <VideoPlayer
          videoContent={props.bannerMedia?.videoSrc}
          title={props.bannerMedia?.videoTitle ?? 'Video'}
          videoPlayerInitState={props.videoPlayerInitState}
          enableVideo={props.enableVideo}
        />
      )}
      <SigninModal
        enableSignIn={props.enableSignIn}
        hideModalPopup={props.onHideSignin}
      />
      <Modal
        visible={props.showEditContent}
        hideModal={props.onHideEditContent}
        closeModalPopup={props.onHideEditContent}
        title={''}
        width={1000}
        footer={
          <div className='editBannerFooter'>
            <OutlineButton
              className='normalButton viewButton'
              onClick={props?.onHideEditContent}
            >
              Cancel
            </OutlineButton>
            <Button
              onClick={props?.onSaveWebContent}
              className={'normalButton viewButton'}
            >
              Save
            </Button>
          </div>
        }
        centered
        className='editModalPopup'
        closable={false}
      >
        <div className='editableModal'>
          <div className='clearfix'>
            <label className='float-left labelText'>
              {' '}
              {props.badge === 'Text' ? 'Section Title' : 'Section Description'}
            </label>
          </div>
          {
            props.isLink ?
              <>
                <Text
                  value={props.editText}
                  row={0}
                  onChange={props.onEditData}
                />
                <div className='formItem'>
                  <div className='clearfix'>
                    <label className='float-left labelText'>Link</label>
                  </div>
                  <Text value={props.editLink} onChange={(e) => props.setEditLink(e.target.value)} />
                </div>
              </> : props?.fieldKey === 'content' || props?.fieldKey === 'overview' ? (
                <>
                  <Textarea
                    value={props.editText}
                    rows={6}
                    onChangeEvent={props.onEditData}
                  />
                </>
              ) : <Text
                value={props.editText}
                onChange={props.onEditData}
              />
          }
        </div>
      </Modal>
      <Modal
        visible={props.showEditBanner}
        hideModal={props.onHideEditBannerImage}
        closeModalPopup={props.onHideEditBannerImage}
        title={''}
        width={400}
        footer={
          <div className='editBannerFooter'>
            <OutlineButton
              className='normalButton viewButton'
              onClick={props?.onHideEditBannerImage}
            >
              Cancel
            </OutlineButton>
            <Button
              className={'normalButton viewButton'}
              onClick={props?.onSaveBannerImg}
            >
              Save
            </Button>
          </div>
        }
        centered
        className='bannerImageModal'
        closable={false}
      >
        <div className='formItem'>
          <label className='labelText'>Upload Image</label>
          <div className='imageContainer'>
            <div className='imageList'>
              <div className='imageBlock'>
                {/* onImageUpload */}
                <img
                  src={
                    typeof props.previewImg === 'string'
                      ? props?.previewImg
                      : window?.URL?.createObjectURL(props.previewImg)
                  }
                  alt='imagePreview'
                  className='imagePreview'
                />
              </div>
              <div className='imageName'>
                <span title={props.fileName}>{props.fileName}</span>
              </div>
            </div>
          </div>
          <Upload
            icon={<img src={Image} alt='add-icon' className='imageIcon' />}
            text={'Replace Image'}
            uploadProps={props.uploadProps}
          />
          <p className='required noteText'>
            Please upload the image with resolution of 570px * 420px
          </p>
        </div>
        <div className='formItem'>
          <label className='labelText'>Upload Video</label>
          <div className='imageContainer'>
            <div className='imageList'>
              <div className='imageBlock'>
                {props.previewVideo ? (
                  <video
                    ref={props.videoRef}
                    width='100%'
                    height={'100%'}
                    controls
                    loop={false}
                    controlsList='nodownload'
                  >
                    <source src={props.previewVideo} type='video/mp4' />
                  </video>
                ) : (
                  <p>Preview not available</p>
                )}
              </div>
              <div className='imageName'>
                <span title={props.videofileName}>{props.videofileName}</span>
              </div>
            </div>
          </div>
          <Upload
            icon={<img src={Image} alt='replace-icon' className='imageIcon' />}
            text={'Replace Video'}
            uploadProps={props.uploadVideoProps}
          />
        </div>
      </Modal>
    </div>
  );
};

export default Banner;
