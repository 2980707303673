import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../BaseLayout/Layout/Footer';
import Layout from './Layout';
import Navigation from './Navigation';

import './BaseLayout.scss';

const BaseLayout = (props) => {
  return (
    <div className='App'>
      <Layout setMenu={props.setShowMenu} isAdmin={props?.isAdmin}>
        <div className={`splitRow ${props.editable? 'editable':''}`}>
          <div className={`splitCol ${!props.showMenu ? 'showMenu' : ''}`}>
            <Navigation
              showMenu={props.showMenu}
              navItems={props.navItems}
              onNavClick={props?.onNavClick}
              setShowMenu={props.setShowMenu}
              mobile={props.mobile}
            />
          </div>
          <div className='splitCol'>
            <Outlet />
            <Footer/>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default BaseLayout;
