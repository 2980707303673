import React from 'react';

import { Checkbox } from 'antd';
import './Checkbox.scss';

const CheckboxComponent = ({
  type = '', 
  className='',
  onClickEvent = () => {},
  style = {},
  disabled = false,
  checked = false,
  ...props
}) => {
  
  return (
    <Checkbox
      type={type}
      className={className}
      onClick={onClickEvent}
      style={style}
      disabled={disabled}
      checked={checked}
    >
      {props.children}
    </Checkbox>
  );

};

export default CheckboxComponent;