import React from 'react';
import {
  Upload,
  // MessageComponent,
} from '../../../CommonComponents';
// import { OutlineButton } from '../../../../../../../../CommonComponents';
import './ProjectDocument.scss';
import Download from '../../../../assets/images/download.png';
import Trash from '../../../../assets/images/trash.png';
import fileicon from '../../../../assets/images/fileicon.png';
import Add from '../../../../assets/images/smallAdd.png';

const ProjectDocument = (props) => {
  const uploadProps = {
    name: 'file',
    headers: {
      authorization: 'authorization-text',
    },
    maxCount: 1,
    beforeUpload: () => {
      /* update state here */
      return false;
    },
    onChange(info) {
      props?.onFileUpload(info?.file);
    },
  };


  return (
    <>
      <div className='card spec'>
        <p className='spec-heading'>{props?.title || 'Supporting documents'}</p>
        {props.projectDocuments?.map((item, index) => {
          return (
            <div className={`clearfix projectDoc ${!props.editable ? 'viewable':''}`} key={index}>
              <div className='float-left leftDoc'>
                <img src={fileicon} alt='fileIcon' className='fileListIcon' />
                <span role={!props?.isAdmin ? 'button' : 'none'} onClick={() => !props?.isAdmin ? props?.onDownloadFile(item) : {}} className='titleDoc'>
                  {item?.split('/').pop().split('.')[0]}
                </span>
              </div>
              {props?.isAdmin && (
                <div className='float-right toolbox'>
                  <div
                    className='boxList downloadbox'
                    onClick={() => props?.onDownloadFile(item)}
                  >
                    <img src={Download} alt='download' />
                  </div>
                  <div className='boxList trashbox'>
                    <img
                      src={Trash}
                      alt='Delete Image'
                      onClick={() => props?.onDeleteFile(item)}
                    />
                  </div>
                </div>
              )}
            </div>
          );
        })}
        {props.editable && !props.userDetails?.partnerName ? (
          <Upload
            icon={<img src={Add} alt='add-icon' className='addIcon' />}
            uploadProps={uploadProps}
            text={' Add Document'}
          />
        ) : null}

        {/* <input type="file" onChange={props?.onFileUpload} id="myfile" name="myfile" multiple/> */}
        {/* <OutlineButton className='normalButton viewButton borderedBtn'>
          <img src={Add} alt='add-icon' className='addIcon' />
          Add Document
        </OutlineButton> */}
      </div>
    </>
  );
};

export default ProjectDocument;
