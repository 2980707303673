import React, { useEffect } from 'react';
import APIDescriptor from './APIDescriptor';
import { useDispatch, useSelector } from 'react-redux';
import { operations as appOps } from '../../../../state/features/app';

const APIDescriptorContainer = (props) => {
  const dispatch = useDispatch();
  const dispatchGetApiDescJson = appOps.dispatchGetApiDescJson(dispatch);
  const userDetails = useSelector((state) => state?.app?.userDetails || {});
  const jsonData = useSelector((state) => state?.app?.apiDescJson || {});
  const { partnerName } = userDetails;
  const currentRoute = useSelector((state) => state?.app?.currentRoute || []);

  useEffect(() => {
    if (currentRoute?.length === 4 && partnerName) {
      dispatchGetApiDescJson(partnerName, currentRoute[1], currentRoute[2]);
    }
  }, [partnerName, currentRoute]);
  return (
    !props?.editable && (
      <APIDescriptor content={props.content} jsonData={jsonData} />
    )
  );
};

export default APIDescriptorContainer;
