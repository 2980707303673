import React, { useEffect, useMemo, useState } from 'react';
import { Checkbox, Button } from '../../../../CommonComponents';
import './Subscription.scss';

const Subscription = (props) => {
  const { availableServices, onSubscribeCheckChange } = props;
  const allServices = useMemo(
    () => [...availableServices],
    [availableServices]
  );

  const [activeSub, setActiveSub] = useState([]);
  const [pendingSub, setPendingSub] = useState([]);

  useEffect(() => {
    if (allServices?.length > 0) {
      let tempAct = [];
      let tempPending = [];
      allServices?.forEach((item) => {
        if (item?.subscriptionStatus === 'Subscribed') {
          tempAct.push(item);
        } else if (item?.subscriptionStatus === 'Pending') {
          tempPending.push(item);
        }
      });
      setActiveSub(tempAct);
      setPendingSub(tempPending);
    }
  }, [allServices]);

  return (
    <div className='subscription-modal'>
      <div>
        <p className='heading'>Active Subscription</p>
        {activeSub?.length > 0 ? (
          activeSub?.map((item, idx) => (
            <p className='title' key={idx}>
              {item?.title}
            </p>
          ))
        ) : (
          <div className='bgGrey'>No Active Subscriptions</div>
        )}
      </div>
      <div className='line'></div>
      <div>
        <p className='heading'>Pending Subscription</p>
        {pendingSub?.length > 0 ? (
          pendingSub?.map((item, idx) => (
            <p className='title' key={idx}>
              {item?.title}
            </p>
          ))
        ) : (
          <div className='bgGrey'>No Active Subscriptions</div>
        )}
      </div>
      <div className='line'></div>
      <div>
        <p className='heading'>Available Services</p>
        <p>
          Select the services you would like to request access to. Your Cummins
          administrator will review your request.
        </p>
        <div className='checkboxList clearfix'>
          {allServices?.length > 0 ? (
            allServices?.map((item, idx) => {
              return (
                <Checkbox
                  onClickEvent={(e) => {
                    onSubscribeCheckChange(e, item);
                  }}
                  disabled={item?.subscriptionStatus === 'Pending'}
                  key={idx}
                  checked={item?.subscriptionStatus === 'Subscribed'}
                >
                  {item?.title}
                  <p>{item?.content}</p>
                </Checkbox>
              );
            })
          ) : (
            <div className='bgGrey'>No Available Subscriptions</div>
          )}
        </div>
      </div>
      <div className='centerButton'>
        <Button
          className='normalButton largeButton'
          onClick={props?.onClickSave}
          disabled={allServices?.length === 0}
        >
          Save Changes
        </Button>
      </div>
    </div>
  );
};

export default Subscription;
