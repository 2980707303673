import React, { useEffect, useState } from 'react';
import ProductsServiceAPI from './ProductsServiceAPI';
import {
  OutlineButton,
  Switch,
  Button,
} from '../../../../../../CommonComponents';
import SortObject from '../../../../../../../utils/SortObject';
import {
  operations as adminOps,
  selectors as adminSelector,
} from '../../../../../../../state/features/admin';
import { useDispatch, useSelector } from 'react-redux';

const ProductsServiceAPIContainer = (props) => {
  const statusOption = [
    {
      text: 'Active',
      id: 1,
    },
    {
      text: 'InActive',
      id: 2,
    },
  ];

  const environmentOption = [
    {
      text: 'Environment 1',
      id: 1,
    },
    {
      text: 'Environment 2',
      id: 2,
    },
  ];

  const editAPIColumn = [
    {
      title: 'API Endpoint Name',
      dataIndex: 'apiName',
      key: 'apiName',
      sorter: (a, b) => {
        return SortObject(a.apiName, b.apiName);
      },
    },
    {
      title: 'Description',
      dataIndex: 'Description',
      width: '200px',
      editable: true,
    },
  ];

  const productColumns = [
    {
      title: 'Product',
      dataIndex: 'productName',
      key: 'productName',
      render: (data) => {
        return (
          <div className='boldName'>
            <p className='name'>
              {data}
            </p>
          </div>
        );
      },
      width: 160,
      sorter: (a, b) => {
        return SortObject(a.productName, b.productName);
      },
    },
    {
      title: 'Total Services',
      dataIndex: 'serviceCount',
      key: 'serviceCount',
      width: 120,
      align: 'right',
      sorter: (a, b) => {
        return SortObject(a.serviceCount, b.serviceCount);
      },
    },
    {
      title: 'Published',
      dataIndex: 'isPublished',
      key: 'isPublished',
      width: 120,
      // align: 'right',
      sorter: (a, b) => {
        return SortObject(a.isPublished, b.isPublished);
      },
      render: (data, row) => {
        return (
          <Switch
            checked={data === 'Yes'}
            onChangeEvent={() => onClickSwitch(data, row)}
          />
        );
      },
    },
    {
      title: 'Modified Date',
      dataIndex: 'modifiedDate',
      key: 'modifiedDate',
      width: 160,
      // align: 'right',
      sorter: (a, b) => {
        return SortObject(a.modifiedDate, b.modifiedDate);
      },
    },
    {
      title: 'Action',
      dataIndex: 'Action',
      key: 'Action',
      width: 160,
      align: 'center',
      render: (_, row) => {
        return (
          <div className='viewBlock'>
            <OutlineButton
              className='normalButton viewButton'
              onClick={() => onClickEditBtn('Edit Product', row)}
            >
              Edit
            </OutlineButton>
            <Button
              className='normalButton viewButton'
              onClick={() =>
                onClickViewBtn(
                  ['Product', 'Service'],
                  serviceColumns,
                  'Service',
                  row
                )
              }
            >
              View
            </Button>
          </div>
        );
      },
    },
  ];

  const apiEndpointOption = [
    {
      value: '',
      label: 'All',
    },
    {
      value: 'Managed API',
      label: 'Managed API',
    },
    {
      value: 'UnManaged API',
      label: 'UnManaged API',
    },
  ];

  const serviceColumns = [
    {
      title: 'Service Name',
      dataIndex: 'serviceName',
      key: 'serviceName',
      render: (data) => {
        return (
          <div className='boldName'>
            <p className='name'>
              {data}
              {/* {row.isModified === 'Yes' ? (
                <span className='marker'>(Draft)</span>
              ) : null} */}
            </p>
          </div>
        );
      },
      width: 160,
      sorter: (a, b) => {
        return SortObject(a.serviceName, b.serviceName);
      },
    },
    {
      title: 'Total APIs',
      dataIndex: 'apiCount',
      key: 'apiCount',
      width: 120,
      align: 'right',
      sorter: (a, b) => {
        return SortObject(a.apiCount, b.apiCount);
      },
    },
    // {
    //   title: 'Status',
    //   dataIndex: 'status',
    //   key: 'status',
    //   width: 60,
    //   align: 'center',
    //   render: (data, row) => {
    //     return (
    //       <Switch
    //         checked={data === 'Active'}
    //         onChangeEvent={() => onClickSwitch(data, row)}
    //       />
    //     );
    //   },
    // },
    {
      title: 'Published',
      dataIndex: 'isPublished',
      key: 'isPublished',
      width: 120,
      // align: 'right',
      sorter: (a, b) => {
        return SortObject(a.isPublished, b.isPublished);
      },
      render: (data, row) => {
        return (
          <Switch
            checked={data === 'Yes'}
            onChangeEvent={() => onClickSwitch(data, row)}
          />
        );
      },
    },
    {
      title: 'Modified Date',
      dataIndex: 'modifiedDate',
      key: 'modifiedDate',
      width: 140,
      // align: 'right',
      sorter: (a, b) => {
        return SortObject(a.modifiedDate, b.modifiedDate);
      },
    },
    {
      title: 'Action',
      dataIndex: 'Action',
      key: 'Action',
      width: 160,
      align: 'center',
      render: (_, row) => {
        return (
          <div className='viewBlock'>
            <OutlineButton
              className='normalButton viewButton'
              onClick={() => onClickEditBtn('Edit Service', row)}
            >
              Edit
            </OutlineButton>
            <Button
              className='normalButton viewButton'
              onClick={() => {
                onClickViewBtn(
                  ['Product', 'Service', 'API'],
                  apiColumns,
                  'API',
                  row
                );
              }}
            >
              View
            </Button>
          </div>
        );
      },
    },
  ];

  const apiColumns = [
    {
      title: 'API Name',
      dataIndex: 'apiName',
      key: 'apiName',
      render: (data) => {
        return (
          <div className='boldName'>
            <p className='name'>
              {data}
              {/* {row.isModified === 'Yes' ? (
                <span className='marker'>(Draft)</span>
              ) : null} */}
            </p>
          </div>
        );
      },
      width: 160,
      sorter: (a, b) => {
        return SortObject(a.apiName, b.apiName);
      },
    },
    {
      title: 'Type',
      dataIndex: 'apiType',
      key: 'apiType',
      width: 60,
      align: 'center',
    },
    {
      title: 'Status',
      dataIndex: 'apiStatus',
      key: 'apiStatus',
      width: 60,
      align: 'center',
      sorter: (a, b) => {
        return SortObject(a.apiStatus, b.apiStatus);
      },

    },
    {
      title: 'Published',
      dataIndex: 'isPublished',
      key: 'isPublished',
      width: 120,
      // align: 'right',
      sorter: (a, b) => {
        return SortObject(a.isPublished === 'Yes' ? 1 : 0, b.isPublished === 'Yes' ? 1 : 0);
      },
      render: (data, row) => {
        return (
          <Switch
            checked={data === 'Yes'}
            onChangeEvent={() => onClickSwitch(data, row)}
          />
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'Action',
      key: 'Action',
      width: 180,
      align: 'center',
      render: (_, row) => {
        return (
          <div className='viewBlock'>
            <OutlineButton
              className='normalButton viewButton'
              onClick={() => showEditAPIModal('Edit API', row)}
            >
              Edit
            </OutlineButton>
            <Button
              className='normalButton viewButton'
              onClick={() => onShowModal(row?.apiName, row?.apiId)}
            >
              Edit Web Page
            </Button>
          </div>
        );
      },
    },
  ];

  const productPageHeadingInfo = {
    heading: 'Products',
    subHeading: 'Content Management',
    addBtnName: 'Product',
  };

  const [productIds, setProductIds] = useState({
    productId: 0,
    serviceId: 0,
    apiId: 0,
  });
  const [editModal, setEditModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('Add Product');
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [gridColumns, setGridColumns] = useState(productColumns);
  const [pageHeadingInfo, setPageHeadingInfo] = useState(
    productPageHeadingInfo
  );
  const [gridData, setGridData] = useState([]);
  const [liveScreenName, setLiveScreenName] = useState('Product');
  const [editAPIModal, setEditAPIModal] = useState(false);
  const [addManually, setAddManually] = useState(false);
  const [breadcrumbList, setBreadcrumbList] = useState(['Product']);
  const [selectedProdServiceAPI, setSelectedProdServiceAPI] = useState({});
  const [tableKey, setTableKey] = useState('productId');
  const [apiList, setApiList] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    status: 'Active',
  });
  const [currentRow, setCurrentRow] = useState(null);
  const [selectedApiType, setSelectedApiKey] = useState('');
  const [file, setFile] = useState(null);
  const [fileList, setFileList] = useState([]);

  const [visible, setVisible] = useState(false);
  const [currentScreen, setCurrentScreen] = useState('/Products');
  const [apiId, setApiId] = useState(0);
  const [statusMsg, setStatusMsg] = useState({
    name: undefined,
    description: undefined,
  });

  const dispatch = useDispatch();
  const dispatchGetProductData = adminOps.dispatchGetProdServiceApi(dispatch);
  const dispatchCreateProduct = adminOps.dispatchCreateProductApi(dispatch);
  const dispatchCreateService = adminOps.dispatchCreateServiceApi(dispatch);
  const dispatchCreateApi = adminOps.dispatchAPIcreateApi(dispatch);
  const dispatchGetApiEndpoints = adminOps.dispatchApiEndpointsApi(dispatch);
  const dispatchPublishChanges = adminOps.dispatchSetPublishChanges(dispatch);
  const dispatchApiPublish = adminOps.dispatchGetApiPublish(dispatch);

  const { productData } = useSelector((state) => ({
    productData: adminSelector.productData(state),
  }));

  const { loading, apiEndpoints } = productData;

  useEffect(() => {
    let data = [];
    if (apiEndpoints?.length > 0) {
      apiEndpoints.forEach((li) => {
        if (selectedApiType === 'Managed API') {
          li?.apiType === 'Managed' && data?.push({ ...li, key: li?.apiId });
        } else if (selectedApiType === 'UnManaged API') {
          li?.apiType === 'UnManaged' && data?.push({ ...li, key: li?.apiId });
        } else {
          data?.push({ ...li, key: li?.apiId });
        }
      });
      setApiList(data);
    }
  }, [apiEndpoints, selectedApiType]);

  useEffect(() => {
    const keys = Object.keys(productIds);
    const nonZeros = keys.filter((k) => productIds[k] !== 0);
    let data = [];
    if (
      nonZeros?.length === 1 &&
      productData?.services[productIds?.productId]
    ) {
      data = [...productData?.services[productIds?.productId]];
      setGridData(data);
    } else if (
      nonZeros?.length === 2 &&
      productData?.API[productIds?.serviceId]
    ) {
      data = [...productData?.API[productIds?.serviceId]];
      setGridData(data);
      setSelectedRowKeys(data?.map((api) => api?.apiId));
    } else if (
      productIds?.productId === 0 &&
      productIds?.serviceId === 0 &&
      productData?.products?.length > 0
    ) {
      let data = [...productData?.products];
      setGridData(data);
    } else {
      dispatchGetProductData(productIds);
    }
    setSelectedProdServiceAPI({
      ...selectedProdServiceAPI,
      [liveScreenName]: productIds,
    });
    return () => {
      setGridData([]);
    };
  }, [productIds]);

  useEffect(() => {
    const keys = Object.keys(productIds);
    const nonZeros = keys.filter((k) => productIds[k] !== 0);
    let data = [];
    if (
      nonZeros?.length === 1 &&
      productData?.services[productIds?.productId]
    ) {
      data = [...productData?.services[productIds?.productId]];
      setGridData(data);
    } else if (
      nonZeros?.length === 2 &&
      productData?.API[productIds?.serviceId]
    ) {
      data = [...productData?.API[productIds?.serviceId]];
      setGridData(data);
      setSelectedRowKeys(
        productData?.API[productIds?.serviceId]?.map((api) => api?.apiId)
      );
    } else if (
      productData?.products?.length > 0 &&
      liveScreenName === 'Product'
    ) {
      data = [...productData?.products];
      setGridData(data);
    }
    return () => {
      setGridData([]);
    };
  }, [productData]);

  const onShowAddManually = () => {
    setEditAPIModal(false);
    setTimeout(() => {
      setAddManually(true);
    }, 500);
  };

  const onHideManually = () => {
    setAddManually(false);
    setTimeout(() => {
      setEditAPIModal(true);
    }, 500);
  };

  const showEditAPIModal = (title, row) => {
    setEditAPIModal(true);
    setModalTitle(title ? title : 'Add API');
    setCurrentRow(row);
    setFormData((prevState) => ({
      ...prevState,
      name: row?.apiName,
      description: row?.apiDescription || '',
      status: row?.apiStatus || 'Active',
    }));
  };

  const hideEditAPIModal = () => {
    setSelectedRowKeys([]);
    setEditAPIModal(false);
  };

  const hideEditModal = () => {
    setEditModal(false);
    setCurrentRow(null);
    setFormData({
      name: '',
      description: '',
      status: 'Active',
    });
    setFile(null);
    setFileList(null);
  };

  const onClickEditBtn = (title, row) => {
    setEditModal(true);
    setModalTitle(title);
    setCurrentRow(row);
    setFormData((prevState) => ({
      ...prevState,
      name: row?.productName || row?.serviceName,
      description: row?.productDescription || row?.serviceDescription,
      status: row?.status || 'Active',
    }));
    setFile(row?.productImage || null);
  };

  const onClickViewBtn = (
    newBreadCrumbsList,
    columnInfo = [],
    toServiceName = '',
    row = ''
  ) => {
    let headingText = toServiceName;
    if (toServiceName === 'Service') {
      headingText = row?.productName ?? 'Service';
      setTableKey('serviceId');
    } else if (toServiceName === 'API') {
      headingText = row?.serviceName ?? 'API';
      setTableKey('apiId');
    }
    row !== '' &&
      setProductIds((prevState) => ({
        ...prevState,
        productId: row?.productId || 0,
        serviceId: row?.serviceId || 0,
      }));
    setPageHeadingInfo({
      heading: headingText === 'Product' ? 'Products' : headingText,
      subHeading: 'Content Management',
      addBtnName: toServiceName,
    });
    setGridColumns(columnInfo);
    setLiveScreenName(toServiceName);
    setBreadcrumbList(newBreadCrumbsList);
  };

  const onClickSwitch = (_, row) => {
    if (row?.serviceId && row?.productId) {
      let data = {
        serviceId: row?.serviceId,
        isPublished: row?.isPublished === 'Yes' ? 'No' : 'Yes',
        productId: row?.productId,
      };
      dispatchCreateService(data, true);
      setGridData((prevState) => {
        let data = [...prevState];
        let currentIndex = prevState.findIndex(
          (o) => o?.serviceId === row?.serviceId
        );
        data[currentIndex] = {
          ...data[currentIndex],
          isPublished: row?.isPublished === 'Yes' ? 'No' : 'Yes',
        };
        return data;
      });
    } else if (row?.apiId) {
      let editData = {
        serviceId: row?.serviceId,
        apiId: row?.apiId,
        apiDescription: row?.apiDescriptiond,
        isPublished: row?.isPublished === 'Yes' ? 'No' : 'Yes',
      };
      dispatchApiPublish(editData);

      setGridData((prevState) => {
        let data = [...prevState];
        let currentIndex = prevState.findIndex((o) => o?.apiId === row?.apiId);
        data[currentIndex] = {
          ...data[currentIndex],
          isPublished: row?.isPublished === 'Yes' ? 'No' : 'Yes',
        };
        return data;
      });
    } else {
      let data = {
        productId: row?.productId,
        isPublished: row?.isPublished === 'Yes' ? 'No' : 'Yes',
      };
      dispatchCreateProduct(data, true);
      setGridData((prevState) => {
        let data = [...prevState];
        let currentIndex = prevState.findIndex(
          (o) => o?.productId === row?.productId
        );
        data[currentIndex] = {
          ...data[currentIndex],
          isPublished: row?.isPublished === 'Yes' ? 'No' : 'Yes',
        };
        return data;
      });
    }
  };

  const onAddModal = () => {
    setAddModal(true);
    if (liveScreenName !== 'API') {
      setEditModal(true);
    } else {
      if (apiEndpoints?.length === 0) {
        dispatchGetApiEndpoints();
      }
      setEditAPIModal(true);
    }

    setModalTitle(`Add ${liveScreenName}`);
  };

  const onCancelClick = () => {
    setEditModal(false);
    setCurrentRow(null);
    setFormData({
      name: '',
      description: '',
      status: 'Active',
    });
    setFile(null);
    setFileList(null);
  };
  const onSaveClick = (formData, screenName) => {
    const isEditMode = currentRow !== null;

    if (screenName === 'Product') {
      if (isEditMode) {
        const data = {
          productName: formData?.name,
          productDescription: formData?.description,
          productId: currentRow?.productId,
          status: formData?.status,
        };
        dispatchCreateProduct(data, isEditMode, file);
      } else {
        dispatchCreateProduct(
          {
            productName: formData?.name,
            productDescription: formData?.description,
            status: formData?.status,
          },
          isEditMode,
          file
        );
      }
    } else if (screenName === 'Service') {
      if (isEditMode) {
        const data = {
          serviceId: currentRow?.serviceId,
          serviceName: formData?.name,
          serviceDescription: formData?.description,
          status: formData?.status,
          integrationManager: [],
          productId: productIds?.productId,
        };
        dispatchCreateService(data, isEditMode);
      } else {
        const data = {
          serviceName: formData?.name,
          serviceDescription: formData?.description,
          status: formData?.status,
          integrationManager: [],
          productId: productIds?.productId,
        };
        dispatchCreateService(data, isEditMode);
      }
    } else {
      if (isEditMode) {
        let editData = {
          serviceId: productIds?.serviceId,
          apiId: currentRow?.apiId,
          apiDescription: formData?.description,
          status: formData?.status,
          apiName: formData?.name
        };
        dispatchApiPublish(editData);
      } else {
        const data = {
          serviceId: productIds?.serviceId,
          apiIds: selectedRowKeys,
        };
        dispatchCreateApi(data, productIds?.productId, isEditMode);
      }
    }

    hideEditAPIModal();
    setEditModal(false);
    setCurrentRow(null);
  };

  const breadcrumbOnClick = (e) => {
    setGridData([]);
    const selectedBreadcrumb = e?.target?.innerText ?? '';
    if (selectedBreadcrumb !== '') {
      let column = [];
      let breadCrumbList = [];
      if (selectedBreadcrumb === 'Product') {
        column = productColumns;
        breadCrumbList = ['Product'];
      } else if (selectedBreadcrumb === 'Service') {
        column = serviceColumns;
        breadCrumbList = ['Product', 'Service'];
      } else if (selectedBreadcrumb === 'API') {
        column = apiColumns;
        breadCrumbList = ['Product', 'Service', 'API'];
      }
      let currentRow = {};
      if (selectedBreadcrumb === 'Service') {
        let currentArr = productData?.products;
        currentArr.forEach((item) => {
          if (item?.productId === productIds?.productId) {
            currentRow = item;
          }
        });
      } else {
        currentRow = '';
      }
      onClickViewBtn(breadCrumbList, column, selectedBreadcrumb, currentRow);
      const prodIds = selectedProdServiceAPI[selectedBreadcrumb] ?? '';
      prodIds !== '' && setProductIds(prodIds);
    }
  };

  const onEndpointChange = (value) => {
    setSelectedApiKey(value);
  };

  // const onImageUpload = (file) => {
  //   setFile(file);
  // };

  const onImageUpload = (info) => {
    setFile(info?.fileList[0]);
    let newFileList = [...info.fileList];
    newFileList = newFileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    setFileList(newFileList);
  };


  const onRemoveUpload = () => {
    setFile(null);
    setFileList(null);
  };

  const onClickPublish = () => {
    let data = {
      publishType: liveScreenName === 'API' ? 'Api' : liveScreenName,
      ...(productIds?.productId !== 0 && { productId: productIds?.productId }),
      ...(productIds?.serviceId !== 0 && { serviceId: productIds?.serviceId }),
    };
    dispatchPublishChanges(data, productIds);
  };

  const onShowModal = (title, id) => {
    setCurrentScreen(title ? `/${title}` : liveScreenName);
    setApiId(id || 0);
    setVisible(true);
  };

  const onFormChange = (value, fieldName) => {
    setFormData((prevState) => ({ ...prevState, [fieldName]: value }));
  };

  const onClickSave = () => {
    if (formData?.name === '' && formData?.description === '' && liveScreenName !== 'API') {
      setStatusMsg((prevState) => ({
        ...prevState,
        name: `Please enter ${liveScreenName.toLowerCase()} name`,
        description: `Please enter ${liveScreenName.toLowerCase()} description`,
      }));
    } else if (formData?.name === '' && formData?.description !== '') {
      setStatusMsg((prevState) => ({
        ...prevState,
        name: `Please enter ${liveScreenName.toLowerCase()} name`,
        description: undefined,
      }));
    } else if (formData?.name !== '' && formData?.description === '') {
      setStatusMsg((prevState) => ({
        ...prevState,
        name: undefined,
        description: `Please enter ${liveScreenName.toLowerCase()} description`,
      }));
    } else {
      setStatusMsg(undefined);
      onSaveClick(formData, liveScreenName);
      setFormData({
        name: '',
        description: '',
        status: 'Active',
      });
    }
  };

  const onClickCancel = () => {
    setStatusMsg(undefined);
    setFormData({
      name: '',
      description: '',
      status: 'Active',
    });
    onCancelClick();
    hideEditAPIModal();
  };

  return (
    <>
      <ProductsServiceAPI
        dataSource={gridData}
        columns={gridColumns}
        editModal={editModal}
        hideEditModal={hideEditModal}
        modalTitle={modalTitle}
        onAddModal={onAddModal}
        addModal={addModal}
        onCancelClick={onCancelClick}
        onSaveClick={onSaveClick}
        pageHeadingInfo={pageHeadingInfo}
        liveScreenName={liveScreenName}
        showEditAPIModal={showEditAPIModal}
        hideEditAPIModal={hideEditAPIModal}
        editAPIModal={editAPIModal}
        statusOption={statusOption}
        environmentOption={environmentOption}
        editAPIColumn={editAPIColumn}
        editAPIDataSource={apiList}
        breadcrumbList={breadcrumbList}
        addManually={addManually}
        onShowAddManually={onShowAddManually}
        onHideManually={onHideManually}
        breadcrumbOnClick={breadcrumbOnClick}
        tableKey={tableKey}
        loading={loading}
        formData={formData}
        setFormData={setFormData}
        selectedRowKeys={selectedRowKeys}
        onSelectChange={(value) => setSelectedRowKeys(value)}
        apiEndpointOption={apiEndpointOption}
        onEndpointChange={onEndpointChange}
        onImageUpload={onImageUpload}
        fileImgSrc={file}
        onRemoveUpload={onRemoveUpload}
        onClickPublish={onClickPublish}
        productIds={productIds}
        visible={visible}
        setVisible={setVisible}
        onShowModal={onShowModal}
        currentScreen={currentScreen}
        apiId={apiId}
        currentRow={currentRow}
        isAdmin={props?.isAdmin}
        onFormChange={onFormChange}
        statusMsg={statusMsg}
        onClickSave={onClickSave}
        onClickCancel={onClickCancel}
        fileList={fileList}
      />
    </>
  );
};

export default ProductsServiceAPIContainer;
