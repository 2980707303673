import React from 'react';
import Header from './Header';
import './Layout.scss';

const Layout = (props) => {
  return (
    <div className='layout'>
      <Header setMenu={props.setMenu} isAdmin={props?.isAdmin} />
      <div className='content'>{props.children}</div>
    </div>
  );
};

export default Layout;
