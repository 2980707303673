import React from 'react';

const TabAndSearch = (props) => {
  return (
    <div className='innerTabs'>
      {props.tabs.map((item, index) => {
        return (
          <div
            key={index}
            className={`tabList ${item.key} ${
              props.activeTabs == item.name ? 'active' : ''
            }`}
            onClick={() => props.onClickTabs(item)}
          >
            <div className={'colorBlock'}></div>
            <div className='listBlock'>
              <div className='clearfix'>
                <div className='float-left'>
                  {item.count} {item.name}
                </div>
                <div className='float-right'>
                  <div className='boxshadow'>
                    <img src={item.img} alt='icon' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TabAndSearch;
