import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import ApiProject from './ApiProject';
import {
  Button,
  Modal,
  OutlineButton,
  Popover,
  Tag,
} from '../../../../../../CommonComponents';

import {
  operations as adminOps,
  selectors as adminSelector,
} from '../../../../../../../state/features/admin';
import {
  operations as appOps,
  selectors as appSelector,
} from '../../../../../../../state/features/app';
import sortData from '../../../../../../../utils/sortData';
import getImg from './getImg';

const ApiProjectContainer = (props) => {
  const [activeTabs, setActiveTabs] = useState('');
  const [selectedView, setSelectedView] = useState('All Partners');
  const [rejectSubscription, setRejectSubscription] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    pageSizeOptions: [5, 10, 20, 50, 100],
    showSizeChanger: true,
  });
  const [AllPagination, setAllPagination] = useState({
    current: 1,
    pageSize: 5,
    pageSizeOptions: [5, 10, 20, 50, 100],
    showSizeChanger: true,
  });
  const [rejectDesc, setRejectDesc] = useState('');
  const [descErr, setDescErr] = useState(false);
  const [viewBlock, setViewBlock] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const [params, setParams] = useState({ partnerId: '', filter: '' });
  const { partnerId, filter } = params;
  const [searchValue, setSerachValue] = useState('');
  const [statusCount, setStatusCount] = useState({});
  const [pendingSubscription, setPendingSubscription] = useState();
  const [allSubscription, setAllSubscription] = useState();
  const [projectId, setProjectId] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const [contactList, setContactList] = useState([]);
  const [otherData, setOtherData] = useState({});
  const [isUpload, setIsUpload] = useState(false);
  const [managers, setmanagers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    CustomerRefernce: '',
    TSPName: ''
  });
  // const [error, setError] = useState({
  //   CustomerRefernce: undefined,
  //   TSPName: undefined
  // });
  // const [row, setRow] = useState({});



  const dispatch = useDispatch();
  const dispatchApiProject = adminOps.dispatchGetApiProject(dispatch);
  const dispatchSubmitSubscription = appOps.dispatchSubmitSubRequest(dispatch);
  const dispatchUploadProjectDOc = adminOps.dispatchUploadProjectDoc(dispatch);
  const dispatchDeleteProjectDoc = adminOps.dispatchDeleteProjectDoc(dispatch);
  const dispatcProductView = adminOps.dispatchGetProjectView(dispatch);
  const dispatchSetProjectMilestone =
    adminOps.dispatchSetProjectMilestone(dispatch);
  const dispatchToggleAlertPopup = adminOps.dispatchToggleAlertPopup(dispatch);
  const dispatchIsProjectDataChanged =
    adminOps.dispatchIsProjectDataChanged(dispatch);
  const getProjectPartners =
    adminOps.dispatchGetProjectPartners(dispatch);

  // adminSelector
  const {
    apiProjects,
    loading,
    partners,
    userDetails,
    projectView,
    alertPopup,
    projectDataChanged,
  } = useSelector((state) => ({
    apiProjects: adminSelector.apiProjects(state),
    loading: adminSelector.loading(state),
    partners: adminSelector.partners(state),
    userDetails: appSelector.userDetails(state),
    projectView: adminSelector.projectView(state),
    alertPopup: adminSelector.alertPopup(state),
    projectDataChanged: adminSelector.projectDataChanged(state),
  }));

  useEffect(() => {
    if (partners?.length === 0) {
      getProjectPartners('');
    }
  }, []);

  useEffect(() => {
    const count = apiProjects?.statusCount ?? '';
    if (activeTabs === '' && count !== '') {
      setStatusCount(count);
    }

    setPendingSubscription(apiProjects?.pendingSubscription);
    setAllSubscription(apiProjects?.allSubscription);
  }, [apiProjects]);

  const columnsSubscription = [
    {
      title: 'Partner Name',
      dataIndex: 'Partner',
      key: 'Partner',
      width: 120,
      sorter: (a, b) => sortData(a.Partner, b.Partner),
      render: (text) => {
        return <div className='boldText'>{text}</div>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      key: 'Status',
      sorter: (a, b) => sortData(a.Status, b.Status),
      render: (text, row) => {
        return (
          <div className='statusBox'>
            <img src={getImg(text)} alt='icon' />
            <div className='textBlock'>{text}</div>
            {text === 'Rejected' ? (
              <Popover content={row.rejectedReason}>
                <span className='fa fa-info-circle infoIcon'></span>
              </Popover>
            ) : null}
          </div>
        );
      },
      width: 160,
    },
    {
      title: 'Product Name',
      dataIndex: 'Product',
      key: 'Product',
      sorter: (a, b) => sortData(a.Product, b.Product),
      width: 130,
    },
    {
      title: 'Service Name',
      dataIndex: 'Service',
      key: 'Service',
      sorter: (a, b) => sortData(a?.Service, b?.Service),
      width: 130,
    },
    {
      title: 'Milestone',
      dataIndex: 'Milestone',
      key: 'Milestone',
      sorter: (a, b) => sortData(a.Milestone, b.Milestone),
      width: 120,
    },
    {
      title: 'Integration Manager',
      dataIndex: 'IntegrationManager',
      key: 'IntegrationManager',
      render: (_, row) => {
        let remaining = 0;
        if (row?.IntegrationManager?.length > 1) {
          remaining = row?.IntegrationManager?.length - 1;
        }

        if (row?.IntegrationManager?.length > 1) {
          return (
            <>
              {row?.IntegrationManager?.slice(0, 1)}
              <Popover
                content={row?.IntegrationManager?.slice(
                  1,
                  row?.IntegrationManager?.length
                ).join(', ')}
              >
                <span className='leftMoreSpace'>
                  <Tag color={'#108ee9'}>+ {remaining} more</Tag>
                </span>
              </Popover>
            </>
          );
        }
        return row?.IntegrationManager?.toString();
      },
      sorter: (a, b) => sortData(a.IntegrationManager, b.IntegrationManager),
      width: 170,
    },
    {
      title: 'Actions',
      dataIndex: 'Action',
      key: 'Action',
      width: 150,
      align: 'center',
      render: (data, row) => {
        return (
          <OutlineButton
            className='normalButton viewButton'
            onClick={() => onClickViewBtn(data, row)}
          >
            View Project
          </OutlineButton>
        );
      },
    },
  ];

  const hideModalPopup = () => {
    setShowModal(false);
    // setError({
    //   CustomerRefernce: undefined,
    //   TSPName: undefined
    // });

  };
  // const onManangeClick = (data, row) => {
  //   setRow(row);
  //   setFormData({
  //     CustomerRefernce: '',
  //     TSPName: ''
  //   });
  //   setShowModal(true);
  // };
  const onFormChange = (value, fieldName) => {
    // let letters = /[^a-zA-Z0-9. ]/;
    // const errors = error;
    // if (value === '') {
    //   errors[fieldName] = `Please enter ${formField}.`;
    // } else if (value.match(letters)) {
    //   errors[fieldName] = 'Special charecters are not allowed.';
    // } else {
    //   delete errors[fieldName];
    // }

    // setError(errors);
    setFormData((prevState) => ({ ...prevState, [fieldName]: value }));
  };

  const columns = [
    {
      title: 'Partner Name',
      dataIndex: 'Partner',
      key: 'Partner',
      width: 150,
      sorter: (a, b) => sortData(a.Partner, b.Partner),
      render: (text) => {
        return <div className='boldText'>{text}</div>;
      },
    },
    {
      title: 'Product Name',
      dataIndex: 'Product',
      key: 'Product',
      sorter: (a, b) => sortData(a.Product, b.Product),
      width: 180,
    },
    {
      title: 'Service Name',
      dataIndex: 'Service',
      key: 'Service',
      sorter: (a, b) => (a?.Service || '').localeCompare(b?.Service || ''),
      width: 150,
    },
    {
      title: 'Partner Contact',
      dataIndex: 'PartnerContact',
      key: 'PartnerContact',
      sorter: (a, b) => sortData(a.PartnerContact, b.PartnerContact),
      width: 250,
    },
    {
      title: 'Request Date',
      dataIndex: 'RequestDate',
      key: 'RequestDate',
      sorter: (a, b) => sortData(a.RequestDate, b.RequestDate),
      width: 150,
      align: 'right',
    },
    {
      title: 'Actions',
      dataIndex: 'partnerId',
      key: 'partnerId',
      width: 250,
      align: 'center',
      render: (data, row) => {
        return (
          <div className='btnActions'>
            {userDetails?.partnerName ? (
              <OutlineButton
                className='normalButton acceptBtn'
                onClick={() => onCancelClick(data, row)}
              >
                Cancel
              </OutlineButton>
            ) : (
              <>
                <OutlineButton
                  className='normalButton acceptBtn'
                  onClick={() => onClickAccept(data, row)}
                // onClick={() => onManangeClick(data, row)}
                >
                  Accept
                </OutlineButton>
                <OutlineButton
                  className='normalButton rejectBtn'
                  onClick={() => showRejecteSubscription(data, row)}
                >
                  Reject
                </OutlineButton>
              </>
            )}
          </div>
        );
      },
    },
  ];

  const dataSource = useMemo(() => {
    return (pendingSubscription || []).map((item, key) => ({
      key,
      Partner: item?.partnerName,
      Status: item?.subscriptionStatus,
      Product: item?.productName,
      productId: item?.productId,
      Service: item?.serviceName,
      serviceId: item?.serviceId,
      PartnerContact: item?.partnerContact,
      RequestDate: item?.requestedDate,
      projectId: item?.projectId,
      partnerId: item?.partnerId,
      serviceName: item?.serviceName,
    }));
  }, [pendingSubscription]);

  const dataSubscription = useMemo(() => {
    return (allSubscription || []).map((item, key) => ({
      key,
      Partner: item?.partnerName,
      Status: item?.projectStatus,
      Product: item?.productName,
      productId: item?.productId,
      serviceId: item?.serviceId,
      Service: item?.serviceName,
      Milestone: item?.milestone,
      IntegrationManager: item?.integrationManager,
      projectId: item?.projectId,
      rejectedReason: item?.rejectedReason,
    }));
  }, [allSubscription]);

  const tabs = useMemo(() => {
    return [
      {
        name: 'Pending',
        key: 'Pending',
        count: statusCount?.Pending || 0,
        img: 'https://d1lgeay9zr70r7.cloudfront.net/IntegrationManagementProject/Pending.png',
      },
      {
        name: 'Rejected',
        key: 'Rejected',
        count: statusCount?.Rejected || 0,
        img: 'https://d1lgeay9zr70r7.cloudfront.net/IntegrationManagementProject/Rejected.png',
      },
      {
        name: 'In Progress',
        key: 'InProgress',
        count: statusCount?.['In Progress'] || 0,
        img: 'https://d1lgeay9zr70r7.cloudfront.net/IntegrationManagementProject/InProgress.png',
      },
      {
        name: 'Ready for Production',
        key: 'ReadyforProduction',
        count: statusCount?.['Ready For Production'] || 0,
        img: 'https://d1lgeay9zr70r7.cloudfront.net/IntegrationManagementProject/ReadyForProduction.png',
      },
      {
        name: 'Production Release',
        key: 'FullProduction',
        count: statusCount?.['Production Release'] || 0,
        img: 'https://d1lgeay9zr70r7.cloudfront.net/IntegrationManagementProject/FullProduction.png',
      },
    ];
  }, [statusCount]);

  const contactOption = useMemo(() => {
    if (projectView?.partnerContacts?.length > 0) {
      return projectView?.partnerContacts?.map((data) => ({
        label: data?.contactType,
        value: data?.contactType,
      }));
    }
    return [];
  }, [projectView]);

  const managerList = useMemo(() => {
    if (projectView?.integrationManager?.length > 0) {
      return projectView?.integrationManager?.map((data) => ({
        label: data?.name,
        value: data?.name,
      }));
    }
    return [];
  }, [projectView]);

  useEffect(() => {
    setmanagers(managerList?.length > 0 ? managerList[0]?.label : null);
  }, [managerList]);

  useEffect(() => {
    if (Object?.keys(projectView)?.length > 0) {
      if (projectView?.partnerContacts?.length > 0) {
        setSelectedContact(
          projectView?.partnerContacts[0]?.contactType || null
        );
        setContactList(projectView?.partnerContacts[0]?.contacts || []);
      }
      setOtherData({
        accessKey: projectView?.apiAccessKey || '',
        hardwareVersion: projectView?.hardwareVersion || '',
        ecmCode: projectView?.CD_ECM_codeReport || '',
        registrationStatus: projectView?.registrationStatus || '',
        subscription: `Subscription approved on ${projectView?.approvedDate || ''} by ${projectView?.approvedUser || ''}`,
      });
    }
  }, [projectView]);

  const onClickTabs = (item) => {
    setActiveTabs(item.name);
  };

  useEffect(() => {
    dispatchApiProject(partnerId, filter, activeTabs, userDetails?.unique_name || '', userDetails?.partnerName);
  }, [partnerId, filter, activeTabs]);

  useEffect(() => {
    if (projectId !== null) {
      dispatcProductView(projectId);
    }
  }, [projectId]);

  const onClickViewBtn = (data, row) => {
    setProjectId(row?.projectId);
    setViewBlock(true);
  };

  const onSearchChange = (e) => {
    setSerachValue(e?.target?.value || '');
  };

  const onSearch = (value) => {
    setParams((prevState) => ({ ...prevState, filter: value }));
  };


  const onChangeView = (view) => {
    setParams((prevState) => ({ ...prevState, partnerId: view }));
    setSelectedView(view);
  };

  const hideRejecteSubscription = () => {
    setRejectSubscription(false);
    setDescErr(false);
  };

  const showRejecteSubscription = (data, row) => {
    setRejectSubscription(true);
    setCurrentRow(row);
  };

  const onBlur = () => {
    setParams((prevState) => ({ ...prevState, filter: searchValue }));
  };
  const onEnter = () => {
    setParams((prevState) => ({ ...prevState, filter: searchValue }));
  };


  const onClickAccept = (data, row) => {
    // let letters = /^[\w\d\s@;:.,-/$/]*$/;
    // let errors = {};
    // if (formData?.CustomerRefernce === '') {
    //   errors['CustomerRefernce'] = 'Please enter customer reference.';
    // } else if (!formData?.CustomerRefernce.match(letters)) {
    //   errors['CustomerRefernce'] = 'Special charecters are not allowed.';
    // }

    // if (formData?.TSPName === '') {
    //   errors['TSPName'] = 'Please enter TSP name.';
    // } else if (!formData?.TSPName.match(letters)) {
    //   errors['TSPName'] = 'Special charecters are not allowed.';
    // }

    // setError(errors);
    const body = {
      actionType: 'A',
      partnerId: row?.partnerId,
      partnerName: row?.Partner,
      product_id: row?.productId,
      emailId: userDetails?.unique_name,
      services: [row?.serviceId],
      productName: row?.Product,
      serviceName: row?.serviceName,
      customerReferenceNumber: formData?.CustomerRefernce,
      tspName: formData?.TSPName
    };
    // if (Object.keys(errors).length === 0) {
    //   dispatchSubmitSubscription(body, params);
    //   setShowModal(false);
    // }
    dispatchSubmitSubscription(body, params);
    setShowModal(false);
  };

  const onRejecteSubscription = (row) => {
    const body = {
      actionType: 'R',
      partnerId: row?.partnerId,
      partnerName: row?.Partner,
      product_id: row?.productId,
      emailId: userDetails?.unique_name,
      services: [row?.serviceId],
      productName: row?.Product,
      rejection_reason: row?.reason || '',
      serviceName: row?.serviceName,
    };
    dispatchSubmitSubscription(body, params);
    hideRejecteSubscription();
  };

  const onCancelClick = (data, row) => {
    const body = {
      actionType: 'C',
      partnerId: row?.partnerId,
      partnerName: row?.Partner,
      product_id: row?.productId,
      emailId: userDetails?.unique_name,
      services: [row?.serviceId],
      productName: row?.Product,
      rejection_reason: row?.reason || '',
      serviceName: row?.serviceName,
    };
    dispatchSubmitSubscription(body, params, true);
  };

  const breadcrumbOnClick = () => {
    if (!projectDataChanged) {
      setViewBlock(false);
    } else {
      dispatchToggleAlertPopup(true, false, () =>
        dispatchToggleAlertPopup(false, false, null)
      );
    }
  };

  const onRejectModalClose = () => {
    hideRejecteSubscription();
    setDescErr(false);
    setRejectDesc('');
  };

  const onClickReject = () => {
    if (rejectDesc === '') {
      setDescErr(true);
    } else {
      setDescErr(false);
      onRejecteSubscription({ ...currentRow, reason: rejectDesc });
    }
  };

  const onClickClear = () => {
    setParams({ partnerId: '', filter: '' });
    setActiveTabs('');
    setSerachValue('');
    setSelectedView('All Partners');
  };

  const onFileUpload = (file) => {
    dispatchIsProjectDataChanged(true);
    setIsUpload(true);
    dispatchUploadProjectDOc(file, projectView?.projectId);
  };

  const onDeleteFile = (key) => {
    dispatchIsProjectDataChanged(true);
    setIsUpload(true);
    dispatchDeleteProjectDoc(key);
  };

  const onDownloadFile = (key) => {
    window.open(`${projectView?.basePath}${key}`, '_blank');
  };

  const onContactSelectChange = (value) => {
    setSelectedContact(value);
    const currentRow = projectView?.partnerContacts?.find(
      (o) => o?.contactType === value
    );
    setContactList(currentRow?.contacts || []);
  };

  const onChangeOtherData = (value, name) => {
    dispatchIsProjectDataChanged(true);
    setOtherData((prevState) => ({ ...prevState, [name]: value }));
  };

  const selectedManagers = useMemo(() => {
    const data = projectView?.integrationManager?.filter(
      (o) => o?.name === managers
    );
    return data;
  }, [projectView, managers]);

  const onManagerChange = (value) => {
    setmanagers(value);
  };

  const onClickSave = (milestone) => {
    dispatchIsProjectDataChanged(false);
    dispatchToggleAlertPopup(false, false, null);
    let data = {
      projectId: projectView?.projectId,
      hardwareVersion: otherData?.hardwareVersion,
      CD_ECM_codeReport: otherData?.ecmCode,
      registrationStatus: otherData?.registrationStatus,
      isDocumentUpdated: isUpload ? 'Yes' : 'No',
      projectDocument: projectView?.projectDocument || [],
      isMilestoneUpdated: milestone?.length > 0 ? 'Yes' : 'No',
      milestones: milestone.map((mile) => {
        return {
          ...mile,
          expectedCompletionDate:
            mile?.expectedCompletionDate &&
              typeof mile?.expectedCompletionDate === 'string'
              ? mile?.expectedCompletionDate
              : mile?.expectedCompletionDate &&
                typeof mile?.expectedCompletionDate === 'object'
                ? moment(mile?.expectedCompletionDate).format('DD/MM/YYYY')
                : null,
        };
      }),
    };

    dispatchSetProjectMilestone(data);
  };

  const onCloseModal = () => {
    dispatchToggleAlertPopup(false, false, null);
  };

  const onCloseModalClick = () => {
    setViewBlock(false);
    dispatchIsProjectDataChanged(false);
    alertPopup?.callback();
  };

  const onRegTextChange = (value) => {
    if (value === '') {
      setDescErr(true);
    } else {
      setDescErr(false);
    }
    setRejectDesc(value);
  };

  return (
    <>
      <ApiProject
        dataSource={dataSource}
        columns={columns}
        searchValue={searchValue}
        onSearchChange={onSearchChange}
        onSearch={onSearch}
        onBlur={onBlur}
        onEnter={onEnter}
        onClickTabs={onClickTabs}
        activeTabs={activeTabs}
        viewBlock={viewBlock}
        breadcrumbOnClick={breadcrumbOnClick}
        selectedView={selectedView}
        onChangeView={onChangeView}
        columnsSubscription={columnsSubscription}
        dataSubscription={dataSubscription}
        loading={loading}
        tabs={tabs}
        rejectSubscription={rejectSubscription}
        hideRejecteSubscription={hideRejecteSubscription}
        onRejecteSubscription={onRejecteSubscription}
        currentRow={currentRow}
        partners={partners}
        userDetails={userDetails}
        pagination={pagination}
        onPaginationChange={(page) => setPagination(page)}
        AllPagination={AllPagination}
        onAllPaginationChange={(page) => setAllPagination(page)}
        rejectDesc={rejectDesc}
        setRejectDesc={setRejectDesc}
        descErr={descErr}
        setDescErr={setDescErr}
        onClickClose={onRejectModalClose}
        onClickReject={onClickReject}
        onTextAreaChange={onRegTextChange}
        onClickClear={onClickClear}
        onFileUpload={onFileUpload}
        onDeleteFile={onDeleteFile}
        viewData={projectView}
        contactOption={contactOption}
        selectedContact={selectedContact}
        contactList={contactList}
        onContactSelectChange={onContactSelectChange}
        otherData={otherData}
        onChangeOtherData={onChangeOtherData}
        onClickSave={onClickSave}
        onDownloadFile={onDownloadFile}
        onManagerChange={onManagerChange}
        managerList={managerList}
        managers={managers}
        selectedManagers={selectedManagers}
        isAdmin={props?.isAdmin}
        isDataChanged={dispatchIsProjectDataChanged}
        showModal={showModal}
        hideModalPopup={hideModalPopup}
        formData={formData}
        onFormChange={onFormChange}
        onClickAccept={onClickAccept}
        // error={error}
      />
      <Modal
        visible={alertPopup?.status}
        hideModal={onCloseModal}
        closeModalPopup={onCloseModal}
        title={''}
        className='Deactivate'
        width={550}
        footer={
          <div className='viewBlock'>
            <OutlineButton
              className='normalButton viewButton'
              onClick={onCloseModal}
            >
              Cancel
            </OutlineButton>
            <Button
              className={'normalButton viewButton'}
              onClick={onCloseModalClick}
            >
              Exit
            </Button>
          </div>
        }
        centered
      >
        Unsaved changes identified, Are your sure want to close?
      </Modal>
    </>
  );
};

export default ApiProjectContainer;
