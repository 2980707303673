import React, { useEffect, useState } from 'react';
import Method from './Method';
import './AvailableMethods.scss';

const AvailableMethods = (props) => {
  const content = props?.content ?? {};
  const heading = content?.heading ?? '';
  const title = content?.title ?? '';
  const methods = content.methods ?? [];
  const [contentValue, setContentValue] = useState(content?.method1 ?? {});

  useEffect(() => {
    let methodContent = content[props.methodSelected];
    setContentValue(methodContent);
  }, [props.methodSelected, content]);

  return (
    <>
      <div className='splitLine'></div>
      <div
        className={`availableMethods ${
          content.className == 'white' ? 'white' : ''
        }`}
      >
        {heading ? <p className='caption'>{heading}</p> : null}
        {title ? <p className='title'>{title}</p> : null}
        <div className={`${methods.length ? 'split' : ''}`}></div>
        {methods.length ? (
          <div className='methods'>
            {methods.map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={() => props.methodClick(item.key)}
                  className={`${
                    props.methodSelected === item.key ? 'active' : ''
                  }`}
                >
                  {item.title}
                </div>
              );
            })}
          </div>
        ) : null}
        <div className={`${methods.length ? 'split' : ''}`}></div>
        

        <Method
          editable={props?.editable}
          onClickField={props?.onClickField}
          onSaveWebContent={props?.onSaveWebContent}
          onHideEditContent={props?.onHideEditContent}
          methodContent={contentValue}
          methodLength={methods.length}
          editField={props?.editField}
          onImageUpload={props?.onImageUpload}
        />
      </div>
    </>
  );
};

export default AvailableMethods;
