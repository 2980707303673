import React from 'react';
import Table from './Table';

const TableContainer = (props) => {
  return (
    <Table
      heading={props?.heading}
      dataSource={props.dataSource}
      columns={props.columns}
      scroll={{ x: 'max-content' }}
      loading={props?.loading}
      onChangeEvent={props?.onChangeEvent}
      pagination={props?.pagination}
    />
  );
};

export default TableContainer;
