import React from 'react';
import ExternalResponseAPI from './ExternalResponseAPI';

const ExternalResponseContainer = (props) => {
  const content = (
    <div>
      <p>client_id=&lt;clientId&gt;&amp;client_secret=&lt;clientSecret&gt;&amp;scope=&lt;scope&gt;</p>
      <p>username=&lt;username&gt;&amp;password=&lt;password&gt;&amp;grant_type=&lt;grantType&gt;</p>
    </div>
  );
  

  const optionsValue = [{
    value: 'REST',
    label: 'REST'
  }, {
    value: 'SOAP',
    label: 'SOAP'
  }, {
    value: 'Azure-Event-Hub',
    label: 'Azure-Event-Hub'
  }];

  const fileTypeOptions = [{
    value: 'JSON',
    label: 'JSON'
  },
  {
    value: 'XML',
    label: 'XML'
  }
  ];

  const locationOptions = [{
    value: 'header',
    label: 'header'
  },
  {
    value: 'params',
    label: 'params'
  }
  ];

  const authMethodOptions = [
    {
      value: 'No Auth',
      label: 'No Auth'
    },
    {
      value: 'OAuth',
      label: 'OAuth'
    },
    {
      value: 'x-Api-Key',
      label: 'x-Api-Key'
    },
    {
      value: 'SOAPCredentials',
      label: 'SOAPCredentials'
    },
    {
      value: 'mTLS',
      label: 'mTLS'
    }
  ];

  return(
    <ExternalResponseAPI
      onFormChange={props.onFormChange}
      formData={props.formData}
      error={props.error}
      optionsValue={optionsValue}
      fileTypeOptions={fileTypeOptions}
      locationOptions={locationOptions}
      authMethodOptions={authMethodOptions}
      content={content}
      partners={props?.partners}
      editMode={props?.editMode}
      dropdownsChange={props?.dropdownsChange}
      prodOptions={props?.prodOptions}
      onChangeProducts={props?.onChangeProducts}
      serviceOptions={props?.serviceOptions}
      dropdownLoader={props?.dropdownLoader}
      prognosticErr={props?.prognosticErr}
    />
  );
};

export default ExternalResponseContainer;