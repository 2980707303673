import React, { useState } from 'react';
import {
  OutlineButton,
  Button,
  Table,
  Modal,
  Spinner,
} from '../../../../../../../CommonComponents';
import Edit from '../../../../../../../../assets/images/edit.png';
import Add from '../../../../../../../../assets/images/smallAdd.png';
import MainContent from '../../../../../MainContent';
import Header from '../../../../../../../../components/Home/BaseLayout/Layout/Header';
import Footer from '../../../../../../../../components/Home/BaseLayout/Layout/Footer';
import { useSelector } from 'react-redux';
import { selectors as appSelector } from '../../../../../../../../state/features/app';

const CommonContent = (props) => {
  const content = props.content ?? {};
  const heading = content?.heading ?? '';
  const subHeading = content?.subHeading ?? '';
  const addBtnName = content?.addBtnName ?? '';
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    pageSizeOptions: [5, 10, 20, 50, 100],
    showSizeChanger: true,
  });

  const { contentFetchingState } = useSelector((state) => ({
    contentFetchingState: appSelector.contentFetchingState(state),
  }));

  // contentFetchingState

  const getEditBtnName = () => {
    if (props.screen == 'Product') {
      return 'Edit Home Webpage';
    } else if (props.screen == 'Service') {
      return 'Edit Product Webpage';
    }
    return 'Edit Service Webpage';
  };

  return (
    <div className=''>
      <div className='clearfix headerblock'>
        <div className='float-left'>
          <span className='heading'>{heading}</span>
          <p className='topic'>{subHeading}</p>
        </div>
        <div className='float-right buttonTools'>
          <div>
            <OutlineButton
              className='buttonSpace normalButton'
              onClick={() => props.onShowModal(heading)}
            >
              <img src={Edit} alt='editIcon' className='editIcon' />
              {getEditBtnName()}
            </OutlineButton>

            {/* <Button className='normalButton' onClick={props?.onClickPublish}>
              Publish Changes
            </Button> */}
          </div>
        </div>
      </div>
      <Table
        rowKey={props.tableKey}
        dataSource={props.dataSource}
        columns={props.columns}
        scroll={{ x: 'max-content' }}
        showSorterTooltip={true}
        pageSizeOptions={[5, 10, 20, 50, 100]}
        onChangeEvent={(pageData) => setPagination(pageData)}
        pagination={pagination}
        loading={props.loading}
      />
      <OutlineButton
        className='addbtn buttonSpace normalButton'
        onClick={props.onAddModal}
      >
        <img src={Add} alt='addIcon' className='addIcon' />
        {`Add ${addBtnName}`}
      </OutlineButton>
      <Modal
        visible={props.visible}
        hideModal={props.onHideModal}
        closeModalPopup={props.onHideModal}
        title={props?.title}
        width={'95%'}
        footer={null}
        className='editableContainer'
        closable={false}
      >
        <Spinner spinning={contentFetchingState} tip='Loading'>
          <div className='editModal'>
            <Header
              editable={props.visible}
              onHideModal={props.onHideModal}
              onPublishClick={props?.onPublishClick}
              onClickPrevious={props?.onClickPrevious}
              previousClick={props?.previousClick}
              sections={props.webContent?.sections}
              // onWebFieldChange={props?.onWebFieldChange}
            />
            <div className='mainContent'>
              {props?.webContent ? (
                <MainContent
                  bodyContent={props.webContent?.sections}
                  editable={props.visible}
                  onWebFieldChange={props?.onWebFieldChange}
                  onHideEditContent={props?.onHideEditContent}
                  onSaveWebContent={props?.onSaveWebContent}
                  onClickField={props?.onClickField}
                  editField={props?.editField}
                  fieldKey={props?.fieldKey}
                  showEditContent={props?.showEditContent}
                  editText={props?.editText}
                  setEditText={props?.setEditText}
                  showLink={props?.showLink}
                  editLink={props?.editLink}
                  setEditLink={props?.setEditLink}
                  productIds={props?.productIds}
                  isLink={props?.isLink}
                  editFieldLink={props?.editFieldLink}
                  onImageUpload={props?.onImageUpload}
                  onVideoUpload={props?.onVideoUpload}
                  onMethodImgUpload={props?.onMethodImgUpload}
                  enableDelete={props?.enableDelete}
                  onWebDocDelete={props?.onWebDocDelete}
                  onFileUpload={props?.onFileUpload}
                  onTileSave={props?.onTileSave}
                  apiId={props?.apiId}y
                  isAdmin={props?.isAdmin}
                />
              ) : <p>
                <span className='noDataFound'>No Data Found</span>
              </p>
              }
            </div>
            <Footer editable={props.visible} />
          </div>
        </Spinner>
      </Modal>

      <Modal
        visible={props.closeEditModal}
        hideModal={props?.onCloseModal}
        closeModalPopup={props?.onCloseModal}
        title={props.status}
        className='Deactivate'
        width={550}
        footer={
          <div className='viewBlock'>
            <OutlineButton
              className='normalButton viewButton'
              onClick={props?.onClickModalClose}
            >
              Cancel
            </OutlineButton>
            <Button
              className={'normalButton viewButton'}
              onClick={props.onCloseModal}
            >
              Exit
            </Button>
          </div>
        }
        centered
      >
        Unsaved changes identified, Are your sure want to close?
      </Modal>
    </div>
  );
};

export default CommonContent;
