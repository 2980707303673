import React from 'react';
import TitleSection from './TitleSection';

const TitleSectionContainer = (props) => {
  return (
    <TitleSection
      partners={props?.partners}
      userDetails={props?.userDetails}
      selectedView={props?.selectedView}
      onChangeView={props?.onChangeView}
      onSearchChange={props?.onSearchChange}
      onSearch={props?.onSearch}
      searchValue={props?.searchValue}
      onEnter={props?.onEnter}
      onBlur={props?.onBlur}
      onClickClear={props?.onClickClear}
    />
  );
};

export default TitleSectionContainer;
