const sortData = (a, b) => {
  try {
    if ((a || a >= 0) && (b || b >= 0)) {
      if (typeof a === 'string' && typeof b === 'string') {
        return (a || '').localeCompare(b || '');
        // if (a.toUpperCase() > b.toUpperCase()) {
        //   console.info('a is greater ', a.toUpperCase());
        //   return -1;
        // }
        // if (a.toUpperCase() < b.toUpperCase()) {
        //   console.info('b is greater ', b.toUpperCase());
        //   return 1;
        // }
      }
      // return a - b;
      return a.localeCompare(b, undefined, {numeric: true});
    }
    return 0;
  } catch (error) {
    console.error(error.message, 'error');
  }
};

export default sortData;
