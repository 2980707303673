import React from 'react';
import { RowComponent, ColComponent,OutlineButton, 
  Modal, /* Text, */ Button, Upload,/* MessageComponent */ } from '../../../../CommonComponents';
// import { UploadOutlined } from '@ant-design/icons';
import Edit from '../../../../../assets/images/edit.png';
import Image from '../../../../../assets/images/image.png';

import Specifications from '../../Specifications';
// import Docs from '../../Docs';
import './Method.scss';

const Method = (props) => {
  // const uploadProps = {
  //   name: 'file',
  //   action: '',
  //   headers: {
  //     authorization: 'authorization-text',
  //   },
  //   onChange(info) {
  //     if (info.file.status === 'done') {
  //       MessageComponent(
  //         `${info.file.name} file uploaded successfully`,
  //         'success'
  //       );
  //     } else if (info.file.status === 'error') {
  //       MessageComponent(`${info.file.name} file upload failed.`, 'success');
  //     }
  //   },
  // };
  const editUI = (value, field, key) => {
    return props.editable ? <div className='editableTools'><div className='titleBadge'>{props.badge}</div>
      <Button className='normalButton viewButton' onClick={() =>props.onClickField(value, field, key)}>
        <img src={Edit} alt='editImg' className='editImage'/>Edit</Button></div>
      : null;
  };

  const editImage = () =>{
    return props.editable ? <div className='editableTools'><div className='titleBadge'>Image</div>
      <Button className='normalButton viewButton' onClick={props.onEditBannerImage}>
        <img src={Edit} alt='editImg' className='editImage'/>Edit</Button></div>: null;
  };

  return <div className={`methodsBlock ${props.methodLength? '': 'topnone' }`}>
    <RowComponent> 
      <ColComponent xs={24} md={24} lg={17}>
        <div className='overviewBlock'>
          <p className='overviewText' onMouseOver={()=>{props.onMouseOver('title');}}>
            {editUI(props.title, 'method1', 'title')}
            {props.title}
          </p>
          <p className='oviewview' onMouseOver={()=>{props.onMouseOver('overview');}}>
            {editUI(props.overview, 'method1', 'overview')}
            {props.overview}
          </p>
          {props.methodContent?.img ? <div className='methodImageBlock'>
            {editImage()}
            <div className={`${props.editable ? 'editable': ''}`}><img src={typeof props.methodContent?.img === 'string' ? props?.methodContent?.img : window?.URL?.createObjectURL(props.methodContent?.img)} className={'methodImg'} alt='method'/>
            </div>
          </div> : null}
        </div>
      </ColComponent>
      <ColComponent xs={24} md={24} lg={7}>
        {
          Object.keys(props.specifications).length ? 
            <Specifications data={props.specifications}/>: null
        }
      </ColComponent>
    </RowComponent>
    <Modal
      visible={props.showEditBanner}
      hideModal={props.onHideEditBannerImage}
      closeModalPopup={props.onHideEditBannerImage}
      title={''}
      width={400}
      footer={
        <div className='editBannerFooter'>
          <OutlineButton
            className='normalButton viewButton'
            onClick={props?.onHideEditBannerImage}
          >
            Cancel
          </OutlineButton>
          <Button
            className={'normalButton viewButton'}
            onClick={props?.onSaveBannerImg}
          >
            Save
          </Button>
        </div>
      }
      centered  
      className='bannerImageModal'
      closable={false}
    >
      <div className='imageContainer'>
        <div className='imageList'>
          <div className='imageBlock'>
            <img src={typeof props.previewImg === 'string' ? props?.previewImg : window?.URL?.createObjectURL(props.previewImg)} alt='imagePreview' className='imagePreview'/>
          </div>
          <div className='imageName'>
            <span>{props.fileName}</span>
          </div>
        </div>
      </div>
      <Upload 
        icon={<img src={Image} 
          alt='add-icon' 
          className='imageIcon' />}
        text={'Replace Image'} 
        uploadProps={props.uploadProps}/>
    </Modal>
  </div>;
};

export default Method;