import React from 'react';
import './Details.scss';

const Details = (props) => {
  const content = props.content ?? {};
  const title = content?.title ?? '';
  const subHeading = content?.subHeading ?? '';
  const body = content?.body ?? '';
  const card = content?.card ?? '';

  return <div className='details'>
    <div className='details-header'>
      <div>{title}</div>
    </div>
    <div className='details-body'>
      <div className='details-subheading'>{subHeading}</div>
      <div className='details-body'>{body}</div>
    </div>
    <div className='details-card'>
      <div>{card}</div>
    </div>
  </div>;
};

export default Details;