import {
  addEditPartner,
  deleteProjectDoc,
  getApiEndPoints,
  getApiProject,
  getApiPublish,
  getApprovalsList,
  getCertifiedProducts,
  getCreateApi,
  getCreateProduct,
  getCreateService,
  getHistoryList,
  getProductServiceAPI,
  getProducts,
  getProjectPartners,
  getProjectPartnersSubscriptions,
  getProjectView,
  getRegHistoryResp,
  getRoleUsers,
  isProjectDataChanged,
  onClickApproval,
  onClickReject,
  savePermissions,
  setProducts,
  setProjectMileStone,
  setPublishChanges,
  subscribeProducts,
  toggleAlertPopup,
  updateRegExtResp,
  updateWebcontent,
  uploadProjectDoc,
} from './actions';

const dispatchGetProdServiceApi = (dispatch) => (entityDetails) => {
  dispatch(getProductServiceAPI(entityDetails));
};
const dispatchCreateProductApi = (dispatch) => (body, isEditMode, file) => {
  dispatch(getCreateProduct(body, isEditMode, file));
};
const dispatchCreateServiceApi = (dispatch) => (body, isEditMode) => {
  dispatch(getCreateService(body, isEditMode));
};
const dispatchAPIcreateApi = (dispatch) => (body, productId, isEditMode) => {
  dispatch(getCreateApi(body, productId, isEditMode));
};
const dispatchApiEndpointsApi = (dispatch) => () => {
  dispatch(getApiEndPoints());
};
const dispatchGetApiPublish = (dispatch) => (body) => {
  dispatch(getApiPublish(body));
};
const dispatchGetApiProject = (dispatch) => (partnerId, filter, status, email, partnerName) => {
  dispatch(getApiProject(partnerId, filter, status, email, partnerName));
};
const dispatchGetProjectPartners = (dispatch) => (searchValue, dropdown = false) => {
  dispatch(getProjectPartners(searchValue, dropdown));
};
const dispatchGetRolesUsers = (dispatch) => () => {
  dispatch(getRoleUsers());
};
const dispatchGetProjectPartnerSubscriptions = (dispatch) => (partnerId) => {
  dispatch(getProjectPartnersSubscriptions(partnerId));
};
const dispatchUploadProjectDoc = (dispatch) => (data, id, fromWebContent, isCurrent, schema) => {
  dispatch(uploadProjectDoc(data, id, fromWebContent, isCurrent, schema));
};
const dispatchDeleteProjectDoc = (dispatch) => (key, fromContent, route, resetDoc) => {
  dispatch(deleteProjectDoc(key, fromContent, route, resetDoc));
};

const dispatchSetPublishChanges = (dispatch) => (data, productIds) => {
  dispatch(setPublishChanges(data, productIds));
};
const dispatchGetProjectView = (dispatch) => (data, productIds) => {
  dispatch(getProjectView(data, productIds));
};
const dispatchSetProjectMilestone = (dispatch) => (data, productIds) => {
  dispatch(setProjectMileStone(data, productIds));
};
const dispatchUpdateWebcontent = (dispatch) => (data, ids, schema, isImageUpdated, isVideoUpdated, basePath, isMethodImgUpdated) => {
  dispatch(updateWebcontent(data, ids, schema, isImageUpdated, isVideoUpdated, basePath, isMethodImgUpdated));
};
const dispatchSavePermissions = (dispatch) => (data) => {
  dispatch(savePermissions(data));
};

const dispatchToggleAlertPopup = (dispatch) => (status, fromNav = false, callback) => {
  dispatch(toggleAlertPopup(status, fromNav, callback));
};
const dispatchIsProjectDataChanged = (dispatch) => (status) => {
  dispatch(isProjectDataChanged(status));
};
const dispatchAddEditPartner = (dispatch) => (body) => {
  dispatch(addEditPartner(body));
};
const dispatchClickReject = (dispatch) => (body) => {
  dispatch(onClickReject(body));
};
const dispatchOnClickApproval = (dispatch) => (body) => {
  dispatch(onClickApproval(body));
};
const dispatchGetApprovalsList = (dispatch) => (body) => {
  dispatch(getApprovalsList(body));
};
const dispatchGetHistoryList = (dispatch) => (body) => {
  dispatch(getHistoryList(body));
};
const dispatchGetRegHistoryResp = (dispatch) => (partnerName) => {
  dispatch(getRegHistoryResp(partnerName));
};
const dispatchUpdatetRegHistoryResp = (dispatch) => (body) => {
  dispatch(updateRegExtResp(body));
};
const dispatchGetCertifiedProducts = (dispatch) => () => {
  dispatch(getCertifiedProducts());
};
const dispatchGetProducts = (dispatch) => (id, dropdown = false) => {
  dispatch(getProducts(id, dropdown));
};
const dispatchSubscribeProducts = (dispatch) => (body) => {
  dispatch(subscribeProducts(body));
};
const dispatchSetProducts = (dispatch) => (data) => {
  dispatch(setProducts(data));
};



const operations = {
  dispatchGetProdServiceApi,
  dispatchCreateProductApi,
  dispatchCreateServiceApi,
  dispatchApiEndpointsApi,
  dispatchAPIcreateApi,
  dispatchGetApiPublish,
  dispatchGetApiProject,
  dispatchGetProjectPartners,
  dispatchGetProjectPartnerSubscriptions,
  dispatchGetRolesUsers,
  dispatchUploadProjectDoc,
  dispatchSetPublishChanges,
  dispatchGetProjectView,
  dispatchSetProjectMilestone,
  dispatchDeleteProjectDoc,
  dispatchUpdateWebcontent,
  dispatchSavePermissions,
  dispatchToggleAlertPopup,
  dispatchIsProjectDataChanged,
  dispatchAddEditPartner,
  dispatchClickReject,
  dispatchOnClickApproval,
  dispatchGetApprovalsList,
  dispatchGetHistoryList,
  dispatchGetRegHistoryResp,
  dispatchUpdatetRegHistoryResp,
  dispatchGetCertifiedProducts,
  dispatchGetProducts,
  dispatchSubscribeProducts,
  dispatchSetProducts
};

export default operations;
