import React from 'react';
import { Upload, Button } from 'antd';
import './Upload.scss';

const UploadComponent = (props) => {
  return (
    <Upload {...props.uploadProps}>
      <Button icon={props.icon}>{props.text}</Button>
    </Upload>
  );
};

export default UploadComponent;
