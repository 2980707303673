import React from 'react';
import AddManualChildren from './AddManualChildren';

const AddManualChildrenContainer = (props) => {
  return (
    <AddManualChildren
      environmentOption={props.environmentOption}
      statusOption={props.statusOption}
      visible={props.visible}
      hideModal={props.hideModal}
      closeModalPopup={props.closeModalPopup}
      title={props?.title}
      width={props?.width}
      onHideManually={props?.onHideManually}
      onSaveAddManually={props?.onSaveAddManually}
    />
  );
};

export default AddManualChildrenContainer;
