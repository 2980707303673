import React from 'react';
import AddPartner from './AddPartner';
import { useSelector } from 'react-redux';

const AddPartnerContainer = (props) => {

  const loading = useSelector((state) => state?.admin?.loading);

  return (
    <AddPartner
      hideAddPartnerPopup={props.hideAddPartnerPopup}
      showAddPartnerModal={props.showAddPartnerModal}
      radioCompanyyReg={props.radioCompanyyReg}
      radioSubscription={props.radioSubscription}
      radioDataIntegration={props.radioDataIntegration}
      formData={props.formData}
      onChangeCompanyRegEvent={props.onChangeCompanyRegEvent}
      onChangeSubscriptionEvent={props.onChangeSubscriptionEvent}
      onChangedataintegrationEvent={props.onChangedataintegrationEvent}
      optionsValue={props.optionsValue}
      productsOptions={props.productsOptions}
      onFormChange={props.onFormChange}
      companyReg={props.companyReg}
      subscriptionOption={props.subscriptionOption}
      dataIntegrationOption={props.dataIntegrationOption}
      onClickSave={props.onClickSave}
      error={props?.error}
      loading={loading}
      editMode={props?.editMode}
    />
  );
};

export default AddPartnerContainer;