import React from 'react';
import { Tabs } from 'antd';
import './Tabs.scss';

const TabComponent = ({
  defaultActiveKey = '0',
  type = 'card',
  tabComponent = [],
  onTabClick = () => {},
  className,
  editable
}) => {
  return (
    <div>
      {!editable && (
        <Tabs
          defaultActiveKey={defaultActiveKey}
          type={type}
          onTabClick={onTabClick}
          className={className}
          items={tabComponent}
        />
      )}
    </div>
  );
};

export default TabComponent;
