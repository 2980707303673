import React from 'react';
import {
  Button,
  // Dropdowns,
  // MessageComponent,
  Modal,
  OutlineButton,
  // Switch,
  Text,
  Textarea,
  Upload,
} from '../../../../../../../CommonComponents';

const EditModelChildren = (props) => {
  const {
    onFormChange,
    formData,
    statusMsg,
    onImageUpload,
    currentRow,
    onRemoveUpload,
    fileList
  } = props;

  const uploadProps = {
    name: 'file',
    headers: {
      authorization: 'authorization-text',
    },
    maxCount: 1,
    beforeUpload: () => {
      /* update state here */
      return false;
    },
    onChange(info) {
      onImageUpload(info);
    },
    // showUploadList : false,
    fileList: fileList,
    // showUploadList: props.fileImgSrc && props.fileImgSrc !== 'string',
    onRemove: () => {
      onRemoveUpload();
    }
  };


  return (
    <Modal
      visible={props.visible}
      hideModal={props.hideModal}
      closeModalPopup={props.closeModalPopup}
      title={props.title}
      width={props?.width}
      footer={
        <div className='viewBlock'>
          <OutlineButton
            className='normalButton viewButton'
            onClick={props?.onClickCancel}
          >
            Cancel
          </OutlineButton>
          <Button
            className='normalButton viewButton'
            onClick={props?.onClickSave}
          >
            {currentRow?.isPublished === 'Yes' ? 'Publish' : 'Save'}
          </Button>
        </div>
      }
      centered
    >
      <div className='editModal'>
        <div>
          <label className='label'>
            {`${props.liveScreenName} Name `}
            <span style={{ color: 'red' }}>*</span>
          </label>
          <Text
            className='productNameText'
            value={formData?.name}
            onChange={(e) => onFormChange(e.target.value, 'name')}
            statusMsg={statusMsg?.name}
          />
        </div>
        <div className='formControl'>
          <label className='label'>
            {`${props.liveScreenName} Description `}
            <span style={{ color: 'red' }}>*</span>
          </label>
          <Textarea
            className='descriptionText'
            statusMsg={statusMsg?.description}
            rows={'6'}
            value={formData?.description}
            onChangeEvent={(e) => onFormChange(e.target.value, 'description')}
          />
        </div>
        {props.liveScreenName !== 'Product' ? (
          <>
            <p className='note'>
              Making a service active will allow Partners to request to
              subscribe to the service
            </p>
          </>
        ) : null}
        {props.liveScreenName === 'Product' ? (
          <div className='formControl'>
            <label className='label'>Upload Image</label>
            {/* <Upload uploadProps={uploadProps} /> */}
            {props.fileImgSrc ? (
              <div className='imageUpload'>
                <img
                  src={
                    typeof props.fileImgSrc === 'string'
                      ? props?.fileImgSrc
                      : URL.createObjectURL(props?.fileImgSrc?.originFileObj || props?.fileImgSrc)
                  }
                  className='bannerImage'
                  alt='bannerImg'
                />
              </div>
            ) : null}

            <div className='upload-btn-wrapper'>
              <Upload uploadProps={uploadProps} text={'Upload'} />
            </div>
            <p className='required noteText'>
              Please upload the image with resolution of 330 * 330
            </p>
          </div>
        ) : null}
      </div>
    </Modal>
  );
};

export default EditModelChildren;
