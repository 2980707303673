import React from 'react';
import ProjectDocument from './ProjectDocument';

const ProjectDocumentsContainer = (props) => {
  return (
    <ProjectDocument
      userDetails={props.userDetails}
      projectDocuments={props?.projectDocuments}
      onFileUpload={props?.onFileUpload}
      onDeleteFile={props?.onDeleteFile}
      onDownloadFile={props?.onDownloadFile}
      editable={props.editable}
      title={props?.title}
      hideOptions={props?.hideOptions}
      isAdmin={props?.isAdmin || false}
      enableDocDelete={props?.enableDocDelete}
    />
  );
};

export default ProjectDocumentsContainer;
