import React, { useEffect, useState } from 'react';
import RegisteredExternalResponse from './RegistredExternalResponse';
import { useDispatch, useSelector } from 'react-redux';
import { operations as adminOps, selectors as adminSelector } from '../../../../../../../state/features/admin';
import { OutlineButton } from '../../../../../../CommonComponents';


const RegisteredExternalResponseContainer = () => {
  const heading = 'Registered External Response';
  const subHeading = 'Integration Management';
  const dispatch = useDispatch();
  const dispatchGetRegHistoryResp = adminOps.dispatchGetRegHistoryResp(dispatch);
  const dispatchUpdatetRegHistoryResp = adminOps.dispatchUpdatetRegHistoryResp(dispatch);
  // const dispatchGetProductData = adminOps.dispatchGetProdServiceApi(dispatch);
  const dispatchGetProjectPartners = adminOps.dispatchGetProjectPartners(dispatch);
  const dispatchGetProducts = adminOps.dispatchGetProducts(dispatch);
  const dispatchSetProducts = adminOps.dispatchSetProducts(dispatch);

  const {
    loading,
    regExtResp,
    partners,
    products,
    dropdownLoader
  } = useSelector((state) => ({
    loading: adminSelector.loading(state),
    regExtResp: adminSelector.regExtResp(state),
    partners: adminSelector.partners(state),
    products: adminSelector?.products(state),
    dropdownLoader: adminSelector?.dropdownLoader(state)
  }));
  const [responsePayloadValue, setResponsePayloadValue] = useState('');
  const [responsePayload, setResponsePayload] = useState(false);
  const [openEditResponse, setOpenEditReponse] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editRecord, setEditRecord] = useState({
    partnerName: '',
    productName: '',
    serviceName: '',
    AccessKey: '',
    externalResponseUrl: '',
    urlType: 'REST',
    fileType: 'JSON',
    authMethod: 'No Auth',
    accessTokenUrl: '',
    payload: '',
    header: '',
    location: 'header',
    apikey: '',
    paramName: '',
    soapEnvelope: '',
    soapHeader: '',
    soapAction: '',
    certSecretName: '',
    diagnostics: true,
    prognostics: false,
    externalId: null
  });
  const [error, setError] = useState({});
  const [activePagination, setActivePagination] = useState({
    current: 1,
    pageSize: 5,
    pageSizeOptions: [5, 10, 20, 50, 100],
    showSizeChanger: true,
  });
  const [partnerOptions, setPartnerOptions] = useState([]);
  const [prodOptions, setProdOptions] = useState([]);
  const [serviceOptions, setServiceOptions] = useState([]);
  const [currentPartner, setCurrentPartner] = useState([]);
  const [services, setServices] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [prognosticErr, setPrognosticErr] = useState(false);
  const { subscribedProducts, registeredProducts } = products;

  useEffect(() => {
    if (!editMode && editRecord?.partnerName) {
      dispatchGetProducts(editRecord?.partnerName, true);
    }
  }, [editRecord?.partnerName, editMode]);

  const columns = [
    {
      title: 'Partner Name',
      dataIndex: 'partnerName',
      key: 'partnerName',
      render: (row) => {
        return (
          <div>
            {row}
          </div>
        );
      },
      width: 200,
    },
    {
      title: 'Product Name',
      dataIndex: 'productName',
      key: 'productName',
      width: 160,
      align: 'left',
    },
    {
      title: 'Service Name',
      dataIndex: 'serviceName',
      key: 'serviceName',
      width: 180,
      align: 'left',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 160,
      align: 'left',
    },
    {
      title: 'Payload',
      dataIndex: 'apiInfo',
      key: 'apiInfo',
      align: 'left',
      width: 300,
      render(val) {
        // let val = text;
        return {
          // children: <div className='payload'>{payload}</div>
          children: <div className='payload'>{`${JSON.stringify(val)?.slice(0, 40)}${JSON.stringify(val)?.length > 40 ? '...' : ''}`}</div>
        };
      },
      onCell: (row) => {
        return {
          onClick: () => {
            setResponsePayload(true);
            setResponsePayloadValue(row?.apiInfo);
          },
        };
      },
    },
    {
      title: 'Actions',
      dataIndex: 'Action',
      key: 'Action',
      width: 200,
      align: 'center',
      render: (text, record) => {
        return (
          <>
            <OutlineButton
              className='normalButton viewButton approveBtn'
              onClick={() => {
                const payload = record?.apiInfo;
                setOpenEditReponse(true);
                setEditMode(true);
                setEditRecord({
                  partnerName: record?.partnerName || '',
                  productName: record?.productName || '',
                  serviceName: record?.serviceName || '',
                  AccessKey: payload?.AccessKey || '',
                  externalResponseUrl: payload?.externalResponseUrl || '',
                  urlType: payload?.urlType || 'REST',
                  fileType: payload?.fileType || 'JSON',
                  authMethod: payload?.authMethod || 'No Auth',
                  accessTokenUrl: payload?.authInfo?.accessTokenUrl || '',
                  // clientId: '',
                  payload: payload?.authInfo?.payload || '',
                  header: payload?.authInfo?.header || '',
                  // clientSecret: '',
                  // scope: '',
                  // password: '',
                  // userName: '',
                  location: payload?.authInfo?.location || 'header',
                  apikey: payload?.authInfo?.apiKey || '',
                  paramName: payload?.authInfo?.paramName || '',
                  soapEnvelope: payload?.authInfo?.soapEnvelope || '',
                  soapHeader: payload?.authInfo?.soapHeader || '',
                  soapAction: payload?.authInfo?.soapAction || '',
                  certSecretName: payload?.authInfo?.certSecretName || '',
                  diagnostics: payload?.diagnosticsEnabled,
                  prognostics: payload?.prognosticsEnabled,
                  externalId: record?.externalId,
                });
              }}
            >
              Edit
            </OutlineButton>
          </>
        );
      },
    }
  ];

  const onChangePagination = (data) => {
    setActivePagination(data);
  };


  const hideModal = () => {
    dispatchSetProducts([]);
    setOpenEditReponse(false);
    setError({});
    setServiceOptions([]);
    setProdOptions([]);
    setPartnerOptions([]);
    setPrognosticErr(false);
    setEditRecord({
      partnerName: '',
      productName: '',
      serviceName: '',
      AccessKey: '',
      externalResponseUrl: '',
      urlType: 'REST',
      fileType: 'JSON',
      authMethod: 'No Auth',
      accessTokenUrl: '',
      payload: '',
      header: '',
      location: 'header',
      apikey: '',
      paramName: '',
      soapEnvelope: '',
      soapHeader: '',
      soapAction: '',
      certSecretName: '',
      diagnostics: true,
      prognostics: false
    });
  };

  const onAddExtResponse = () => {
    setError({});
    setEditMode(false);
    setOpenEditReponse(true);
    // dispatchGetProductData({productId: 0, serviceId: 0, apiId: 0, });
    dispatchGetProjectPartners('', true);
  };

  const onChangePayload = (value) => {
    setEditRecord(JSON?.parse(value));
  };

  const jsonCheck = (str) => {
    try {
      JSON.parse(str);
    } catch (error) {
      return { isError: true };
    }
    return { isError: false };
  };

  const validateForm = () => {
    const isValid = isValidUrl(editRecord['externalResponseUrl']);
    const isValidaccessUrl = isValidUrl(editRecord['accessTokenUrl']);
    let letters = /[^a-zA-Z0-9. ]/;
    let errors = {};
    let progErr = false;

    if (editRecord['partnerName'] === '') {
      errors['partnerName'] = 'Please select partner name.';
    }
    // External Responses already added for the selected Partner. Please select edit option for updating the information.
    if (subscribedProducts?.length === 0 && editRecord['serviceName'] === '') {
      errors['serviceName'] = 'External Responses already added for the selected Partner. Please select edit option for updating the information.';
    } else if (editRecord['serviceName'] === '' && editRecord['serviceName'] !== '') {
      errors['serviceName'] = 'Please select service name.';
    } else if (editRecord['serviceName'] === '') {
      errors['serviceName'] = 'Please select service name.';
    }
    if (editRecord['productName'] === '') {
      errors['productName'] = 'Please select product name.';
    }

    if (editRecord['externalResponseUrl'] === '') {
      errors['externalResponseUrl'] = 'Please enter external response url.';
    } else if (!isValid) {
      errors['externalResponseUrl'] = 'Invalid external response URL.';
    }

    if (editRecord?.authMethod === 'OAuth') {
      if (editRecord?.accessTokenUrl === '') {
        errors['accessTokenUrl'] = 'Please enter access token url.';
      } else if (!isValidaccessUrl) {
        errors['accessTokenUrl'] = 'Invalid access token url.';
      }
      if (!editRecord?.payload.trim()) {
        errors['payload'] = 'Please enter payload.';
      } else if (editRecord?.payload.includes(' ')) {
        errors['payload'] = 'Spaces not allowed!';
      }
      if (editRecord?.header === '') {
        errors['header'] = 'Please enter header.';
      }
      const headerJson = jsonCheck(editRecord.header);
      if (headerJson?.isError) {
        if (editRecord.header === '') {
          errors['header'] = 'Please enter header.';
        } else {
          errors['header'] = 'Invalid json format.';
        }
      }
    }

    if (editRecord?.authMethod === 'x-Api-Key') {
      if (editRecord?.apikey === '') {
        errors['apikey'] = 'Please enter api key.';
      } else if (editRecord?.apikey.match(letters)) {
        errors['apikey'] = 'Special charecters are not allowed.';
      }
      if (editRecord?.location === '') {
        errors['location'] = 'Please enter location.';
      } else if (editRecord?.location.match(letters)) {
        errors['location'] = 'Special charecters are not allowed.';
      }
    }

    if (editRecord?.authMethod === 'SOAPCredentials') {
      if (editRecord?.soapEnvelope === '') {
        errors['soapEnvelope'] = 'Please enter SOAP envelope.';
      } else if (editRecord?.soapEnvelope.match(letters)) {
        errors['soapEnvelope'] = 'Special charecters are not allowed.';
      }
      if (editRecord?.soapAction === '') {
        errors['soapAction'] = 'Please enter SOAP action.';
      } else if (editRecord?.soapAction.match(letters)) {
        errors['soapAction'] = 'Special charecters are not allowed.';
      }
      if (editRecord?.soapHeader === '') {
        errors['soapHeader'] = 'Please enter SOAP header.';
      } else if (editRecord?.soapHeader.match(letters)) {
        errors['soapHeader'] = 'Special charecters are not allowed.';
      }

    }

    if (editRecord?.authMethod === 'mTLS') {
      if (editRecord?.certSecretName === '') {
        errors['certSecretName'] = 'Please enter cert secret name.';
      } else if (editRecord?.certSecretName.match(letters)) {
        errors['certSecretName'] = 'Special charecters are not allowed.';
      }
    }

    if (!editRecord?.diagnostics && !editRecord?.prognostics) {
      progErr = true;
      setPrognosticErr(true);
    } else {
      progErr = false;
      setPrognosticErr(false);
    }
    setError(errors);

    if (Object.keys(errors).length === 0 && !progErr) {
      const body = editRecord?.authMethod === 'OAuth' ? {
        ...(editRecord?.externalId && { externalId: editRecord?.externalId }),
        partnerName: editRecord?.partnerName,
        productName: editRecord?.productName,
        serviceName: editRecord?.serviceName,
        externalResponseUrl: editRecord?.externalResponseUrl,
        urlType: editRecord?.urlType,
        fileType: editRecord?.fileType,
        authMethod: editRecord?.authMethod,
        diagnosticsEnabled: editRecord?.diagnostics,
        prognosticsEnabled: editRecord?.prognostics,
        authInfo: {
          accessTokenUrl: editRecord?.accessTokenUrl.trim() || '',
          // clientId: editRecord?.clientId,
          header: editRecord?.header,
          payload: editRecord?.payload,
          // clientSecret: editRecord?.clientSecret,
          // scope: editRecord?.scope,
          // userName: editRecord?.userName,
          // password: editRecord?.password
        }
      } : editRecord?.authMethod === 'x-Api-Key' ? {
        ...(editRecord?.externalId && { externalId: editRecord?.externalId }),
        partnerName: editRecord?.partnerName,
        productName: editRecord?.productName,
        serviceName: editRecord?.serviceName,
        externalResponseUrl: editRecord?.externalResponseUrl,
        urlType: editRecord?.urlType,
        fileType: editRecord?.fileType,
        authMethod: editRecord?.authMethod,
        diagnosticsEnabled: editRecord?.diagnostics,
        prognosticsEnabled: editRecord?.prognostics,
        authInfo: {
          apiKey: editRecord?.apikey?.trim(),
          location: editRecord?.location,
          paramName: editRecord?.paramName?.trim()
        }
      } : editRecord?.authMethod === 'SOAPCredentials' ? {
        ...(editRecord?.externalId && { externalId: editRecord?.externalId }),
        partnerName: editRecord?.partnerName,
        productName: editRecord?.productName,
        serviceName: editRecord?.serviceName,
        externalResponseUrl: editRecord?.externalResponseUrl,
        urlType: editRecord?.urlType,
        fileType: editRecord?.fileType,
        authMethod: editRecord?.authMethod,
        diagnosticsEnabled: editRecord?.diagnostics,
        prognosticsEnabled: editRecord?.prognostics,
        authInfo: {
          soapEnvelope: editRecord?.soapEnvelope?.trim(),
          soapHeader: editRecord?.soapHeader?.trim(),
          soapAction: editRecord?.soapAction?.trim(),
        }
      } : editRecord?.authMethod === 'No Auth' ? {
        ...(editRecord?.externalId && { externalId: editRecord?.externalId }),
        partnerName: editRecord?.partnerName,
        productName: editRecord?.productName,
        serviceName: editRecord?.serviceName,
        externalResponseUrl: editRecord?.externalResponseUrl,
        urlType: editRecord?.urlType,
        fileType: editRecord?.fileType,
        authMethod: editRecord?.authMethod,
        diagnosticsEnabled: editRecord?.diagnostics,
        prognosticsEnabled: editRecord?.prognostics,
        authInfo: {},

      } : {
        ...(editRecord?.externalId && { externalId: editRecord?.externalId }),
        partnerName: editRecord?.partnerName,
        productName: editRecord?.productName,
        serviceName: editRecord?.serviceName,
        externalResponseUrl: editRecord?.externalResponseUrl,
        urlType: editRecord?.urlType,
        fileType: editRecord?.fileType,
        authMethod: editRecord?.authMethod,
        diagnosticsEnabled: editRecord?.diagnostics,
        prognosticsEnabled: editRecord?.prognostics,
        authInfo: {
          certSecretName: editRecord?.certSecretName?.trim(),
        }
      };
      console.log('body', body);
      return body;
    }
  };

  const onClickSaveEdit = () => {
    const body = validateForm(editRecord);
    if (body) {
      hideModal();
      dispatchUpdatetRegHistoryResp(body);
    }
  };


  useEffect(() => {
    dispatchGetRegHistoryResp(searchValue);
  }, []);

  const hideResponsepayloadModal = () => {
    setResponsePayload(false);
  };

  const isValidUrl = (url) => {
    //eslint-disable-next-line
    let urlRegex = /^(?:(?:https?|ftp):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/;
    let isValid = urlRegex.test(url);
    return isValid;
  };

  useEffect(() => {
    let partnerData = [];
    if (partners) {
      partners?.forEach((item) => {
        partnerData.push({ text: item?.partner_name, id: item?.partner_name });
      });
      setPartnerOptions(partnerData);
    }
  }, [partners]);

  useEffect(() => {
    let prods = [];
    if (products) {
      subscribedProducts?.forEach((item) => {
        prods.push({ text: item?.title, id: item?.title });
      });
      setProdOptions(prods);
    }
  }, [products]);

  useEffect(() => {
    let errors = error;
    if (subscribedProducts?.length === 0 && registeredProducts?.length === 0 && openEditResponse) {
      errors['serviceName'] = 'No Subscribed Product and Service available for the selected Partner. please add product subscription before proceeding.';
    } else if (subscribedProducts?.length === 0 && registeredProducts?.length > 0) {
      errors['serviceName'] = 'External Responses already added for the selected Partner. Please select edit option for updating the information.';
    } else {
      delete errors['serviceName'];
    }
    setError(errors);
  }, [subscribedProducts, registeredProducts, openEditResponse]);

  useEffect(() => {
    let servs = [];
    if (services) {
      services?.forEach((item) => {
        servs.push({ text: item?.title, id: item?.title });
      });
      setServiceOptions(servs);
    }
  }, [services]);

  const onFormChange = (value, fieldName, formField) => {
    let letters = /[^a-zA-Z0-9. ]/;
    let errors = error;
    if (fieldName === 'authMethod') {
      delete errors['accessTokenUrl'];
      delete errors['payload'];
      delete errors['header'];
      delete errors['location'];
      delete errors['apikey'];
      delete errors['paramName'];
      delete errors['soapEnvelope'];
      delete errors['soapHeader'];
      delete errors['soapAction'];
      delete errors['certSecretName'];

      setEditRecord((prevState) => ({
        ...prevState,
        accessTokenUrl: '',
        payload: '',
        header: '',
        location: 'header',
        apikey: '',
        paramName: '',
        soapEnvelope: '',
        soapHeader: '',
        soapAction: '',
        certSecretName: '',
      }));
    }

    if (value === '' && fieldName !== 'scope' && fieldName !== 'paramName') {
      errors[fieldName] = `Please enter ${formField}`;
    } else {
      delete errors[fieldName];
    }

    if (typeof value === 'string' && value.match(letters) && fieldName !== 'diagnostics' && fieldName !== 'scope' && fieldName !== 'paramName' && fieldName !== 'accessTokenUrl' && fieldName !== 'externalResponseUrl' && fieldName !== 'header' && fieldName !== 'payload' && fieldName !== 'authMethod') {
      errors[fieldName] = 'Special charecters are not allowed.';
    }

    if (fieldName === 'accessTokenUrl' || fieldName === 'externalResponseUrl') {
      const isValid = isValidUrl(value);
      !isValid ? errors[fieldName] = 'Invalid access token url.' : delete errors[fieldName];
    }
    if (fieldName === 'externalResponseUrl') {
      const isValid = isValidUrl(value);
      !isValid ? errors[fieldName] = 'Invalid external response url.' : delete errors[fieldName];
    }
    setError(errors);
    if (fieldName === 'location') {
      setEditRecord((prevState) => ({ ...prevState, [fieldName]: value, paramName: '' }));
    } else {
      setEditRecord((prevState) => ({ ...prevState, [fieldName]: value }));
    }
  };

  const dropdownsChange = (value, fieldName) => {
    let errors = error;
    if (value === '') {
      errors[fieldName] = `Please select ${fieldName}.`;
    } else {
      delete errors[fieldName];
    }

    if (fieldName === 'serviceName' && value !== '' && (subscribedProducts?.length === 0 && registeredProducts?.length > 0)) {
      errors['serviceName'] = 'External Responses already added for the selected Partner. Please select edit option for updating the information.';
    }

    setError(errors);

    if (fieldName === 'partnerName') {
      const currentPartner = partners?.find((item) => item?.partner_name?.trim() === value);
      setCurrentPartner(currentPartner);
      setEditRecord((prevState) => ({ ...prevState, partnerName: value, productName: '', serviceName: '' }));
    } else {
      setEditRecord((prevState) => ({ ...prevState, [fieldName]: value }));
    }
  };

  const onChangeProducts = (value) => {
    let errors = error;
    if (value === '') {
      errors['productName'] = 'Please select product name';
    } else {
      delete errors['productName'];
    }
    setError(errors);
    setEditRecord((prevState) => ({ ...prevState, productName: value, serviceName: '' }));
    let service = [];
    const allProducts = subscribedProducts;
    if (allProducts?.length > 0) {
      allProducts?.forEach((item) => {
        if (item?.title === value) {
          service = item?.children;
        }
      });
    }
    setServices(service);
  };

  const onSearchChange = (e) => {
    const value = e.target.value;
    setSearchValue(value === ' ' ? '' : value);
  };

  const onSearch = (value) => {
    setSearchValue(value);
    dispatchGetRegHistoryResp(value.trim());
  };


  return (
    <RegisteredExternalResponse
      heading={heading}
      subHeading={subHeading}
      tableData={regExtResp}
      loading={loading}
      columns={columns}
      responsePayloadValue={responsePayloadValue}
      responsePayload={responsePayload}
      hideResponsepayloadModal={hideResponsepayloadModal}
      activePagination={activePagination}
      onChangePagination={onChangePagination}
      isVisible={openEditResponse}
      editRecord={editRecord}
      hideModal={hideModal}
      onChangePayload={onChangePayload}
      onClickSave={onClickSaveEdit}
      onFormChange={onFormChange}
      error={error}
      onAddExtResponse={onAddExtResponse}
      partners={partnerOptions}
      editMode={editMode}
      dropdownsChange={dropdownsChange}
      prodOptions={prodOptions}
      services={services}
      onChangeProducts={onChangeProducts}
      serviceOptions={serviceOptions}
      dropdownLoader={dropdownLoader}
      currentPartner={currentPartner}
      registeredProducts={registeredProducts}
      subscribedProducts={subscribedProducts}
      searchValue={searchValue}
      onSearchChange={onSearchChange}
      onSearch={onSearch}
      onEnter={() => dispatchGetRegHistoryResp(searchValue.trim())}
      onBlur={() => { }}
      prognosticErr={prognosticErr}
    />
  );
};

export default RegisteredExternalResponseContainer;