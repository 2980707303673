import React from 'react';
import Logo from '../../../../../assets/images/logo.png';
import './Footer.scss';

const Footer = () => {
  return <div className="footer">
    <div className="clearfix">
      <div className="float-left footerLeft">
        <img src={Logo} alt='logoImage'/>
      </div>
      <div className="float-right footerRight">
        <div className="footer-link">
          <a href={'https://www.cummins.com/privacy-and-legal'}>Legal Notices & Privacy</a>
        </div>
      </div>
    </div>
  </div>;
};

export default Footer;
