import React, { useEffect, useState } from 'react';
import RolesAndPermission from './RolesAndPermission';
import { Select, Button } from '../../../../CommonComponents';
import {
  operations as adminOps,
  selectors as adminSelector,
} from '../../../../../state/features/admin';
import { useDispatch, useSelector } from 'react-redux';

const RolesAndPermissionContainer = () => {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    pageSizeOptions: [5, 10, 20, 50, 100],
    showSizeChanger: true,
  });

  const dispatch = useDispatch();
  // const dispatchGetProductData = adminOps.dispatchGetProdServiceApi(dispatch);
  const dispatchGetRolesUsers = adminOps.dispatchGetRolesUsers(dispatch);
  const getProjectPartners = adminOps.dispatchGetProjectPartners(dispatch);
  const savePermissions = adminOps.dispatchSavePermissions(dispatch);
  const dispatchToggleAlertPopup = adminOps.dispatchToggleAlertPopup(dispatch);
  const dispatchIsProjectDataChanged = adminOps.dispatchIsProjectDataChanged(dispatch);

  const { productData, users, partners, alertPopup } = useSelector((state) => ({
    productData: adminSelector.productData(state),
    users: adminSelector.users(state),
    partners: adminSelector.partners(state),
    alertPopup: adminSelector.alertPopup(state),
  }));

  const { /* services, */ loading } = productData;
  // const [productOption, setProductOption] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [viewingOption, setViewOption] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const columns = [
    {
      title: 'Partner',
      dataIndex: 'partnerName',
      key: 'partnerName',
      render: (row) => {
        return (
          <div className='boldName'>
            <p className='name'>{row}</p>
          </div>
        );
      },
      width: 500,
    },
    {
      title: 'Integration Manager',
      dataIndex: 'integrationManager',
      key: 'integrationManager',
      render: (data, record, idx) => {
        return (
          <Select
            multiple={true}
            className='itemSelect'
            options={viewingOption}
            value={data}
            placeholder={'Select User'}
            onChange={(data, row) => onManagerChange(row, data, idx, record)}
          />
        );
      },
    },
    {
      title: 'Action',
      width: 140,
      align: 'center',
      render: (row, data) => {
        return (
          <Button
            className='normalButton viewButton rolesSave'
            disabled={data.disabled}
            onClick={() => onClickSave(row)}
          >
            Save
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    getProjectPartners('');
    dispatchGetRolesUsers();
  }, []);

  useEffect(() => {
    let tempArr = [];
    users?.map((us) => {
      tempArr.push({ label: us?.emailId, value: us?.emailId });
    });
    setViewOption(tempArr);
  }, [users]);

  useEffect(() => {
    let rows = (partners || []).map((ser) => ({
      key: ser?.partner_id,
      partnerName: ser?.partner_name,
      integrationManager: ser?.integrationManager || null,
      disabled: true,
    }));
    setDataSource(rows);
  }, [partners]);



  const onChangeProduct = (value) => {
    setSelectedProduct(value);
  };

  const onChangeTable = (data) => {
    setPagination(data);
  };

  const onManagerChange = (row, data, idx, record) => {
    dispatchIsProjectDataChanged(true);
    let tempData = [...dataSource];
    const recordId = tempData.findIndex((item) => item?.key === record?.key);
    tempData[recordId].integrationManager = data;
    tempData[recordId].disabled = false;
    setDataSource(tempData);
  };

  const onClickSave = (row) => {
    const body = {
      partnerId: row?.key,
      integrationManager: row?.integrationManager || [],
    };
    dispatchIsProjectDataChanged(false);
    savePermissions(body);
  };

  const onCloseModal = () => {
    dispatchToggleAlertPopup(false, false, null);
  };

  const onCloseModalClick = () => {
    dispatchIsProjectDataChanged(false);
    alertPopup?.callback();
  };

  const onSearchChange = (e) => {
    const value = e?.target?.value;
    setSearchValue(value === ' ' ? '' : value);
  };

  const onSearch = (value) => {
    setSearchValue(value);
    getProjectPartners(value?.trim());
  };



  return (
    <RolesAndPermission
      dataSource={dataSource}
      columns={columns}
      pagination={pagination}
      // productOption={productOption}
      onChangeProduct={onChangeProduct}
      selectedProduct={selectedProduct}
      onChangeTable={onChangeTable}
      loading={loading}
      visible={alertPopup?.status}
      onCloseModal={onCloseModal}
      onCloseModalClick={onCloseModalClick}
      searchValue={searchValue}
      onSearchChange={onSearchChange}
      onSearch={onSearch}
      onEnter={() => getProjectPartners(searchValue.trim())}
      onBlur={() => { }}

    />
  );
};

export default RolesAndPermissionContainer;
