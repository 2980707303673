import React from 'react';
import { BreadcrumbComponent } from '../../../../../../CommonComponents';
import CommonContent from './CommonContent';
import './ProductsServiceAPI.scss';
import APIChildren from './APIChildren';
import AddManualChildren from './AddManualChildren';
import EditModelChildren from './EditModalChildren';

const ProductsServiceAPI = (props) => {

  const { formData, onImageUpload, onRemoveUpload, onFormChange, statusMsg, onClickSave, onClickCancel } = props;

  return (
    <div className='content-manager-products'>
      {props.breadcrumbList.length ? (
        <BreadcrumbComponent
          onClickEvent={props.breadcrumbOnClick}
          valueList={props.breadcrumbList}
        />
      ) : null}
      <CommonContent
        tableKey={props.tableKey}
        dataSource={props.dataSource}
        columns={props.columns}
        editModal={props.editModal}
        hideEditModal={props.hideEditModal}
        modalTitle={props.modalTitle}
        addModal={props.addModal}
        onAddModal={props.onAddModal}
        content={props.pageHeadingInfo}
        loading={props.loading}
        screen={props.liveScreenName}
        onClickPublish={props?.onClickPublish}
        productIds={props?.productIds}
        visible={props?.visible}
        setVisible={props?.setVisible}
        onShowModal={props?.onShowModal}
        currentScreen={props?.currentScreen}
        apiId={props?.apiId}
        isAdmin={props?.isAdmin}
      />

      {/* Edit Modal */}
      <EditModelChildren
        liveScreenName={props.liveScreenName}
        statusOption={props?.statusOption}
        formData={formData}
        onFormChange={onFormChange}
        statusMsg={statusMsg}
        visible={props.editModal}
        hideModal={props.hideEditModal}
        closeModalPopup={props.hideEditModal}
        title={props.modalTitle}
        width={440}
        onClickCancel={onClickCancel}
        onClickSave={onClickSave}
        onImageUpload={onImageUpload}
        onRemoveUpload={onRemoveUpload}
        fileImgSrc={props.fileImgSrc}
        currentRow={props?.currentRow}
        fileList={props?.fileList}
      />
      {/* API Modal */}

      <APIChildren
        liveScreenName={props.liveScreenName}
        statusOption={props?.statusOption}
        onSelectChange={props?.onSelectChange}
        selectedRowKeys={props?.selectedRowKeys}
        editAPIDataSource={props.editAPIDataSource}
        editAPIColumn={props.editAPIColumn}
        loading={props?.loading}
        apiEndpointOption={props.apiEndpointOption}
        visible={props.editAPIModal}
        hideModal={props.hideEditAPIModal}
        closeModalPopup={props.hideEditAPIModal}
        title={props.modalTitle}
        width={440}
        onShowAddManually={props?.onShowAddManually}
        hideEditAPIModal={props?.hideEditAPIModal}
        onEndpointChange={props?.onEndpointChange}
        formData={formData}
        onFormChange={onFormChange}
        statusMsg={statusMsg}
        onClickCancel={onClickCancel}
        onClickSave={onClickSave}
        currentRow={props?.currentRow}
      />

      {/* AddManual */}

      <AddManualChildren
        environmentOption={props.environmentOption}
        statusOption={props.statusOption}
        visible={props.addManually}
        hideModal={props.onHideManually}
        closeModalPopup={props.onHideManually}
        title={'Add Custom API'}
        width={440}
        onHideManually={props?.onHideManually}
        onSaveAddManually={props?.onSaveAddManually}
      />
    </div>
  );
};

export default ProductsServiceAPI;
