import React, { useEffect, useMemo, useRef, useState } from 'react';
import Banner from './Banner';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectors as appSelector } from '../../../../state/features/app';
// import { Notification } from '../../../CommonComponents';

const BannerContainer = (props) => {
  const location = useLocation();
  const [imgError, setImgError] = useState(false);
  const { pathname } = location;
  const path = pathname?.split('/')?.slice(1)[0];
  const [videoPlayerInitState, setVideoPlayerInitState] = useState(false);
  const [control, setControls] = useState(false);
  const [enableSignIn, setEnableSignIn] = useState(false);
  const [showEditBanner, setShowEditBanner] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [videofileList, setVideoFileList] = useState([]);
  const { editField, editText, setEditText } = props;

  const { userDetails } = useSelector((state) => ({
    userDetails: appSelector.userDetails(state),
  }));
  const content = props.content ?? {};
  const heading = content?.heading ?? {};
  const body = content?.body ?? {};
  const bannerMedia = content?.bannerMedia ?? {};
  const userName = props.userDetails?.unique_name ?? '';
  const pathLink = props.path ?? '';
  const currentRoute = useMemo(() => {
    return location?.search.replaceAll('%20', ' ').split('nav=')[1]?.split('/');
  }, [location]);

  const breadcrumbList = useMemo(
    () => (currentRoute?.length > 0 ? [...currentRoute].splice(1) : []),
    [currentRoute]
  );

  const [editRowLength, setEditRowLength] = useState(1);
  const [badge, setBadge] = useState('Text');
  const editImage = bannerMedia?.mobileImgSrc ?? '';
  const editVideo = bannerMedia?.videoSrc ?? '';
  const [video, setVieo] = useState(editVideo);
  const [previewImg, setPreviewImage] = useState('https://d1lgeay9zr70r7.cloudfront.net/HomeScreen/noImage.png');
  const [previewVideo, setPreviewVideo] = useState('');

  const [fileName, setFileName] = useState('');
  const [videofileName, setVideoFileName] = useState('');
  const videoRef = useRef(null);

  useEffect(() => {
    if (editImage) {
      const imageName = editImage?.split('/')?.splice(-1)[0];
      setFileName(imageName);
    }
    return () => {
      setFileName('');
    };
  }, [editImage]);

  useEffect(() => {
    // if (editImage) {
    //   const videoName = editVideo
    //     ?.split('/')
    //     ?.splice(-1)[0]
    //     .replaceAll('+', '');
    //   setVideoFileName(videoName);
    // }
    return () => {
      setVideoFileName('');
    };
  }, [editVideo]);
  useEffect(() => {
    if (Object.keys(bannerMedia)?.length) {
      setPreviewImage(bannerMedia?.imgSrc);
      setPreviewVideo(bannerMedia?.videoSrc);
    }
  }, [props.bannerMedia]);

  const loadImage = async () => {
    if (fileList?.length) {
      let name = fileList[0].name;
      setFileName(name);
      let src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(fileList[fileList?.length - 1]?.originFileObj);
        reader.onload = () => {
          resolve(reader?.result);
        };
      });
      setPreviewImage(src);
    }
  };
  const loadVideo = async () => {
    if (videofileList?.length) {
      let name = videofileList[0].name;
      setVideoFileName(name);
      let src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(
          videofileList[videofileList?.length - 1]?.originFileObj
        );
        reader.onload = (e) => {
          let videoBlob = new Blob([new Uint8Array(e.target.result)], {
            type: 'video/mp4',
          });

          // The blob gives us a URL to the video file:
          let url = window.URL.createObjectURL(videoBlob);
          resolve(url);
        };
      });
      setPreviewVideo(src);
    }
  };
  useEffect(() => {
    videoRef.current?.load();
  }, [previewVideo]);

  useEffect(() => {
    loadImage();
  }, [fileList]);

  useEffect(() => {
    loadVideo();
  }, [videofileList]);

  const onMouseOver = (field) => {
    if (field === 'content') {
      setBadge('Paragraph');
    } else {
      setBadge('Text');
    }
  };

  useEffect(() => {
    if (
      (Object.keys(heading)?.length || Object.keys(body)?.length) &&
      editField
    ) {
      if (editField == 'heading') {
        setEditRowLength(1);
      } else if (editField === 'subHeading') {
        setEditRowLength(1);
      } else {
        setEditRowLength(4);
      }
    }
  }, [editField, heading, body]);

  const enableVideo = () => {
    setVideoPlayerInitState(!videoPlayerInitState);
    setControls(true);
  };

  const onEditBannerImage = () => {
    setShowEditBanner(true);
  };

  const onHideEditBannerImage = () => {
    setShowEditBanner(false);
    setPreviewImage(editImage);
  };

  const onEditData = (e) => {
    const value = e.target.value;
    setEditText(value);
  };

  const uploadProps = {
    name: 'file',
    headers: {
      authorization: 'authorization-text',
    },
    accept: 'image/png, image/jpeg, image/jpg',
    maxCount: 1,
    beforeUpload: () => false,
    onChange(info) {
      setFileList(info?.file);
      setPreviewImage(info?.file);
      setFileName(info?.fileList[0]?.name);
    },
  };

  const onSaveBannerImg = () => {
    props?.onImageUpload(previewImg);
    if (typeof video !== 'string') {
      props?.onVideoUpload(video);
    }
    setShowEditBanner(false);
  };

  const uploadVideoProps = {
    name: 'file',
    headers: {
      authorization: 'authorization-text',
    },
    accept: 'video/mp4,video/x-m4v,video/',
    maxCount: 1,
    beforeUpload: () => false,
    onChange: (info) => {
      setVideoFileList(info?.fileList);
      setVieo(info?.file);
    },
  };

  // onVideoUpload

  const breadcrumbOnClick = (_, index) => {
    let breadcrumb = [...breadcrumbList];
    let navigateArray = breadcrumb.slice(0, index + 1);
    let navigateLink = navigateArray.join('/');
    props?.onNavClick([currentRoute[0], ...navigateArray], navigateLink);
  };

  const onshowSignIn = () => {
    setEnableSignIn(true);
  };

  const onHideSignin = () => {
    setEnableSignIn(false);
  };

  const playVideo = () => {
    videoRef?.current?.play();
  };

  const onError = (img) => {
    if (img) {
      img.src = 'https://d1lgeay9zr70r7.cloudfront.net/HomeScreen/noImage.png';
      setImgError(true);
    }
  };

  return (
    <Banner
      controls={control}
      content={props.content}
      mobile={props.mobile}
      footer={null}
      enableVideo={enableVideo}
      videoPlayerInitState={videoPlayerInitState}
      breadcrumbOnClick={breadcrumbOnClick}
      userDetails={userDetails}
      fieldKey={props?.fieldKey}
      path={path}
      enableSignIn={enableSignIn}
      onHideSignin={onHideSignin}
      onshowSignIn={onshowSignIn}
      breadcrumbList={breadcrumbList}
      videoRef={videoRef}
      playVideo={playVideo}
      onError={onError}
      imgError={imgError}
      editable={props.editable}
      onEditBannerImage={onEditBannerImage}
      showEditBanner={showEditBanner}
      onHideEditBannerImage={onHideEditBannerImage}
      uploadProps={uploadProps}
      fileList={fileList}
      onEditData={onEditData}
      pathLink={pathLink}
      heading={heading}
      body={body}
      bannerMedia={bannerMedia}
      userName={userName}
      onClickField={props?.onClickField}
      onHideEditContent={props?.onHideEditContent}
      editText={editText}
      editRowLength={editRowLength}
      badge={badge}
      onMouseOver={onMouseOver}
      fileName={fileName}
      videofileName={videofileName}
      previewImg={previewImg}
      previewVideo={previewVideo}
      uploadVideoProps={uploadVideoProps}
      onSaveWebContent={props?.onSaveWebContent}
      showEditContent={props?.showEditContent}
      onSaveBannerImg={onSaveBannerImg}
      showLink={props?.showLink}
      editLink={props?.editLink}
      setEditLink={props?.setEditLink}
      isLink={props?.isLink}
      editFieldLink={props?.editFieldLink}
    />
  );
};

export default BannerContainer;
