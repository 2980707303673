const GET_PRODUCT_SERVICE_API = 'admin/GET_PRODUCT_SERVICE_API';
const SET_PRODUCT_SERVICE_API = 'admin/SET_PRODUCT_SERVICE_API';
const SET_PRODUCT_SERVICE_API_FLG = 'admin/SET_PRODUCT_SERVICE_API_FLG';
const SET_LOADER_STATUS = 'admin/SET_LOADER_STATUS';
const GET_CREATE_PRODUCT = 'admin/GET_CREATE_PRODUCT';
const SET_CREATE_PRODUCT = 'admin/SET_CREATE_PRODUCT';
const GET_CREATE_SERVICE = 'admin/GET_CREATE_SERVICE';
const SET_CREATE_SERVICE = 'admin/SET_CREATE_SERVICE';
const GET_API_ENDPOINTS = 'admin/GET_API_ENDPOINTS';
const SET_API_ENDPOINTS = 'admin/SET_API_ENDPOINTS';
const GET_CREATE_API = 'admin/GET_CREATE_API';
const SET_CREATE_API = 'admin/SET_CREATE_API';
const SHOW_ADMIN_POPUP = 'admin/SHOW_ADMIN_POPUP';
const GET_API_PUBLISH = 'admin/GET_API_PUBLISH';
const SET_API_PUBLISH = 'admin/SET_API_PUBLISH';
const GET_API_PROJECT = 'admin/GET_API_PROJECT';
const SET_API_PROJECT = 'admin/SET_API_PROJECT';
const GET_PROJECT_PARTNERS = 'admin/GET_PROJECT_PARTNERS';
const SET_PROJECT_PARTNERS = 'admin/SET_PROJECT_PARTNERS';
const GET_PROJECT_PARTNERS_SUBSCRIPTIONS =
  'admin/GET_PROJECT_PARTNERS_SUBSCRIPTIONS';
const GET_ROLES_USERS = 'admin/GET_ROLES_USER';
const SET_ROLES_USERS = 'admin/SET_ROLES_USERS';
const SET_PARTNER_ACTIVE_SUBSCRIPTION = 'admin/SET_PARTNER_ACTIVE_SUBSCRIPTION';
const SET_PARTNER_INACTIVE_SUBSCRIPTION =
  'admin/SET_PARTNER_INACTIVE_SUBSCRIPTION';
const SET_PARTNERS_DATA = 'admin/SET_PARTNERS_DATA';
const UPLOAD_PROJECT_DOC = 'admin/UPLOAD_PROJECT_DOC';
const SET_PUBLISH_CHANGES = 'adin/SET_PUBLISH_CHANGES';
const GET_PROJECT_VIEW = 'admin/GET_PROJECT_VIEW';
const SET_PROJECT_VIEW = 'admin/SET_PROJECT_VIEW';
const SET_PROJECT_MILESTONE = 'admin/SET_PROJECT_MILESTONE';
const SET_PROJECT_UPLOAD = 'admin/SET_PROJECT_UPLOAD';
const GET_DELETE_FILE = 'admin/GET_DELETE_FILE';
const SET_DELETED_FILE = 'admin/SET_DELETED_FILE';
const UPDATE_EDIT_WEBPAGE = 'admin/UPDATE_EDIT_WEBPAGE';
const SAVE_PERMISSIONS = 'admin/SAVE_PERMISSIONS';
const TOGGLE_ALERT_POPUP = 'admin/TOGGLE_ALERT_POPUP';
const PROJECT_DATA_CHANGED = 'admin/PROJECT_DATA_CHANGED';
const ADD_EDIT_PARTNER = 'admin/ADD_EDIT_PARTNER';
const ON_CLICK_REJECT = 'admin/ON_CLICK_REJECT';
const ON_CLICK_APPROVAL = 'admin/ON_CLICK_APPROVAL';
const GET_APPROVALS_LIST = 'admin/GET_APPROVALS_LIST';
const SET_APPROVALS_LIST = 'admin/SET_APPROVALS_LIST';
const GET_HISTORY = 'admin/GET_HISTORY';
const SET_HISTORY = 'admin/SET_HISTORY';
const GET_REg_EXT_RESP = 'admin/GET_REg_EXT_RESP';
const SET_REg_EXT_RESP = 'admin/SET_REg_EXT_RESP';
const UPDATE_REG_EXT_RESP = 'admin/UPDATE_REG_EXT_RESP';
const GET_CERTIFIED_PRODUCTS = 'admin/GET_CERTIFIED_PRODUCTS';
const SET_CERTIFIED_PRODUCTS = 'admin/SET_CERTIFIED_PRODUCTS';
const GET_PRODUCTS = 'admin/GET_PRODUCTS';
const SET_PRODUCTS = 'admin/SET_PRODUCTS';
const SUBSCRIBE_PRODUCTS = 'admin/SUBSCRIBE_PRODUCTS';
const SET_DROPDOWN_LOADER = 'admin/SET_DROPDOWN_LOADER';

/*  */
export {
  GET_PRODUCT_SERVICE_API,
  SET_PRODUCT_SERVICE_API,
  SET_PRODUCT_SERVICE_API_FLG,
  SET_LOADER_STATUS,
  GET_CREATE_PRODUCT,
  SET_CREATE_PRODUCT,
  GET_CREATE_SERVICE,
  SET_CREATE_SERVICE,
  GET_API_ENDPOINTS,
  SET_API_ENDPOINTS,
  SHOW_ADMIN_POPUP,
  GET_CREATE_API,
  SET_CREATE_API,
  GET_API_PUBLISH,
  SET_API_PUBLISH,
  GET_API_PROJECT,
  SET_API_PROJECT,
  GET_PROJECT_PARTNERS,
  SET_PROJECT_PARTNERS,
  GET_PROJECT_PARTNERS_SUBSCRIPTIONS,
  GET_ROLES_USERS,
  SET_ROLES_USERS,
  SET_PARTNER_ACTIVE_SUBSCRIPTION,
  SET_PARTNER_INACTIVE_SUBSCRIPTION,
  SET_PARTNERS_DATA,
  UPLOAD_PROJECT_DOC,
  SET_PUBLISH_CHANGES,
  GET_PROJECT_VIEW,
  SET_PROJECT_VIEW,
  SET_PROJECT_MILESTONE,
  SET_PROJECT_UPLOAD,
  GET_DELETE_FILE,
  SET_DELETED_FILE,
  UPDATE_EDIT_WEBPAGE,
  SAVE_PERMISSIONS,
  TOGGLE_ALERT_POPUP,
  PROJECT_DATA_CHANGED,
  ADD_EDIT_PARTNER,
  ON_CLICK_REJECT,
  ON_CLICK_APPROVAL,
  GET_APPROVALS_LIST,
  SET_APPROVALS_LIST,
  GET_HISTORY,
  SET_HISTORY,
  GET_REg_EXT_RESP,
  SET_REg_EXT_RESP,
  UPDATE_REG_EXT_RESP,
  GET_CERTIFIED_PRODUCTS,
  SET_CERTIFIED_PRODUCTS,
  GET_PRODUCTS,
  SET_PRODUCTS,
  SUBSCRIBE_PRODUCTS,
  SET_DROPDOWN_LOADER
};
