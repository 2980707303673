import React from 'react';
import RejectModal from './RejectModal';

const RejectModalContainer = (props) => {
  return (
    <RejectModal
      showRejectModal={props.showRejectModal}
      hideRejectModal={props.hideRejectModal}
      onClickReject={props.onClickReject}
      textAreaValue={props.textAreaValue}
      handleTextAreaChange={props.handleTextAreaChange}
      onClickModalReject={props.onClickModalReject}
      rejectErr={props?.rejectErr}
    />
  );
};

export default RejectModalContainer;