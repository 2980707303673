import React from 'react';
import {
  RowComponent,
  Modal,
  OutlineButton,
} from '../../../../../../../CommonComponents';
import PartnersTable from '../PartnersTable';
import Contact from './Contact';
import TitleComponent from './TitleComponent';
import './PartnersView.scss';
import Subscription from './Subscription';

const PartnersViewContainer = (props) => {
  return (
    <div className='rolesAndPermissions'>
      <TitleComponent
        breadcrumbList={props.breadcrumbList}
        breadcrumbOnClick={props.breadcrumbOnClick}
        partnerName={props.partnerName}
        onAddSubscription={props?.onAddSubscription}
      />

      <PartnersTable
        heading={'Active Subscriptions'}
        columns={props.activeSubscription}
        loading={props?.loading}
        dataSource={props.dataSource}
        pagination={props?.pagination}
        onPaginationChange={props?.onPaginationChange}
      />

      <PartnersTable
        heading={'Inactive Subscriptions'}
        columns={props.inactiveSubscription}
        loading={props?.loading}
        dataSource={props.inActiveSubscriptionData}
        pagination={props?.inactivePagination}
        onPaginationChange={props?.onInactivePaginationChange}
      />

      <div className='borderLine'></div>
      <div className='patnersView'>
        <div className='clearfix headerblock'>
          <div className='float-left'>
            <span className='subHeading'>Partner Contacts</span>
            <div className='infoBorder'>
              These Partner contacts are managed in Salesforce. Login to your
              Cummins Sales Cloud account or contact the Partner's Account
              Executive to update contacts.
            </div>
          </div>
        </div>
        <div className='tiles'>
          <RowComponent gutter={50}>
            <Contact
              item={{ ...props?.technicalContacts, title: 'Technical Contact' }}
            />
            <Contact
              item={{ ...props?.businessContacts, title: 'Business Contact' }}
            />
            <Contact
              item={{
                ...props?.supportContacts,
                title: 'Customer Support Contact',
              }}
            />
          </RowComponent>

          {/* <RowComponent gutter={50}>
            {props.contactdetails?.map((item, index) => {
              return <Contact item={item} key={index} />;
            })}
          </RowComponent> */}
        </div>
      </div>
      <Modal
        visible={props.deactivateSubscription}
        hideModal={props?.hideDeactivate}
        closeModalPopup={props?.hideDeactivate}
        title={props.status}
        className='Deactivate'
        width={550}
        footer={
          <div className='viewBlock'>
            <OutlineButton
              className='normalButton viewButton'
              onClick={props?.hideDeactivate}
            >
              Cancel
            </OutlineButton>
            <OutlineButton
              className={`normalButton viewButton ${props?.status === 'Activate'
                ? 'activateButton'
                : 'deactivateButton'
              } `}
              onClick={() => props?.onSubmitBtn(props?.status)}
            >
              {props.status}
            </OutlineButton>
          </div>
        }
        centered
      >
        {`Are you sure do you want to ${props.status?.toLowerCase()}?`}
      </Modal>
      <Modal
        visible={props.openSubscription}
        hideModal={props?.hideSubscription}
        closeModalPopup={props?.hideSubscription}
        title={'Add Subscription'}
        className='Deactivate'
        width={550}
        footer={
          <div className='viewBlock'>
            <OutlineButton
              className='normalButton viewButton'
              onClick={props?.hideSubscription}
            >
              Cancel
            </OutlineButton>
            <OutlineButton
              className={'normalButton viewButton activateButton'}
              onClick={() => props?.onSaveSubscription()}
              disabled={props?.services?.length === 0}
            >
              {'Subscribe'}
            </OutlineButton>
          </div>
        }
        centered
      >
        <Subscription
          prodOptions={props?.prodOptions}
          product={props?.product}
          onChangeProducts={props?.onChangeProducts}
          services={props?.services}
          onServiceCheck={props?.onServiceCheck}
        />
      </Modal>
    </div>
  );
};

export default PartnersViewContainer;
