import React from 'react';
import './Specifications.scss';

const Specifications = (props) => {
  const data = props.data ?? {};
  const heading = data?.heading ?? '';
  const list = data?.list ?? [];
  return <div className='specification'>
    <p className='heading'>{heading}</p>
    <div className='cardBlock'>
      {
        list.map((item,index) => {
          return <div key={index}>
            <p className='name'>{item.name}</p>
            <p>{item.description}</p>
          </div>;
        })
      }
    </div>
  </div>;
};

export default Specifications;
