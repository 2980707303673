import React from 'react';
import Account_box from '../../../../../../../../../assets/images/account_box.png';
import mail from '../../../../../../../../../assets/images/mail.png';
import { Select } from '../../../../../../../../CommonComponents';

const PartnerContact = (props) => {
  const { contactOption, selectedContact, contactList, onContactSelectChange } = props;

  return (
    <div className='cardList'>
      <p className='spec-heading'>Partner Contact</p>
      <div className='form-item noBorder'>
        <Select
          options={contactOption || []}
          onChange={onContactSelectChange}
          value={selectedContact}
          placeholder={'Contacts'}
          className='contactInfo'
        />
      </div>
      {contactList?.length > 0 && (
        <>
          {contactList?.map((contact, idx) => (
            <div key={idx}>
              <div>
                <img src={Account_box} alt='userAccount' className='account' />
                {contact?.name}
              </div>
              <div className='mailList'>
                <img src={mail} alt='userAccount' className='mail' />
                {contact?.email}
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default PartnerContact;
