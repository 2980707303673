import React from 'react';
import './PendingInfoBox.scss';
import pendingIcon from '../../../../../../src/assets/images/circle-pending.svg';
const PendingInfoBox = () => {
  return(
    <div className='clearfix pendingInfo'>
      <div className='borderBlock'>
        <div className='iconView'>
          <img src={pendingIcon} alt="failed" style={{maxWidth : '100%'}} />
        </div>
        <div className='textView'>
          <div className='title'>Request to External Response API is Pending</div>
          <div className='description'>Your request for external response is pending. </div>
        </div>
      </div>
    </div>
  );
};
export default PendingInfoBox;