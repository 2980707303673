import {
  getLoginUserDetails,
  getNavRoutes,
  // setLoginUserDetails,
  getNavContent,
  setNavContent,
  getFileContentJson,
  redirectToExternalLogin,
  getGlobalFAQContent,
  setLocalBodyFlg,
  getSwaggerJson,
  submitSubRequest,
  setProServiceMap,
  getUserAuth,
  setCurrentRoute,
  setRouteKeyMap,
  submitManageSubscription,
  getApiDescJson,
  setSimulate,
  sendContactMessage,
  setUserAuth,
  addEditExtResponse,
  getExternalResponse
} from './actions';

const dispatchRouteKeyMap = (dispatch) => (routeKeyMap) => {
  dispatch(setRouteKeyMap(routeKeyMap));
};

const dispatchSubmitSubRequest = (dispatch) => (subRequestData, params, fromProject = false, projectId) => {
  dispatch(submitSubRequest(subRequestData, params, fromProject, projectId));
};

const dispatchSetProServiceMap = (dispatch) => (map) => {
  dispatch(setProServiceMap(map));
};

const dispatchGetGlobalFAQ = (dispatch) => (fileURL) => {
  dispatch(getGlobalFAQContent(fileURL));
};

const dispatchRedirectionExtLogin = (dispatch) => (adName) => {
  dispatch(redirectToExternalLogin(adName));
};

const dispatchGetUserDetails = (dispatch) => () => {
  dispatch(getLoginUserDetails());
};

const dispatchSetUserDetails = (dispatch) => (userDetails) => {
  // dispatch(setLoginUserDetails(userDetails));
  dispatch(setUserAuth(userDetails));
};

const dispatchGetNavRoutes = (dispatch) => (userId) => {
  dispatch(getNavRoutes(userId));
};
const dispatchGetUserAuth = (dispatch) => (userId) => {
  dispatch(getUserAuth(userId));
};

const dispatchGetNavRouteContent = (dispatch) => (routeName, isContentEdit) => {
  dispatch(getNavContent(routeName, isContentEdit));
};

const dispatchSetLocalBodyLoad = (dispatch) => (localBodyLoad) => {
  dispatch(setLocalBodyFlg(localBodyLoad));
};

const dispatchSetNavRouteContent = (dispatch) => (routeContent) => {
  dispatch(setNavContent(routeContent));
};

const dispatchGetFileJsonData = (dispatch) => (fileURL) => {
  dispatch(getFileContentJson(fileURL));
};

const dispatchGetSwaggerJson = (dispatch) => (apiId) => {
  dispatch(getSwaggerJson(apiId));
};

const dispatchSetCurrentRoute = (dispatch) => (data) => {
  dispatch(setCurrentRoute(data));
};

const dispatchSubmitManageSubscription = (dispatch) => (data) => {
  dispatch(submitManageSubscription(data));
};
const dispatchGetApiDescJson = (dispatch) => (partner, product, service) => {
  dispatch(getApiDescJson(partner, product, service));
};
const dispatchSetSimulate = (dispatch) => (data) => {
  dispatch(setSimulate(data));
};

const dispatchSendContactMessage = (dispatch) => (body) => {
  dispatch(sendContactMessage(body));
};

const dispatchAddEditExtResp = (dispatch) => (body) => {
  dispatch(addEditExtResponse(body));
};
const dispatchGetExtResponse = (dispatch) => (body) => {
  dispatch(getExternalResponse(body));
};

const operations = {
  dispatchRouteKeyMap,
  dispatchSubmitSubRequest,
  dispatchGetGlobalFAQ,
  dispatchRedirectionExtLogin,
  dispatchGetUserDetails,
  dispatchSetUserDetails,
  dispatchGetNavRoutes,
  dispatchGetNavRouteContent,
  dispatchSetNavRouteContent,
  dispatchGetFileJsonData,
  dispatchSetLocalBodyLoad,
  dispatchGetSwaggerJson,
  dispatchSetProServiceMap,
  dispatchGetUserAuth,
  dispatchSetCurrentRoute,
  dispatchSubmitManageSubscription,
  dispatchGetApiDescJson,
  dispatchSetSimulate,
  dispatchSendContactMessage,
  dispatchAddEditExtResp,
  dispatchGetExtResponse
};

export default operations;
