import React from 'react';
import Collapsible from '../../../CommonWidget/Collapsible';
import './FAQ.scss';

const FAQ = (props) => {
  return <div className='faq'>
    <Collapsible content={props.content}/>
  </div>;
};

export default FAQ;
