import React, { useState } from 'react';
import {
  ColComponent,
  RowComponent,
  Button,
  Modal,
} from '../../../../CommonComponents';
import './ProductCard.scss';
import { useNavigate } from 'react-router-dom';
import Subscription from '../../TileDescriptor/Subscription/Subscription-container';

const ProductCard = (props) => {
  const userName = props.userDetails?.unique_name ?? '';
  const {prodServiceMap} = props;
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [productId, setProductId] = useState(null);
  const [services, setServices] = useState([]);

  const onClickTitle = (title, tree) => {
    navigate({
      pathname: `/${title}`,
      search: `?portal=${false}&nav=${tree?.length > 0 ? tree.join('/') : ''}`,
    });
  };

  const onManangeClick = (item) => {
    const availableChildren = prodServiceMap['Available Products']?.find((o) => o?.title === item?.title);
    const subscribedChildren = prodServiceMap['My Subscriptions']?.find((o) => o?.title === item?.title);
    const allChildren = [...availableChildren?.children || [], ...subscribedChildren?.children || []];
    setShowModal(true);
    setProductId(item?.id);
    setServices(allChildren);
  };

  const LoadsubscribeButton = ({ onManangeClick }) => {
    return (
      <Button className='normalButton' onClick={onManangeClick}>
        Manage Subscription
      </Button>
    );
  };

  const hideModalPopup = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className='productsCard'>
        <div>
          <img
            src={
              props?.item?.imageSrc ||
              'https://d1lgeay9zr70r7.cloudfront.net/ProductImages/ConnectedDiagnostics.png'
            }
            alt='productsImg'
            className='productImg'
          />
        </div>
        <div>
          <p
            className='productTitle'
            onClick={() => onClickTitle(props?.item?.title, props?.item?.tree)}
            role='button'
          >
            {props?.item?.title}
          </p>
          <p className='productContent'>
            {props?.item?.content || props?.item?.description}
          </p>
          <div className='stLine'></div>
          <div className='servicesOffered'>
            <p>{props?.item?.listName}</p>
            <RowComponent className='servicesOfferedRow' gutter={[5, 5]}>
              {(props.item.links || props?.item?.children).map((el, index) => (
                <ColComponent key={index} xs={24} md={24} lg={12}>
                  {el?.title || el}
                </ColComponent>
              ))}
            </RowComponent>
            {userName ? (
              <div className='listButton'>
                <LoadsubscribeButton
                  onManangeClick={() => onManangeClick(props?.item)}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <Modal
        visible={showModal}
        hideModal={hideModalPopup}
        closeModalPopup={hideModalPopup}
        title={'Manage Subscription'}
        width={620}
        footer={null}
        centered
        className='leftTitle-popup'
      >
        <Subscription
          hideModalPopup={hideModalPopup}
          services={services}
          setServices={setServices}
          localProdId={productId}
        />
      </Modal>
    </>
  );
};

export default ProductCard;
