import React from 'react';
import './ExternalResponseAPI.scss';
import {
  Text,
  Select,
  Textarea,
  Popover,
  Switch,
  Dropdowns,
} from '../../../../CommonComponents';

const ExternalResponseAPI = (props) => {
  const {
    onFormChange,
    formData,
    error,
    optionsValue,
    fileTypeOptions,
    locationOptions,
    authMethodOptions,
    content,
    partners,
    editMode = true,
    prodOptions,
    onChangeProducts,
    serviceOptions,
    dropdownLoader,
    prognosticErr
  } = props;

  return (
    <div className='ExternalResponseAPI'>
      <div className='editModal'>
        <div className='externalResponseWrapper'>
          <div className='labelView'>
            <label className='label'>
              Partner Name
            </label>
          </div>
          <div className='splitView'>
            :
          </div>
          <div className='valueView'>
            <div>{editMode ?
              formData?.partnerName
              :
              <Dropdowns
                placeholder='Please select a Partner name'
                optionKeyName={'id'}
                optionTextName={'text'}
                style={{ width: '100%' }}
                options={partners}
                value={formData?.partnerName}
                loading={dropdownLoader}
                onChangeEvent={(value) =>
                  props?.dropdownsChange(value, 'partnerName')
                }
                className='registrationStatus'
                statusMsg={error?.partnerName ?? error?.partnerName}

              />
            }</div>
          </div>
        </div>
        <div className='externalResponseWrapper'>
          <div className='labelView'>
            <label className='label'>
              Product Name
            </label>
          </div>
          <div className='splitView'>
            :
          </div>
          <div className='valueView'>
            <div>{editMode ?
              formData?.productName
              :
              <Dropdowns
                placeholder='Please select a Product name'
                optionKeyName={'id'}
                optionTextName={'text'}
                style={{ width: '100%' }}
                options={prodOptions}
                disabled={formData?.partnerName === ''}
                loading={dropdownLoader}
                value={formData?.productName}
                onChangeEvent={(value) =>
                  onChangeProducts(value)
                }
                className='registrationStatus'
                statusMsg={error?.productName ?? error?.productName}
              />
            }</div>
          </div>
        </div>
        <div className='externalResponseWrapper'>
          <div className='labelView'>
            <label className='label'>
              Service Name
            </label>
          </div>
          <div className='splitView'>
            :
          </div>
          <div className='valueView'>
            <div>{editMode ?
              formData?.serviceName
              :
              <Dropdowns
                placeholder='Please select a service name'
                optionKeyName={'id'}
                optionTextName={'text'}
                style={{ width: '100%' }}
                options={serviceOptions}
                value={formData?.serviceName}
                disabled={formData?.productName === ''}
                // loading={dropdownLoader}
                onChangeEvent={(value) =>
                  props?.dropdownsChange(value, 'serviceName')
                }
                className='registrationStatus'
                statusMsg={error?.serviceName ?? error?.serviceName}
              />
            }</div>
          </div>
        </div>
        <div className="formControl">
          <label className='label'>
            External Response URL  <span style={{ color: 'red' }}>*</span>
          </label>
          <Text
            className='partnerNameText'
            value={formData?.externalResponseUrl}
            onChange={(e) => onFormChange(e.target.value, 'externalResponseUrl', 'external response url')}
            statusMsg={error?.externalResponseUrl ?? error?.externalResponseUrl}
          />
        </div>
        <div className="formControl">
          <label className='label'>
            URL Type <span style={{ color: 'red' }}>*</span>
          </label>
          <Select value={formData?.urlType} className='partnerNameText' options={optionsValue} onChange={(e) => onFormChange(e, 'urlType', 'url type')} />
        </div>
        <div className="formControl">
          <label className='label'>
            File Type <span style={{ color: 'red' }}>*</span>
          </label>
          <Select className='partnerNameText' value={formData?.fileType} options={fileTypeOptions} onChange={(e) => onFormChange(e, 'fileType', 'file type')} />
        </div>
        <div className='switchControl'>
          <div className="formControl switchPrognostics">
            <label className='label prognosticsLable'>
              Prognostics
            </label>
            <Switch
              checked={formData?.prognostics}
              onChangeEvent={(data) => onFormChange(data, 'prognostics', 'prognostics')}
            />
          </div>
          <div className="formControl switchDaignostics">
            <label className='label daignosticsLable'>
              Diagnostics
            </label>
            <Switch
              checked={formData?.diagnostics}
              onChangeEvent={(data) => onFormChange(data, 'diagnostics', 'diagnostics')}
            />
          </div>
        </div>
        {prognosticErr ? <p className="mandatoryMsg">Please enable any one of prognostics or diagnostics.</p> : null}
        <div className="formControl">
          <label className='label'>
            Auth Method  <span style={{ color: 'red' }}>*</span>
          </label>
          <Select value={formData?.authMethod} className='partnerNameText' options={authMethodOptions} onChange={(e) => onFormChange(e, 'authMethod', 'auth method')} />
        </div>
        {
          formData.authMethod === 'OAuth' ?
            <>
              <div className="formControl">
                <label className='label'>
                  Access Token URL <span style={{ color: 'red' }}>*</span>
                </label>
                <Text
                  className='partnerNameText'
                  value={formData?.accessTokenUrl}
                  onChange={(e) => onFormChange(e.target.value, 'accessTokenUrl', 'access token url')}
                  statusMsg={error?.accessTokenUrl ?? error?.accessTokenUrl}
                />
              </div>
              <div className="formControl">
                <label className='label'>
                  Payload  <span style={{ color: 'red' }}>*</span>  <span>
                    <Popover content={content}>
                      <span className='fa fa-info-circle authinfoIcon'></span>
                    </Popover>
                  </span>
                </label>
                <Text
                  className='partnerNameText'
                  value={formData?.payload}
                  placeholder='grant_type=<grantType>&client_id=<clientId>&client_secret=<clientSecret>&scope=<scope>'
                  onChange={(e) => onFormChange(e.target.value, 'payload', 'Payload')}
                  statusMsg={error?.payload ?? error?.payload}
                />
              </div>
              <div className="formControl">
                <label className='label'>
                  Header <span style={{ color: 'red' }}>*</span>
                </label>
                <Textarea
                  className='partnerNameText'
                  value={formData?.header}
                  onChangeEvent={(e) => onFormChange(e.target.value, 'header', 'Header')}
                  statusMsg={error?.header ?? error?.header}
                />
              </div>
              {/* <div className="formControl">
                <label className='label'>
                  Client Secret <span style={{ color: 'red' }}>*</span>
                </label>
                <Text
                  className='partnerNameText'
                  value={formData?.clientSecret}
                  onChange={(e) => onFormChange(e.target.value, 'clientSecret', 'client secret')}
                  statusMsg={error?.clientSecret ?? error?.clientSecret}
                />
              </div>
              <div className="formControl">
                <label className='label'>
                  Scope
                </label>
                <Text
                  className='partnerNameText'
                  value={formData?.scope}
                  onChange={(e) => onFormChange(e.target.value, 'scope', 'scope')}
                  statusMsg={error?.scope ?? error?.scope}
                />
              </div>
              <div className="formControl">
                <label className='label'>
                  User Name
                </label>
                <Text
                  className='partnerNameText'
                  value={formData?.userName}
                  onChange={(e) => onFormChange(e.target.value, 'userName', 'User Name')}
                  statusMsg={error?.scope ?? error?.scope}
                />
              </div>
              <div className="formControl">
                <label className='label'>
                  Password
                </label>
                <Text
                  className='partnerNameText'
                  value={formData?.password}
                  onChange={(e) => onFormChange(e.target.value, 'password', 'Password')}
                  type='password'
                />
              </div> */}
            </> : null
        }
        {
          formData.authMethod === 'x-Api-Key' ?
            <>
              <div className="formControl">
                <label className='label'>
                  API Key <span style={{ color: 'red' }}>*</span>
                </label>
                <Text
                  className='partnerNameText'
                  value={formData?.apikey}
                  onChange={(e) => onFormChange(e.target.value, 'apikey', 'api key')}
                  statusMsg={error?.apikey ?? error?.apikey}
                />
              </div>
              <div className="formControl">
                <label className='label'>
                  Location <span style={{ color: 'red' }}>*</span>
                </label>
                <Select
                  className='partnerNameText'
                  value={formData?.location}
                  options={locationOptions}
                  onChange={(e) => onFormChange(e, 'location', 'location')}
                  statusMsg={error?.location ?? error?.location}
                />

              </div>
              <div className="formControl">
                <label className='label'>
                  Param Name
                </label>
                <Text
                  className='partnerNameText'
                  value={formData?.paramName}
                  onChange={(e) => onFormChange(e.target.value, 'paramName', 'param name')}
                  statusMsg={error?.paramName ?? error?.paramName}
                  disabled={formData?.location === 'header'}
                />
              </div>
            </> : null
        }
        {
          formData.authMethod === 'SOAPCredentials' ?
            <>
              <div className="formControl">
                <label className='label'>
                  Soap Envelope <span style={{ color: 'red' }}>*</span>
                </label>
                <Text
                  className='partnerNameText'
                  value={formData?.soapEnvelope}
                  onChange={(e) => onFormChange(e.target.value, 'soapEnvelope', 'SOAP envelope')}
                  statusMsg={error?.soapEnvelope ?? error?.soapEnvelope}
                />
              </div>
              <div className="formControl">
                <label className='label'>
                  Soap Header <span style={{ color: 'red' }}>*</span>
                </label>
                <Text
                  className='partnerNameText'
                  value={formData?.soapHeader}
                  onChange={(e) => onFormChange(e.target.value, 'soapHeader', 'SOAP header')}
                  statusMsg={error?.soapHeader ?? error?.soapHeader}
                />
              </div>
              <div className="formControl">
                <label className='label'>
                  Soap Action <span style={{ color: 'red' }}>*</span>
                </label>
                <Text
                  className='partnerNameText'
                  value={formData?.soapAction}
                  onChange={(e) => onFormChange(e.target.value, 'soapAction', 'SOAP action')}
                  statusMsg={error?.soapAction ?? error?.soapAction}
                />
              </div>
            </> : null
        }
        {
          formData.authMethod === 'mTLS' ?
            <>
              <div className="formControl">
                <label className='label'>
                  Cert Secret Name <span style={{ color: 'red' }}>*</span>
                </label>
                <Text
                  className='partnerNameText'
                  value={formData?.certSecretName}
                  onChange={(e) => onFormChange(e.target.value, 'certSecretName', 'cert secret name')}
                  statusMsg={error?.certSecretName ?? error?.certSecretName}
                />
              </div>
            </> : null
        }
      </div>
    </div>
  );
};

export default ExternalResponseAPI;