import React, { useState } from 'react';
import StageDescriptor from './StageDescriptor';
import {useSelector} from 'react-redux';
import {
  selectors as appSelector
} from '../../../../state/features/app';

const StageDescriptorContainer = (props) => {
  const {
    userDetails
  } = useSelector(
    state => ({
      userDetails: appSelector.userDetails(state)
    })
  );
  const [enableSignIn,setEnableSignIn] = useState(false);
  const onSigninClick = (e) => {
    e.preventDefault();
    setEnableSignIn(true);
  };
  const onSigninHide = () => {
    setEnableSignIn(false);
  };
  return <StageDescriptor
    content={props.content}
    userDetails={userDetails}
    onSigninClick={onSigninClick}
    onSigninHide={onSigninHide}
    enableSignIn={enableSignIn} />;
};

export default StageDescriptorContainer;