import React from 'react';
import {Accordion} from '../../../CommonComponents';
import './Collapsible.scss';

const Collapsible = (props) => {
  const content = props.content ?? {};
  const heading = content.heading ?? {};
  const body = props.globalFAQContent ?? [];
  
  const AccordionItems = () => {
    let array = [];
    body?.forEach((item)=> {
      array.push({
        headerText: item.question,
        content: <div>
          {item.answer}
        </div>
      });
    });

    return array;
  };

  return <div className='collapsible'>
    <p className='heading'>{heading}</p>
    <Accordion ghost={true} AccordionItems={AccordionItems()} activeKey={props.openPanels} onChange={props.onAccordianClick} />
  </div>;
};

export default Collapsible;