import {
  SET_LOGIN_USER_DETAILS,
  SET_NAV_ROUTES,
  IS_FETCHING_NAV_ROUTES,
  SET_NAV_CONTENT,
  IS_FETCHING_NAV_CONTENT,
  SET_CODE_FILE_CONTENT_JSON,
  SET_GLOBAL_FAQ_CONTENT,
  SET_GLOBAL_FAQ_CONTENT_LOADING,
  SET_LOCAL_BODY_CONTENT_FLG,
  SET_SWAGGER_JSON,
  IS_FETCHING_SWAGGER_JSON,
  SET_PROD_SERVICE,
  SET_USER_AUTH,
  SET_CURRENT_ROUTE,
  SET_ROUTE_KEY_MAP,
  SET_API_DESC_JSON,
  SET_DELETE_FILE,
  UPDATE_DOC_DATA,
  SET_EXTERNAL_RESPONSE,
} from './types';

const reducers = (state = { currentRoute: [] }, action) => {
  switch (action.type) {
    case SET_ROUTE_KEY_MAP: {
      const routeKeyMap = action.routeKeyMap;
      return {
        ...state,
        routeKeyMap,
      };
    }
    case SET_PROD_SERVICE: {
      const prodServiceMap = action.map;
      return {
        ...state,
        prodServiceMap,
      };
    }
    case SET_LOCAL_BODY_CONTENT_FLG: {
      const localBodyLoad = action.localBodyLoad;
      return {
        ...state,
        localBodyLoad,
      };
    }
    case SET_GLOBAL_FAQ_CONTENT_LOADING: {
      const globalFAQContentLoadingState = action.globalFAQContentLoadingState;
      return {
        ...state,
        globalFAQContentLoadingState,
      };
    }
    case SET_GLOBAL_FAQ_CONTENT: {
      const globalFAQContent = action.globalFAQContent;
      return {
        ...state,
        globalFAQContent,
      };
    }
    case SET_LOGIN_USER_DETAILS: {
      const userDetails = action.userDetails;
      return {
        ...state,
        userDetails,
      };
    }
    case SET_NAV_ROUTES: {
      const navRoutes = action.navRoutes;
      return {
        ...state,
        navRoutes,
      };
    }
    case IS_FETCHING_NAV_ROUTES: {
      const navRouteFetchingState = action.navRouteFetchingState;
      return {
        ...state,
        navRouteFetchingState,
      };
    }
    case SET_NAV_CONTENT: {
      const existingRouteContent = state?.routeContent ?? {};
      const routeContent = { ...existingRouteContent, ...action.routeContent };
      return {
        ...state,
        routeContent,
      };
    }
    case IS_FETCHING_NAV_CONTENT: {
      const contentFetchingState = action.contentFetchingState;
      return {
        ...state,
        contentFetchingState,
      };
    }
    case SET_CODE_FILE_CONTENT_JSON: {
      const existingFileContentJSON = state?.fileJsonContent ?? {};
      const fileJsonContent = {
        ...existingFileContentJSON,
        ...action.fileJsonContent,
      };
      return {
        ...state,
        fileJsonContent,
      };
    }
    case SET_SWAGGER_JSON: {
      return {
        ...state,
        swaggerJson: {
          ...state?.swaggerJson,
          [action?.apiId]: action?.data,
        },
      };
    }
    case SET_EXTERNAL_RESPONSE: {
      return {
        ...state,
        externalResponse: action?.body
      };
    }
    case IS_FETCHING_SWAGGER_JSON: {
      return {
        ...state,
        swaggerJson: {
          ...state?.swaggerJson,
          loading: action?.data,
        },
      };
    }
    case SET_USER_AUTH: {
      const userDetails = action?.userDetails;
      return {
        ...state,
        userAuth: userDetails,
        userDetails
      };
    }
    case SET_CURRENT_ROUTE: {
      let data = [...action?.data];
      return {
        ...state,
        currentRoute: [...data],
      };
    }
    case SET_API_DESC_JSON: {
      let data = action?.data;
      return {
        ...state,
        apiDescJson: data,
      };
    }
    case SET_DELETE_FILE: {
      let { key, route, resetDoc } = action;
      let prod = state.prodServiceMap[route[0]];
      let currentProdIdx = state.prodServiceMap[route[0]]?.findIndex((o) => o?.title === route[1]);
      let currentServIdx = prod[currentProdIdx]?.children?.findIndex((o) => o?.title === route[2]);
      prod[currentProdIdx].children[currentServIdx].documents = prod[currentProdIdx].children[currentServIdx].documents.filter((o) => o !== key);
      let prodMap = state?.prodServiceMap;
      prodMap[route[0]] = prod;
      return {
        ...state,
        prodServiceMap: prodMap,
        resetDoc: !resetDoc
      };
    }
    case UPDATE_DOC_DATA: {
      const { key, isCurrent, schema } = action;
      let tempState = { ...state };
      let version = isCurrent ? 'currentVersion' : 'previousVersion';
      tempState.routeContent[schema][version].sections[1].body.documents = [...tempState.routeContent[schema][version].sections[1].body.documents, key];
      return {
        ...tempState
      };
    }
    default:
      return state;
  }
};

export default reducers;
