import React,{useState,useEffect} from 'react';
import CardDescriptor from './CardDescriptor';
import { useSelector } from 'react-redux';
import { selectors as appSelector } from '../../../../state/features/app';

const CardDescriptorContainer = (props) => {
  const { userDetails, prodServiceMap } = useSelector((state) => ({
    userDetails: appSelector.userDetails(state),
    prodServiceMap: appSelector.prodServiceMap(state),
  }));
  const content = props.content ?? {};
  const heading = content.heading ?? {};
  const tileContent = content.tileContent ?? [];
  const [editText,setEditText] = useState('');
  const [editField,setEditField] = useState('');
  const [badge,setBadge] = useState('Text');
  const [editRowLength,setEditRowLength] = useState(1);

  const [showEditContent,setShowEditContent] = useState(false);
  
  useEffect(()=> {
    if((Object.keys(heading)?.length) && editField) {
      if(editField == 'heading') {
        setEditText(heading?.name);
        setEditRowLength(1);
      }
      else if (editField === 'subHeading') {
        setEditText(heading.content);
        setEditRowLength(3);
      }
    }
  },[editField, heading]);

  const onMouseOver = (field) => {
    if(field === 'subHeading') {
      setBadge('Paragraph');
    }
    else {
      setBadge('Text');
    }
  };

  const onClickField = (field) => {
    setEditField(field);
    setShowEditContent(true);
    if(field === 'content') {
      setBadge('paragraph');
    }
  };

  const onHideEditContent =() => {
    setShowEditContent(false);

  };

  return (
    <>
      {props?.content?.heading?.name === 'Available Products & Services' ? (
        <CardDescriptor
          content={content}
          availableServices={prodServiceMap['Available Products']}
          userDetails={userDetails}
          editable={props.editable}
          editText={editText}
          badge={badge}
          showEditContent={showEditContent}
          onClickField={onClickField}
          onHideEditContent={onHideEditContent}
          tileContent={tileContent}
          heading={heading}
          editRowLength={editRowLength}
          onMouseOver={onMouseOver}
        />
      ) : (
        props?.content?.heading?.name === 'My Subscriptions' && (
          <CardDescriptor
            content={content}
            availableServices={prodServiceMap['My Subscriptions']}
            userDetails={userDetails}
            editable={props.editable}
            editText={editText}
            badge={badge}
            showEditContent={showEditContent}
            onClickField={onClickField}
            onHideEditContent={onHideEditContent}
            tileContent={tileContent} 
            heading={heading}  
            editRowLength={editRowLength}
            onMouseOver={onMouseOver}
          />
        )
      )}
    </>
  );
};

export default CardDescriptorContainer;
