import React from 'react';
import RejectModal from './RejectModal';

const RejectModalContainer = (props) => {
  return (
    <RejectModal
      rejectSubscription={props?.rejectSubscription}
      onClickClose={props?.onClickClose}
      onTextAreaChange={props?.onTextAreaChange}
      rejectDesc={props?.rejectDesc}
      onClickReject={props?.onClickReject}
      descErr={props?.descErr}
      currentRow={props?.currentRow}
    />
  );
};

export default RejectModalContainer;
