import React, { useMemo } from 'react';
import {
  Button,
  OutlineButton,
  Modal,
  Text,
  Textarea,
} from '../../../../CommonComponents';
import subscribedIcon from '../../../../../assets/images/subscriptionIcon.png';
import unsubscribedIcon from '../../../../../assets/images/unsubscribedIcon.png';
import Edit from '../../../../../assets/images/edit.png';

const Card = (props) => {
  const userName = props.userDetails?.unique_name ?? '';
  const onClickLink = () => {
    if (
      props?.tileContent?.productType &&
      props?.tileContent?.productType === 'Available Products' &&
      !props?.tileContent?.isApi &&
      userName &&
      props?.services?.length > 0
    ) {
      props?.clickLink(
        props?.tileContent,
        props?.tileContent?.component?.link?.split('?')[0] ||
          props?.tileContent?.link?.split('?')[0] ||
          props?.link
      );
    } else if (
      userName &&
      props?.services?.length > 0 &&
      props?.tileContent?.productType &&
      props?.tileContent?.productType !== 'Available Products'
    ) {
      props?.clickLink(
        props?.tileContent,
        props?.tileContent?.component?.link?.split('?')[0] ||
          props?.tileContent?.link?.split('?')[0] ||
          props?.link
      );
    }
  };
  

  const editUI = (tileContent) => {
    return props.editable ? (
      <div className='editableTools'>
        <div className='titleBadge'>{'text'}</div>
        <Button
          className='normalButton viewButton'
          onClick={() => props.onClickField(tileContent)}
        >
          <img src={Edit} alt='editImg' className='editImage' />
          Edit
        </Button>
      </div>
    ) : null;
  };

  const isLink = useMemo(() => {
    if (props?.tileContent?.productType !== 'Available Products') {
      if (userName && props?.services?.length > 0) {
        return true;
      }
    } else if (userName && props?.currentRoute?.length !== 3) {
      return true;
    }else if(!props?.tileContent?.productType){
      return false;
    }else {
      return false;
    }
  }, [userName, props?.tileContent?.productType, props?.services, props?.currentRoute]);


  return (
    <div
      className={` ${!isLink && !props.editable ? 'disabled tileView1' : 'tileView'} ${props.editable ? 'editable' : ''} ${
        props?.tileContent?.className
      } `}
    >
      {editUI(props?.tileContent)}
      {props?.tileContent?.imgSrc ? (
        <img
          src={props?.tileContent?.imgSrc}
          className='cardBannerImg'
          alt='bannerImg'
        />
      ) : null}
      <div className='subBlock'>
        {userName && props?.tileContent?.subscribed ? (
          <Button
            className={`rounded ${
              props?.tileContent?.subscribed === 'Pending'
                ? 'Pending'
                : props?.tileContent?.subscribed === 'Subscribed'
                  ? 'subscribed'
                  : 'unsubscribed'
            }`}
          >
            {props?.tileContent?.subscribed === 'Pending' ? (
              <img src={unsubscribedIcon} alt='graph' />
            ) : props?.tileContent?.subscribed === 'Subscribed' ? (
              <img src={subscribedIcon} alt='graph' />
            ) : (
              <img src={unsubscribedIcon} alt='graph' />
            )}
            {props?.tileContent?.subscribed === 'UnSubscribed'
              ? 'Unsubscribed'
              : props?.tileContent?.subscribed}
          </Button>
        ) : null}
        {!userName && (
          <img
            src={
              props?.tileContent?.iconSrc ||
              'https://d1lgeay9zr70r7.cloudfront.net/PrevenTech/ServiceChip.png'
            }
            alt='icon'
          />
        )}
        <p
          role={isLink && props?.tileContent?.productType ? 'button' : 'text'}
          onClick={(e) => isLink ? onClickLink(e) : ''}
          className={`title ${isLink ? 'hoverStyle' : ''}`}
        >
          {props?.tileContent?.title || props?.tileContent?.name || ''}
        </p>
        {
          <p className={`subTitle ${props.height ? 'fixedHeight' : ''}`}>
            {props?.tileContent?.body?.content ||
              props?.tileContent?.content ||
              ''}
          </p>
        }

        {userName && props?.tileContent?.subscribed && (
          <OutlineButton
            className='normalButton borderedButton'
            disabled={!isLink && !props?.editable}
            onClick={(e) =>
              props.navigateToSubService(
                e,
                props?.tileContent?.subscribed === 'UnSubscribed' ||
                  props?.tileContent?.subscribed === 'Canceled' ||
                  props?.tileContent?.subscribed === 'Rejected'
                  ? 'P'
                  : props?.tileContent?.subscribed === 'Subscribed'
                    ? 'U'
                    : 'C',
                props?.tileContent?.component?.link || props?.link,
                props?.tileContent
              )
            }
          >
            {props?.tileContent?.subscribed === 'UnSubscribed' ||
            props?.tileContent?.subscribed === 'Canceled' ||
            props?.tileContent?.subscribed === 'Rejected'
              ? 'Subscribe'
              : props?.tileContent?.subscribed === 'Subscribed'
                ? 'Unsubsribe'
                : 'Cancel'}
          </OutlineButton>
        )}
        {props?.tileContent?.subscribed === 'Subscribed' && userName && (
          <a className='link' role={'button'} onClick={() => onClickLink()}>
            {props?.tileContent?.component?.name ?? ''}
          </a>
        )}
        {props?.tileContent?.points ? (
          <ul className='points'>
            {props?.tileContent?.points?.map((item, index) => {
              return <li key={index}>{item}</li>;
            })}
          </ul>
        ) : null}
      </div>
      <Modal
        visible={props.showEditContent}
        hideModal={props.onHideEditContent}
        closeModalPopup={props.onHideEditContent}
        title={''}
        width={600}
        footer={
          <div className='editBannerFooter'>
            <OutlineButton
              className='normalButton viewButton'
              onClick={props?.onHideEditContent}
            >
              Cancel
            </OutlineButton>
            <Button className={'normalButton viewButton'} onClick={props?.onTileSave}>Save</Button>
          </div>
        }
        centered
        className='editModalPopup'
        closable={false}
      >
        <div className='editableModal'>
          <div className='formItem'>
            <div className='clearfix'>
              <label className='float-left labelText'>Section Title</label>
            </div>
            <Text
              value={props.editText}
              onChange={(value) => props.onEditData(value, 'editText')}
            />
          </div>
          <div className='formItem'>
            <div className='clearfix'>
              <label className='float-left labelText'>
                Section Description
              </label>
            </div>
            <Textarea
              value={props.editDescription}
              row={6}
              onChangeEvent={(value) => props.onEditData(value, 'editDescription')}
            />
          </div>
          {props?.tileContent?.points ? (
            <div className='formItem'>
              <label className='labelText addPoints'>Highlited Points</label>
              <div className='clearfix'>
                <div className='float-left addPoints'>
                  <Text
                    value={props.addPoints}
                    placeholder='Add Highlighted Points'
                    row={0}
                    className='editAddPoints'
                    onChange={(value) => props.onEditData(value, 'addPoints')}
                  />
                </div>
                <div className='float-right'>
                  <Button
                    disabled={props.addPoints.length === 0}
                    className={'normalButton viewButton addBtn'}
                    onClick={() => props.onAddPoints()}
                  >
                    Add
                  </Button>
                </div>
              </div>
              <ul className='listedPoints'>
                {props.editPoints?.map((item, index) => {
                  return (
                    <li key={index}>
                      <div className='clearfix'>
                        <div className='float-left'>{item}</div>
                        <div className='float-right'>
                          <span
                            className='fa fa-trash trashImg'
                            onClick={() => props.deleteList(index)}
                          ></span>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : null}
        </div>
      </Modal>
    </div>
  );
};

export default Card;
