import React from 'react';
import { Spin } from 'antd';
import './Spin.scss';

const SpinComponent = ({
  className = '',
  // tip='',
  spinning = false,
  ...props
}) => {


  return (
    <>
      <Spin spinning={spinning} className={className} tip={props?.tip || 'Loading...'}>
        {props.children}
      </Spin>
    </>

  );

};

export default SpinComponent;

