import React /* , { useMemo } */ from 'react';
import ReactJson from 'react-json-view';
import { useSelector } from 'react-redux';
import { Spinner } from '../../CommonComponents';
import { selectors as appSelector } from '../../../state/features/app';
import './JSONCodeReader.scss';

const YMLEditor = ({ file,onEdit }) => {
  const { jsonList } = useSelector((state) => ({
    jsonList: appSelector.swaggerJson(state),
  }));

  const jsonLoading = jsonList?.loading || false;

  return (
    <>
      {jsonLoading ? (
        <Spinner spinning={true} tip='Loading Code...' />
      ) : (
        <ReactJson src={file} displayDataTypes={false} onEdit={onEdit} />
      )}
    </>
  );
};

export default YMLEditor;
