import React from 'react';
import Subscription from './Subscription';

const SubscriptionContainer = (props) => {
  return (
    <Subscription
      breadcrumbList={props.breadcrumbList}
      breadcrumbOnClick={props.breadcrumbOnClick}
      partnerName={props.partnerName}
      onAddSubscription={props?.onAddSubscription}
      prodOptions={props?.prodOptions}
      product={props?.product}
      onChangeProducts={props?.onChangeProducts}
      onServiceCheck={props?.onServiceCheck}
      services={props?.services}
    />
  );
};

export default SubscriptionContainer;