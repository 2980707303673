const SortObject = (a, b) => {
  try {
    if ((a || a >= 0) && (b || b >= 0)) {
      if (typeof a == 'string' && typeof b == 'string') {
        if (a.toUpperCase() > b.toUpperCase()) { return -1; }
        if (a.toUpperCase() < b.toUpperCase()) { return 1; }
      } else {
        return a - b;
      }
    }
    return 0;
  } catch (ex) {
    console.error(ex);
  }
};

export default SortObject;