import React from 'react';
import { Input } from 'antd';
import './Searchbar.scss';

const Searchbar = ({
  placeholder,
  onSearch=() => { },
  style='',
  onChange=() => { },
  className='',
  onEnter = () => { },
  onBlur = () => { },
  value=undefined,
}) => {
  const { Search } = Input;

  return (
    <div className={className}>
      <Search
        placeholder={placeholder}
        onSearch={onSearch}
        allowClear
        value={value}
        onChange={onChange}
        style={style}
        onPressEnter={onEnter}
        onBlur={onBlur}
      />
    </div>
  );
};

export default Searchbar;
