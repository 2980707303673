import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  // operations as appOps,
  selectors as appSelector,
} from '../../../../state/features/app';
import {
  selectors as adminSelector,
} from '../../../../state/features/admin';

import Navigation from './Navigation';
import { useLocation } from 'react-router-dom';

const NavigationContainer = (props) => {
  const { routeKeyMap, projectDataChanged } = useSelector((state) => ({
    routeKeyMap: appSelector.routeKeyMap(state),
    projectDataChanged: adminSelector.projectDataChanged(state),
  }));

  const location = useLocation();

  const currentRoute = useMemo(() => {
    return location?.search.replaceAll('%20', ' ').split('nav=')[1]?.split('/');
  }, [location]);

  const [selectedKeys, setSelectedKeys] = useState([]);
  const [activeTopic, setActiveTopic] = useState('');
  const [expandKeys, setExpandKeys] = useState([]);
  // const [currentTopic, setCurrentTopic] = useState('');
  const currentTopic = useRef('');
  const currentKeys = useRef([]);

  // const changedTopic = useMemo(() => projectDataChanged ? currentTopic.current : activeTopic, [projectDataChanged]);
  // const changedKeys = useMemo(() => projectDataChanged ? currentKeys.current : selectedKeys, [projectDataChanged]);


  useEffect(() =>{
    if(!projectDataChanged){
      currentTopic.current = activeTopic;
    }
  },[activeTopic, projectDataChanged]);

  useEffect(() =>{
    if(!projectDataChanged){
      currentKeys.current = selectedKeys;
    }
  },[selectedKeys, projectDataChanged]);

  useEffect(() => {
    if (currentRoute?.length > 0) {
      let cloneArray = [...currentRoute];
      const sectionName = cloneArray;
      const stringArray = `${cloneArray.join('/')}`;
      const selectedKey = routeKeyMap?.[stringArray] ?? '';
      if (selectedKey !== '') {
        let selectedKeys = [selectedKey];
        setSelectedKeys([...selectedKeys]);
        setActiveTopic(sectionName.shift());

        while (cloneArray.length > 0) {
          cloneArray.pop();
          const stringArray = `/${cloneArray.join('/')}`;
          const selectedKey = routeKeyMap?.[stringArray] ?? '';
          if (selectedKey !== '') {
            selectedKeys.push(selectedKey);
          }
        }
        setExpandKeys([...expandKeys, ...selectedKeys]);
      }
    } else {
      setActiveTopic('');
      setSelectedKeys([]);
    }
  }, [currentRoute]);

  const triggerRedirection = useCallback(
    (routeName = '', localBodyLoad = false, routePathArr, heading) => {
      const tempArr = routePathArr?.map((ar) => ar?.title || ar);
      const tree = [heading, ...tempArr];
      props?.onNavClick(tree, routeName, localBodyLoad, projectDataChanged);
      if (props.mobile) {
        props.setShowMenu(false);
      }
    },
    [currentRoute, projectDataChanged]
  );

  const getPath = (arr, search) => {
    if (arr.key === search) {
      return [{ title: arr.title, renderFrom: arr.renderFrom }];
    } else if (arr.children || Array.isArray(arr)) {
      let children = Array.isArray(arr) ? arr : arr.children;
      for (let child of children) {
        let result = getPath(child, search);
        if (result) {
          if (arr.key) {
            result.unshift(arr.title);
          }
          return result;
        }
      }
    }
  };

  const onLeftNavSelection = (key, { node }) => {
    if(key?.length > 0) {
      const selectedSectionName = node?.tree?.[0] ?? '';
      const selectedItem = node?.key ?? '';
      const selectedNav = props?.navItems.find(
        (item) => item.heading === selectedSectionName
      );
      const routePathArr = getPath(selectedNav?.nav || [], selectedItem);
  
      const constructedRoute = [...routePathArr];
      constructedRoute[constructedRoute.length - 1] =
        constructedRoute[constructedRoute.length - 1].title;
      const redirectRoutePath = constructedRoute.join('/');
      let renderFromNav =
        routePathArr[routePathArr.length - 1]?.renderFrom == 'portal';
      if (redirectRoutePath) {
        triggerRedirection(
          redirectRoutePath,
          renderFromNav,
          routePathArr,
          selectedNav?.heading
        );
      }
      setSelectedKeys(key);
      setActiveTopic(selectedSectionName);
    }
  };

  const onExpandTree = (keys) => {
    setExpandKeys(keys);
  };



  return (
    <Navigation
      navItems={props.navItems}
      showMenu={props.showMenu}
      selectedKeys={selectedKeys}
      activeTopic={activeTopic}
      // selectedKeys={projectDataChanged ? changedKeys : selectedKeys}
      // activeTopic={projectDataChanged ? changedTopic : activeTopic}
      setSelectedKeys={setSelectedKeys}
      setActiveTopic={setActiveTopic}
      onLeftNavSelection={onLeftNavSelection}
      onExpandTree={onExpandTree}
      expandKeys={expandKeys}
    />
  );
};

export default NavigationContainer;
