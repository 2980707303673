import React from 'react';
import { RowComponent, ColComponent,Button } from '../../../CommonComponents';
import {SigninModal} from '../../CommonWidget';
import './StageDescriptor.scss';

const StageDescriptor = (props) => {
  const content = props.content ?? {};

  const heading = content?.heading ?? {};
  const body = content?.body ?? {};
  const userName = props.userDetails?.unique_name ?? '';
  
  const loadHeading = () => {
    if(Object.keys(heading)?.length > 0) {
      if(heading?.type === 'image') {
        const imageSrc = heading?.imgSrc ?? '';
        return <img src={imageSrc} alt='Heading' className='bannerImage'/>;
      } 
      return (
        <>
          <p>{heading?.quickNote}</p>
          <p className='topic'>{heading?.name}</p>
        </>
      );
      
    } 
    
  };

  const loadComponent = (component = {}) => {
    if(Object.keys(component)?.length > 0) {
      if(component?.type === 'button') {
        return <>
          <Button className=''>{component?.name}</Button>
        </>;
      }
    }
  };

  const loadBody = () => {
    if(Object.keys(body)?.length > 0) {
      if(heading?.type === 'image') {
        const imageSrc = heading?.imgSrc ?? '';
        return <img src={imageSrc} alt='Step Descriptor Body' className='bannerImage'/>;
      } 
      return (
        <>
          <p className='para'>{body?.content}</p>
          {
            !userName?
              <>
                {/* <Button className=''>Become a Partner</Button> */}
                <div className='loginlink'>Already a partner? 
                  <a href='/' onClick={(e) =>props.onSigninClick(e)}>Sign In</a></div>
              </>
              : loadComponent(body?.component)
          }
        </>
      );
      
    } 
    
  };

  const loadStepper = () => {
    const stepList = body?.steps ?? [];

    return stepList.map((step, index) => {
      return (
        <div key={index}>
          <div className='stepperOuter'>
            <div className='stepperInner'></div>
          </div>
          <p className='stepperName'>{step?.name ?? ''}</p>
        </div>
      );
    });
  };

  return (
    <>
      <div className='registration'>
        {loadHeading()}
        <RowComponent>
          <ColComponent xs={24} md={24} lg={12}>
            {loadBody()}
          </ColComponent>
          <ColComponent xs={24} md={24} lg={12} className='centerItem'>
            <div className='stepperBlock'>
              {loadStepper()}
            </div>
          </ColComponent>
        </RowComponent>
      </div>
      <SigninModal
        enableSignIn={props.enableSignIn}
        hideModalPopup={props.onSigninHide}
      />
    </>
  );
};

export default StageDescriptor;