import React, {useEffect, useState} from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Spinner } from '../../../CommonComponents';
import Collapsible from './Collapsible';

import {
  operations as appOps,
  selectors as appSelector
} from '../../../../state/features/app';

const CollapsibleContainer = (props) => {

  const dispatch = useDispatch();
  const dispatchGetGlobalFAQ = appOps.dispatchGetGlobalFAQ(dispatch);

  const {
    globalFAQContent,
    globalFAQContentLoadingState
  } = useSelector(
    state => ({
      globalFAQContent: appSelector.globalFAQContent(state),
      globalFAQContentLoadingState: appSelector.globalFAQContentLoadingState(state),
    })
  );

  const [openPanels, setOpenPanels] = useState([]);
  const [faqPath, setFaqPath] = useState('');

  const onAccordianClick = (open) => {
    setOpenPanels(open);
  };

  useEffect(() => {
    const faqURL = props.content?.body?.bodyContentURL ?? '';
    dispatchGetGlobalFAQ(faqURL);
    setFaqPath(faqURL);
  }, []);

  return (
    globalFAQContentLoadingState === true ?
      <Spinner spinning={globalFAQContentLoadingState} tip='Loading FAQ...' /> :
      <Collapsible
        openPanels={openPanels}
        onAccordianClick={onAccordianClick}
        content={props.content}
        globalFAQContent={globalFAQContent?.[faqPath] ?? []}
      />
  );
};

export default CollapsibleContainer;