import React from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { operations as appOps } from '../../../../state/features/app';

import CodeBlock from './CodeBlock';
import { useDispatch } from 'react-redux';

const CodeBlockContainer = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const dispatchSetSimulate = appOps.dispatchSetSimulate(dispatch);

  const onSwaggerClick = () => {
    navigate(
      {
        pathname: '/swagger',
        search: `?portal=${true}&nav=${searchParams.get('nav')}/swagger`,
      },
      { state: location?.pathname }
    );
  };

  const onClickSimulate = (data) => {
    dispatchSetSimulate(data);
  };

  // dispatchSetSimulate

  return (
    <CodeBlock
      content={props.content}
      onSwaggerClick={onSwaggerClick}
      loading={props?.loading}
      swaggerJson={props?.swaggerJson}
      onClickSimulate={onClickSimulate}
      showSimulate={props?.showSimulate}
      heading={props?.heading}
    />
  );
};

export default CodeBlockContainer;
