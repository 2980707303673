const getImg = (name) => {
  if (name == 'Pending') {
    return 'https://d1lgeay9zr70r7.cloudfront.net/IntegrationManagementProject/Pending.png';
  } else if (name == 'Rejected') {
    return 'https://d1lgeay9zr70r7.cloudfront.net/IntegrationManagementProject/Rejected.png';
  } else if (name == 'In Progress') {
    return 'https://d1lgeay9zr70r7.cloudfront.net/IntegrationManagementProject/InProgress.png';
  } else if (name == 'Ready For Production') {
    return 'https://d1lgeay9zr70r7.cloudfront.net/IntegrationManagementProject/ReadyForProduction.png';
  } else if (name == 'Production Release') {
    return 'https://d1lgeay9zr70r7.cloudfront.net/IntegrationManagementProject/FullProduction.png';
  }
  return 'https://d1lgeay9zr70r7.cloudfront.net/IntegrationManagementProject/Pending.png';
};

export default getImg;
