import React from 'react';
import ApproveModal from './ApproveModal';
const ApproveModalContainer = (props) => {
  return (
    <ApproveModal
      showApproveModal={props.showApproveModal}
      hideApproveModal={props.hideApproveModal}
      onClickApprove={props.onClickApprove}
      rowValue={props.rowValue}
      onClickModalApprove={props.onClickModalApprove}
      updatedValue={props.updatedValue}
    />
  );
};

export default ApproveModalContainer;