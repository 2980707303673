import React from 'react';

import {Modal} from '../../../CommonComponents';

import './VideoPlayer.scss';

const VideoPlayer = (props) => {

  return (
    props.showModal &&
      <Modal
        visible={props.showModal}
        hideModal={props.hideModalPopup}
        closeModalPopup={props.hideModalPopup}
        title={props.title}
        width={900}
        footer={null}
        centered
      >
        <video width="100%" controls autoPlay loop={false} controlsList="nodownload">
          <source src={props.videoContent} type="video/mp4" />
        </video>
      </Modal>
  );
};

export default VideoPlayer;