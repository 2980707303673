import React from 'react';
import './InfoBox.scss';
const InfoBox = () => {
  return(
    <div className='clearfix'>
      <div className='borderBlock'>
        <div className='title'>No External Response Registered</div>
        <div className='description'>Please Click the <span>Register External Response</span> button to Register the external response API</div>
      </div>
    </div>
  );
};
export default InfoBox;