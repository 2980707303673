import React from 'react';
import RegisteredTable from './RegisteredTable';
import { Button, Modal, OutlineButton, Searchbar } from '../../../../../../CommonComponents';
import ExternalResponseAPI from '../../../../../CommonWidget/ExternalResponseBadge/RegisterExternalResponseAPI';

const RegisteredExternalResponse = (props) => {
  const {
    subHeading,
    heading,
    tableData,
    columns,
    loading,
    responsePayloadValue,
    responsePayload,
    hideResponsepayloadModal,
    activePagination,
    onChangePagination,
    isVisible,
    hideModal,
    onClickSave,
    editRecord,
    onFormChange,
    error,
    onAddExtResponse,
    partners,
    editMode,
    dropdownsChange,
    prodOptions,
    onChangeProducts,
    serviceOptions,
    dropdownLoader,
    prognosticErr
  } = props;


  const content = (
    <div>
      <p>client_id=&lt;clientId&gt;&amp;client_secret=&lt;clientSecret&gt;&amp;scope=&lt;scope&gt;</p>
      <p>username=&lt;username&gt;&amp;password=&lt;password&gt;&amp;grant_type=&lt;grantType&gt;</p>
    </div>
  );


  const optionsValue = [{
    value: 'REST',
    label: 'REST'
  }, {
    value: 'SOAP',
    label: 'SOAP'
  }, {
    value: 'Azure-Event-Hub',
    label: 'Azure-Event-Hub'
  }];

  const fileTypeOptions = [{
    value: 'JSON',
    label: 'JSON'
  },
  {
    value: 'XML',
    label: 'XML'
  }
  ];

  const locationOptions = [{
    value: 'header',
    label: 'header'
  },
  {
    value: 'params',
    label: 'params'
  }
  ];

  const authMethodOptions = [
    {
      value: 'No Auth',
      label: 'No Auth'
    },
    {
      value: 'OAuth',
      label: 'OAuth'
    },
    {
      value: 'x-Api-Key',
      label: 'x-Api-Key'
    },
    {
      value: 'SOAPCredentials',
      label: 'SOAPCredentials'
    },
    {
      value: 'mTLS',
      label: 'mTLS'
    }
  ];

  return (
    <div className='rolesAndPermissions'>
      <div className='approvableTable'>
        <div className={`clearfix ${subHeading ? 'headerblock' : ''}`}>
          <div className='float-left'>
            <p className={subHeading ? 'heading' : 'subHeading'}>{heading}</p>
            {subHeading ? <p className='subHeading'>{subHeading}</p> : null}
          </div>
        </div>
        <div className='float-left'>
          <Searchbar
            value={props?.searchValue}
            onChange={props?.onSearchChange}
            onSearch={props?.onSearch}
            onEnter={props?.onEnter}
            onBlur={props?.onBlur}
            placeholder={'Search by partner name'}
            className='searchbar'
          />

          {/*  value={props?.searchValue}
              onChange={props?.onSearchChange}
              onSearch={props?.onSearch}
              onEnter={props?.onEnter}
              onBlur={props?.onBlur}
              placeholder={'Search by partner name'}
              className='searchbar' */}
        </div>
        <div className="float-right">
          <Button className='normalButton viewButton' onClick={onAddExtResponse}>
            Register external response
          </Button>
        </div>
      </div>
      <RegisteredTable
        tableData={tableData}
        loading={loading}
        columns={columns}
        responsePayloadValue={responsePayloadValue}
        responsePayload={responsePayload}
        hideResponsepayloadModal={hideResponsepayloadModal}
        activePagination={activePagination}
        onChangePagination={onChangePagination}
      />
      <Modal
        visible={isVisible}
        hideModal={dropdownLoader ? null : hideModal}
        closeModalPopup={dropdownLoader ? null : hideModal}
        title={editMode ? 'Edit Registered External Response' : 'Register External Response'}
        width={620}
        className={'Payload'}
        footer={
          <div className='viewBlock'>
            <OutlineButton
              className='normalButton viewButton'
              onClick={hideModal}
              disabled={dropdownLoader}
            >
              Cancel
            </OutlineButton>
            <Button
              className={'normalButton viewButton'}
              disabled={Object.keys(error).length > 0}
              onClick={onClickSave}
            >
              Save
            </Button>
          </div>
        }
        centered
      >
        <div>
          <div className="formControl">
            <ExternalResponseAPI
              onFormChange={onFormChange}
              formData={editRecord}
              error={error}
              optionsValue={optionsValue}
              fileTypeOptions={fileTypeOptions}
              locationOptions={locationOptions}
              authMethodOptions={authMethodOptions}
              content={content}
              partners={partners}
              editMode={editMode}
              dropdownsChange={dropdownsChange}
              prodOptions={prodOptions}
              onChangeProducts={onChangeProducts}
              serviceOptions={serviceOptions}
              dropdownLoader={dropdownLoader}
              prognosticErr={prognosticErr}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default RegisteredExternalResponse;