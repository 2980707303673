import React, { useState, useEffect, useCallback } from 'react';
import FooterContent from './FooterContent';
import { useDispatch, useSelector } from 'react-redux';
import { operations as appOps } from '../../../../state/features/app';

const FooterContentContainer = (props) => {
  const content = props.content ?? {};
  const title = content.title ?? '';
  const content1 = content.content1 ?? {};
  const content2 = content.content2 ?? {};
  // const [editField,setEditField] = useState('');
  const [badge, setBadge] = useState('Text');
  const [editRowLength, setEditRowLength] = useState(1);
  // const [showEditContent, setShowEditContent] = useState(false);
  const [showLink, setShowLink] = useState(false);
  const [visibleMessage, setVisibleMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    orgName: '',
    message: ''
  });

  const [error, setError] = useState({});

  const userDetails = useSelector((state) => state?.app?.userDetails);
  const dispatch = useDispatch();
  const sendMessage = appOps.dispatchSendContactMessage(dispatch);


  const { editField, editLink, /* setEditLink, */ editText, setEditText } =
    props;

  useEffect(() => {
    if (editField) {
      if (Object.keys(content1)?.length) {
        if (editField == 'title') {
          setShowLink(false);
          setEditRowLength(1);
        } else if (editField === 'text') {
          setShowLink(false);
          setEditRowLength(2);
        } else {
          // setEditLink(content1.link);
          setShowLink(true);
          setEditRowLength(1);
        }
      }
    }
  }, [editField, JSON.stringify(content1)]);

  const onClickFieldInter = (value, field, key, isLink) => {
    props?.onClickField(value, field, key, isLink);
    if (key === 'text') {
      setBadge('paragraph');
    }
  };


  const contactClick = (e) => {
    e.preventDefault();
    setVisibleMessage(true);
    setMessage('');
  };

  const hideMessagepopup = () => {
    setVisibleMessage(false);
    setError({});
  };

  const onEditData = (e) => {
    setEditText(e?.target?.value);
  };

  const onChangeMessage = (e) => {
    setMessage(e.target.value);
  };

  const onSendClick = useCallback((userDetails) => {
    let body = {};
    if (userDetails) {
      body = {
        message: message,
        userName: userDetails?.name,
        emailId: userDetails?.unique_name,
        partnerName: userDetails?.partnerName,
      };
    } else {
      body = {
        message: formData?.message,
        userName: formData?.name,
        emailId: formData?.email,
        partnerName: formData?.orgName,
      };
    }

    sendMessage(body);
    hideMessagepopup();
  }, [userDetails, message, formData]);

  const onClickSubmit = () => {
    let mailFormat = /^w+([.-]?w+)*@w+([.-]?w+)*(.w{2,3})+$/;
    if (userDetails) {
      if (message === '') {
        setError({
          ...error,
          message: 'Please enter message'
        });
      } else {
        onSendClick(userDetails);
        setError({});
      }
    } else {
      if (formData?.message === '' && formData?.email === '') {
        setError({
          ...error,
          message: 'Please enter message',
          email: 'please enter email'
        });
      } else if (formData?.message === '') {
        setError({
          message: 'Please enter message'
        });
      } else if (formData?.email === '') {
        setError({
          email: 'Please enter Email'
        });
      } else if (formData?.email !== '' && mailFormat.test(formData.email)) {
        setError({
          email: 'Please enter a valid Email'
        });
      } else {
        onSendClick(userDetails);
        setError({});
      }
    }
  };

  const onFOrmDataChange = (e, name) => {
    setFormData({
      ...formData,
      [name]: e.target.value
    });
  };

  return (
    <FooterContent
      content={content}
      title={title}
      content1={content1}
      content2={content2}
      editable={props.editable}
      editText={editText}
      editField={editField}
      showEditContent={props?.showEditContent}
      onClickField={onClickFieldInter}
      onHideEditContent={props?.onHideEditContent}
      badge={badge}
      editRowLength={editRowLength}
      showLink={showLink}
      editLink={editLink}
      setEditLink={props?.setEditLink}
      onEditData={onEditData}
      onSaveWebContent={props?.onSaveWebContent}
      contactClick={contactClick}
      visibleMessage={visibleMessage}
      hideMessagepopup={hideMessagepopup}
      message={message}
      onChangeMessage={onChangeMessage}
      onSendClick={onSendClick}
      error={error}
      formData={formData}
      onClickSubmit={onClickSubmit}
      userDetails={userDetails}
      onFOrmDataChange={onFOrmDataChange}
    />
  );
};

export default FooterContentContainer;
