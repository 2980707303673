import React from 'react';
import CodeBlock from '../CodeBlock';

const APIDescriptor = (props) => {
  const staticData = {
    metadata: {
      messageID: 'e7c85328-55ad-4114-8e38-1c250b12a43e',
      deviceId: '192000000000001',
      esn: '19200001',
      customer: 'Cummins',
      oem: 'Navistar',
      bu: 'PSBU',
      dataType: 'J1939',
      subType: 'HB',
      fileType: 'JSON',
      externalKeys: ['12345', 'abc-def'],
    },
    data: {},
  };

  return (
    <>
      <CodeBlock
        content={props.content}
        swaggerJson={props.jsonData}
        loading={props.loading}
        title={props?.title}
        showHeading={props?.showHeading}
        showSimulate={true}
      />
      <CodeBlock
        content={props.content}
        swaggerJson={staticData}
        loading={props.loading}
        title={props?.title}
        heading="Sample Response"
        showHeading={props?.showHeading}
      />


    </>
  );
};

export default APIDescriptor;
