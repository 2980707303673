import React, { useState, useEffect, useCallback } from 'react';
import { OutlineButton } from '../../../../../../CommonComponents';
import Partners from './Partners';
import {
  operations as adminOps,
  selectors as adminSelector,
} from '../../../../../../../state/features/admin';
import { useDispatch, useSelector } from 'react-redux';
import sortData from '../../../../../../../utils/sortData';
import Zonar from '../../../../../../../assets/images/zonar.png';

// const sampleData = {
//   'PartnerName': 'sX',
//   'EmailAddress': 'SAC',
//   'MoratoriumDuration': 'Cz',
//   'CustomerDataUsage': [
//     'Product & Service Improvement',
//     'Commercial Use'
//   ],
//   'CertifiedProducts': 'PreventTech',
//   'TSPID': '12345'
// };

const PartnersContainer = () => {
  const columns = [
    {
      title: 'Partner Name',
      dataIndex: 'partner_name',
      key: 'partner_name',
      render: (row) => {
        return (
          <div>
            <img src={Zonar} className='logoIcon' /> {row}
          </div>
        );
      },
      sorter: (a, b) => sortData(a.partner_name, b.partner_name),
      width: 200,
    },
    {
      title: 'Partner Type',
      dataIndex: 'partnerType',
      key: 'partnerType',
      width: 160,
      align: 'left',
      sorter: (a, b) => {
        sortData(a.partnerType, b.partnerType);
      },
    },
    {
      title: 'Partner ID',
      dataIndex: 'partner_id',
      key: 'partner_id',
      width: 180,
      align: 'left',
      sorter: (a, b) => sortData(a.partner_id, b.partner_id),
    },
    {
      title: 'TSP ID',
      dataIndex: 'tspId',
      key: 'tspId',
      width: 160,
      align: 'left',
      sorter: (a, b) => sortData(a.TSPID, b.TSPID),
    },
    {
      title: 'Subscriptions',
      dataIndex: 'activeSubscriptions',
      key: 'activeSubscriptions',
      width: 80,
      align: 'left',
      sorter: (a, b) => sortData(a.activeSubscriptions.toString(), b.activeSubscriptions.toString()),
    },
    {
      title: 'Actions',
      dataIndex: 'Action',
      key: 'Action',
      width: 330,
      align: 'center',
      render: (_, row) => {
        return (
          <>
            <OutlineButton
              className='normalButton viewButton'
              onClick={() => onClickEdit(row)}
            >
              Edit
            </OutlineButton>
            <OutlineButton
              className='normalButton viewButton'
              onClick={() => onClickViewBtn(row)}
            >
              View Profile
            </OutlineButton>
          </>
        );
      },
    },
  ];

  const dispatch = useDispatch();
  const dispatchGetProjectPartners =
    adminOps.dispatchGetProjectPartners(dispatch);
  const dispatchGetProjectPartnerSubscriptions =
    adminOps.dispatchGetProjectPartnerSubscriptions(dispatch);
  const dispatchGetCertifiedProducts =
    adminOps.dispatchGetCertifiedProducts(dispatch);

  const dispatchAddEditPartner = adminOps.dispatchAddEditPartner(dispatch);

  const { partners } = useSelector((state) => state?.admin || {});
  const { loading, certifiedProducts } = useSelector((state) => ({
    loading: adminSelector.loading(state),
    certifiedProducts: adminSelector.certifiedProducts(state),
  }));

  const [viewBlock, setViewBlock] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [partnerId, setPartnerId] = useState('');
  const [partnerName, setPartnerName] = useState('');
  const [showAddPartnerModal, setShowAddPartnerModal] = useState(false);
  const [radioCompanyyReg, setRadioCompanyyReg] = useState('Off');
  const [radioSubscription, setRadioSubscription] = useState('Off');
  const [radioDataIntegration, setRadioDataIntegration] = useState('Off');
  const [productsOptions, setPartnerOptions] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    pageSizeOptions: [5, 10, 20, 50, 100],
    showSizeChanger: true,
  });
  const [formData, setFormData] = useState({
    PartnerName: '',
    TSPID: '',
    EmailAddress: '',
    CompanyRegistrationAPI: radioCompanyyReg,
    SubscriptionAPI: radioSubscription,
    DataIntegratorSupport: '',
    CertifiedProducts: [],
    partnerId: null,
    tspId: null,
  });
  const [error, setError] = useState({
    PartnerName: undefined,
    EmailAddress: undefined
  });
  const companyReg = ['On', 'Off'];
  const subscriptionOption = ['On', 'Off'];
  const dataIntegrationOption = ['On', 'Off'];



  useEffect(() => {
    if (certifiedProducts?.length > 0) {
      let data = [];
      certifiedProducts?.map((item) => {
        data?.push({ label: item?.name, value: item?.id });
      });
      setPartnerOptions(data);
    }
  }, [certifiedProducts]);

  const breadcrumbOnClick = () => {
    setViewBlock(false);
  };

  const onClickViewBtn = (row) => {
    dispatchGetProjectPartnerSubscriptions(row?.partner_id);
    setPartnerId(row?.partner_id);
    setPartnerName(row?.partner_name);
    setViewBlock(true);
  };

  const triggerGetApi = useCallback((searchValue) => {
    dispatchGetProjectPartners(searchValue);
  }, []);

  useEffect(() => {
    triggerGetApi('');
    dispatchGetCertifiedProducts();
  }, []);

  const onSearchChange = (e) => {
    setSearchValue(e?.target?.value);
  };

  const onSearch = (value) => {
    setSearchValue(value);
    triggerGetApi(value);
  };

  const hideAddPartnerPopup = () => {
    setShowAddPartnerModal(false);
    setEditMode(false);
    setFormData({
      PartnerName: '',
      TSPID: '',
      EmailAddress: '',
      CompanyRegistrationAPI: 'Off',
      SubscriptionAPI: 'Off',
      DataIntegratorSupport: 'Off',
      CertifiedProducts: [],
    });
    setRadioCompanyyReg('Off');
    setRadioSubscription('Off');
    setRadioDataIntegration('Off');
    setError({});
  };
  const onAddPartnerClick = () => {
    setShowAddPartnerModal(true);
  };

  const onChangeCompanyRegEvent = (e) => {
    setRadioCompanyyReg(e.target.value);
  };
  const onChangeSubscriptionEvent = (e) => {
    setRadioSubscription(e.target.value);
  };
  const onChangedataintegrationEvent = (e) => {
    setRadioDataIntegration(e.target.value);
  };

  const onFormChange = (value, fieldName/* , formField */) => {
    let letters = /[^a-zA-Z0-9. ]/;
    let errors = {};
    let isValidEmail = validateEmail(formData?.EmailAddress);
    if (fieldName === 'PartnerName') {
      if (value.trim() === '') {
        errors['partnerName'] = 'Please enter partner name.';
      } else if (formData.PartnerName.trim().match(letters)) {
        errors['partnerName'] = 'Special charecters are not allowed.';
      }
    } else {
      delete errors[fieldName];
    }
    if (fieldName === 'EmailAddress') {
      if (value === '') {
        errors['emailAddress'] = 'Please enter email address.';
      } else if (!isValidEmail) {
        errors['emailAddress'] = 'Please enter valid email address.';
      }
    } else {
      delete errors[fieldName];
    }

    // if (value === '') {
    //   errors[fieldName] = `Please enter ${formField}`;
    // } 
    setError(errors);
    setFormData((prevState) => ({ ...prevState, [fieldName]: value }));
  };


  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validateForm = () => {
    let letters = /[^a-zA-Z0-9. ]/;
    let errors = {};
    let isValidEmail = validateEmail(formData?.EmailAddress);
    if (formData?.PartnerName.trim() === '') {
      errors['partnerName'] = 'Please enter partner name.';
    } else if (formData.PartnerName.trim().match(letters)) {
      errors['partnerName'] = 'Special charecters are not allowed.';
    }
    if (formData['EmailAddress'] === '') {
      errors['emailAddress'] = 'Please enter email address.';
    } else if (!isValidEmail) {
      errors['emailAddress'] = 'Please enter valid email address.';
    }
    setError(errors);
    if (Object.keys(errors)?.length === 0) {
      let certificate = [];
      productsOptions?.forEach((item) => {
        let isAlreadyAvailable = formData?.CertifiedProducts.findIndex((cert) => cert === item?.value) > -1;
        if (isAlreadyAvailable) {
          certificate.push({
            id: item?.value,
            name: item?.label,
            isEnabled: true
          });
        } else {
          certificate.push({
            id: item?.value,
            name: item?.label,
            isEnabled: false
          });
        }
      });
      const body = {
        ...(formData?.partnerId && { partnerId: formData?.partnerId }),
        ...(formData?.tspId && { tspId: formData?.tspId }),
        partnerName: formData?.PartnerName?.trim(),
        emailAddress: formData?.EmailAddress,
        companyRegistartionApi: radioCompanyyReg !== 'Off',
        subscriptionApi: radioSubscription !== 'Off',
        dataIntegratorSupport: radioDataIntegration !== 'Off',
        certifiedProducts: certificate
      };
      return body;
    }
  };

  const onClickSave = () => {
    const body = validateForm();
    if (body) {
      hideAddPartnerPopup();
      setSearchValue('');
      dispatchAddEditPartner(body);
    }
  };

  const onClickEdit = (row) => {
    setEditMode(true);
    let certificates = [];
    row?.certifiedProducts?.map((item) => {
      if (item?.isEnabled === true) {
        certificates.push(item?.id);
      }
    });
    setFormData({
      PartnerName: row?.partner_name.trim(),
      TSPID: row?.tspId,
      EmailAddress: row?.emailAddress,
      CompanyRegistrationAPI: row?.companyRegistartionApi === true,
      SubscriptionAPI: row?.subscriptionApi === false ? 'Off' : 'On',
      DataIntegratorSupport: row?.dataIntegratorSupport === false ? 'Off' : 'On',
      CertifiedProducts: certificates,
      partnerId: row?.partner_id,
      tspId: row?.tspId
    });
    setRadioCompanyyReg(row?.companyRegistartionApi === false ? 'Off' : 'On');
    setRadioSubscription(row?.subscriptionApi === false ? 'Off' : 'On');
    setRadioDataIntegration(row?.dataIntegratorSupport === false ? 'Off' : 'On',);
    setShowAddPartnerModal(true);

  };

  return (
    <Partners
      dataSource={partners}
      columns={columns}
      viewBlock={viewBlock}
      breadcrumbOnClick={breadcrumbOnClick}
      loading={loading}
      searchValue={searchValue}
      onSearchChange={onSearchChange}
      onSearch={onSearch}
      onEnter={() => triggerGetApi(searchValue)}
      onBlur={() => {}}
      partnerId={partnerId}
      partnerName={partnerName}
      onAddPartnerClick={onAddPartnerClick}
      hideAddPartnerPopup={hideAddPartnerPopup}
      showAddPartnerModal={showAddPartnerModal}
      radioCompanyyReg={radioCompanyyReg}
      radioSubscription={radioSubscription}
      radioDataIntegration={radioDataIntegration}
      formData={formData}
      onChangeCompanyRegEvent={onChangeCompanyRegEvent}
      onChangeSubscriptionEvent={onChangeSubscriptionEvent}
      onChangedataintegrationEvent={onChangedataintegrationEvent}
      productsOptions={productsOptions}
      onFormChange={onFormChange}
      companyReg={companyReg}
      subscriptionOption={subscriptionOption}
      dataIntegrationOption={dataIntegrationOption}
      onClickSave={onClickSave}
      error={error}
      pagination={pagination}
      onPaginationChange={(page) => setPagination(page)}
      editMode={editMode}
    />
  );
};

export default PartnersContainer;
