import React from 'react';
import SimulateModal from './SimulateModal';

const SimulateModalContainer = (props) => {

  const { simulateData, onChangeSimulate, simulateErr, process, onDiagnosticChange, diagnosticsOption, disabledDiagnostics } = props;



  return (
    <SimulateModal
      simulateData={process}
      onChangeSimulate={onChangeSimulate}
      simulateErr={simulateErr}
      url={simulateData?.externalUrl}
      onDiagnosticChange={onDiagnosticChange}
      diagnosticsOption={diagnosticsOption}
      disabledDiagnostics={disabledDiagnostics}
    />
  );
};

export default SimulateModalContainer;