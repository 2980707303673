import React, { useMemo } from 'react';
import {
  // OutlineButton,
  JSONCodeReader,
  YAMLReader,
} from '../../../CommonComponents';
import Copy from '../../../../assets/images/content_copy.png';
import './CodeBlock.scss';
import * as R from 'ramda';

// const staticData = {
//   metadata: {
//     messageID: 'e7c85328-55ad-4114-8e38-1c250b12a43e',
//     deviceId: '192000000000001',
//     esn: '19200001',
//     customer: 'Cummins',
//     oem: 'Navistar',
//     bu: 'PSBU',
//     dataType: 'J1939',
//     subType: 'HB',
//     fileType: 'JSON',
//     externalKeys: ['12345', 'abc-def'],
//   },
//   data: {},
// };

const CodeBlock = (props) => {
  const content = props?.content ?? {};
  // const heading = content?.heading ?? '';
  const body = content?.body ?? {};
  // const title = body?.title ?? {};
  const bodyContentURL = body?.bodyContentURL ?? '';
  const data = body?.data ?? {}; //sample data

  // const isSampleResponse = heading === 'External Response Sample API' || heading === 'Register External Response API';

  const type = body.type ?? '';
  // const buttonName = content?.component?.name ?? 'View in Swagger';

  const swaggerData = useMemo(() => {
    return props?.swaggerJson;
  }, [props?.swaggerJson]);

  return (
    <div className={`codeBlock ${content.className == 'white' ? 'white' : ''}`}>
      {/* <div className='clearfix'>
        <div className='float-left'>
          <p className='titleText'>{props?.heading || heading}</p>
        </div>
         <div className='float-right'>
          {buttonName === 'View in Swagger' ? (
            <OutlineButton onClick={props.onSwaggerClick}>
              {buttonName}
            </OutlineButton>
          ) : (
            props?.showSimulate && (
              <OutlineButton
                // title={'To simulate the process of registering for an External Response, please navigate to the "Code Sample" section below and access the "View in Swagger" option to provide the necessary details.'}
                title={R.isEmpty(swaggerData) ? 'To simulate the process of registering for an External Response, please navigate to the "Code Sample" section below and access the "View in Swagger" option to provide the necessary details.' : ''}
                disabled={R.isEmpty(swaggerData)}
                // disabled={true}
                onClick={() => props?.onClickSimulate(swaggerData)}
              >
                {buttonName}
              </OutlineButton>
            )
          )}
        </div> 
      </div>*/}
      <div className='codingSection'>
        <div className='sectionHeader'>
          <div className='clearfix'>
            <div className='float-left headerText'>Registered External Response Info</div>
            {/* <div className='float-left headerText'>{title}</div> */}
            <div className='float-right'>
              <img src={Copy} alt='copyIcon' className='copyIcon' />
            </div>
          </div>
        </div>
        {bodyContentURL !== '' && (
          <div className='sectionBody'>
            {type === 'yaml' ? (
              <YAMLReader fileURL={bodyContentURL} />
            ) : (
              <JSONCodeReader file={!R.isEmpty(swaggerData) ? swaggerData : data} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CodeBlock;
