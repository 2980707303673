import {
  SET_PRODUCT_SERVICE_API,
  SET_PRODUCT_SERVICE_API_FLG,
  SET_LOADER_STATUS,
  SET_CREATE_PRODUCT,
  SET_CREATE_SERVICE,
  SET_API_ENDPOINTS,
  SET_CREATE_API,
  SET_API_PUBLISH,
  SET_API_PROJECT,
  SET_PROJECT_PARTNERS,
  SET_ROLES_USERS,
  SET_PARTNER_ACTIVE_SUBSCRIPTION,
  SET_PARTNER_INACTIVE_SUBSCRIPTION,
  SET_PARTNERS_DATA,
  SET_PROJECT_VIEW,
  SET_PROJECT_UPLOAD,
  SET_DELETED_FILE,
  TOGGLE_ALERT_POPUP,
  PROJECT_DATA_CHANGED,
  SET_APPROVALS_LIST,
  SET_HISTORY,
  SET_REg_EXT_RESP,
  SET_CERTIFIED_PRODUCTS,
  SET_PRODUCTS,
  SET_DROPDOWN_LOADER
} from './types';

const initialState = {
  loading: false,
  products: [],
  services: {},
  API: {},
  apiEndpoints: [],
  apiProjects: {},
  partners: [],
  partnerSubscriptions: [],
  users: [],
  projectView: {},
  alertPopup: {
    status: false,
    fromNav: false,
    callback: null
  },
  projectDataChanged: false,
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADER_STATUS: {
      return {
        ...state,
        loading: action.status,
      };
    }
    case SET_DROPDOWN_LOADER: {
      return {
        ...state,
        dropdownLoader: action.status,
      };
    }
    case SET_PRODUCT_SERVICE_API: {
      return {
        ...action?.productServiceAPIResponse,
        loading: false,
      };
    }
    case SET_PRODUCT_SERVICE_API_FLG: {
      const productServiceAPIStatus = action.productServiceAPIStatus;
      return {
        ...state,
        productServiceAPIStatus,
      };
    }
    case SET_CREATE_PRODUCT: {
      const newProd = action.data;
      const isEditMode = action?.isEditMode;
      const productId = newProd?.productId;
      let obj = {};
      if (isEditMode) {
        const currentIdx = state?.products?.findIndex(
          (o) => o?.productId === productId
        );
        obj = [...state?.products];
        obj[currentIdx] = {
          ...obj[currentIdx],
          productName: newProd?.productName,
          productDescription: newProd?.productDescription,
          isPublished: newProd?.isPublished,
          productImage: newProd?.productImage || null,
          isModified: newProd?.isModified || 'No',
          status: newProd?.status || 'Active',
        };
      } else {
        obj = [...state?.products, { ...newProd }];
      }
      return {
        ...state,
        products: obj,
      };
    }
    case SET_API_ENDPOINTS: {
      return {
        ...state,
        apiEndpoints: action.list,
      };
    }
    case SET_CREATE_SERVICE: {
      const newProd = action.data;
      const prodId = action?.prodId;
      let products = [...state?.products];
      // let idx = products.findIndex((o) => o?.productId === prodId);
      const isEditMode = action?.isEditMode;
      // products[idx].serviceCount += 1;
      let obj = {};
      if (isEditMode) {
        const currentIdx = state?.services[prodId]?.findIndex(
          (o) => o?.serviceId === newProd?.serviceId
        );

        obj = {
          ...state?.services,
          [prodId]: [...state?.services[prodId]],
        };

        obj[prodId][currentIdx] = {
          ...state?.services[prodId][currentIdx],
          serviceName: newProd?.serviceName,
          serviceDescription: newProd?.serviceDescription,
          status: newProd?.status,
          isPublished: newProd?.isPublished,
          isModified: newProd?.isModified,
          integrationManager: newProd?.integrationManager || '',
          productId: prodId,
        };
      } else {
        obj = {
          ...state?.services,
          [prodId]: [
            ...state?.services[prodId],
            {
              ...newProd,
              apiCount: 0,
              isPublished: newProd?.isPublished,
              productId: prodId,
            },
          ],
        };
      }
      return {
        ...state,
        products,
        services: obj,
      };
    }
    case SET_CREATE_API: {
      let services = { ...state?.services };
      let { serviceId, productId } = action;
      let idx = services[productId].findIndex(
        (o) => o?.serviceId === serviceId
      );
      services[productId][idx].apiCount = action?.data?.length;
      let apiData = action?.data?.map((api) => ({ ...api, serviceId }));

      let obj = {
        ...state?.API,
        services,
        [serviceId]: apiData,
      };
      return {
        ...state,
        API: obj,
      };
    }
    case SET_API_PUBLISH: {
      const { serviceId, apiId, isPublished, apiDescription } = action;
      let currentApi = state?.API[serviceId];
      const currentIdx = currentApi.findIndex((o) => o?.apiId === apiId);
      currentApi[currentIdx] = {
        ...currentApi[currentIdx],
        isPublished: isPublished,
        apiDescription,
      };
      let obj = {
        ...state,
        API: {
          ...state?.API,
          [serviceId]: currentApi,
        },
      };
      return obj;
    }
    case SET_API_PROJECT: {
      return {
        ...state,
        apiProjects: action?.data,
      };
    }
    case SET_PROJECT_PARTNERS: {
      return {
        ...state,
        partners: action?.data,
      };
    }
    case SET_ROLES_USERS: {
      return {
        ...state,
        users: action?.data,
      };
    }
    case SET_PARTNER_ACTIVE_SUBSCRIPTION: {
      return {
        ...state,
        activeSubscription: action?.data,
      };
    }
    case SET_PARTNER_INACTIVE_SUBSCRIPTION: {
      return {
        ...state,
        inActiveSubscription: action?.data,
      };
    }
    case SET_PARTNERS_DATA: {
      return {
        ...state,
        partnerData: action?.data,
      };
    }
    case SET_PROJECT_VIEW: {
      return {
        ...state,
        projectView: action?.data,
      };
    }
    case SET_PROJECT_UPLOAD: {
      return {
        ...state,
        projectView: {
          ...state?.projectView,
          projectDocument: [
            ...state?.projectView?.projectDocument,
            action?.data,
          ],
        },
      };
    }
    case SET_DELETED_FILE: {
      let removeData =
        state?.projectView?.projectDocument?.length > 0
          ? state?.projectView?.projectDocument.filter(
            (img) => img !== action?.key
          )
          : [];
      return {
        ...state,
        projectView: { ...state?.projectView, projectDocument: removeData },
      };
    }
    case TOGGLE_ALERT_POPUP: {
      const { status, fromNav, callback } = action;
      return {
        ...state,
        alertPopup: {
          status,
          fromNav,
          callback: callback ? callback : null,
        },
      };
    }
    case PROJECT_DATA_CHANGED: {
      return {
        ...state,
        projectDataChanged: action?.status,
      };
    }
    case SET_APPROVALS_LIST: {
      const states = state;
      states['approvals'] = action?.data;
      return states;
    }
    case SET_HISTORY: {
      const states = state;
      states['history'] = action?.data;
      return states;
    }
    case SET_REg_EXT_RESP: {
      const states = state;
      states['regExtResp'] = action?.data;
      return states;
    }
    case SET_CERTIFIED_PRODUCTS: {
      const states = state;
      states['certifiedProducts'] = action?.data;
      return states;
    }
    case SET_PRODUCTS: {
      const states = state;
      states['products'] = action?.data;
      return states;
    }
    default:
      return state;
  }
};

export default reducers;
