import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import {
  operations as appOps,
  selectors as appSelector,
} from '../../../state/features/app';
import BodyLayout from './BodyLayout';

const BodyLayoutContainer = (props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const pathname = location?.pathname?.replace('%20', ' ');

  const dispatch = useDispatch();
  const dispatchGetNavRouteContent = appOps.dispatchGetNavRouteContent(dispatch);
  // const dispatchJson = appOps.dispatchGetSwaggerJson(dispatch);

  const { routeContent, contentFetchingState, /* currentRoute, proServiceMap, */ jsonList } = useSelector((state) => ({
    routeContent: appSelector.routeContent(state),
    contentFetchingState: appSelector.contentFetchingState(state),
    currentRoute: appSelector.currentRoute(state),
    proServiceMap: appSelector.prodServiceMap(state),
    jsonList: appSelector.swaggerJson(state),
  }));

  const jsonLoading = jsonList?.loading || false;


  const [bodyContent, setBodyContent] = useState({});
  const [layoutName, setLayoutName] = useState('');
  // const [swaggerJson, setSwaggerJson] = useState({});



  useEffect(() => {
    if (
      // searchParams.get('portal') === false &&
      contentFetchingState === false
    ) {
      if (
        props.routeDetails &&
        Object.prototype.hasOwnProperty.call(routeContent, props.routeDetails)
      ) {
        const bodyContent = routeContent[props.routeDetails] ?? '';

        if (bodyContent !== '' && Object.keys(bodyContent).length > 0) {
          const layoutName = bodyContent?.layoutName ?? '';
          const sections = bodyContent?.sections ?? [];

          setLayoutName(layoutName);
          setBodyContent(sections);
        } else if (
          props.routeDetails !== '/swagger' &&
          props.routeDetails !== '/FAQ'
        ) {
          alert('Site under construction');
          navigate(-1);
        }
      }
      // else if(searchParams.get('portal') == false) {
      //   dispatchGetNavRouteContent(props.routeDetails);
      // }
      else if (
        props.routeDetails !== '/Products Services & API' &&
        props.routeDetails !== '/Projects' &&
        props.routeDetails !== '/Partners' &&
        props.routeDetails !== '/Roles and Permissions' &&
        props.routeDetails !== '/Approvals' &&
        props.routeDetails !== '/External Response'
      ) {
        // if (props.routeDetails !== '/API Project') {
        dispatchGetNavRouteContent(props.routeDetails);
        // }
      }
    }
  }, [props.routeDetails, contentFetchingState]);

  // useEffect(() => {
  //   if (currentRoute?.length === 4) {
  //     const product = proServiceMap?.[currentRoute[0]]?.find(
  //       (o) => o?.title === currentRoute[1]
  //     );
  //     const service = product.children?.find(
  //       (o) => o?.title === currentRoute[2]
  //     );
  //     const api = service?.children?.find((o) => o?.title === currentRoute[3]);
  //     if (api?.id) {
  //       if (jsonList[api?.id]) {
  //         setSwaggerJson(jsonList[api?.id]);
  //       } else {
  //         dispatchJson(api?.id);
  //       }
  //     }
  //   }
  // }, [currentRoute]);
  
  // useEffect(() => {
  //   if (currentRoute?.length === 4) {
  //     const product = proServiceMap?.[currentRoute[0]]?.find(
  //       (o) => o?.title === currentRoute?.[1]
  //     );
  //     const service = product.children.find(
  //       (o) => o?.title === currentRoute?.[2]
  //     );
  //     const api = service?.children?.find((o) => o?.title === currentRoute?.[3]);
  //     const apiId = api?.id;
  //     if (jsonList[apiId]) {
  //       setSwaggerJson(jsonList[apiId]);
  //     }
  //   }
  // }, [jsonList]);

  return (
    <BodyLayout
      bodyContent={bodyContent}
      layoutName={layoutName}
      contentFetchingState={contentFetchingState}
      localBodyLoad={searchParams.get('portal')}
      pathname={pathname}
      onNavClick={props?.onNavClick}
      isAdmin={props?.isAdmin}
      // swaggerJson={swaggerJson}
      jsonList={jsonList}
      jsonLoading={jsonLoading}
    />
  );
};

export default BodyLayoutContainer;
