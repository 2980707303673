import React from 'react';
import RegisteredTable from './RegisteredTable';
// import Zonar from '../../../../../../../../assets/images/zonar.png';

const RegisteredTableContainer = ({
  tableData,
  loading,
  columns,
  responsePayloadValue,
  responsePayload,
  hideResponsepayloadModal,
  activePagination,
  onChangePagination
}) => {





  return (
    <RegisteredTable
      activePagination={activePagination}
      columns={columns}
      dataSource={tableData}
      responsePayload={responsePayload}
      responsePayloadValue={responsePayloadValue}
      hideResponsepayloadModal={hideResponsepayloadModal}
      loading={loading}
      onChangePagination={onChangePagination}
    />
  );
};

export default RegisteredTableContainer;