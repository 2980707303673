import React, { useEffect, useState } from 'react';
// import { /* xml2json, */ js2xml } from 'xml-js';
import MethodTabs from './MethodTabs';
import ExternalResponseBadge from '../ExternalResponseBadge/ExternalResponse-container';
import { useSelector, useDispatch } from 'react-redux';
import { selectors as appSelector, operations as appOps } from '../../../../state/features/app';

const samplePayload = {
  device: {
    deviceId: 'string',
    supportedInterfaces: {
      AudioPlayer: {}
    },
    persistentEndpointId: 'amzn1.alexa.endpoint.[unique-value-here]'
  },
  application: {
    applicationId: 'amzn1.ask.skill.[unique-value-here]'
  },
  user: {
    userId: 'amzn1.ask.account.[unique-value-here]',
    accessToken: 'Atza|AAAAAAAA...'
  }
};

const prognostics = {
  'Equipment_ID': '1XKYD49X5LJ392510',
  'Response_Version': '2.003.000',
  'Message_Type': 'Prognostics',
  'VIN': '1XKYD49X5LJ392510',
  'Engine_Serial_Number': '80197047',
  'AlertData': [
    {
      'Component': 'Aftertreatment Intake NOx Sensor',
      'AlertDate': '2024-02-14 23:20:13.040',
      'ExtractionDate': null,
      'output': [
        {
          'ParamName': 'RecommendedAction',
          'ParamValue': 'The identified component is expected to fail by 465602.34 miles. Days to failure will vary based on vehicle usage, daily mileage accumulation, sensor run response time etc. EXPIRING SOON: Please replace indicated component within estimated mileage or timeframe, unless already actioned. Refer to quickserve.cummins.com and parts.cummins.com for details.'
        },
        {
          'ParamName': 'OdometerMiles',
          'ParamValue': '432202.34'
        },
        {
          'ParamName': 'DaysToFailure',
          'ParamValue': '30'
        },
        {
          'ParamName': 'MinMilesForFailure',
          'ParamValue': '13400'
        },
        {
          'ParamName': 'MaxMilesForFailure',
          'ParamValue': '33400'
        },
        {
          'ParamName': 'StandardRepairTime',
          'ParamValue': null
        },
        {
          'ParamName': 'PartNumber',
          'ParamValue': null
        },
        {
          'ParamName': 'VMRSCode',
          'ParamValue': '043-M03-11'
        },
        {
          'ParamName': 'VMRSDescription',
          'ParamValue': 'Sensor - Nitrogen Oxide (NOX)  - Prognostic Repair Due Soon'
        }
      ]
    }
  ],
  'ConfirmationLink': 'https://customeralertfeedback-stg.cummins.com/?payload=iP24B26zrGyPEGZrazNe8kffxzy9GRWeeulPBIcZ2uPQpmYi0rGiUCN7R8aT1GQo2YfJkOP1rMmyV0CSSM_cEo8A_v_CT8ZKo2LIb6R4ob-IvcU_bzvwOvK48vdtPf6VQGp09W5iGgzhJ1Q8EbiL2NqcP5m7min3art79pmHmJuoOymTq7YWrb9vM-1UMpu4K25_IdiECQKgh0AefA_7NoZ55PA5vY51N6IbbjTHlg-n2nsqihPATuk8Vw1c_d95C9AK-OQb2JJCvNTSCFUUgDjJy0_bFqpZBrFkrRshjp5WnSJT4SthUxrMDcRqffhxKfmHaIfrVbCrh_7MYK_YiQ'
};


const MethodTabsContainer = (props) => {
  const { content,/* key,  swaggerJson, */ loading, editable } = props;
  // const body = content.body ?? {};
  const dispatch = useDispatch();
  const dispatchAddEditExt = appOps.dispatchAddEditExtResp(dispatch);
  const dispatchGetExtResponse = appOps.dispatchGetExtResponse(dispatch);
  const dispatchSetSimulate = appOps.dispatchSetSimulate(dispatch);

  const [showModal, setShowModal] = useState(false);
  const [showSimulateModal, setShowSimulateModal] = useState(false);
  const { userDetails, currentRoute, externalResponse } = useSelector((state) => ({
    userDetails: appSelector.userDetails(state),
    currentRoute: appSelector.currentRoute(state),
    externalResponse: appSelector.externalResponse(state),
  }));

  const diagnostics = {
    Derate_Flag: 'No',
    Additional_Diagnostic_Info: '',
    Latitude: '00.0000000',
    Primary_SPN: '91',
    SPN: '91',
    URL_4: '',
    Direction_Heading: '',
    Equipment_ID: 'Ed_ARM test3 (563534)',
    URL_2: '',
    URL_3: '',
    Customer_Reference: '',
    Telematics_Partner_Name: userDetails?.partnerName,
    Response_Version: '2.003.000',
    Telematics_Partner_Message_ID: '',
    Derate_Value3: '',
    Message_Type: 'FC',
    Priority: 'Stop Now',
    Primary_Fault_Code: '',
    Longitude: '00.0000000',
    Active: '1',
    Service_Model_Name: 'X15 CM2350 X114B',
    Related: [],
    Engine_Serial_Number: '67000002',
    Fault_Code_Category: 'OEM',
    OEM: '',
    URL_Cummins_General: 'https://www.cummins.com/parts-and-service/digital-products-and-services/connected-diagnostics',
    Primary_FMI: '4',
    Instruction_To_Operator: '',
    Primary_Fault_Code_Description: 'Accelerator Pedal or Lever Position Sensor 1 Circuit - Voltage Below Normal or Shorted to Low Source',
    GPS_Vehicle_Speed: '',
    Report_Type: 'Immediate',
    Fault_Root_Cause4: 'Engine Control Module',
    Fault_Root_Cause3: 'Accelerator Pedal or Lever Position Sensor',
    Fault_Root_Cause2: 'OEM Wiring Harness',
    Shutdown_Flag: 'No',
    Fault_Root_Cause1: 'OEM Component',
    Source_Address: '0',
    Notification_ID: 'CGQAIL_7351405',
    Datalink_Bus: '',
    Derate_Value1: '',
    Cummins_Name: '',
    Telematics_Box_ID: '',
    Derate_Value2: '',
    Location_Text_Description: '',
    Occurrence_Count: '1',
    FMI: '4',
    Occurrence_Date_Time: '21-Mar-2024 20:55:00.000',
    Fault_Likelihood1: '56%',
    Fault_Likelihood2: '16%',
    Vehicle_Distance: '',
    Lamp_Color: '',
    Fault_Likelihood3: '16%',
    Fault_Likelihood4: '6%',
    Service_Locator_Market_Application: '103',
    Service_Locator_Link: 'http://locator.cummins.com/GlobalLocator/searchResults.action?language=en_US&amp;country=122&amp;region=1&amp;unit=M&amp;bizEntity=E&amp;nearLat=00.0000000&amp;nearLong=00.0000000&amp;prodCode=&amp;mktApp=',
    Equipment_SubApplication: '',
    Fault_Code: '',
    Shutdown_Description: '',
    Fault_Code_Description: 'Accelerator Pedal or Lever Position Sensor 1 Circuit - Voltage Below Normal or Shorted to Low Source',
    Instruction_To_Fleet_Mgr: 'If unit is not currently in shop, then vehicle may be experiencing performance derate. Make arrangements to bring this unit to a shop immediately.',
    Primary_Occurrence_Date_Time: '21-Mar-2024 20:55:00.000',
    VIN: 'LABTESTBENCHTFL02',
    Additional_Info_To_Operator: '',
    General_Assistance_PhoneNumber: '1-800-343-7357',
    Altitude: ''
  };

  const [process, setProcess] = useState(diagnostics);

  const [formData, setFormData] = useState({
    partnerName: '',
    productName: '',
    serviceName: '',
    AccessKey: '',
    externalResponseUrl: '',
    urlType: 'REST',
    fileType: 'JSON',
    authMethod: 'OAuth',
    accessTokenUrl: '',
    // clientId: '',
    payload: '',
    header: '',
    // clientSecret: '',
    // scope: '',
    // password: '',
    // userName: '',
    location: 'header',
    apikey: '',
    paramName: '',
    soapEnvelope: '',
    soapHeader: '',
    soapAction: '',
    certSecretName: '',
    diagnostics: true,
    prognostics: false
  });

  const [error, setError] = useState({
    externalResponseUrl: undefined,
  });


  const [simulateData, setSimulateData] = useState({
    externalUrl: '',
    externalPayload: JSON.stringify(samplePayload, undefined, 4),
  });
  const [simulateErr, setSimulateErr] = useState(undefined);
  const [editedFields, setEditedFields] = useState([]);
  const [diagnosticsOption, setDiagnosticsOption] = useState('Diagnostics');
  const [disabledDiagnostics, setDisabledDiagnostics] = useState(null);
  const [prognosticErr, setPrognosticErr] = useState(false);

  useEffect(() => {
    if (!externalResponse && !editable) {
      dispatchGetExtResponse({
        partnerName: userDetails?.partnerName,
        productName: currentRoute?.[1] || '',
        serviceName: currentRoute?.[2] || ''
      });
    }
  }, [externalResponse]);



  const resetForm = () => {
    setFormData({
      partnerName: '',
      ProductName: '',
      serviceName: '',
      accessKey: '',
      externalResponseUrL: '',
      urlType: 'REST',
      fileType: 'JSON',
      authMethod: 'No Auth',
      accessTokenUrl: '',
      // clientId: '',
      payload: '',
      header: '',
      // clientSecret: '',
      paramName: '',
      // scope: '',
      location: 'header',
      apikey: '',
      soapEnvelope: '',
      soapHeader: '',
      soapAction: '',
      certSecretName: '',
      // password: '',
      // userName: '',
    });
  };

  const hideModalPopup = () => {
    setShowModal(false);
    resetForm();
    setError({ externalResponseUrl: undefined });
    setPrognosticErr(false);
  };
  const hideModalSimulatePopup = () => {
    setShowSimulateModal(false);
  };
  const onManangeClick = () => {
    setShowModal(true);
    setFormData((prevState) => ({ ...prevState, partnerName: userDetails?.partnerName, productName: currentRoute?.[1] || '', serviceName: currentRoute?.[2] || '' }));

  };
  const onSimulateClick = () => {
    setSimulateData({ externalUrl: externalResponse?.apiInfo?.externalResponseUrl || '', externalPayload: JSON.stringify(externalResponse?.apiInfo, undefined, 4) });
    setShowSimulateModal(true);
    setSimulateErr(undefined);
    if (!externalResponse?.apiInfo?.diagnosticsEnabled && externalResponse?.apiInfo?.prognosticsEnabled) {
      setDiagnosticsOption('Prognostics');
      setProcess(prognostics);
      setDisabledDiagnostics('Diagnostics');
    } else if (externalResponse?.apiInfo?.diagnosticsEnabled && externalResponse?.apiInfo?.prognosticsEnabled) {
      setDiagnosticsOption('Diagnostics');
      setProcess(diagnostics);
      setDisabledDiagnostics('null');
    } else {
      setDiagnosticsOption('Diagnostics');
      setProcess(diagnostics);
      setDisabledDiagnostics('Prognostics');
    }
  };
  const onClickCancel = () => {
    resetForm();
    setShowModal(false);
    setError({ externalResponseUrl: undefined });
    setPrognosticErr(false);
  };

  const onClickSimulateCancel = () => {
    setShowSimulateModal(false);
    setFormData((prevState) => ({ ...prevState, partnerName: userDetails?.partnerName, productName: currentRoute?.[1] || '', serviceName: currentRoute?.[2] || '' }));

  };

  const isValidUrl = (url) => {
    //eslint-disable-next-line
    let urlRegex = /^(?:(?:https?|ftp):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/;
    let isValid = urlRegex.test(url);
    return isValid;
  };

  const onFormChange = (value, fieldName, formField) => {
    let letters = /[^a-zA-Z0-9. ]/;
    let errors = error;
    let fields = editedFields;
    let fieldAlreadyExist = fields.findIndex((i) => i === fieldName) > -1;
    if (externalResponse?.externalId) {
      if (!fieldAlreadyExist) {
        fields.push(fieldName);
      }
    }

    if (value === '' && fieldName !== 'scope' && fieldName !== 'paramName') {
      errors[fieldName] = `Please enter ${formField}`;
    } else {
      delete errors[fieldName];
    }

    if (typeof value === 'string' && value.match(letters) && fieldName !== 'diagnostics' && fieldName !== 'scope' && fieldName !== 'paramName' && fieldName !== 'accessTokenUrl' && fieldName !== 'externalResponseUrl' && fieldName !== 'header' && fieldName !== 'payload') {
      errors[fieldName] = 'Special charecters are not allowed.';
    }

    if (fieldName === 'accessTokenUrl' || fieldName === 'externalResponseUrl') {
      const isValid = isValidUrl(value);
      !isValid ? errors[fieldName] = 'Invalid access token url.' : delete errors[fieldName];
    }
    if (fieldName === 'externalResponseUrl') {
      const isValid = isValidUrl(value);
      !isValid ? errors[fieldName] = 'Invalid external response url.' : delete errors[fieldName];
    }
    setError(errors);
    setEditedFields(fields);
    if (fieldName === 'location') {
      setFormData((prevState) => ({ ...prevState, [fieldName]: value === ' ' ? '' : value, paramName: '' }));
    } else {
      setFormData((prevState) => ({ ...prevState, [fieldName]: value === ' ' ? '' : value }));
    }
  };


  const jsonCheck = (str) => {
    try {
      JSON.parse(str);
    } catch (error) {
      return { isError: true };
    }
    return { isError: false };
  };

  const validateForm = () => {
    const isValid = isValidUrl(formData['externalResponseUrl']);
    const isValidaccessUrl = isValidUrl(formData['accessTokenUrl']);
    let letters = /[^a-zA-Z0-9. ]/;
    let errors = {};
    let progErr = false;
    if (formData['externalResponseUrl'] === '') {
      errors['externalResponseUrl'] = 'Please enter external response url.';
    } else if (!isValid) {
      errors['externalResponseUrl'] = 'Invalid external response URL.';
    }

    if (formData?.authMethod === 'OAuth') {
      if (formData?.accessTokenUrl === '') {
        errors['accessTokenUrl'] = 'Please enter access token url.';
      } else if (!isValidaccessUrl) {
        errors['accessTokenUrl'] = 'Invalid access token url.';
      }
      // if (formData?.clientId === '') {
      //   errors['clientId'] = 'Please enter client Id.';
      // } else if (formData?.clientId.match(letters)) {
      //   errors['clientId'] = 'Special charecters are not allowed.';
      // }
      if (!formData?.payload.trim()) {
        errors['payload'] = 'Please enter payload.';
      } else if (formData?.payload.includes(' ')) {
        errors['payload'] = 'Spaces not allowed!';
      }
      if (formData?.header === '') {
        errors['header'] = 'Please enter header.';
      }
      const headerJson = jsonCheck(formData.header);
      if (headerJson?.isError) {
        if (formData.header === '') {
          errors['header'] = 'Please enter header.';
        } else {
          errors['header'] = 'Invalid json format.';
        }
      }


      // if (formData?.clientSecret === '') {
      //   errors['clientSecret'] = 'Please enter client secret.';
      // }
      // else if (formData?.clientSecret.match(letters)) {
      //   errors['clientSecret'] = 'Special charecters are not allowed.';
      // }
    }

    if (formData?.authMethod === 'x-Api-Key') {
      if (formData?.apikey === '') {
        errors['apikey'] = 'Please enter api key.';
      } else if (formData?.apikey.match(letters)) {
        errors['apikey'] = 'Special charecters are not allowed.';
      }
      if (formData?.location === '') {
        errors['location'] = 'Please enter location.';
      } else if (formData?.location.match(letters)) {
        errors['location'] = 'Special charecters are not allowed.';
      }
    }

    if (formData?.authMethod === 'SOAPCredentials') {
      if (formData?.soapEnvelope === '') {
        errors['soapEnvelope'] = 'Please enter SOAP envelope.';
      } else if (formData?.soapEnvelope.match(letters)) {
        errors['soapEnvelope'] = 'Special charecters are not allowed.';
      }
      if (formData?.soapAction === '') {
        errors['soapAction'] = 'Please enter SOAP action.';
      } else if (formData?.soapAction.match(letters)) {
        errors['soapAction'] = 'Special charecters are not allowed.';
      }
      if (formData?.soapHeader === '') {
        errors['soapHeader'] = 'Please enter SOAP header.';
      } else if (formData?.soapHeader.match(letters)) {
        errors['soapHeader'] = 'Special charecters are not allowed.';
      }

    }

    if (formData?.authMethod === 'mTLS') {
      if (formData?.certSecretName === '') {
        errors['certSecretName'] = 'Please enter cert secret name.';
      } else if (formData?.certSecretName.match(letters)) {
        errors['certSecretName'] = 'Special charecters are not allowed.';
      }
    }

    if (!formData?.prognostics && !formData?.diagnostics) {
      progErr = true;
      setPrognosticErr(true);
    } else {
      progErr = false;
      setPrognosticErr(false);
    }

    setError(errors);

    if (Object.keys(errors).length === 0 && !progErr) {
      const body = formData?.authMethod === 'OAuth' ? {
        ...(externalResponse?.externalId && { externalId: externalResponse?.externalId }),
        partnerName: formData?.partnerName,
        productName: formData?.productName,
        serviceName: formData?.serviceName,
        externalResponseUrl: formData?.externalResponseUrl,
        urlType: formData?.urlType,
        fileType: formData?.fileType,
        authMethod: formData?.authMethod,
        diagnosticsEnabled: formData?.diagnostics,
        prognosticsEnabled: formData?.prognostics,
        authInfo: {
          accessTokenUrl: formData?.accessTokenUrl || '',
          // clientId: formData?.clientId,
          header: formData?.header,
          payload: formData?.payload,
          // clientSecret: formData?.clientSecret,
          // scope: formData?.scope,
          // userName: formData?.userName,
          // password: formData?.password
        }
      } : formData?.authMethod === 'x-Api-Key' ? {
        ...(externalResponse?.externalId && { externalId: externalResponse?.externalId }),
        partnerName: formData?.partnerName,
        productName: formData?.productName,
        serviceName: formData?.serviceName,
        externalResponseUrl: formData?.externalResponseUrl,
        urlType: formData?.urlType,
        fileType: formData?.fileType,
        authMethod: formData?.authMethod,
        diagnosticsEnabled: formData?.diagnostics,
        prognosticsEnabled: formData?.prognostics,
        authInfo: {
          apiKey: formData?.apikey,
          location: formData?.location,
          paramName: formData?.paramName
        }
      } : formData?.authMethod === 'SOAPCredentials' ? {
        ...(externalResponse?.externalId && { externalId: externalResponse?.externalId }),
        partnerName: formData?.partnerName,
        productName: formData?.productName,
        serviceName: formData?.serviceName,
        externalResponseUrl: formData?.externalResponseUrl,
        urlType: formData?.urlType,
        fileType: formData?.fileType,
        authMethod: formData?.authMethod,
        diagnosticsEnabled: formData?.diagnostics,
        prognosticsEnabled: formData?.prognostics,
        authInfo: {
          soapEnvelope: formData?.soapEnvelope,
          soapHeader: formData?.soapHeader,
          soapAction: formData?.soapAction,
        }
      } : formData?.authMethod === 'No Auth' ? {
        ...(externalResponse?.externalId && { externalId: externalResponse?.externalId }),
        partnerName: formData?.partnerName,
        productName: formData?.productName,
        serviceName: formData?.serviceName,
        externalResponseUrl: formData?.externalResponseUrl,
        urlType: formData?.urlType,
        fileType: formData?.fileType,
        authMethod: formData?.authMethod,
        diagnosticsEnabled: formData?.diagnostics,
        prognosticsEnabled: formData?.prognostics,

      } : {
        ...(externalResponse?.externalId && { externalId: externalResponse?.externalId }),
        partnerName: formData?.partnerName,
        productName: formData?.productName,
        serviceName: formData?.serviceName,
        externalResponseUrl: formData?.externalResponseUrl,
        urlType: formData?.urlType,
        fileType: formData?.fileType,
        authMethod: formData?.authMethod,
        diagnosticsEnabled: formData?.diagnostics,
        prognosticsEnabled: formData?.prognostics,
        authInfo: {
          certSecretName: formData?.certSecretName,
        }
      };
      return body;
    }
  };

  const onClickSave = () => {
    const body = validateForm(formData);
    if (body) {
      dispatchAddEditExt({ ...body, updatedFields: editedFields });
      setShowModal(false);
    }
  };

  const onClickSimulateSave = () => {
    if (!simulateErr) {
      let simulation = { ...process, Telematics_Partner_Name: userDetails?.partnerName };
      setSimulateErr(undefined);
      setShowSimulateModal(false);
      dispatchSetSimulate({
        partnerName: userDetails?.partnerName,
        productName: currentRoute?.[1] || '',
        serviceName: currentRoute?.[2] || '',
        externalId: externalResponse?.externalId,
        diagnosticsEnabled: diagnosticsOption === 'Diagnostics',
        prognosticsEnabled:  diagnosticsOption === 'Prognostics',
        payload: simulation
      });
    }
  };

  const onEditClick = () => {
    setShowModal(true);
    setFormData({
      partnerName: externalResponse?.partnerName || userDetails?.partnerName,
      productName: currentRoute?.[1] || '',
      serviceName: currentRoute?.[2] || '',
      externalResponseUrl: externalResponse?.apiInfo?.externalResponseUrl || '',
      urlType: externalResponse?.apiInfo?.urlType || '',
      fileType: externalResponse?.apiInfo?.fileType || '',
      authMethod: externalResponse?.apiInfo?.authMethod || '',
      accessTokenUrl: externalResponse?.apiInfo?.authInfo?.accessTokenUrl || '',
      // clientId: externalResponse?.apiInfo?.authInfo?.clientId || '',
      payload: externalResponse?.apiInfo?.authInfo?.payload || '',
      header: externalResponse?.apiInfo?.authInfo?.header || '',
      // clientSecret: externalResponse?.apiInfo?.authInfo?.clientSecret || '',
      // scope: externalResponse?.apiInfo?.authInfo?.scope || '',
      // userName: externalResponse?.apiInfo?.authInfo?.userName || '',
      // password: externalResponse?.apiInfo?.authInfo?.password || '',
      location: externalResponse?.apiInfo?.authInfo?.location || 'header',
      apikey: externalResponse?.apiInfo?.authInfo?.apiKey || '',
      paramName: externalResponse?.apiInfo?.authInfo?.paramName || '',
      soapEnvelope: externalResponse?.apiInfo?.authInfo?.soapEnvelope || '',
      soapHeader: externalResponse?.apiInfo?.authInfo?.soapHeader || '',
      soapAction: externalResponse?.apiInfo?.authInfo?.soapAction || '',
      certSecretName: externalResponse?.apiInfo?.authInfo?.certSecretName || '',
      diagnostics: externalResponse?.apiInfo?.diagnosticsEnabled,
      prognostics: externalResponse?.apiInfo?.prognosticsEnabled
    });
  };

  const onChangeSimulate = (value) => {
    setProcess(value?.updated_src);
    setSimulateErr(value === '' ? 'Simulation payload cannot be empty.' : undefined);
    // setSimulateData((prevState) => ({ ...prevState, externalPayload: e.target.value?.updated_src }));
  };

  const onDiagnosticChange = (e) => {
    const value = e.target.value;
    setDiagnosticsOption(value);
    if (value === 'Diagnostics') {
      setProcess(diagnostics);
    } else {
      setProcess(prognostics);
    }
  };


  return (
    <div>
      {
        !editable &&
        <ExternalResponseBadge
          onFormChange={onFormChange}
          formData={formData}
          onClickCancel={onClickCancel}
          showModal={showModal}
          hideModalPopup={hideModalPopup}
          onManangeClick={onManangeClick}
          onClickSave={onClickSave}
          externalResponse={externalResponse}
          hideModalSimulatePopup={hideModalSimulatePopup}
          showSimulateModal={showSimulateModal}
          onClickSimulateCancel={onClickSimulateCancel}
          onClickSimulateSave={onClickSimulateSave}
          onSimulateClick={onSimulateClick}
          error={error}
          onEditClick={onEditClick}
          simulateData={simulateData}
          simulateErr={simulateErr}
          onChangeSimulate={onChangeSimulate}
          process={process}
          onDiagnosticChange={onDiagnosticChange}
          diagnosticsOption={diagnosticsOption}
          disabledDiagnostics={disabledDiagnostics}
          prognosticErr={prognosticErr}
        />
      }

      {externalResponse && <MethodTabs
        content={content}
        swaggerJson={externalResponse?.apiInfo}
        loading={loading}
        editable={editable} />}


    </div>
  );

};

export default MethodTabsContainer;
