import React from 'react';
import IntegrationManagementTab from './IntegrationManagementTab';

const InegrationManagementTabContainer = (props) => {
  const { 
    subHeading, 
    heading, 
    columns, 
    showPayloadModal, 
    hidePayloadModal, 
    payloadValue, 
    rejectErr, 
    loading, 
    data, 
    showApproveModal,
    rowValue,
    hideApproveModal,
    onClickModalApprove,
    showRejectModal,
    textAreaValue,
    handleTextAreaChange,
    hideRejectModal,
    onClickReject,
    onClickModalReject,
    updatedValue
  } = props;

  return (
    <IntegrationManagementTab
      subHeading={subHeading}
      heading={heading}
      columns={columns}
      showPayloadModal={showPayloadModal}
      hidePayloadModal={hidePayloadModal}
      payloadValue={payloadValue}
      rejectErr={rejectErr}
      loading={loading}
      data={data}
      showApproveModal={showApproveModal}
      rowValue={rowValue}
      hideApproveModal={hideApproveModal}
      onClickModalApprove={onClickModalApprove}
      showRejectModal={showRejectModal}
      textAreaValue={textAreaValue}
      handleTextAreaChange={handleTextAreaChange}
      hideRejectModal={hideRejectModal}
      onClickReject={onClickReject}
      onClickModalReject={onClickModalReject}
      updatedValue={updatedValue}
    />
  );
};

export default InegrationManagementTabContainer;