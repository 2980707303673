import React from 'react';
import EditModalChildren from './EditModalChildren';

const EditModalChildrenContainer = (props) => {
  return (
    <EditModalChildren
      liveScreenName={props.liveScreenName}
      statusOption={props?.statusOption}
      formData={props?.formData}
      onFormChange={props?.onFormChange}
      statusMsg={props?.statusMsg}
      visible={props.visible}
      hideModal={props.hideModal}
      closeModalPopup={props.closeModalPopup}
      title={props.title}
      width={props?.width}
      onClickCancel={props?.onClickCancel}
      onClickSave={props?.onClickSave}
      onImageUpload={props?.onImageUpload}
      onRemoveUpload={props?.onRemoveUpload}
      fileImgSrc={props.fileImgSrc}
      currentRow={props?.currentRow}
      fileList={props?.fileList}
    />
  );
};

export default EditModalChildrenContainer;
