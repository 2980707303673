import React from 'react';
import { Table } from 'antd';
import './Table.scss';

const TableComponent = ({
  columns = [],
  dataSource = [],
  scroll = {},
  pagination = { current: 1, pageSize: 10, showSizeChanger: true },
  className = '',
  rowClassName = '',
  onClickEvent = () => {},
  onChangeEvent = () => {},
  onRow = () => {},
  summary = () => {},
  style = {},
  loading = false,
  expandable = {},
  components = {},
  rowKey = 'key',
  rowSelection=false,
}) => {
  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      showSorterTooltip={false}
      scroll={scroll}
      className={className}
      onClick={onClickEvent}
      onChange={onChangeEvent}
      style={style}
      onRow={onRow}
      summary={summary}
      loading={{
        spinning: loading,
        tip: 'loading',
      }}
      pagination={pagination}
      expandable={expandable}
      components={components}
      rowClassName={rowClassName}
      rowKey={rowKey}
      rowSelection={rowSelection ? rowSelection : false}
    />
  );
};

export default TableComponent;
