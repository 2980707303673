import { put, call, takeEvery, all, select } from 'redux-saga/effects';
import api from '../../../utils/clientApiHelper';
import {
  ADD_EDIT_PARTNER,
  GET_API_ENDPOINTS,
  GET_API_PROJECT,
  GET_API_PUBLISH,
  GET_APPROVALS_LIST,
  GET_CERTIFIED_PRODUCTS,
  GET_CREATE_API,
  GET_CREATE_PRODUCT,
  GET_CREATE_SERVICE,
  GET_DELETE_FILE,
  GET_HISTORY,
  GET_PRODUCTS,
  GET_PRODUCT_SERVICE_API,
  GET_PROJECT_PARTNERS,
  GET_PROJECT_PARTNERS_SUBSCRIPTIONS,
  GET_PROJECT_VIEW,
  GET_REg_EXT_RESP,
  GET_ROLES_USERS,
  ON_CLICK_APPROVAL,
  SAVE_PERMISSIONS,
  SET_PROJECT_MILESTONE,
  SET_PUBLISH_CHANGES,
  SUBSCRIBE_PRODUCTS,
  UPDATE_EDIT_WEBPAGE,
  UPDATE_REG_EXT_RESP,
  UPLOAD_PROJECT_DOC,
} from './types';
import * as adminAction from './actions';
import * as appAction from '../app/actions';
import { Notification } from '../../../components/CommonComponents';

function* getProductServiceAPISaga(payload) {
  yield put(adminAction.setLoaderStatus(true));
  const { entityDetails } = payload || {};

  try {
    const response = yield call(
      api.callPost,
      '/admin/fetchProductServiceApiData',
      { ...entityDetails }
    );
    const jsonData = response?.data ?? '';
    const adminData = yield select((state) => state?.admin || {});
    const keys = Object.keys(entityDetails || {});
    const nonZeros = keys.filter((k) => entityDetails[k] !== 0);
    if (jsonData !== '' && jsonData?.message === 'Success') {
      let data = {};
      if (nonZeros.length === 0) {
        data = { ...adminData, products: jsonData?.data || {} };
      } else if (nonZeros.length === 1) {
        let productKey = entityDetails.productId;
        let serviceData = [];
        jsonData?.data?.map((ser) => {
          serviceData?.push({ ...ser, productId: productKey });
        });

        data = {
          ...adminData,
          services: {
            ...adminData?.services,
            [productKey]: serviceData,
          },
        };
      } else if (nonZeros.length === 2) {
        let serviceKey = entityDetails.serviceId;
        let apiData = [];
        jsonData?.data?.map((api) => {
          apiData.push({ ...api, serviceId: serviceKey });
        });
        data = {
          ...adminData,
          API: { ...adminData?.API, [serviceKey]: apiData },
        };
      } else {
        data = adminData;
      }
      yield put(adminAction.setProductServiceAPI(data));
      yield put(adminAction.setLoaderStatus(false));
    } else {
      yield put(adminAction.setProductServiceAPI({}));
      yield put(adminAction.setLoaderStatus(false));
      console.error('Failed to Fetch the Product/ Service/ API data');
    }
  } catch (ex) {
    yield put(adminAction.setProductServiceAPI({}));
    yield put(adminAction.setLoaderStatus(false));
    console.error('Error in Fetching the Product/ Service/ API data ', ex);
  }
}

function* createProductSaga(req) {
  yield put(adminAction.setLoaderStatus(true));
  try {
    let response;
    let jsonData = '';
    if (req?.file && req?.file !== null && typeof req?.file === 'object') {
      const formData = new FormData();
      formData.append('file', req?.file?.originFileObj || req?.file);
      formData.append('folder', 'productImages');
      const fileResponse = yield call(api.callPost, '/admin/images', formData, {
        'Content-Type': 'multipart/form-data',
      });
      if (fileResponse?.data?.key || fileResponse?.data?.Key) {
        response = yield call(api.callPost, '/admin/createProduct', {
          ...req?.body,
          productImage: fileResponse?.data?.key || fileResponse?.data?.Key,
          isImageUpdated: 'Yes',
        });
      }
      jsonData = response?.data ?? '';

      if (jsonData !== '' && jsonData?.message === 'Success') {
        yield put(adminAction.setLoaderStatus(false));
        yield put(
          adminAction.setCreateProduct(jsonData?.data, req?.isEditMode)
        );
        Notification({
          type: 'success',
          message: 'Success',
          duration: 1,
          description: req?.isEditMode
            ? 'Product Updated Successfully!'
            : 'Product created Successfully!',
          placement: 'bottom',
        });
      } else {
        yield put(adminAction.setLoaderStatus(false));
        console.error('Failed to create Product data');
        Notification({
          type: 'error',
          message: 'Error',
          description: 'Something went wrong!. Please try again.',
          placement: 'bottom',
        });
      }
    } else {
      response = yield call(api.callPost, '/admin/createProduct', req?.body);
      jsonData = response?.data ?? '';
      if (jsonData !== '' && jsonData?.message === 'Success') {
        yield put(adminAction.setLoaderStatus(false));
        yield put(
          adminAction.setCreateProduct(jsonData?.data, req?.isEditMode)
        );
        Notification({
          type: 'success',
          message: 'Success',
          duration: 1,
          description: req?.isEditMode
            ? 'Product Updated Successfully!'
            : 'Product created Successfully!',
          placement: 'bottom',
        });
      } else {
        yield put(adminAction.setLoaderStatus(false));
        console.error('Failed to create Product data');
        Notification({
          type: 'error',
          message: 'Error',
          description: jsonData?.message || 'Something went wrong!. Please try again.',
          placement: 'bottom',
        });
      }
    }
  } catch (error) {
    yield put(adminAction.setLoaderStatus(false));
    console.error('Error in Creating Product data', error);
    Notification({
      type: 'error',
      message: 'Error',
      description: error?.message || 'Something went wrong!. Please try again.',
      placement: 'bottom',
    });
  }
}

function* createServiceSage(req) {
  yield put(adminAction.setLoaderStatus(true));

  try {
    const response = yield call(
      api.callPost,
      '/admin/createService',
      req?.body
    );
    const jsonData = response?.data ?? '';
    if (jsonData !== '' && jsonData?.message === 'Success') {
      yield put(adminAction.setLoaderStatus(false));
      yield put(
        adminAction.setCreateService(
          jsonData?.data,
          req?.body?.productId,
          req?.isEditMode,
        )
      );
      Notification({
        type: 'success',
        message: 'Success',
        duration: 1,
        description: req?.isEditMode
          ? 'Service Updated Successfully!'
          : 'Service created Successfully!',
        placement: 'bottom',
      });
    } else {
      yield put(adminAction.setLoaderStatus(false));
      console.error('Failed to create Service data');
      Notification({
        type: 'error',
        message: 'Error',
        description: jsonData?.message || 'Something went wrong!. Please try again.',
        placement: 'bottom',
      });
    }
  } catch (error) {
    yield put(adminAction.setLoaderStatus(false));
    console.error('Error in Creating Service data');
    Notification({
      type: 'error',
      message: 'Error',
      description: error?.message || 'Something went wrong!. Please try again.',
      placement: 'bottom',
    });
  }
}

function* createApiSaga(req) {
  yield put(adminAction.setLoaderStatus(true));

  try {
    const response = yield call(
      api.callPost,
      '/admin/createApiEndpoints',
      req?.body
    );
    const jsonData = response?.data ?? '';
    if (jsonData !== '' && jsonData?.message === 'Success') {
      yield put(adminAction.setLoaderStatus(false));
      yield put(
        adminAction.setCreateApi(
          jsonData?.data,
          req?.productId,
          req?.body?.serviceId,
          req?.isEditMode
        )
      );
      Notification({
        type: 'success',
        message: 'Success',
        duration: 1,
        description: req?.isEditMode
          ? 'API Updated Successfully!'
          : 'API created Successfully!',
        placement: 'bottom',
      });
    } else {
      console.error('Failed to create API data');
      yield put(adminAction.setLoaderStatus(false));
      Notification({
        type: 'error',
        message: 'Error',
        description: 'Something went wrong!. Please try again.',
        placement: 'bottom',
      });
    }
  } catch (error) {
    console.error('Error in Creating API data');
    yield put(adminAction.setLoaderStatus(false));
    Notification({
      type: 'error',
      message: 'Error',
      description: error?.message || 'Something went wrong!. Please try again.',
      placement: 'bottom',
    });
  }
}
function* getApiPublishSaga(req) {
  yield put(adminAction.setLoaderStatus(true));

  try {
    const response = yield call(api.callPost, '/admin/publishApi', req?.body);
    const jsonData = response?.data ?? '';

    if (jsonData !== '' && jsonData?.message === 'Success') {
      yield put(
        adminAction.setApiPublish(
          req?.body?.serviceId,
          req?.body?.apiId,
          jsonData?.data?.isPublished,
          req?.body?.apiDescription
        )
      );
      yield put(adminAction.setLoaderStatus(false));
      Notification({
        type: 'success',
        message: 'Success',
        duration: 1,
        description: 'API Updated Successfully!',
        placement: 'bottom',
      });
    } else {
      console.error('Failed to publish API data');
      yield put(adminAction.setLoaderStatus(false));
      Notification({
        type: 'error',
        message: 'Error',
        description: 'Something went wrong!. Please try again.',
        placement: 'bottom',
      });
    }
  } catch (error) {
    console.error('Error in publishing API data');
    yield put(adminAction.setLoaderStatus(false));
    Notification({
      type: 'error',
      message: 'Error',
      description: error?.message || 'Something went wrong!. Please try again.',
      placement: 'bottom',
    });
  }
}

function* getApiEndPointsSaga() {
  yield put(adminAction.setLoaderStatus(true));

  try {
    const response = yield call(api.callGet, '/admin/getApiEndpoints');
    const jsonData = response?.data ?? '';
    if (jsonData !== '') {
      yield put(adminAction.setLoaderStatus(false));
      yield put(adminAction.setApiEndPoints(jsonData?.data || []));
    } else {
      console.error('Failed to fetch API End points');
      yield put(adminAction.setLoaderStatus(false));
      yield put(adminAction.setApiEndPoints([]));
    }
  } catch (error) {
    console.error('Error in Fetching API End points');
    yield put(adminAction.setLoaderStatus(false));
  }
}
function* getApiProjectSaga(req) {
  yield put(adminAction.setLoaderStatus(true));
  try {
    const response = yield call(
      api.callGet,
      `/admin/getApiProject?partnerId=${req?.partnerId}&filter=${req?.filter
      }&status=${req?.status || ''}&emailId=${req?.email}&partnerName=${req?.partnerName}`
    );
    const jsonData = response?.data ?? '';
    if (jsonData !== '' && jsonData?.message === 'Success') {
      yield put(adminAction.setLoaderStatus(false));
      yield put(adminAction.setApiProject(jsonData?.data || []));
    } else {
      console.error('Failed to fetch API End points');
      yield put(adminAction.setLoaderStatus(false));
    }
  } catch (error) {
    console.error('Error in Fetching API End points');
    yield put(adminAction.setLoaderStatus(false));
  }
}

function* projectPartnersSaga(req) {
  if (req?.dropdown) {
    yield put(adminAction.setDropdownStatus(true));
  } else {
    yield put(adminAction.setLoaderStatus(true));
  }
  try {
    const response = yield call(
      api.callGet,
      `/admin/getProjectPartners?filter=${req?.value || ''}`
    );
    // const response = yield call(api.callGet, '/admin/partners-list');
    const jsonData = response?.data ?? '';
    if (jsonData !== '' && jsonData?.message === 'Success') {
      yield put(adminAction.setLoaderStatus(false));
      yield put(adminAction.setDropdownStatus(false));
      yield put(adminAction.setProjectPartners(jsonData?.data || []));
    } else {
      console.error('Failed to fetch API End points');
      yield put(adminAction.setLoaderStatus(false));
    }
  } catch (error) {
    console.error('Error in Fetching API End points');
    yield put(adminAction.setLoaderStatus(false));
    yield put(adminAction.setDropdownStatus(false));
  }
}

function* projectPartnerSubscriptionSaga(req) {
  yield put(adminAction.setLoaderStatus(true));

  try {
    const response = yield call(
      api.callGet,
      `/admin/getProjectPartners?partnerId=${req?.partnerId || ''}`
    );
    const jsonData = response?.data ?? '';
    if (jsonData !== '' && jsonData?.message === 'Success') {
      yield put(adminAction.setLoaderStatus(false));
      yield put(
        adminAction.setPartnerActiveSubscription(
          jsonData?.data?.activeSubscriptions || []
        )
      );
      yield put(adminAction.setPartnerInActiveSubscription(jsonData?.data?.inactiveSubscriptions || []));
      yield put(adminAction.setPartnersData(jsonData?.data || {}));
    } else {
      console.error('Failed to fetch API End points');
      yield put(adminAction.setLoaderStatus(false));
    }
  } catch (error) {
    console.error('Error in Fetching API End points');
    yield put(adminAction.setLoaderStatus(false));
  }
}

function* getRoleUsersSage() {
  yield put(adminAction.setLoaderStatus(true));
  try {
    const response = yield call(
      api.callGet, '/admin/getRoleUsers'

    );
    const jsonData = response?.data ?? '';
    if (jsonData !== '' && jsonData?.message === 'Success') {
      yield put(adminAction.setLoaderStatus(false));
      yield put(adminAction.setRolesUsers(jsonData?.data || []));
    } else {
      console.error('Failed to fetch API End points');
      yield put(adminAction.setLoaderStatus(false));
    }
  } catch (error) {
    console.error('Error in Fetching API End points');
    yield put(adminAction.setLoaderStatus(false));
  }
}

function* uploadProjectDocSaga(req) {
  // yield put(adminAction.setLoaderStatus(true));
  yield put(appAction.setNavContentIsFetching(true));
  try {
    const fromWebContent = req?.fromWebContent;
    const isCurrent = req?.isCurrent;
    const schema = req?.schema;
    const formData = new FormData();
    formData.append('file', req?.data);
    formData.append(
      'folder',
      fromWebContent ? `webcontent/${schema}` : 'projectDocuments'
    );
    formData.append('id', req?.id);
    const fileResponse = yield call(api.callPost, '/admin/images', formData, {
      'Content-Type': 'multipart/form-data',
    });
    if (fileResponse?.data?.key || fileResponse?.data?.Key) {
      yield put(appAction.setNavContentIsFetching(false));
      if (fromWebContent) {
        yield put(
          appAction.updateWebDoc((fileResponse?.data?.key || fileResponse?.data?.Key), isCurrent, schema)
        );
      } else {
        yield put(adminAction.setProjectUpload(fileResponse?.data?.key || fileResponse?.data?.Key));
      }
    } else {
      yield put(appAction.setNavContentIsFetching(false));
    }
  } catch (error) {
    yield put(appAction.setNavContentIsFetching(false));
    console.error('Error in Creating Product data', error);
    Notification({
      type: 'error',
      message: 'Error',
      description: error?.message || 'Something went wrong!. Please try again.',
      placement: 'bottom',
    });
  }
}
function* deleteProjectDocSaga(req) {
  // yield put(adminAction.setLoaderStatus(true));
  yield put(appAction.setNavContentIsFetching(true));
  const fromContent = req?.fromContent || false;
  const currentRoute = req?.route || [];
  const resetDoc = req?.resetDoc || false;
  try {
    const fileResponse = yield call(api.callPost, '/admin/deleteImg', {
      key: req?.key,
    });
    if (fileResponse?.status === 200) {
      yield put(appAction.setNavContentIsFetching(false));
      if (fromContent) {
        yield put(appAction.setDeleteFile(req?.key, currentRoute, resetDoc));
      } else {
        yield put(adminAction.setDeletedFile(req?.key));
      }
    } else {
      yield put(appAction.setNavContentIsFetching(false));
    }
  } catch (error) {
    yield put(appAction.setNavContentIsFetching(false));
    console.error('Error in Creating Product data', error);
    Notification({
      type: 'error',
      message: 'Error',
      description: error?.message || 'Something went wrong!. Please try again.',
      placement: 'bottom',
    });
  }
}

function* pubclishChangesSaga(req) {
  yield put(appAction.setNavContentIsFetching(true));
  try {
    const jsonData = yield call(
      api.callPost,
      '/admin/publishProduct',
      req?.data
    );
    if (jsonData?.data?.message === 'Success') {
      yield put(appAction.setNavContentIsFetching(false));
      yield put(adminAction?.getProductServiceAPI(req?.productIds));
      Notification({
        type: 'success',
        message: 'Success',
        duration: 1,
        description: 'Published Changes Successfully!',
        placement: 'bottom',
      });
    } else {
      yield put(appAction.setNavContentIsFetching(false));
      Notification({
        type: 'error',
        message: 'Error',
        description: 'Something went wrong!. Please try again.',
        placement: 'bottom',
      });
    }
  } catch (error) {
    yield put(appAction.setNavContentIsFetching(false));
    console.error('Error in Publishing data', error);
    Notification({
      type: 'error',
      message: 'Error',
      description: error?.message || 'Something went wrong!. Please try again.',
      placement: 'bottom',
    });
  }
}

function* getProjectViewSaga(req) {
  yield put(appAction.setNavContentIsFetching(true));
  try {
    const jsonData = yield call(
      api.callGet,
      `/admin/getProjectView?id=${req?.id}`
    );
    if (jsonData?.data?.message === 'Success') {
      yield put(appAction.setNavContentIsFetching(false));
      yield put(adminAction?.setProjectView(jsonData?.data?.data || {}));
    } else {
      yield put(appAction.setNavContentIsFetching(false));
      Notification({
        type: 'error',
        message: 'Error',
        description: 'Something went wrong!. Please try again.',
        placement: 'bottom',
      });
    }
  } catch (error) {
    yield put(appAction.setNavContentIsFetching(false));
    console.error('Error in viewing project data', error);
    Notification({
      type: 'error',
      message: 'Error',
      description: error?.message || 'Something went wrong!. Please try again.',
      placement: 'bottom',
    });
  }
}

function* setProjectMileStoneSaga(req) {
  yield put(appAction.setNavContentIsFetching(true));
  try {
    const projectId = req?.data?.projectId;
    const jsonData = yield call(
      api.callPut,
      `/admin/updateProjectMilestone?id=${projectId}`,
      req?.data
    );
    if (jsonData?.data?.message === 'Success') {
      yield put(appAction.setNavContentIsFetching(false));
      Notification({
        type: 'success',
        message: 'Success',
        description: 'Milestones updated successfully!',
        placement: 'bottom',
      });
      yield put(adminAction.getProjectView(projectId));
    } else {
      yield put(appAction.setNavContentIsFetching(false));
      Notification({
        type: 'error',
        message: 'Error',
        description: 'Something went wrong!. Please try again.',
        placement: 'bottom',
      });
    }
  } catch (error) {
    yield put(appAction.setNavContentIsFetching(false));
    console.error('Error in viewing project data', error);
    Notification({
      type: 'error',
      message: 'Error',
      description: error?.message || 'Something went wrong!. Please try again.',
      placement: 'bottom',
    });
  }
}

function* updateWebcontentSaga(req) {
  yield put(appAction.setNavContentIsFetching(true));
  const imageUpdated = req?.isImageUpdated;
  const videoUpdated = req?.isVideoUpdated;
  const isMethodImgUpdated = req?.isMethodImgUpdated;
  let data = req?.data;
  const basePath = req?.basePath;
  const schema = req?.schema
    ?.slice(1)
    ?.replaceAll(' ', '')
    ?.replaceAll('&', '%26');
  const { productId, serviceId, apiId } = req?.ids;
  try {
    if (
      imageUpdated &&
      typeof data.sections[0].bannerMedia.imgSrc !== 'string'
    ) {
      const formData = new FormData();
      formData.append('file', data?.sections?.[0]?.bannerMedia?.imgSrc);
      formData.append('folder', `webcontent/${schema}`);
      const fileResponse = yield call(api.callPost, '/admin/images', formData, {
        'Content-Type': 'multipart/form-data',
      });
      if (fileResponse?.data?.key || fileResponse?.data?.Key) {
        data.sections[0].bannerMedia.imgSrc = `${basePath}${fileResponse?.data?.key || fileResponse?.data?.Key}`;
        // if(data.sections[0].bannerMedia.videoSrc !== ''){
        //   data.sections[0].bannerMedia.type = 'video';
        //   data.sections[0].bannerMedia.imgText = 'WATCH VIDEO';
        // }else{
        //   data.sections[0].bannerMedia.type = 'image';
        // }
      }
    }
    if (
      videoUpdated &&
      typeof data?.sections?.[0]?.bannerMedia?.videoSrc !== 'string'
    ) {
      const formData = new FormData();
      formData.append('file', data?.sections?.[0]?.bannerMedia?.videoSrc);
      formData.append('folder', `webcontent/${schema}`);
      const videoResponse = yield call(
        api.callPost,
        '/admin/images',
        formData,
        {
          'Content-Type': 'multipart/form-data',
        }
      );
      if (videoResponse?.data?.Key || videoResponse?.data?.key) {
        data.sections[0].bannerMedia.videoSrc = `${basePath}${videoResponse?.data?.key || videoResponse?.data?.Key}`;
        data.sections[0].bannerMedia.type = 'video';
        data.sections[0].bannerMedia.imgText = 'WATCH VIDEO';
      }
    }
    if (
      isMethodImgUpdated &&
      typeof data.sections[1].method1.img !== 'string'
    ) {
      const formData = new FormData();
      formData.append('file', data.sections[1].method1.img);
      formData.append('folder', `webcontent/${schema}`);
      const updateResponse = yield call(
        api.callPost,
        '/admin/images',
        formData,
        {
          'Content-Type': 'multipart/form-data',
        }
      );
      if (updateResponse?.data?.Key || updateResponse?.data?.key) {
        data.sections[1].method1.img = `${basePath}${updateResponse?.data?.key || updateResponse?.data?.Key}`;
      }
    }

    const jsonData = yield call(
      api.callPut,
      `/admin/updateSchema?schema=${schema}&productId=${productId}&serviceId=${serviceId}&apiId=${apiId}&isImageUpdated=${imageUpdated || videoUpdated ? 'Yes' : ''
      }`,
      data
    );
    if (jsonData?.data?.message === 'Success') {
      yield put(appAction.setNavContentIsFetching(false));
      Notification({
        type: 'success',
        message: 'Success',
        description: 'Webcontent updated successfully!',
        placement: 'bottom',
      });
    } else {
      yield put(appAction.setNavContentIsFetching(false));
      Notification({
        type: 'error',
        message: 'Error',
        description: 'Something went wrong!. Please try again.',
        placement: 'bottom',
      });
    }
  } catch (error) {
    yield put(appAction.setNavContentIsFetching(false));
    console.error('Error in updating webcontent data', error);
    Notification({
      type: 'error',
      message: 'Error',
      description: error?.message || 'Something went wrong!. Please try again.',
      placement: 'bottom',
    });
  }
}

function* savePermissionsSaga(req) {
  yield put(adminAction.setLoaderStatus(true));
  try {
    const jsonData = yield call(
      api.callPost,
      '/admin/savePermission',
      req?.data
    );
    if (jsonData?.data?.message === 'Success') {
      yield put(adminAction.setLoaderStatus(false));
      Notification({
        type: 'success',
        message: 'Success',
        description: 'Roles updated successfully!',
        placement: 'bottom',
      });
    } else {
      yield put(adminAction.setLoaderStatus(false));
      Notification({
        type: 'error',
        message: 'Error',
        description: 'Something went wrong!. Please try again.',
        placement: 'bottom',
      });
    }
  } catch (error) {
    yield put(adminAction.setLoaderStatus(false));
    console.error('Error in updating Roles data', error);
    Notification({
      type: 'error',
      message: 'Error',
      description: error?.message || 'Something went wrong!. Please try again.',
      placement: 'bottom',
    });
  }
}

function* addEditPartnerSaga(req) {
  yield put(adminAction.setLoaderStatus(true));
  try {
    const jsonData = yield call(
      api.callPost,
      '/admin/onboardingPartner ',
      req?.body
    );
    if (jsonData?.data?.message === 'Success') {
      yield put(adminAction.setLoaderStatus(false));
      yield put(adminAction.getProjectPartners(''));
      Notification({
        type: 'success',
        message: 'Success',
        description: 'Partner details updated successfully!',
        placement: 'bottom',
      });
    } else {
      yield put(adminAction.setLoaderStatus(false));
      Notification({
        type: 'error',
        message: 'Error',
        description: jsonData?.data?.message || 'Something went wrong!. Please try again.',
        placement: 'bottom',
      });
    }
  } catch (error) {
    yield put(adminAction.setLoaderStatus(false));
    console.error('Error in updating Roles data', error);
    Notification({
      type: 'error',
      message: 'Error',
      description: error?.message || 'Something went wrong!. Please try again.',
      placement: 'bottom',
    });
  }
}
// function* onClickRejectSaga(req) {
//   yield put(adminAction.setLoaderStatus(false));
//   console.log('req?.body', req?.comment);
// try {
//   const jsonData = yield call(
//     api.callPost,
//     '/admin/savePermission',
//     req?.data
//   );
//   if (jsonData?.data?.message === 'Success') {
//     yield put(adminAction.setLoaderStatus(false));
//     Notification({
//       type: 'success',
//       message: 'Success',
//       description: 'Roles updated successfully!',
//       placement: 'bottom',
//     });
//   } else {
//     yield put(adminAction.setLoaderStatus(false));
//     Notification({
//       type: 'error',
//       message: 'Error',
//       description: 'Something went wrong!. Please try again.',
//       placement: 'bottom',
//     });
//   }
// } catch (error) {
//   yield put(adminAction.setLoaderStatus(false));
//   console.error('Error in updating Roles data', error);
//   Notification({
//     type: 'error',
//     message: 'Error',
//     description: error?.message || 'Something went wrong!. Please try again.',
//     placement: 'bottom',
//   });
// }
// }

function* onClickApprovalSaga(req) {
  yield put(adminAction.setLoaderStatus(true));
  try {
    const jsonData = yield call(
      api.callPost,
      '/admin/approveRejectApproval',
      req?.body
    );
    if (jsonData?.data?.message === 'Request Approved' || jsonData?.data?.message === 'Request Rejected') {
      yield put(adminAction.setLoaderStatus(false));
      yield put(adminAction.getApprovalsList());
      Notification({
        type: 'success',
        message: 'Success',
        description: jsonData?.data?.message === 'Request Approved' ? 'Request approved successfully!' : 'Request rejected successfully!',
        placement: 'bottom',
      });
    } else {
      yield put(adminAction.setLoaderStatus(false));
      Notification({
        type: 'error',
        message: 'Error',
        description: 'Something went wrong!. Please try again.',
        placement: 'bottom',
      });
    }
  } catch (error) {
    yield put(adminAction.setLoaderStatus(false));
    console.error('Error in updating Roles data', error);
    Notification({
      type: 'error',
      message: 'Error',
      description: error?.message || 'Something went wrong!. Please try again.',
      placement: 'bottom',
    });
  }
}
function* getApprovalsListSaga() {
  yield put(adminAction.setLoaderStatus(true));
  try {
    const jsonData = yield call(
      api.callGet,
      '/admin/getApprovals',
    );
    if (jsonData?.data?.message === 'Success') {
      yield put(adminAction.setLoaderStatus(false));
      yield put(adminAction.setApprovalsList(jsonData?.data?.data || []));
    } else {
      yield put(adminAction.setLoaderStatus(false));
      Notification({
        type: 'error',
        message: 'Error',
        description: 'Something went wrong!. Please try again.',
        placement: 'bottom',
      });
    }
  } catch (error) {
    yield put(adminAction.setLoaderStatus(false));
    console.error('Error in updating Roles data', error);
    Notification({
      type: 'error',
      message: 'Error',
      description: error?.message || 'Something went wrong!. Please try again.',
      placement: 'bottom',
    });
  }
}

function* getHistoryListSaga() {
  yield put(adminAction.setLoaderStatus(true));
  try {
    const jsonData = yield call(
      api.callGet,
      '/admin/getHistoryList',
    );
    if (jsonData?.data?.message === 'Success') {
      yield put(adminAction.setLoaderStatus(false));
      yield put(adminAction.setHistoryList(jsonData?.data?.data || []));
    } else {
      yield put(adminAction.setLoaderStatus(false));
      Notification({
        type: 'error',
        message: 'Error',
        description: 'Something went wrong!. Please try again.',
        placement: 'bottom',
      });
    }
  } catch (error) {
    yield put(adminAction.setLoaderStatus(false));
    console.error('Error in updating Roles data', error);
    Notification({
      type: 'error',
      message: 'Error',
      description: error?.message || 'Something went wrong!. Please try again.',
      placement: 'bottom',
    });
  }
}
function* getExtRepSaga(req) {
  yield put(adminAction.setLoaderStatus(true));
  try {
    const jsonData = yield call(
      api.callGet,
      `/admin/getExtRespList?partnerName=${req?.partnerName}`,
    );
    if (jsonData?.data?.message === 'Success') {
      yield put(adminAction.setLoaderStatus(false));
      yield put(adminAction.setRegHistoryResp(jsonData?.data?.data || []));
    } else {
      yield put(adminAction.setLoaderStatus(false));
      Notification({
        type: 'error',
        message: 'Error',
        description: 'Something went wrong!. Please try again.',
        placement: 'bottom',
      });
    }
  } catch (error) {
    yield put(adminAction.setLoaderStatus(false));
    console.error('Error in updating Roles data', error);
    Notification({
      type: 'error',
      message: 'Error',
      description: error?.message || 'Something went wrong!. Please try again.',
      placement: 'bottom',
    });
  }
}
function* getCertifiedProductsSaga() {
  yield put(adminAction.setLoaderStatus(true));
  try {
    const jsonData = yield call(
      api.callGet,
      '/admin/getCertifiedProducts',
    );
    if (jsonData?.data?.message === 'Success') {
      yield put(adminAction.setLoaderStatus(false));
      yield put(adminAction.setCertifiedProducts(jsonData?.data?.data?.appFeatureList || []));
    } else {
      yield put(adminAction.setLoaderStatus(false));
      Notification({
        type: 'error',
        message: 'Error',
        description: 'Something went wrong!. Please try again.',
        placement: 'bottom',
      });
    }
  } catch (error) {
    yield put(adminAction.setLoaderStatus(false));
    console.error('Error in updating Roles data', error);
    Notification({
      type: 'error',
      message: 'Error',
      description: error?.message || 'Something went wrong!. Please try again.',
      placement: 'bottom',
    });
  }
}

function* updateExtRepSaga(req) {
  yield put(adminAction.setLoaderStatus(true));
  try {
    const jsonData = yield call(
      api.callPost,
      '/admin/updateExtResp',
      req?.body
    );
    if (jsonData?.data?.message === 'Success') {
      yield put(adminAction.setLoaderStatus(false));
      yield put(adminAction.getRegHistoryResp(''));
      Notification({
        type: 'success',
        message: 'Success',
        description: 'External Response Updated Successfully!',
        placement: 'bottom',
      });
    } else {
      yield put(adminAction.setLoaderStatus(false));
      Notification({
        type: 'error',
        message: 'Error',
        description: jsonData?.data?.message || 'Something went wrong!. Please try again.',
        placement: 'bottom',
      });
    }
  } catch (error) {
    yield put(adminAction.setLoaderStatus(false));
    console.error('Error in updating External response data', error);
    Notification({
      type: 'error',
      message: 'Error',
      description: error?.message || 'Something went wrong!. Please try again.',
      placement: 'bottom',
    });
  }
}

function* getProductsSaga(req) {
  if (req?.dropdown) {
    yield put(adminAction.setDropdownStatus(true));
  } else {
    yield put(adminAction.setLoaderStatus(true));
  }
  try {
    const jsonData = yield call(
      api.callGet,
      `/admin/getProducts?partnerName=${req?.id}`,
    );
    if (jsonData?.data?.message === 'Success') {
      yield put(adminAction.setLoaderStatus(false));
      yield put(adminAction.setDropdownStatus(false));
      yield put(adminAction.setProducts(jsonData?.data?.data || []));
    } else {
      yield put(adminAction.setLoaderStatus(false));
      yield put(adminAction.setDropdownStatus(false));
      Notification({
        type: 'error',
        message: 'Error',
        description: jsonData?.data?.message || 'Something went wrong!. Please try again.',
        placement: 'bottom',
      });
    }
  } catch (error) {
    yield put(adminAction.setLoaderStatus(false));
    yield put(adminAction.setDropdownStatus(false));
    console.error('Error in updating Roles data', error);
    Notification({
      type: 'error',
      message: 'Error',
      description: error?.message || 'Something went wrong!. Please try again.',
      placement: 'bottom',
    });
  }
}

function* subscribeProductsSaga(req) {
  yield put(adminAction.setLoaderStatus(true));
  try {
    const jsonData = yield call(
      api.callPost,
      '/admin/subscribeProducts',
      req?.body
    );
    if (jsonData?.data?.message === 'Success') {
      yield put(adminAction.setLoaderStatus(false));
      yield put(adminAction.getProjectPartnersSubscriptions(req?.body?.partnerId));
      yield put(adminAction.getProducts(req?.body?.partnerName));
      Notification({
        type: 'success',
        message: 'Success',
        description: 'Subscriptions Updated Successfully!',
        placement: 'bottom',
      });
    } else {
      yield put(adminAction.setLoaderStatus(false));
      Notification({
        type: 'error',
        message: 'Error',
        description: jsonData?.data?.message || 'Something went wrong!. Please try again.',
        placement: 'bottom',
      });
    }
  } catch (error) {
    yield put(adminAction.setLoaderStatus(false));
    console.error('Error in updating External response data', error);
    Notification({
      type: 'error',
      message: 'Error',
      description: error?.message || 'Something went wrong!. Please try again.',
      placement: 'bottom',
    });
  }
}

export default function* combinedSaga() {
  yield all([takeEvery(GET_PRODUCT_SERVICE_API, getProductServiceAPISaga)]);
  yield all([takeEvery(GET_CREATE_PRODUCT, createProductSaga)]);
  yield all([takeEvery(GET_CREATE_SERVICE, createServiceSage)]);
  yield all([takeEvery(GET_CREATE_API, createApiSaga)]);
  yield all([takeEvery(GET_API_ENDPOINTS, getApiEndPointsSaga)]);
  yield all([takeEvery(GET_API_PUBLISH, getApiPublishSaga)]);
  yield all([takeEvery(GET_API_PROJECT, getApiProjectSaga)]);
  yield all([takeEvery(GET_PROJECT_PARTNERS, projectPartnersSaga)]);
  yield all([
    takeEvery(
      GET_PROJECT_PARTNERS_SUBSCRIPTIONS,
      projectPartnerSubscriptionSaga
    ),
  ]);
  yield all([takeEvery(GET_ROLES_USERS, getRoleUsersSage)]);
  yield all([takeEvery(UPLOAD_PROJECT_DOC, uploadProjectDocSaga)]);
  yield all([takeEvery(SET_PUBLISH_CHANGES, pubclishChangesSaga)]);
  yield all([takeEvery(GET_PROJECT_VIEW, getProjectViewSaga)]);
  yield all([takeEvery(SET_PROJECT_MILESTONE, setProjectMileStoneSaga)]);
  yield all([takeEvery(GET_DELETE_FILE, deleteProjectDocSaga)]);
  yield all([takeEvery(UPDATE_EDIT_WEBPAGE, updateWebcontentSaga)]);
  yield all([takeEvery(SAVE_PERMISSIONS, savePermissionsSaga)]);
  yield all([takeEvery(ADD_EDIT_PARTNER, addEditPartnerSaga)]);
  yield all([takeEvery(ON_CLICK_APPROVAL, onClickApprovalSaga)]);
  yield all([takeEvery(GET_APPROVALS_LIST, getApprovalsListSaga)]);
  yield all([takeEvery(GET_HISTORY, getHistoryListSaga)]);
  yield all([takeEvery(GET_REg_EXT_RESP, getExtRepSaga)]);
  yield all([takeEvery(GET_CERTIFIED_PRODUCTS, getCertifiedProductsSaga)]);
  yield all([takeEvery(UPDATE_REG_EXT_RESP, updateExtRepSaga)]);
  yield all([takeEvery(GET_PRODUCTS, getProductsSaga)]);
  yield all([takeEvery(SUBSCRIBE_PRODUCTS, subscribeProductsSaga)]);
}
