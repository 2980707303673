import React from 'react';
import {
  Text,
  Modal,
  OutlineButton,
  Button,
  Radio,
  Select
} from '../../../../../../../CommonComponents';
import './AddPartner.scss';
const AddPartner = (props) => {
  const {
    hideAddPartnerPopup,
    showAddPartnerModal,
    radioCompanyyReg,
    onChangeCompanyRegEvent,
    companyReg,
    subscriptionOption,
    onChangeSubscriptionEvent,
    radioSubscription,
    onChangedataintegrationEvent,
    dataIntegrationOption,
    radioDataIntegration,
    onFormChange,
    formData,
    productsOptions,
    onClickSave,
    error,
    loading,
    editMode
  } = props;
  return (
    <Modal
      visible={showAddPartnerModal}
      hideModal={hideAddPartnerPopup}
      closeModalPopup={hideAddPartnerPopup}
      title={editMode ? 'Update Partner' : 'Add Partner'}
      width={820}
      className={'PartnerModal'}
      footer={
        <div className='viewBlock'>
          <OutlineButton
            className='normalButton viewButton'
            onClick={hideAddPartnerPopup}
          >
            Cancel
          </OutlineButton>
          <Button
            className={'normalButton viewButton'}
            onClick={onClickSave}
          >
            Save
          </Button>
        </div>
      }
      centered
    >
      <div className='AddPartenerWrapper'>
        <div className="formControl">
          <label className='label'>
            Partner Name <span style={{ color: 'red' }}>*</span>
          </label>
          <Text
            className='partnerNameText'
            onChange={(e) => onFormChange(e.target.value, 'PartnerName', 'Partner Name')}
            value={formData?.PartnerName}
            statusMsg={error?.partnerName ?? error?.partnerName}
          />
        </div>
        {formData?.TSPID && <div className="formControl">
          <label className='label'>
            TSP ID
          </label>
          <Text
            className='partnerNameText'
            onChange={(e) => onFormChange(e.target.value, 'TSPID')}
            value={formData?.TSPID}
            disabled
          />
        </div>}
        <div className="formControl mb-1">
          <label className='label'>
            Email Address <span style={{ color: 'red' }}>*</span>
          </label>
          <Text
            className='partnerNameText'
            onChange={(e) => onFormChange(e.target.value, 'EmailAddress', 'Email Address')}
            value={formData?.EmailAddress}
            statusMsg={error?.emailAddress ?? error?.emailAddress}
          />
        </div>
        <div className="formControl">
          <label className='label'>
            Company Registration API
          </label>
          <Radio
            className='partnerNameText'
            options={companyReg}
            onChangeEvent={onChangeCompanyRegEvent}
            value={radioCompanyyReg}
          />
        </div>
        <div className="formControl">
          <label className='label'>
            Subscription API
          </label>
          <Radio
            className='partnerNameText'
            options={subscriptionOption}
            onChangeEvent={onChangeSubscriptionEvent}
            value={radioSubscription}
          />
        </div>
        <div className="formControl">
          <label className='label'>
            Data Integrator Support
          </label>
          <Radio
            className='partnerNameText'
            options={dataIntegrationOption}
            onChangeEvent={onChangedataintegrationEvent}
            value={radioDataIntegration}
          />
        </div>
        <div className="formControl">
          <label className='label'>
            Certified Products
          </label>
          <Select
            className='partnerNameText'
            options={productsOptions}
            multiple={true}
            value={formData?.CertifiedProducts}
            onChange={(e) => onFormChange(e, 'CertifiedProducts')}
            loading={loading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AddPartner;