import React, { useEffect, useState } from 'react';
import SwaggerUI from 'swagger-ui-react';
import { useLocation } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { selectors as appSelector } from '../../../state/features/app';

import './Swagger.scss';
import { BreadcrumbComponent } from '../../CommonComponents';
import * as R from 'ramda';

import 'swagger-ui-react/swagger-ui.css';

const Swagger = (props) => {
  const { currentRoute } = useSelector((state) => ({
    currentRoute: appSelector.currentRoute(state),
  }));

  // const navigate = useNavigate();
  const [breadcrumbList, setBreadcrumbList] = useState([]);

  const breadcrumbOnClick = (_, index) => {
    let breadcrumb = [...breadcrumbList];
    let navigateArray = breadcrumb.slice(0, index + 1);
    let navigateLink = navigateArray.join('/');
    // navigate(`/${navigateLink}`);
    props?.onNavClick([currentRoute[0], ...navigateArray], navigateLink);
  };

  let location = useLocation();

  useEffect(() => {
    let locationFormat = location?.state?.replaceAll('%20', ' ');
    let locationSplit = locationFormat.split('/');
    let locationArray = locationSplit.splice(1);
    setBreadcrumbList(locationArray);
  }, [location]);

  return (
    <div className='swaggerBlock'>
      {breadcrumbList.length ? (
        <BreadcrumbComponent
          onClickEvent={breadcrumbOnClick}
          valueList={[...breadcrumbList, 'Swagger']}
        />
      ) : null}
      <div className='swaggerContainer'>
        {props?.loading ? (
          <p>Loading please wait...</p>
        ) : R.isEmpty(props?.swaggerJson) ? (
          <p>no data found</p>
        ) : (
          <SwaggerUI
            spec={props?.swaggerJson}
            supportedSubmitMethods={props.supportedHTTPMethods ?? []}
          />
        )}
      </div>
    </div>
  );
};

export default Swagger;
