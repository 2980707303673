import React from 'react';
import {
  Modal,
  OutlineButton,
  Button,
  Text
} from '../../../../../../../CommonComponents';
import './AcceptModal.scss';
const AcceptModal = (props) => {
  const { showModal, hideModalPopup, onFormChange, formData, onClickAccept, error } = props;
  return (
    <Modal
      visible={showModal}
      hideModal={hideModalPopup}
      closeModalPopup={hideModalPopup}
      title={'Accept Response'}
      width={620}
      className={'AcceptModal'}
      footer={
        <div className='viewBlock'>
          <OutlineButton
            className='normalButton viewButton'
            onClick={hideModalPopup}
          >
            Cancel
          </OutlineButton>
          <Button
            className={'normalButton viewButton'}
            onClick={onClickAccept}
          >
            Accept
          </Button>
        </div>
      }
      centered
    >
      <div className="acceptModal">
        <div className="formControl">
          <label className='label'>
            TSP Name <span style={{ color: 'red' }}>*</span>
          </label>
          <Text
            className='tspNameText'
            value={formData?.TSPName}
            onChange={(e) => onFormChange(e.target.value, 'TSPName', 'TSP name')}
            statusMsg={error?.TSPName}
          />
        </div>
        <div className="formControl">
          <label className='label'>
            Customer Refernce <span style={{ color: 'red' }}>*</span>
          </label>
          <Text
            className='customerRefernceText'
            value={formData?.CustomerRefernce}
            onChange={(e) => onFormChange(e.target.value, 'CustomerRefernce', 'customer reference')}
            statusMsg={error?.CustomerRefernce}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AcceptModal;
