import React from 'react';
import { Button, OutlineButton, Modal/* , Text */ } from '../../../CommonComponents';
import { SigninModal,ProjectDocument } from '../../CommonWidget';


import Card from './Card';
import Subscription from './Subscription';
import './TileDescriptor.scss';
import Edit from '../../../../assets/images/edit.png';

const TileDescriptor = (props) => {
  const { pathname, services, currentLocation,userName,heading,mainPage,body,content/* ,manageService */ } = props;
  const editUI = (value, field, key) => {
    return props.editable ? <div className='editableTools'><div className='titleBadge'>{props.badge}</div>
      <Button className='normalButton viewButton' onClick={() =>props.onClickField(value, field, key)}>
        <img src={Edit} alt='editImg' className='editImage'/>Edit</Button></div>
      : null;
  };

  const loadsubscription = () => {
    return (
      <>
        {services?.length > 0 && currentLocation?.length === 2 && (
          <Button
            className='mediumButton subscriptionBtns'
            onClick={() => props?.onClickManage()}
          >
            Manage Subscriptions
          </Button>
        )}
      </>
    );
  };

  const loadloginbutton = () => {
    if (mainPage) {
      return (
        <div className='float-right'>
          <OutlineButton onClick={props.onShowSignin}>Sign In</OutlineButton>
        </div>
      );
    }
  };

  const loadHeading = () => {
    // value, field, key
    if (Object.keys(heading)?.length > 0) {
      if (heading?.type === 'image') {
        const imageSrc = heading?.imgSrc ?? '';
        return imageSrc ? (
          <img src={imageSrc} alt='Tile Desc Heading' className='bannerImage' />
        ) : null;
      }
      return (
        <div className='clearfix'>
          <p className='topic float-left'>
            {editUI(heading?.name, 'heading', 'name')}
            {heading?.name ?? ''}</p>
          {
            <>
              {!userName && !props.editable ? (
                loadloginbutton()
              ) : (
                <div className='float-right'>{loadsubscription()}</div>
              )}
            </>
          }
        </div>
      );
    }
  };

  const loadBody = () => {
    if (Object.keys(body)?.length > 0) {
      if (body?.type === 'image') {
        const imageSrc = heading?.imgSrc ?? '';
        return (
          <img src={imageSrc} alt='Tile Desc Body' className='bannerImage' />
        );
      }
      return <p className='contentBlock'>
        {editUI(body?.content, 'body', 'content')}
        {body?.content ?? ''}
      </p>;
    }
  };

  const clickLink = (arr, link) => {
    props?.onNavClick(arr?.tree || [], link);
  };

  const loadTile = () => {
    let tileList = body?.tiles ?? [];
    let loadFromNav = props.editable ? false : body?.loadFrom === 'leftNav';
    if (loadFromNav) {
      return services?.map((tile, index) => {
        return (
          <React.Fragment key={index}>
            <Card
              tileContent={{
                ...tile,
                subscribed: tile?.subscriptionStatus,
                productType: currentLocation?.[0] || '',
              }}
              navigateToSubService={props?.navigateToSubService}
              link={`/${pathname}/${tile?.title ? tile?.title : ''}`}
              services={services}
              clickLink={tile?.title ? (arr, link) => clickLink(arr, link) : null}
              editable={props.editable}
            />
          </React.Fragment>
        );
      });
    }
    return tileList.map((tile, index) => {
      return (
        <React.Fragment key={index}>
          <Card
            tileContent={{
              ...tile,
            }}
            navigateToSubService={props?.navigateToSubService}
            link={`/${pathname}/${tile?.title}`}
            services={services}
            clickLink={tile?.title ? (arr, link) => clickLink(arr, link) : null}
            editable={props.editable && props?.apiId !== 0}
            onTileSave={(title, description, points) => props?.onTileSave(title, description, points, index)}
          />
        </React.Fragment>
      );
    });
  };

  // content

  return (
    <div className={`tileDescriptor ${content?.className}`}>
      {loadHeading()}
      <div className='clearfix'>
        <div className={'float-left leftBlock'}>
          <div>
            {loadBody()}
            <div className='servicesTile'>{loadTile()}</div>
          </div>
        </div>
        {
          props.docs?.length ? 
            <div className='float-right rightBlock'>
              <ProjectDocument
                projectDocuments={ props.docs || []}
                onFileUpload={props?.onFileUpload}
                onDeleteFile={props?.onDeleteFile}
                onDownloadFile={props?.onDocClick}
                editable={props.editable}
                enableDocDelete={props?.enableDocDelete}
                isAdmin={props?.isAdmin}
              />
            </div> : null
        }       
      </div>
      <Modal
        visible={props.showModal}
        hideModal={props.hideModalPopup}
        closeModalPopup={props.hideModalPopup}
        title={`${pathname} Subscription`}
        width={620}
        footer={null}
        centered
        className='leftTitle-popup'
      >
        <Subscription
          hideModalPopup={props.hideModalPopup}
          availableServices={content.availableServices}
          content={content}
          services={services}
          localProdId={props?.localProdId}
          isVisible={props?.showModal}
        />
      </Modal>
      <SigninModal
        enableSignIn={props.enableSignIn}
        hideModalPopup={props.onHideSignin}
      />
      {/* <Modal
        visible={props.showEditContent}
        hideModal={props.onHideEditContent}
        closeModalPopup={props.onHideEditContent}
        title={''}
        width={400}
        footer={
          <div className='editBannerFooter'>
            <OutlineButton
              className='normalButton viewButton'
              onClick={props?.onHideEditContent}
            >
              Cancel
            </OutlineButton>
            <Button
              className={'normalButton viewButton'}
              onClick={props?.onSaveWebContent}
            >
              Save
            </Button>
          </div>
        }
        centered  
        className='editModalPopup'
        closable={false}
      >
        <div className='editableModal'>
          <div className='clearfix'>
            <label className='float-left labelText'> {props.badge === 'Text'? 'Section Title': 'Section Description'}</label>
          </div>
          <Text value={props.editText} row={0} onChange={props.onEditData}/>
        </div>
      </Modal> */}
    </div>
  );
};

export default TileDescriptor;
