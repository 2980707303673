import React from 'react';
import './HeaderContent.scss';

const HeaderContent = (props) => {
  const content = props.content ?? {};
  const title = content?.title ?? '';
  const body =  content?.body ?? '';
    
  return <div className='headerContent'>
    <p className='title'>{title}</p>
    <p className='headerContent-body'>{body}</p>
  </div>;
};

export default HeaderContent;