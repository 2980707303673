import React, { useState } from 'react';
import moment from 'moment';
import MileStones from './MileStones';

const MileStonesContainer = (props) => {
  const dateFormat = 'DD/MM/YYYY';
  const [endDate, setEndDate] = useState(null);
  const [actualCompletionDate, setActualCompletionDate] = useState(null);
  const { isPartner } = props;
  
  const onChangeDate = (date) => {
    setEndDate(date);
  };
  const disabledFromDate = (current) => {
    let customDate = new Date();
    return current && current < moment(customDate, 'YYYY-MM-DD');
  };


  const onChangeActualDate = (date/* , dateString */) => {
    setActualCompletionDate(date);
  };
  const disabledActualDate = (current) => {
    let customDate = new Date();
    return current && current < moment(customDate, 'YYYY-MM-DD');
  };



  return (
    <MileStones
      dataItem={props.dataItem}
      openPanels={props?.openPanels}
      onAccordianClick={props?.onAccordianClick}
      onChangeDate={isPartner ? () => {} : onChangeDate}
      endDate={endDate}
      dateFormat={dateFormat}
      disabledFromDate={disabledFromDate}
      onTickClick={isPartner ? () => {} : props?.onTickClick}
      viewData={props?.viewData}
      actualCompletionDate={actualCompletionDate}
      onChangeActualDate={isPartner ? () => {} : onChangeActualDate}
      disabledActualDate={disabledActualDate}
      onFieldChange={isPartner ? () => {} : props?.onFieldChange}
      isPartner={isPartner}
      
    />
  );
};

export default MileStonesContainer;
