import React from 'react';
import {Modal,Button,OutlineButton} from '../../../CommonComponents';

const SignInModal = (props) => {
  return  props.enableSignIn &&
    <Modal
      visible={props.enableSignIn}
      hideModal={props.hideModalPopup}
      closeModalPopup={props.hideModalPopup}
      title={'Sign In'}
      width={440}
      footer={null}
      centered
    >
      <div className='signinModal'>
        <div>
          <Button className='removeStlying' onClick={() => props.redirectToExternalAD('salesforce')}>I'm a Partner</Button>
        </div>
        <div>
          <OutlineButton onClick={() => props.redirectToExternalAD()}>I'm a Cummins Employee</OutlineButton>
        </div>
      </div>
    </Modal>;
      
};

export default SignInModal;