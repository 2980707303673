import React from 'react';
import './FailedInfoBox.scss';
import FailedIcon from '../../../../../../src/assets/images/circle-warning.svg';
const FailedInfoBox = ({ reason }) => {
  return (
    <div className='clearfix failedinfo'>
      <div className='borderBlock'>
        <div className='iconView'>
          <img src={FailedIcon} alt="failed"  style={{maxWidth : '100%'}} />
        </div>
        <div className='textView'>
          <div className='title'>Request to External Response APIs is Failed</div>
          <div className='description'>comments : {reason || '-'}</div>
        </div>
      </div>
    </div>
  );
};

export default FailedInfoBox;