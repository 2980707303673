import React, { useRef, useState } from 'react';
// import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';

import {
  operations as appOps,
  selectors as appSelector,
} from '../../../../../state/features/app';

import Header from './Header';
import api from '../../../../../utils/clientApiHelper';
import { useNavigate } from 'react-router-dom';

const HeaderContainer = (props) => {
  const navigate = useNavigate();
  const searchRef = useRef(null);
  const idToken = window.sessionStorage.getItem('cognitoUserPoolId') ?? '';

  const dispatch = useDispatch();
  const dispatchSetUserDetails = appOps.dispatchSetUserDetails(dispatch);
  const { userDetails } = useSelector((state) => ({
    userDetails: appSelector.userDetails(state),
  }));

  const [enableSignIn, setEnableSignIn] = useState(false);

  const toggleSignInOption = () => {
    setEnableSignIn(!enableSignIn);
  };

  const silderClick = () => {
    props.setMenu((menu) => !menu);
  };

  const logoutUser = () => {
    try {
      // if(userDetails?.isPortalUser === true) {
      //   window.location.href = 'https://iamosmdev-cumminscss.cs252.force.com/cw/IAM_logout';
      // } else {
      //   window.location.href = 'https://login.microsoftonline.com/b31a5d86-6dda-4457-85e5-c55bbc07923d/saml2';
      // }

      api.callGet('/login/logout');
      setTimeout(() => {
        window.location.reload();
        navigate('/');
      }, [700]);
    } catch (err) {
      console.error(err);
    }

    dispatchSetUserDetails('');
  };

  const hideModalPopup = () => {
    setEnableSignIn(false);
  };
  const onfocusSearch = () => {
    if (searchRef?.current) {
      searchRef.current.placeholder = '';
    }
  };

  const onblurSearch = () => {
    if (!searchRef?.current?.value) {
      searchRef.current.placeholder = 'Search';
    }
  };

  const onCancel = () => {
    props.onHideModal();
  };

  return (
    <Header
      userDetails={userDetails ?? {}}
      silderClick={silderClick}
      idToken={idToken}
      toggleSignInOption={toggleSignInOption}
      enableSignIn={enableSignIn}
      logoutUser={logoutUser}
      hideModalPopup={hideModalPopup}
      searchRef={searchRef}
      onfocusSearch={onfocusSearch}
      onblurSearch={onblurSearch}
      isAdmin={props?.isAdmin}
      editable={props.editable}
      onCancel={onCancel}
      onPublishClick={props?.onPublishClick}
      onClickPrevious={props?.onClickPrevious}
      previousClick={props?.previousClick}
      sections={props?.sections}
    />
  );
};

export default HeaderContainer;
