import React from 'react';
import { Tree } from 'antd';
import './Tree.scss';

const TreeContainer = ({
  autoExpandParent = false,
  defaultExpandedKeys = [],
  defaultSelectedKeys = [],
  expandedKeys = [],
  onExpand = () => {},
  onSelect = () => {},
  onCheck = () => {},
  treeData = [],
  selectable = true,
  selectedKeys = []
}) => {
  return (
    <div>
      <Tree
        autoExpandParent={autoExpandParent}
        defaultExpandedKeys={defaultExpandedKeys}
        defaultSelectedKeys={defaultSelectedKeys}
        expandedKeys={expandedKeys}
        onExpand={onExpand}
        onSelect={onSelect}
        onCheck={onCheck}
        treeData={treeData}
        className='treeView'
        selectable={selectable}
        selectedKeys={selectedKeys}
      />
    </div>
  );
};

export default TreeContainer;
