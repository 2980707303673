import * as R from 'ramda';

const userDetails = (state) => R.pathOr('', ['app', 'userDetails'], state);
const navRoutes = (state) => R.pathOr('', ['app', 'navRoutes'], state);
const navRouteFetchingState = (state) =>
  R.pathOr(false, ['app', 'navRouteFetchingState'], state);
const routeContent = (state) => R.pathOr('', ['app', 'routeContent'], state);
const contentFetchingState = (state) =>
  R.pathOr(false, ['app', 'contentFetchingState'], state);
const fileJsonContent = (state) =>
  R.pathOr('', ['app', 'fileJsonContent'], state);
const globalFAQContent = (state) =>
  R.pathOr('', ['app', 'globalFAQContent'], state);
const globalFAQContentLoadingState = (state) =>
  R.pathOr('', ['app', 'globalFAQContentLoadingState'], state);
const localBodyLoad = (state) =>
  R.pathOr(false, ['app', 'localBodyLoad'], state);
const routeApiMap = (state) => R.pathOr('', ['app', 'routeApiMap'], state);
const currentRoute = (state) => R.pathOr('', ['app', 'currentRoute'], state);
const prodServiceMap = state => R.pathOr('', ['app', 'prodServiceMap'], state);
const routeKeyMap = state => R.pathOr({}, ['app', 'routeKeyMap'], state);
const userAuth = state => R.pathOr({}, ['app', 'userAuth'], state);
const swaggerJson = state => R.pathOr({}, ['app', 'swaggerJson'], state);
const resetDoc = state => R.pathOr(false, ['app', 'resetDoc'], state);
const externalResponse = state => R.pathOr(false, ['app', 'externalResponse'], state);


const selectors = {
  userDetails,
  navRoutes,
  navRouteFetchingState,
  routeContent,
  contentFetchingState,
  fileJsonContent,
  globalFAQContent,
  globalFAQContentLoadingState,
  localBodyLoad,
  routeApiMap,
  currentRoute,
  prodServiceMap,
  routeKeyMap,
  userAuth,
  swaggerJson,
  resetDoc,
  externalResponse
};

export default selectors;
