import * as R from 'ramda';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

function CustomException(error) {
  return {
    endpoint: R.pathOr('', ['response', 'config', 'url'], error),
    statusText: R.pathOr('', ['response', 'statusText'], error),
    message: R.pathOr('', ['message'], error),
    status: R.pathOr(500, ['response', 'status'], error),
    data: R.pathOr(null, ['response', 'data'], error),
  };
}
const get = (endpoint, headers, options = {}) => {
  const commonHeaders = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
    // 'x-correlation-id': uuidv4(),
  };
  const commonOptions = {
    method: 'GET',
    responseType: 'json',
    ...options,
  };
  return axios.get(endpoint,
    {
      ...commonOptions,
      headers: { ...commonHeaders, ...headers },
    })
    .then(response => {
      return response;
    })
    .catch((error) => {
      throw new CustomException(error);
    });
};

const post = (endpoint, body, headers, options = {}) => {
  const commonHeaders = {
    'Content-Type': 'application/json',
    'x-correlation-id': uuidv4(),
  };
  const commonOptions = {
    method: 'POST',
    responseType: 'json',
    ...options,
  };
  return axios.post(endpoint, body,
    {
      ...commonOptions,
      headers: { ...commonHeaders, ...headers },
    })
    .then(response => {
      return response;
    })
    .catch((error) => {
      throw new CustomException(error);
    });
};

const put = (endpoint, body, headers, options = {}) => {
  const commonHeaders = {
    'Content-Type': 'application/json',
    // 'x-correlation-id': uuidv4(),
  };
  const commonOptions = {
    method: 'PUT',
    responseType: 'json',
    ...options,
  };
  return axios.put(endpoint, body,
    {
      ...commonOptions,
      headers: { ...commonHeaders, ...headers },
    })
    .then(response => response)
    .catch((error) => {
      throw new CustomException(error);
    });
};

const deleteCall = (endpoint, data, headers, options = {}) => {
  const commonHeaders = {
    'Content-Type': 'application/json',
    'x-correlation-id': uuidv4(),
  };
  const commonOptions = {
    method: 'DELETE',
    responseType: 'json',
    ...options,
  };
  return axios.delete(endpoint, data,
    {
      ...commonOptions,
      headers: { ...commonHeaders, ...headers },
    })
    .then(response => response)
    .catch((error) => {
      throw new CustomException(error);
    });
};

function exportFunctions() {
  let callGet = get;
  let callPost = post;
  let callPut = put;
  let callDelete = deleteCall;


  return {
    callGet,
    callPost,
    callPut,
    callDelete
  };
}

export default exportFunctions();

export const TestExports = {
  get,
  post,
  put,
  deleteCall
};
