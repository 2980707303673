import React from 'react';
import Logo from '../../../../../assets/images/logo.png';
import Profile from '../../../../../assets/images/profile.png';
// import SearchIcon from '../../../../../assets/images/search.png';
import { SigninModal } from '../../../../../components/Body/CommonWidget';
import {/* Button, */ OutlineButton } from '../../../../../components/CommonComponents';
import './Header.scss';

const Header = (props) => {
  const userName = props.userDetails?.name ?? '';
  return (
    <div className={`header ${props.editable ? 'editable' : ''}`}>
      {
        !props.editable ?
          <div className='clearfix'>
            <div className='float-left logoBlock'>
              <a href={'/'} className='logoLink'><img src={Logo} alt='logo' className='logImg' />
                <span className='logoText'>Partner Integration Portal</span>
              </a>
            </div>
            <div className='float-right mobileHeaderUser'>
              <span className="fa fa-bars menuBar" onClick={props.silderClick}></span>
              {
                userName ?
                  <div className='userBlock'>
                    <img src={Profile} alt='profile' className='profilePic' />
                    <span className='profileText'>
                      <span className='username'>{userName} </span>
                      <span className='menuList whiteBtn signout' onClick={() => props.logoutUser()}>
                        Sign Out
                      </span>
                    </span>
                  </div> :
                  <span className='menuList whiteBtn signin' onClick={props.toggleSignInOption}>
                    Sign In
                  </span>
              }
            </div>
          </div> :
          <div className='clearfix'>
            <div className='float-left headingText'>
              Web Editor
            </div>
            <div className='float-right'>
              <OutlineButton className='buttonSpace normalButton' onClick={props.onCancel}>
                Close
              </OutlineButton>
              <OutlineButton className='normalButton leftSpace' onClick={props?.onClickPrevious}>{props?.previousClick ? 'Current' : 'Previous'} Version</OutlineButton>

              <OutlineButton className='normalButton leftSpace' onClick={props?.onPublishClick}>Publish Changes</OutlineButton>
            </div>
          </div>
      }

      <SigninModal
        enableSignIn={props.enableSignIn}
        hideModalPopup={props.hideModalPopup}
      />
    </div>
  );
};

export default Header;