import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectors as appSelector } from '../../../../state/features/app';

import MainContent from './MainContent';

const MainContentContainer = (props) => {
  const [mobile, setMobile] = useState(false);
  const { userDetails } = useSelector((state) => ({
    userDetails: appSelector.userDetails(state),
  }));

  const resizeFun = () => {
    if (window.innerWidth > 768) {
      setMobile(false);
    } else {
      setMobile(true);
    }
  };

  useEffect(() => {
    resizeFun();
    window.addEventListener('resize', () => {
      resizeFun();
    });
    return window.removeEventListener('resize', resizeFun);
  }, []);

  return (
    <MainContent
      bodyContent={props?.bodyContent}
      swaggerJson={props?.swaggerJson}
      loading={props?.loading}
      mobile={mobile}
      userDetails={userDetails}
      onNavClick={props?.onNavClick}
      editable={props.editable}
      onWebFieldChange={props?.onWebFieldChange}
      onHideEditContent={props?.onHideEditContent}
      onSaveWebContent={props?.onSaveWebContent}
      onClickField={props?.onClickField}
      editField={props?.editField}
      fieldKey={props?.fieldKey}
      showEditContent={props?.showEditContent}
      editText={props?.editText}
      setEditText={props?.setEditText}
      showLink={props?.showLink}
      editLink={props?.editLink}
      setEditLink={props?.setEditLink}
      isLink={props?.isLink}
      editFieldLink={props?.editFieldLink}
      onImageUpload={props?.onImageUpload}
      onVideoUpload={props?.onVideoUpload}
      onMethodImgUpload={props?.onMethodImgUpload}
      enableDelete={props?.enableDelete}
      onFileUpload={props?.onFileUpload}
      onDownloadFile={props?.onDownloadFile}
      onWebDocDelete={props?.onWebDocDelete}
      onTileSave={props?.onTileSave}
      apiId={props?.apiId}
      isAdmin={props?.isAdmin}
    />
  );
};

export default MainContentContainer;
