import React from 'react';
import { Tree } from '../../../../components/CommonComponents';
import './Navigation.scss';

const Navigation = (props) => {
  const loadMenuItem = () => {
    const nav = props?.navItems?.map((item, index) => {
      return (
        <div
          key={index}
          className={`mainNav 
        ${props.activeTopic == item.heading ? 'activeTopic' : ''}`}
        >
          <p className='main-nav-text'>{item.heading}</p>
          <Tree
            treeData={item.nav}
            onSelect={props.onLeftNavSelection}
            selectedKeys={props.selectedKeys}
            onExpand={props.onExpandTree}
            expandedKeys={props.expandKeys}
          />
        </div>
      );
    });
    return nav;
  };

  return (
    <div className={`navigation ${props.showMenu ? 'showMenu' : ''}`}>
      {props.showMenu ? loadMenuItem() : null}
    </div>
  );
};

export default Navigation;
