import React from 'react';
import './ApiProject.scss';
import View from './View';
import TitleSection from './TitleSection/TitleSectionContainer';
import TabAndSearch from './TabAndSearch/TabAndSearchContainer';
import Table from './Table/TableContainer';
import RejectModal from './RejectModal/RejectModalContainer';
import AcceptModal from './AcceptModal';

const ApiProject = (props) => {
  const { rejectDesc, descErr, onClickClose, onClickReject, viewBlock, currentRow } = props;
  return !viewBlock ? (
    <div className='integrationStatus'>
      <TitleSection
        partners={props?.partners}
        userDetails={props?.userDetails}
        selectedView={props?.selectedView}
        onChangeView={props?.onChangeView}
        onSearchChange={props?.onSearchChange}
        onSearch={props?.onSearch}
        searchValue={props?.searchValue}
        onEnter={props?.onEnter}
        onBlur={props?.onBlur}
        onClickClear={props?.onClickClear}

      />
      <TabAndSearch
        tabs={props?.tabs}
        activeTabs={props?.activeTabs}
        onClickTabs={props?.onClickTabs}
      />
      {props.dataSource?.length > 0 && (
        <Table
          heading={'Pending Subscription Requests'}
          dataSource={props.dataSource}
          columns={props.columns}
          scroll={{ x: 'max-content' }}
          loading={props?.loading}
          onChangeEvent={(pageData) => props?.onPaginationChange(pageData)}
          pagination={props?.pagination}
        />
      )}
      <div>
        <Table
          heading={'All Service Subscriptions'}
          dataSource={props.dataSubscription}
          columns={props.columnsSubscription}
          scroll={{ x: 'max-content' }}
          loading={props?.loading}
          onChangeEvent={(pageData) => props?.onAllPaginationChange(pageData)}
          pagination={props?.AllPagination}
        />
      </div>
      <RejectModal
        rejectSubscription={props?.rejectSubscription}
        onClickClose={onClickClose}
        onTextAreaChange={(value) => props?.onTextAreaChange(value)}
        rejectDesc={rejectDesc}
        onClickReject={onClickReject}
        descErr={descErr}
        currentRow={currentRow}
      />
      <AcceptModal
        showModal={props.showModal}
        hideModalPopup={props.hideModalPopup}
        onFormChange={props.onFormChange}
        formData={props.formData}
        onClickAccept={props?.onClickAccept}
        error={props?.error}
      />
    </div>
  ) : (
    <View
      onFileUpload={props?.onFileUpload}
      onDeleteFile={props?.onDeleteFile}
      breadcrumbOnClick={props.breadcrumbOnClick}
      viewData={props?.viewData}
      contactOption={props?.contactOption}
      selectedContact={props?.selectedContact}
      contactList={props?.contactList}
      onContactSelectChange={props?.onContactSelectChange}
      otherData={props?.otherData}
      onChangeOtherData={props?.onChangeOtherData}
      onClickSave={props?.onClickSave}
      onDownloadFile={props?.onDownloadFile}
      onManagerChange={props?.onManagerChange}
      managerList={props?.managerList}
      managers={props?.managers}
      selectedManagers={props?.selectedManagers}
      isAdmin={props?.isAdmin}
    />
  );
};

export default ApiProject;
