import React,{useState} from 'react';
import { Table, Modal } from '../../../../../../../CommonComponents';
import YMLEditor from '../../../../../../../CommonComponents/JSONCodeReader';
const HistoryTab = (props) => {
  const {
    historycolumns,
    history,
    hideHistorypayloadModal,
    historyPayload,
    historyPayloadValue,
    loading
  } = props;

  const [historyaActivePagination, setHistoryaActivePagination] = useState({
    current: 1,
    pageSize: 5,
    pageSizeOptions: [5, 10, 20, 50, 100],
    showSizeChanger: true,
  });

  return (
    <>
      <div className='rolesAndPermissions p5'>
        <Table
          dataSource={history}
          columns={historycolumns}
          scroll={{ x: 'max-content' }}
          pagination={historyaActivePagination}
          onChangeEvent={(pageData) => setHistoryaActivePagination(pageData)}
          rowSelection={false}
          loading={loading}
        />
      </div>
      <Modal
        visible={historyPayload}
        hideModal={hideHistorypayloadModal}
        closeModalPopup={hideHistorypayloadModal}
        title={'Payload'}
        width={620}
        className={'Payload'}
        footer={false}
        centered
      >
        <div>
          <div className="formControl">
            <YMLEditor file={historyPayloadValue} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default HistoryTab;