import {
  GET_LOGIN_USER_DETAILS,
  SET_LOGIN_USER_DETAILS,
  GET_NAV_ROUTES,
  SET_NAV_ROUTES,
  GET_NAV_CONTENT,
  SET_NAV_CONTENT,
  SET_LOCAL_BODY_CONTENT_FLG,
  IS_FETCHING_NAV_CONTENT,
  IS_FETCHING_NAV_ROUTES,
  GET_CODE_FILE_CONTENT_JSON,
  SET_CODE_FILE_CONTENT_JSON,
  CODE_FILE_CONTENT_LOADING,
  REDIRECT_EXTERNAL_LOGIN,
  GET_GLOBAL_FAQ_CONTENT,
  SET_GLOBAL_FAQ_CONTENT,
  SET_GLOBAL_FAQ_CONTENT_LOADING,
  GET_SWAGGER_JSON,
  SET_SWAGGER_JSON,
  IS_FETCHING_SWAGGER_JSON,
  SUBMIT_SUBSCRIPTION_REQUEST,
  SUBMIT_SUBSCRIPTION_LOADING,
  SET_PROD_SERVICE,
  GET_USER_AUTH,
  SET_USER_AUTH,
  SET_CURRENT_ROUTE,
  SET_ROUTE_KEY_MAP,
  SUBMIT_MANAGE_SUBSCRIPTION,
  GET_API_DESC_JSON,
  SET_API_DESC_JSON,
  SET_DELETE_FILE,
  RESET_DOC_DATA,
  UPDATE_DOC_DATA,
  SET_SIMULATE,
  SEND_CONTACT_MESSAGE,
  ADD_EDIT_EXT_RESPONSE,
  SET_EXTERNAL_RESPONSE,
  GET_EXTERNAL_RESPONSE,
} from './types';

const setRouteKeyMap = (routeKeyMap) => ({
  type: SET_ROUTE_KEY_MAP,
  routeKeyMap,
});

const submitSubRequest = (subRequestData, params, fromProject, partnerId) => ({
  type: SUBMIT_SUBSCRIPTION_REQUEST,
  subRequestData,
  params,
  fromProject,
  partnerId,
});

const setProServiceMap = (map) => ({
  type: SET_PROD_SERVICE,
  map,
});

const getGlobalFAQContent = (fileURL) => ({
  type: GET_GLOBAL_FAQ_CONTENT,
  fileURL,
});

const setGlobalFAQContent = (globalFAQContent) => ({
  type: SET_GLOBAL_FAQ_CONTENT,
  globalFAQContent,
});

const setGlobalFAQContentLoading = (loadingState) => ({
  type: SET_GLOBAL_FAQ_CONTENT_LOADING,
  loadingState,
});

const redirectToExternalLogin = (adName) => ({
  type: REDIRECT_EXTERNAL_LOGIN,
  adName,
});

const getFileContentJson = (fileURL) => ({
  type: GET_CODE_FILE_CONTENT_JSON,
  fileURL,
});

const setFileContentJson = (fileJsonContent) => ({
  type: SET_CODE_FILE_CONTENT_JSON,
  fileJsonContent,
});

const setFileLoadingState = (fileDataLoadingState) => ({
  type: CODE_FILE_CONTENT_LOADING,
  fileDataLoadingState,
});

const getLoginUserDetails = () => ({
  type: GET_LOGIN_USER_DETAILS,
});

const setLoginUserDetails = (userDetails) => ({
  type: SET_LOGIN_USER_DETAILS,
  userDetails,
});

const getNavRoutes = (userId) => ({
  type: GET_NAV_ROUTES,
  userId,
});

const getUserAuth = (userId) => ({
  type: GET_USER_AUTH,
  userId,
});

const setUserAuth = (userDetails) => ({
  type: SET_USER_AUTH,
  userDetails,
});

const setNavRoutes = (navRoutes) => ({
  type: SET_NAV_ROUTES,
  navRoutes,
});

const setNavRouteIsFetching = (navRouteFetchingState) => ({
  type: IS_FETCHING_NAV_ROUTES,
  navRouteFetchingState,
});

const getNavContent = (routeName, isContentEdit) => ({
  type: GET_NAV_CONTENT,
  routeName,
  isContentEdit,
});

const setNavContent = (routeContent) => ({
  type: SET_NAV_CONTENT,
  routeContent,
});

const setLocalBodyFlg = (localBodyLoad) => ({
  type: SET_LOCAL_BODY_CONTENT_FLG,
  localBodyLoad,
});

const setNavContentIsFetching = (contentFetchingState) => ({
  type: IS_FETCHING_NAV_CONTENT,
  contentFetchingState,
});

const getSwaggerJson = (apiId) => ({
  type: GET_SWAGGER_JSON,
  apiId,
});

const setSwaggerJson = (data, apiId) => ({
  type: SET_SWAGGER_JSON,
  data,
  apiId,
});

const isFetchingSwaggerJson = (data) => ({
  type: IS_FETCHING_SWAGGER_JSON,
  data,
});

const isSubmitSubscriptionLoading = (data) => ({
  type: SUBMIT_SUBSCRIPTION_LOADING,
  data,
});

const setCurrentRoute = (data) => {
  return {
    type: SET_CURRENT_ROUTE,
    data: data?.length > 0 ? [...data] : [],
  };
};
const submitManageSubscription = (data) => {
  return {
    type: SUBMIT_MANAGE_SUBSCRIPTION,
    data,
  };
};
const getApiDescJson = (partner, product, service) => {
  return {
    type: GET_API_DESC_JSON,
    partner,
    product,
    service,
  };
};
const setApiDescJson = (data) => {
  return {
    type: SET_API_DESC_JSON,
    data,
  };
};

const setDeleteFile = (key, route, resetDoc) => ({
  type: SET_DELETE_FILE,
  key,
  route,
  resetDoc,
});

const updateWebDoc = (key, isCurrent, schema) => ({
  type: UPDATE_DOC_DATA,
  key,
  isCurrent,
  schema,
});

const resetDocData = () => ({
  type: RESET_DOC_DATA,
});

const setSimulate = (data) => ({
  type: SET_SIMULATE,
  data,
});

const sendContactMessage = (body) => ({
  type: SEND_CONTACT_MESSAGE,
  body,
});

const addEditExtResponse = (body) => ({
  type: ADD_EDIT_EXT_RESPONSE,
  body
});

const setExtResponse = (body) => ({
  type: SET_EXTERNAL_RESPONSE,
  body
});
const getExternalResponse = (params) => ({
  type: GET_EXTERNAL_RESPONSE,
  params
});

export {
  setRouteKeyMap,
  submitSubRequest,
  redirectToExternalLogin,
  getFileContentJson,
  setFileContentJson,
  setFileLoadingState,
  getLoginUserDetails,
  setLoginUserDetails,
  getNavRoutes,
  setNavRoutes,
  setNavRouteIsFetching,
  getNavContent,
  setNavContent,
  setLocalBodyFlg,
  setNavContentIsFetching,
  getGlobalFAQContent,
  setGlobalFAQContent,
  setGlobalFAQContentLoading,
  getSwaggerJson,
  setSwaggerJson,
  isFetchingSwaggerJson,
  isSubmitSubscriptionLoading,
  setProServiceMap,
  getUserAuth,
  setUserAuth,
  setCurrentRoute,
  submitManageSubscription,
  getApiDescJson,
  setApiDescJson,
  setDeleteFile,
  resetDocData,
  updateWebDoc,
  setSimulate,
  sendContactMessage,
  addEditExtResponse,
  setExtResponse,
  getExternalResponse
};
