import { combineReducers } from 'redux';

import {reducers as app} from './app';
import {reducers as admin} from './admin';

const rootReducer = combineReducers({
  app,
  admin
});

export default rootReducer;
