import React from 'react';
import { Checkbox, Dropdowns } from '../../../../../../../../CommonComponents';

const Subscription = (props) => {
  return (
    <>
      <div className='cardList'>
        <p className='spec-heading' style={{ margin: '10px 0' }}>Products</p>
        <Dropdowns
          placeholder='Please select a product'
          optionKeyName={'id'}
          optionTextName={'text'}
          style={{ width: '100%' }}
          options={props.prodOptions}
          value={props?.product}
          allowClear
          onChangeEvent={(value) =>
            props?.onChangeProducts(value, 'registrationStatus')
          }
          className='registrationStatus'
        />
        <div>
          {
            props?.services?.map((item, idx) => {
              return (
                <div style={{ marginTop: '10px' }} key={idx}>
                  <Checkbox
                    onClickEvent={(e) => {
                      props?.onServiceCheck(e, item);
                    }}
                    // disabled={item?.subscriptionStatus === 'Pending'}
                    checked={item?.subscriptionStatus === 'Subscribed'}
                  >
                    {item?.title}
                  </Checkbox>
                </div>
              );
            })
          }
        </div>
      </div >
    </>
  );
};

export default Subscription;

