import React from 'react';
import {
  Button,
  Modal,
  OutlineButton
} from '../../../CommonComponents';
import './ExternalResponseBadge.scss';
import ExternalResponseAPI from './RegisterExternalResponseAPI';
import FailedInfoBox from './FailedInfoBox';
import InfoBox from './InfoBox/InfoBox';
import SimulateModal from './SimulateModal';
import PendingInfoBox from './PendingInfoBox';

const ExternalResponseBadge = (props) => {
  const {
    onFormChange,
    formData,
    onClickSave,
    onClickCancel,
    showModal,
    hideModalPopup,
    onManangeClick,
    externalResponse,
    onEditClick,
    hideModalSimulatePopup,
    showSimulateModal,
    onClickSimulateCancel,
    onSimulateClick,
    onChangeSimulate,
    onClickSimulateSave,
    error,
    simulateData,
    simulateErr,
    process,
    onDiagnosticChange,
    diagnosticsOption,
    disabledDiagnostics,
    prognosticErr
  } = props;

  const heading = 'External Response API';
  return (
    <div className={`ExternalResponseDescriptor ${externalResponse && 'py-0'}`}>
      <div className='clearfix'>
        <p className='heading externalResptitle'>{heading}</p>
        <div className='externalRespAction'>

          {
            externalResponse ?
              <>
                <Button
                  className={'normalButton viewButton'}
                  onClick={onEditClick}
                  disabled={externalResponse?.status === 'Pending Approval'}
                >
                  Edit
                </Button>
                {/* {externalResponse?.isRetry && <Button className="outlineButton viewButton">Re-Try</Button>} */}
                {/* {!externalResponse?.isRetry && } */}
                <Button className="outlineButton viewButton"
                  disabled={externalResponse?.status !== 'Completed' && externalResponse?.status !== 'Approved'}
                  onClick={onSimulateClick}>Simulate</Button>
              </>
              :
              <Button className='normalButton viewButton' onClick={onManangeClick}>
                Register External Response
              </Button>
          }
        </div>
      </div>
      {externalResponse?.status === 'Rejected' && <FailedInfoBox reason={externalResponse?.rejectedReason} />}
      {externalResponse?.status === 'Pending Approval' && <PendingInfoBox />}
      {!externalResponse && <InfoBox />}

      <Modal
        visible={showModal}
        hideModal={hideModalPopup}
        closeModalPopup={hideModalPopup}
        title={externalResponse?.externalId ? 'Update External Response API' : 'Register External Response API'}
        width={620}
        className={'RegisterModal'}
        footer={
          <div className='viewBlock'>
            <OutlineButton
              className='normalButton viewButton'
              onClick={onClickCancel}
            >
              Cancel
            </OutlineButton>
            <Button
              className={'normalButton viewButton'}
              onClick={onClickSave}
            >
              Save
            </Button>
          </div>
        }
        centered
      >
        <ExternalResponseAPI onFormChange={onFormChange} formData={formData} error={error} prognosticErr={prognosticErr} />
      </Modal>

      <Modal
        visible={showSimulateModal}
        hideModal={hideModalSimulatePopup}
        closeModalPopup={hideModalSimulatePopup}
        title={'Simulate External Response API'}
        width={620}
        className={'RegisterModal'}
        footer={
          <div className='viewBlock'>
            <OutlineButton
              className='normalButton viewButton'
              onClick={onClickSimulateCancel}
            >
              Cancel
            </OutlineButton>
            <Button
              className={'normalButton viewButton'}
              onClick={onClickSimulateSave}
            >
              Simulate
            </Button>
          </div>
        }
        centered
      >
        <SimulateModal
          simulateData={simulateData}
          onChangeSimulate={onChangeSimulate}
          simulateErr={simulateErr}
          process={process}
          onDiagnosticChange={onDiagnosticChange}
          diagnosticsOption={diagnosticsOption}
          disabledDiagnostics={disabledDiagnostics}
        />
      </Modal>

    </div>
  );
};
export default ExternalResponseBadge;