import React from 'react';
import IntegrationManager from './IntegrationManager';

const IntegrationManagerContainer = (props) => {
  return (
    <IntegrationManager
      options={props?.options || []}
      onManagerChange={props?.onManagerChange}
      managersValue={props?.managersValue}
      selectedManagers={props?.selectedManagers}
    />
  );
};

export default IntegrationManagerContainer;
