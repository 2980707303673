import { put, call, takeEvery, all, select } from 'redux-saga/effects';

import api from '../../../utils/clientApiHelper';
import {
  GET_NAV_ROUTES,
  GET_NAV_CONTENT,
  GET_CODE_FILE_CONTENT_JSON,
  GET_GLOBAL_FAQ_CONTENT,
  GET_SWAGGER_JSON,
  SUBMIT_SUBSCRIPTION_REQUEST,
  GET_USER_AUTH,
  SUBMIT_MANAGE_SUBSCRIPTION,
  GET_API_DESC_JSON,
  SET_SIMULATE,
  SEND_CONTACT_MESSAGE,
  ADD_EDIT_EXT_RESPONSE,
  GET_EXTERNAL_RESPONSE,
} from './types';

import * as appAction from './actions';
import * as adminAction from '../admin/actions';
import { Notification } from '../../../components/CommonComponents';
// import route from './route.json';

function* getNavRoutesSaga(req) {
  yield put(appAction.setNavRouteIsFetching(true));

  try {
    const id = req?.userId ?? '';
    const response = yield call(
      api.callGet,
      `/app/fetchNavRoutes?userId=${id}`
    );
    const jsonData = response?.data?.data ?? '';
    if (jsonData !== '') {
      yield put(appAction.setNavRoutes(jsonData));
      // if (jsonData?.navBar?.sections?.length > 0) {
      // } else {
      //   yield put(appAction.setNavRoutes({ ...jsonData, navBar: route }));
      // }
    } else {
      yield put(appAction.setNavRoutes({}));
      console.error('Failed to Fetch the Nav Routes');
    }
  } catch (ex) {
    yield put(appAction.setNavRoutes({}));
    console.error('Error in Fetching the Nav Routes ', ex);
  }
  yield put(appAction.setNavRouteIsFetching(false));
}
function* getUserAuthSaga(req) {
  yield put(appAction.setNavRouteIsFetching(true));

  try {
    const id = req?.userId ?? '';

    const response = yield call(
      api.callGet,
      `/app/fetchUserAtuh?emailId=${id}`
    );

    const jsonData = response?.data?.data ?? '';
    if (jsonData !== '') {
      yield put(appAction.setUserAuth(jsonData));
    } else {
      yield put(appAction.setNavRoutes({}));
      console.error('Failed to Fetch the User Authorization');
    }
  } catch (ex) {
    yield put(appAction.setNavRoutes({}));
    console.error('Error in Fetching the User Authorization ', ex);
  }
  yield put(appAction.setNavRouteIsFetching(false));
}

function* getNavContentSaga(req) {
  if (req?.routeName == '/Products, Services & API') {
    yield put(appAction.setNavContentIsFetching(false));
  } else {
    yield put(appAction.setNavContentIsFetching(true));
  }

  const routeName = req?.routeName ?? '';
  try {
    if (routeName !== '' && routeName.includes('/')) {
      const response = yield call(
        api.callGet,
        `/app/fetchNavContent?route=${routeName
          ?.slice(1)
          ?.replaceAll(' ', '')
          ?.replaceAll('&', '%26')}&isContentEdit=${req?.isContentEdit || ''}`
      );
      const data = response?.data?.data ?? '';
      if (data) {
        yield put(appAction.setNavContent({ [routeName]: data }));
      } else {
        yield put(appAction.setNavContent({ [routeName]: {} }));
        console.error('Failed to Fetch the route content');
      }
    } /* else {
      console.error('Invalid route name for fetching body content ', routeName);
    } */
  } catch (ex) {
    yield put(appAction.setNavContent({ [routeName]: {} }));
    console.error('Error in Fetching the Route Content ', ex);
  }
  yield put(appAction.setNavContentIsFetching(false));
}

function* getFileContentJsonSaga(req) {
  yield put(appAction.setFileLoadingState(true));

  const fileURL = req?.fileURL ?? '';

  try {
    if (fileURL !== '') {
      const requestURL = `/app/fetchFileContent?filePath=${fileURL}`;
      const response = yield call(api.callGet, requestURL);

      const data = response?.data ?? '';
      if (data) {
        yield put(appAction.setFileContentJson({ [fileURL]: data }));
      } else {
        yield put(appAction.setFileContentJson({ [fileURL]: {} }));
        console.error('Failed to Fetch the file Content JSON');
      }
    } else {
      console.error('Invalid JSON/ YAML file URL specified ', fileURL);
    }
  } catch (ex) {
    yield put(appAction.setFileContentJson({ [fileURL]: {} }));
    console.error('Error in Fetching file content JSON ', ex);
  }
  yield put(appAction.setFileLoadingState(false));
}

function* getGlobalFAQContentSaga(req) {
  yield put(appAction.setGlobalFAQContentLoading(true));

  const fileURL = req?.fileURL ?? '';

  try {
    if (fileURL !== '') {
      const requestURL = `/app/fetchGlobalFAQ?Path=${fileURL}`;
      const response = yield call(api.callGet, requestURL);

      const data = response?.data ?? '';
      if (data) {
        yield put(appAction.setGlobalFAQContent({ [fileURL]: data }));
      } else {
        yield put(appAction.setGlobalFAQContent({ [fileURL]: {} }));
        console.error('Failed to Fetch the FAQ Content');
      }
    } else {
      console.error('Invalid FAQ Content ', fileURL);
    }
  } catch (ex) {
    yield put(appAction.setGlobalFAQContent({ [fileURL]: {} }));
    console.error('Error in Fetching FAQ Content ', ex);
  }
  yield put(appAction.setGlobalFAQContentLoading(false));
}

function* getSwaggerJsonSaga(req) {
  yield put(appAction.isFetchingSwaggerJson(true));
  const apiId = req?.apiId;
  try {
    const requestURL = `/app/getSwaggerJson?apiId=${apiId}`;
    const response = yield call(api.callGet, requestURL);
    const data = response?.data?.data ?? '';
    if (data !== '') {
      yield put(appAction.setSwaggerJson(data, apiId));
      yield put(appAction.isFetchingSwaggerJson(false));
    } else {
      yield put(appAction.setSwaggerJson({}));
      yield put(appAction.isFetchingSwaggerJson(false));
      console.error('Invalid Swagger JSON');
    }
  } catch (ex) {
    yield put(appAction.setSwaggerJson({}));
    yield put(appAction.isFetchingSwaggerJson(false));
    console.error('Error in Fetching Swagger JSON', ex);
  }
}

function* submitSubRequestSaga(req) {
  // yield put(appAction.isFetchingSwaggerJson(true));
  yield put(appAction.setNavContentIsFetching(true));
  const subRequestData = req?.subRequestData ?? '';
  const { productName, serviceName, emailId } = subRequestData;
  const { partnerId, filter } = req?.params ?? {};
  if (subRequestData !== '') {
    try {
      const body = {
        partnerName: subRequestData?.partnerName || '',
        productId: subRequestData?.product_id,
        userId: subRequestData?.emailId,
        rejectionReason: subRequestData?.rejection_reason || '',
        serviceIds: subRequestData?.services,
        actionType: subRequestData?.actionType,
        customerReferenceNumber: subRequestData?.customerReferenceNumber,
        tspName: subRequestData?.tspName
      };
      const requestURL = '/app/submitSubscriptionRequest';
      const response = yield call(api.callPost, requestURL, body);
      const data = response?.data ?? '';
      if (data !== '' && data?.message === 'Success') {
        yield put(appAction.setNavContentIsFetching(false));
        const userId = yield select((state) => state?.app?.userDetails?.unique_name || '');
        if (
          subRequestData?.actionType !== 'A' &&
          subRequestData?.actionType !== 'R' &&
          !req?.fromProject &&
          !req?.partnerId
        ) {
          yield put(appAction.getNavRoutes(userId));
        } else if ((req?.partnerId && subRequestData?.actionType === 'U') || (req?.partnerId && subRequestData?.actionType === 'A')) {
          yield put(adminAction?.getProjectPartnersSubscriptions(req?.partnerId));
          yield put(adminAction.getProducts(body?.partnerName));
        } else {
          yield put(adminAction.getApiProject(
            partnerId,
            filter,
            '',
            emailId,
            ''
          ));
        }
        // productName, serviceName
        Notification({
          type: 'success',
          message:
            productName && serviceName && subRequestData?.actionType === 'P'
              ? `Request Access to ${productName} : ${serviceName} sent`
              : 'Success',
          duration: 1,
          description:
            productName && serviceName && subRequestData?.actionType === 'P'
              ? 'Please check your email for confirmation message and next steps.'
              : 'Request sent Successfully!',
          placement: 'bottom',
        });
        // window.location.reload();
      } else {
        yield put(appAction.setNavContentIsFetching(false));
        Notification({
          type: 'error',
          message: 'Error',
          description: 'Something went wrong!. Please try again.',
          placement: 'bottom',
        });
      }
    } catch (ex) {
      yield put(appAction.setNavContentIsFetching(false));
      Notification({
        type: 'error',
        message: 'Error',
        description: ex?.message || 'Something went wrong!. Please try again.',
        placement: 'bottom',
      });
      console.error('Error in submiting the submission request', ex);
    }
  }
}

function* submitManageSubscriptionSaga(req) {
  yield put(appAction.setNavContentIsFetching(true));
  const subRequestData = req?.data ?? '';
  const { partnerId, filter } = req?.params ?? {};
  if (subRequestData !== '') {
    try {
      const requestURL = '/app/submitManageSubscription';
      const response = yield call(api.callPost, requestURL, subRequestData);
      const data = response?.data ?? '';

      if (data !== '' && data?.message === 'Success') {
        yield put(appAction.setNavContentIsFetching(false));
        const userId = yield select(
          (state) => state?.app?.userDetails?.unique_name || ''
        );
        if (
          subRequestData?.actionType !== 'A' &&
          subRequestData?.actionType !== 'R'
        ) {
          yield put(appAction.getNavRoutes(userId));
        } else {
          yield put(adminAction.getApiProject(partnerId, filter));
        }

        Notification({
          type: 'success',
          message: 'Request sent Successfully!',
          description: 'Please check your email for confirmation message.',
          placement: 'bottom',
          className: 'subscribeNoti',
          closeIcon: null,
        });
        // window.location.reload();
      } else {
        yield put(appAction.setNavContentIsFetching(false));
        Notification({
          type: 'error',
          message: 'Error',
          description: 'Something went wrong!. Please try again.',
          placement: 'bottom',
        });
      }
    } catch (ex) {
      yield put(appAction.setNavContentIsFetching(false));
      Notification({
        type: 'error',
        message: 'Error',
        description: ex?.message || 'Something went wrong!. Please try again.',
        placement: 'bottom',
      });
      console.error('Error in submiting the manage subscription', ex);
    }
  }
}

function* apiDescJsonSaga(req) {
  yield put(appAction.isFetchingSwaggerJson(true));

  try {
    const response = yield call(
      api.callGet,
      `/app/getApiDescJson?partnerName=${req?.partner}&productName=${req?.product}&serviceName=${req?.service}`
    );

    const jsonData = response?.data?.data ?? '';

    if (jsonData !== '') {
      yield put(appAction.isFetchingSwaggerJson(false));
      yield put(appAction.setApiDescJson(jsonData));
    } else {
      yield put(appAction.isFetchingSwaggerJson(false));
      yield put(appAction.setApiDescJson({}));
      console.error('Failed to Fetch the JSON');
    }
  } catch (ex) {
    yield put(appAction.setNavRoutes({}));
    console.error('Error in Fetching the User Authorization ', ex);
  }
  yield put(appAction.setNavRouteIsFetching(false));
}

function* setSimulateSaga(req) {
  yield put(appAction.setNavContentIsFetching(true));

  try {
    const response = yield call(api.callPost, '/app/simulateJson', req?.data);

    const jsonData = response?.data ?? '';

    if (jsonData?.message === 'Success') {
      yield put(appAction.setNavContentIsFetching(false));
      Notification({
        type: 'success',
        message: 'Success',
        description: 'External response initiated!',
        placement: 'bottom',
      });
    } else {
      yield put(appAction.setNavContentIsFetching(false));
      Notification({
        type: 'error',
        message: 'Error',
        description: jsonData?.message || 'Something went wrong!. Please try again.',
        placement: 'bottom',
      });
      console.error('Failed to Fetch the JSON');
    }
  } catch (ex) {
    yield put(appAction.setNavContentIsFetching(false));
    Notification({
      type: 'error',
      message: 'Error',
      description: 'Something went wrong!. Please try again.',
      placement: 'bottom',
    });
    console.error('Error in Fetching the User Authorization ', ex);
  }
}

function* addEditExtSaga(req) {
  yield put(appAction.setNavContentIsFetching(true));
  try {
    const response = yield call(api.callPost, '/app/addEditExt', req?.body);
    const jsonData = response?.data ?? '';
    if (jsonData?.message === 'Success') {
      yield put(appAction.setNavContentIsFetching(false));
      yield put(appAction.getExternalResponse({
        partnerName: req?.body?.partnerName,
        productName: req?.body?.productName,
        serviceName: req?.body?.serviceName,
      }));
      Notification({
        type: 'success',
        message: 'Success',
        description: 'External response updated successfully!',
        placement: 'bottom',
      });
    } else {
      yield put(appAction.setNavContentIsFetching(false));
      Notification({
        type: 'error',
        message: 'Error',
        description: 'Something went wrong!. Please try again.',
        placement: 'bottom',
      });
      console.error('Failed to Fetch the JSON');
    }
  } catch (ex) {
    yield put(appAction.setNavContentIsFetching(false));
    Notification({
      type: 'error',
      message: 'Error',
      description: 'Something went wrong!. Please try again.',
      placement: 'bottom',
    });
    console.error('Error in Fetching the User Authorization ', ex);
  }
}


function* getExtResponseSaga(req) {
  yield put(appAction.setNavContentIsFetching(true));

  try {

    const response = yield call(api.callGet, `/app/getExtResponse?partnerName=${req?.params?.partnerName}&productName=${req?.params?.productName}&serviceName=${req?.params?.serviceName}`);

    const jsonData = response?.data ?? '';

    if (jsonData?.message === 'Success') {
      yield put(appAction.setNavContentIsFetching(false));
      yield put(appAction.setExtResponse(jsonData?.data));
    } else {
      yield put(appAction.setNavContentIsFetching(false));
      if(jsonData?.message !== 'The Given Partner, Product and Service matched records not available'){
        Notification({
          type: 'error',
          message: 'Error',
          description: 'Something went wrong!. Please try again.',
          placement: 'bottom',
        });
      }    
      console.error('Failed to Fetch the JSON');
    }
  } catch (ex) {
    yield put(appAction.setNavContentIsFetching(false));
    Notification({
      type: 'error',
      message: 'Error',
      description: 'Something went wrong!. Please try again.',
      placement: 'bottom',
    });
    console.error('Error in Fetching the User Authorization ', ex);
  }
}

function* sendContactMessageSaga(req) {
  yield put(appAction.setNavContentIsFetching(true));
  try {
    const response = yield call(api.callPost, '/app/sendMessage', req?.body);

    const jsonData = response?.data ?? '';

    if (jsonData?.message === 'Success') {
      yield put(appAction.setNavContentIsFetching(false));
      Notification({
        type: 'success',
        message: 'Success',
        description: 'Message sent successfully!',
        placement: 'bottom',
      });
    } else {
      yield put(appAction.setNavContentIsFetching(false));
      Notification({
        type: 'error',
        message: 'Error',
        description: 'Something went wrong!. Please try again.',
        placement: 'bottom',
      });
      console.error('Failed to send message');
    }
  } catch (ex) {
    yield put(appAction.setNavContentIsFetching(false));
    Notification({
      type: 'error',
      message: 'Error',
      description: 'Something went wrong!. Please try again.',
      placement: 'bottom',
    });
    console.error('Error in send contact message ', ex);
  }
}

export default function* combinedSaga() {
  yield all([
    takeEvery(GET_NAV_ROUTES, getNavRoutesSaga),
    takeEvery(GET_NAV_CONTENT, getNavContentSaga),
    takeEvery(GET_CODE_FILE_CONTENT_JSON, getFileContentJsonSaga),
    takeEvery(GET_GLOBAL_FAQ_CONTENT, getGlobalFAQContentSaga),
    takeEvery(GET_SWAGGER_JSON, getSwaggerJsonSaga),
    takeEvery(SUBMIT_SUBSCRIPTION_REQUEST, submitSubRequestSaga),
    takeEvery(GET_USER_AUTH, getUserAuthSaga),
    takeEvery(SUBMIT_MANAGE_SUBSCRIPTION, submitManageSubscriptionSaga),
    takeEvery(GET_API_DESC_JSON, apiDescJsonSaga),
    takeEvery(SET_SIMULATE, setSimulateSaga),
    takeEvery(SEND_CONTACT_MESSAGE, sendContactMessageSaga),
    takeEvery(ADD_EDIT_EXT_RESPONSE, addEditExtSaga),
    takeEvery(GET_EXTERNAL_RESPONSE, getExtResponseSaga),
  ]);
}
