import React from 'react';
import {Popover} from 'antd';

const PopoverComponent = ({
  content='',
  title='',
  children
}) => {
  return <Popover content={content} title={title} trigger="hover">
    {children}
  </Popover>;
};

export default PopoverComponent;
