import * as R from 'ramda';

const userDetails = (state) => R.pathOr('', ['app', 'userDetails'], state);
const userAuth = (state) => R.pathOr({}, ['app', 'userAuth'], state);
const navRoutes = (state) => R.pathOr('', ['app', 'navRoutes'], state);
const navRouteFetchingState = (state) =>
  R.pathOr(false, ['app', 'navRouteFetchingState'], state);
const routeContent = (state) => R.pathOr('', ['app', 'routeContent'], state);
const contentFetchingState = (state) =>
  R.pathOr(false, ['app', 'contentFetchingState'], state);
const fileJsonContent = (state) =>
  R.pathOr('', ['app', 'fileJsonContent'], state);
const globalFAQContent = (state) =>
  R.pathOr('', ['app', 'globalFAQContent'], state);
const globalFAQContentLoadingState = (state) =>
  R.pathOr('', ['app', 'globalFAQContentLoadingState'], state);
const localBodyLoad = (state) =>
  R.pathOr(false, ['app', 'localBodyLoad'], state);
const productData = (state) => R.pathOr(false, ['admin'], state);
const apiProjects = (state) => R.pathOr({}, ['admin', 'apiProjects'], state);
const loading = (state) => R.pathOr(false, ['admin', 'loading'], state);
const partners = (state) => R.pathOr([], ['admin', 'partners'], state);
const partnerData = (state) => R.pathOr([], ['admin', 'partnerData'], state);
const users = (state) => R.pathOr([], ['admin', 'users'], state);
const activeSubscription = (state) =>
  R.pathOr([], ['admin', 'activeSubscription'], state);
const inActiveSubscription = (state) =>
  R.pathOr([], ['admin', 'inActiveSubscription'], state);
const projectView = (state) => R.pathOr({}, ['admin', 'projectView'], state);
const alertPopup = (state) => R.pathOr(false, ['admin', 'alertPopup'], state);
const projectDataChanged = (state) => R.pathOr(false, ['admin', 'projectDataChanged'], state);
const approvals = (state) => R.pathOr([], ['admin', 'approvals'], state);
const history = (state) => R.pathOr([], ['admin', 'history'], state);
const regExtResp = (state) => R.pathOr([], ['admin', 'regExtResp'], state);
const certifiedProducts = (state) => R.pathOr([], ['admin', 'certifiedProducts'], state);
const products = (state) => R.pathOr([], ['admin', 'products'], state);
const dropdownLoader = (state) => R.pathOr([], ['admin', 'dropdownLoader'], state);

const selectors = {
  userDetails,
  navRoutes,
  navRouteFetchingState,
  routeContent,
  contentFetchingState,
  fileJsonContent,
  globalFAQContent,
  globalFAQContentLoadingState,
  localBodyLoad,
  productData,
  apiProjects,
  loading,
  partners,
  users,
  activeSubscription,
  inActiveSubscription,
  partnerData,
  projectView,
  userAuth,
  alertPopup,
  projectDataChanged,
  approvals,
  history,
  regExtResp,
  certifiedProducts,
  products,
  dropdownLoader
};

export default selectors;
