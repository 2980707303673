import React from 'react';
import {
  Modal,
  OutlineButton,
  Textarea,
} from '../../../../../../../CommonComponents';

const RejectModal = (props) => {
  const { currentRow } = props;

  return (
    <Modal
      visible={props.rejectSubscription}
      hideModal={props?.onClickClose}
      closeModalPopup={props?.onClickClose}
      title={'Reject Subscription'}
      className='rejectSubscription'
      width={550}
      footer={
        <div className='viewBlock'>
          <OutlineButton
            className='normalButton viewButton'
            onClick={props?.onClickClose}
          >
            Cancel
          </OutlineButton>
          <OutlineButton
            className='normalButton viewButton rejectButton'
            onClick={props?.onClickReject}
          >
            Reject
          </OutlineButton>
        </div>
      }
      centered
    >
      <p className='para'>
        Confirm that you wish to reject {currentRow?.Partner}'s request to subscribe to the
        following service:
      </p>
      <p className='para headingText'>{currentRow?.Product}: {currentRow?.Service}</p>
      <p className='para'>
        {currentRow?.Partner}'s partner administrator will receive an email notification of the
        rejected request.
      </p>
      <div className='line'></div>
      <div>
        <p className='labelText'>
          Please leave a reason for rejecting this subscription{' '}
          <span className='required'>*</span>
        </p>
        <Textarea
          value={props?.rejectDesc}
          rows={6}
          onChangeEvent={(e) => props?.onTextAreaChange(e?.target?.value)}
          statusMsg={props?.descErr && 'Please enter rejection reason.'}
        />
      </div>
    </Modal>
  );
};

export default RejectModal;
