import React from 'react';
import {Table,Modal} from '../../../../../../../CommonComponents';
import YMLEditor from '../../../../../../../CommonComponents/JSONCodeReader';
const RegisteredTable = (props) => {

  const {loading, activePagination, onChangePagination} = props;

  return(
    <>
      {/* <div className='float-left'>
        <Searchbar 
          placeholder={'Search by partner name'}
          className='searchbar'
        />
      </div> */}
      <Table
        dataSource={props.dataSource}
        columns={props.columns}
        scroll={{ x: 'max-content' }}
        showSorterTooltip={false}
        pagination={activePagination}
        onChangeEvent={onChangePagination}
        searchbar={true}
        loading={loading}
      />
      <Modal
        visible={props.responsePayload}
        hideModal={props.hideResponsepayloadModal}
        closeModalPopup={props.hideResponsepayloadModal}
        title={'Payload'}
        width={620}
        className={'Payload'}
        footer={false}
        centered
      >
        <div>
          <div className="formControl">
            <YMLEditor file={props.responsePayloadValue} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RegisteredTable;