import React from 'react';
import {
  Button,
  MessageComponent,
  Modal,
  OutlineButton,
  Text,
  Upload,
} from '../../../../../../../CommonComponents';
import { UploadOutlined } from '@ant-design/icons';

const AddManualChildren = (props) => {
  const uploadProps = {
    name: 'file',
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
      if (info.file.status === 'done') {
        MessageComponent(
          `${info.file.name} file uploaded successfully`,
          'success'
        );
      } else if (info.file.status === 'error') {
        MessageComponent(`${info.file.name} file upload failed.`, 'success');
      }
    },
  };
  return (
    <Modal
      visible={props.visible}
      hideModal={props.hideModal}
      closeModalPopup={props.closeModalPopup}
      title={props?.title}
      width={props?.width}
      footer={
        <div className='viewBlock'>
          <OutlineButton
            className='normalButton viewButton'
            onClick={props.onHideManually}
          >
            Cancel
          </OutlineButton>
          <Button
            className='normalButton viewButton'
            onClick={props.onSaveAddManually}
          >
            Save
          </Button>
        </div>
      }
      centered
    >
      <div className='editModal'>
        <div>
          <label className='label'>API Endpoint Name</label>
          <Text className='productNameText' />
        </div>
        <div className='controlItem'>
          <label className='label'>JSON/YAML File</label>
          <div>
            <Upload icon={<UploadOutlined />} uploadProps={uploadProps} text={'Upload File'} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddManualChildren;
