import React from 'react';
import PartnersView from './PartnersView';
import PartnersTable from './PartnersTable';
import './Partners.scss';

const Partners = (props) => {
  return !props.viewBlock ? (
    <div className='rolesAndPermissions'>
      <PartnersTable
        searchbar={true}
        heading={'Partners'}
        subHeading={'Integration Management'}
        columns={props.columns}
        loading={props?.loading}
        dataSource={props.dataSource}
        searchValue={props?.searchValue}
        onSearchChange={props?.onSearchChange}
        onSearch={props?.onSearch}
        onEnter={props?.onEnter}
        onBlur={props?.onBlur}
        onAddPartnerClick={props.onAddPartnerClick}
        hideAddPartnerPopup={props.hideAddPartnerPopup}
        showAddPartnerModal={props.showAddPartnerModal}
        radioCompanyyReg={props.radioCompanyyReg}
        radioSubscription={props.radioSubscription}
        radioDataIntegration={props.radioDataIntegration}
        formData={props.formData}
        onChangeCompanyRegEvent={props.onChangeCompanyRegEvent}
        onChangeSubscriptionEvent={props.onChangeSubscriptionEvent}
        onChangedataintegrationEvent={props.onChangedataintegrationEvent}
        optionsValue={props.optionsValue}
        productsOptions={props.productsOptions}
        onFormChange={props.onFormChange}
        radioOption={props.radioOption}
        companyReg={props.companyReg}
        subscriptionOption={props.subscriptionOption}
        dataIntegrationOption={props.dataIntegrationOption}
        onChangeEvent={props.onChangeEvent}
        onClickSave={props?.onClickSave}
        error={props?.error}
        pagination={props?.pagination}
        onPaginationChange={props?.onPaginationChange}
        editMode={props?.editMode}
      />
    </div>
  ) : (
    <PartnersView
      breadcrumbOnClick={props.breadcrumbOnClick}
      loading={props?.loading}
      onSubmitBtn={props?.onSubmitBtn}
      partnerId={props?.partnerId}
      partnerName={props?.partnerName}
    />
  );
};

export default Partners;
