import React from 'react';
import './OutlineButton.scss';

const OutlineButton = (props) => {
  return (
    <button
      type='button'
      size={props.size}
      title={props?.title}
      disabled={props?.disabled || false}
      onClick={props.onClick}
      className={`${props?.disabled ? 'outlineButton-disabled' : 'outlineButton'} ${props.className}`}
    >
      {props.children}
    </button>
  );
};

export default OutlineButton;
