import React from 'react';
import { Table } from '../../../../../../../CommonComponents';

const TableSection = (props) => {
  return (
    <div>
      <div className='clearfix'>
        <div className='float-left'>
          <p className='boldHeading'>{props?.heading}</p>
        </div>
      </div>

      <div>
        <Table
          dataSource={props.dataSource}
          columns={props.columns}
          scroll={{ x: 'max-content' }}
          loading={props?.loading}
          onChangeEvent={props?.onChangeEvent}
          pagination={props?.pagination}
        />
      </div>
    </div>
  );
};

export default TableSection;
