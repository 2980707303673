import React from 'react';
import FAQ from './FAQ';

const FAQContainer = () => {
  const content = {
    body: {
      bodyContentURL: '-'
      // bodyContentURL: 'https://2cm38c4j5b.execute-api.us-east-1.amazonaws.com/prod/globalfaqs'
    },
    heading: 'Frequently Asked Questions',
    widgetName: 'Accordion'
  };
  return <FAQ 
    content={content}/>;
};

export default FAQContainer;
