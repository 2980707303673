import React, { useMemo } from 'react';
import { Breadcrumb } from 'antd';
import './Breadcrumbs.scss';

const BreadcrumbComponent = ({
  valueList = [],
  onClickEvent = () => { },
  enableClick = false
}) => {

  const items = useMemo(() => {
    return valueList.map((item, idx) => (enableClick === false && idx + 1 === valueList?.length ? { title: item } : { title: <a role={'button'} onClick={(ev) => onClickEvent(ev, idx)}>{item}</a> }));
  }, [valueList]);

  return (
    <Breadcrumb
      items={items}
    />
  );
};

export default BreadcrumbComponent;
