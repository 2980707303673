import React, { useState } from 'react';
import PartnersTable from './PartnersTable';

const PartnersTableContainer = (props) => {
  const [toggle, setToggle] = useState(false);

  const toggleView = () => {
    setToggle(!toggle);
  };


  return (
    <PartnersTable
      dataSource={props.dataSource}
      columns={props.columns}
      searchbar={props.searchbar}
      heading={props.heading}
      subHeading={props.subHeading}
      activePagination={props?.pagination}
      onPaginationChange={props?.onPaginationChange}
      loading={props?.loading}
      toggleView={toggleView}
      toggle={toggle}
      searchValue={props?.searchValue}
      onSearchChange={props?.onSearchChange}
      onSearch={props?.onSearch}
      onEnter={props?.onEnter}
      onBlur={props?.onBlur}
      onAddPartnerClick={props.onAddPartnerClick}
      hideAddPartnerPopup={props.hideAddPartnerPopup}
      showAddPartnerModal={props.showAddPartnerModal}
      radioCompanyyReg={props.radioCompanyyReg}
      radioSubscription={props.radioSubscription}
      radioDataIntegration={props.radioDataIntegration}
      formData={props.formData}
      onChangeCompanyRegEvent={props.onChangeCompanyRegEvent}
      onChangeSubscriptionEvent={props.onChangeSubscriptionEvent}
      onChangedataintegrationEvent={props.onChangedataintegrationEvent}
      productsOptions={props.productsOptions}
      onFormChange={props.onFormChange}
      companyReg={props.companyReg}
      subscriptionOption={props.subscriptionOption}
      dataIntegrationOption={props.dataIntegrationOption}
      onClickSave={props.onClickSave}
      error={props?.error}
      editMode={props?.editMode}
    />
  );
};

export default PartnersTableContainer;
