import React from 'react';
import unsubscribedIcon from '../../../../assets/images/unsubscribedIcon.png';
import { Button } from '../../../CommonComponents';
import './Unsubscribed.scss';

const Unsubscribed = (props) => {
  const content = props.content ?? {};
  const body = content?.body ?? {};
  const list = body?.list ?? [];

  return <div className='unsubscribedContainer'>
    <Button className='rounded unsubscribed'>
      <img src={unsubscribedIcon} alt='graph'/>
      Unsubscribed
    </Button>
    <div className='cardBlock'>
      {
        list.map((item,index) => {
          return <div key={index} className='clearfix'>
            <div className='float-left subscriptionList'>
              {item}
            </div>
            <div className='float-right'>
              <Button className='normalButton list-subscribeBtn'>Subscribe</Button>
            </div>
          </div>;
        })
      }
    </div>
  </div>;
};

export default Unsubscribed;