import React from 'react';
import {
  RowComponent,
  ColComponent,
  Button /* ,Modal,OutlineButton,Text */,
  Modal,
  Text,
  OutlineButton,
} from '../../../CommonComponents';
import Edit from '../../../../assets/images/edit.png';
import './FooterContent.scss';

const FooterContent = (props) => {
  const editUI = (value, field, key, isLink) => {
    return props.editable ? (
      <div className='editableTools'>
        <div className='titleBadge'>{props.badge}</div>
        <Button
          className='normalButton viewButton'
          onClick={() => props.onClickField(value, field, key, isLink)}
        >
          <img src={Edit} alt='editImg' className='editImage' />
          Edit
        </Button>
      </div>
    ) : null;
  };

  const contentConatiner = (body) => {
    return (
      <>
        <p className='boldText headingLabel'>
          {editUI(body.title, 'content1', 'title', false)}
          <span>{body.title}</span>
        </p>
        <p className='contentbox'>
          {editUI(body.text, 'content1', 'text', false)}
          {body.text}
        </p>
        <div className='subcribeLink'>
          {''}
          {editUI(
            [body?.linkText || '', body?.link || ''],
            'content1',
            ['linkText', 'link'],
            true
          )}
          <a
            href={body?.link}
            className={`${props.title == '' ? 'nodecoration' : ''}`}
            onClick={props.contactClick}
          >
            {body.linkText}
          </a>
        </div>
      </>
    );
  };

  return (
    <div className='footerContent'>
      <p className='label'>
        <span>{props.title}</span>
      </p>
      <div className='stLine'></div>

      <RowComponent className='supports' gutter={[32, 32]}>
        <ColComponent xs={24} md={12} lg={12}>
          {contentConatiner(props.content1)}
        </ColComponent>
        {/* <ColComponent xs={24} md={12} lg={11} offset={1}>
          {contentConatiner(props.content2)}
        </ColComponent> */}
      </RowComponent>
      <Modal
        visible={props.visibleMessage}
        hideModal={props.hideMessagepopup}
        closeModalPopup={props.hideMessagepopup}
        title={''}
        width={500}
        footer={
          <div className='viewBlock'>
            <OutlineButton
              className='normalButton viewButton'
              onClick={props.hideMessagepopup}
            >
              Cancel
            </OutlineButton>
            <Button className='normalButton viewButton' onClick={props?.onClickSubmit}>
              Send
            </Button>
          </div>
        }
        centered
      >
        <div>
          {
            !props?.userDetails ?
              <div>
                <div className=''>
                  <label className='labelText'>
                    Name
                  </label>
                  <Text
                    className='messageText'
                    row={0}
                    value={props?.formData?.name}
                    onChange={(e) => props?.onFOrmDataChange(e, 'name')}
                  />
                </div>
                <div className=''>
                  <label className='labelText'>
                    Email <span className='required'>*</span>
                  </label>
                  <Text
                    className='messageText'
                    row={0}
                    value={props?.formData?.email}
                    onChange={(e) => props?.onFOrmDataChange(e, 'email')}
                    type={'email'}
                  />
                  {props?.error?.email && <span style={{ color: '#950101', fontSize: '12px' }}>{props?.error?.email}</span>}
                </div>
                <div className=''>
                  <label className='labelText'>
                    Organization Name
                  </label>
                  <Text
                    className='messageText'
                    row={0}
                    value={props?.formData?.orgName}
                    onChange={(e) => props?.onFOrmDataChange(e, 'orgName')}
                  />
                </div>
                <div className=''>
                  <label className='labelText'>
                    Message <span className='required'>*</span>
                  </label>
                  <Text
                    className='messageText'
                    row={4}
                    value={props?.formData?.message}
                    onChange={(e) => props?.onFOrmDataChange(e, 'message')}
                  />
                  {props?.error?.message !== '' && <span style={{ color: '#950101', fontSize: '12px' }}>{props?.error?.message}</span>}
                </div>
              </div> :
              <div className=''>
                <label className='labelText'>
                  Message <span className='required'>*</span>
                </label>
                <Text
                  className='messageText'
                  row={4}
                  value={props?.message}
                  onChange={props?.onChangeMessage}
                />
                {props?.error?.message && <span style={{ color: '#950101', fontSize: '12px' }}>{props?.error?.message}</span>}
              </div>

          }
        </div>


      </Modal>
      {/* <Modal
        visible={props.showEditContent}
        hideModal={props.onHideEditContent}
        closeModalPopup={props.onHideEditContent}
        title={''}
        width={400}
        footer={
          <div className='editBannerFooter'>
            <OutlineButton
              className='normalButton viewButton'
              onClick={props?.onHideEditContent}
            >
              Cancel
            </OutlineButton>
            <Button
              className={'normalButton viewButton'}
              onClick={props?.onSaveWebContent}
            >
              Save
            </Button>
          </div>
        }
        centered  
        className='editModalPopup'
        closable={false}
      >
        <div className='editableModal'>
          <div className='formItem'>
            <div className='clearfix'>
              <label className='float-left labelText'>{props.badge === 'Text'? 'Section Title': 'Section Description'}</label>
            </div>
            <Text value={props.editText} row={props.editRowLength} onChange={props.onEditData}/>
          </div>
          {
            props.isLink ?
              <div className='formItem'>
                <div className='clearfix'>
                  <label className='float-left labelText'>Link</label>
                </div>
                <Text value={props.editLink}  onChange={(e) => props.setEditLink(e.target.value)} />
              </div> : null
          }
 
        </div>
      </Modal> */}
    </div>
  );
};

export default FooterContent;
