import React from 'react';
import {
  RowComponent,
  ColComponent,
  Button,
} from '../../../../../../../CommonComponents';
import Title from './Title';
import MileStones from './Milestones';
import IntegrationManager from './IntegrationManager';
import PartnerContact from './PartnerContact';
import OtherData from './OtherData';
import {ProjectDocument} from '../../../../../../CommonWidget';

const View = (props) => {
  const { viewData, userDetails } = props;
  const isPartner = userDetails?.partnerName !== '';

  return (
    <div className='integrationStatus'>
      <div className='clearfix'>
        <div className='float-left'>
          <Title
            breadcrumbList={props?.breadcrumbList}
            breadcrumbOnClick={props?.breadcrumbOnClick}
            viewData={props?.viewData}
          />
        </div>
        <div className='float-right'>
          {props.userDetails?.groups?.some(group => group === 'g_Az_Dev_CdoCdpAdmin_Users') ? (
            <Button
              className='normalButton saveChanges'
              onClick={props?.onClickSave}
            >
              Save Changes
            </Button>
          ) : null}
        </div>
      </div>
      <RowComponent>
        <ColComponent xs={24} md={24} lg={16}>
          <MileStones
            dataItem={props.dataItem}
            openPanels={props?.openPanels}
            onAccordianClick={props?.onAccordianClick}
            setDataItem={props?.setDataItem}
            viewData={props?.viewData}
            onTickClick={props?.onTickClick}
            onFieldChange={props?.onFieldChange}
            isPartner={isPartner}
          />
        </ColComponent>
        <ColComponent xs={24} md={24} lg={8}>
          <div className='card spec'>
            <IntegrationManager
              options={props?.managerList}
              onManagerChange={props?.onManagerChange}
              managersValue={props?.managers}
              selectedManagers={props?.selectedManagers}
            />
            <PartnerContact
              contactOption={props?.contactOption}
              selectedContact={props?.selectedContact}
              contactList={props?.contactList}
              onContactSelectChange={props?.onContactSelectChange}
            />
            <OtherData
              otherData={props?.otherData}
              onChangeOtherData={isPartner ? () => {} : props?.onChangeOtherData}
              isPartner={isPartner}
              
            />
          </div>
          <ProjectDocument
            userDetails={props.userDetails}
            projectDocuments={viewData?.projectDocument || []}
            onFileUpload={props?.onFileUpload}
            onDeleteFile={props?.onDeleteFile}
            onDownloadFile={props?.onDownloadFile}
            editable={true}
            title="Project Documents"
            isAdmin={props?.isAdmin || false}
          />
        </ColComponent>
      </RowComponent>
     
    </div>
  );
};

export default View;
