import React, { useMemo } from 'react';
import {
  OutlineButton,
  Searchbar,
  Select,
} from '../../../../../../../CommonComponents';



const TitleSection = (props) => {

  const options = useMemo(() => {
    let tempArr = [];
    if (props?.partners?.length > 0) {
      props?.partners.map((partner) => {
        tempArr.push({
          value: partner?.partner_id,
          label: partner?.partner_name,
        });
      });
    }
    return [{ label: 'All Partners', value: '' }, ...tempArr];
  }, [props?.partners]);

  return (
    <>
      <div className='clearfix headerblock'>
        <div className='float-left'>
          <span className='heading'>Projects</span>
          <p className='subHeading'>Integration Management</p>
        </div>
        {!props.userDetails?.partnerName && (
          <div className='float-right'>
            <span className='viewingLabel'>Viewing</span>
            <Select
              options={options}
              value={props.selectedView}
              onChange={props.onChangeView}
              placeholder={'All Statuses'}
              className='allStatus'
            />
          </div>
        )}
      </div>
      <div className='clearfix'>
        <Searchbar
          onChange={props.onSearchChange}
          placeholder={'Search by products & services'}
          className='searchbar float-left'
          value={props?.searchValue}
          onEnter={props?.onEnter}
          onBlur={props?.onBlur}
          onSearch={props?.onSearch}
        />

        <OutlineButton
          className='normalButton viewButton float-right'
          onClick={props?.onClickClear}
        >
          Clear
        </OutlineButton>
      </div>
    </>
  );
};

export default TitleSection;
