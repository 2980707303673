import React from 'react';
import {
  Button,
  Modal,
  OutlineButton,
  // ColComponent,
  RowComponent,
  Searchbar,
  Table,
  // Select,
} from '../../../../CommonComponents';

import './RolesAndPermission.scss';

const RolesAndPermission = (props) => {
  return (
    <div className='rolesAndPermissions'>
      <div className='clearfix headerblock'>
        <div className='float-left'>
          <span className='heading'>Roles and Permissions</span>
          <p className='subHeading'>User Management</p>
        </div>
      </div>
      <div className='clearfix'>
        <div className='float-left'>
          <p className='description-text'>
            Integration Managers will be responsible for managing Partner
            subscriptions and<br></br> overseeing integration projects for the
            service(s) assigned to them.
          </p>
          <Searchbar
            value={props?.searchValue}
            onChange={props?.onSearchChange}
            onSearch={props?.onSearch}
            onEnter={props?.onEnter}
            onBlur={props?.onBlur}
            placeholder={'Search by partner name'}
            className='searchbar'
          />
        </div>
      </div>
      <RowComponent>
      </RowComponent>
      <div>
        <Table
          dataSource={props.dataSource}
          columns={props.columns}
          scroll={{ x: 'max-content' }}
          showSorterTooltip={false}
          pagination={props.pagination}
          onChangeEvent={props?.onChangeTable}
          loading={props?.loading}
        />
      </div>
      <Modal
        visible={props?.visible}
        hideModal={props?.onCloseModal}
        closeModalPopup={props?.onCloseModal}
        title={''}
        className='Deactivate'
        width={550}
        footer={
          <div className='viewBlock'>
            <OutlineButton
              className='normalButton viewButton'
              onClick={props?.onCloseModal}
            >
              Cancel
            </OutlineButton>
            <Button
              className={'normalButton viewButton'}
              onClick={props?.onCloseModalClick}
            >
              Exit
            </Button>
          </div>
        }
        centered
      >
        Unsaved changes identified, Are your sure want to close?
      </Modal>
    </div>
  );
};

export default RolesAndPermission;
