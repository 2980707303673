import React from 'react';

import 'antd/dist/reset.css';
import './App.scss';
import Home from './components/Home';

const App = () => {
  return (
    <Home />
  );
};

export default App;
