import React from 'react';
import {Searchbar, Table, Button} from '../../../../../../../CommonComponents';
import expand_more from '../../../../../../../../assets/images/expand_more.png';
import AddPartner from '../AddPartner';

const PartnersTable = (props) => {
  return <div className='partnersTable'>
    <div className={`clearfix ${props.subHeading? 'headerblock': ''}`}>
      <div className='float-left'>
        <p className={props.subHeading ? 'heading': 'subHeading'}>{props.heading}</p>
        {props.subHeading ? <p className='subHeading'>{props.subHeading}</p>: null}
      </div>
      {!props.subHeading ?
        <div className='float-right'>
          <img src={expand_more} className={`expandIcon ${props.toggle? 'collapseItem':''}`} alt='expand' onClick={props.toggleView}/>
        </div>: null}
    </div>
    {
      props.searchbar ?
        <>
          <div className='float-left'>
            <Searchbar 
              value={props?.searchValue}
              onChange={props?.onSearchChange}
              onSearch={props?.onSearch}
              onEnter={props?.onEnter}
              onBlur={props?.onBlur}
              placeholder={'Search by partner name'}
              className='searchbar'
            />
          </div>
          <div className="float-right">
            <Button className='normalButton viewButton' onClick={props.onAddPartnerClick}>
              Add Partner
            </Button>
          </div>
          <AddPartner 
            hideAddPartnerPopup={props.hideAddPartnerPopup}
            showAddPartnerModal={props.showAddPartnerModal}
            radioCompanyyReg={props.radioCompanyyReg}
            radioSubscription={props.radioSubscription}
            radioDataIntegration={props.radioDataIntegration}
            formData={props.formData}
            onChangeCompanyRegEvent={props.onChangeCompanyRegEvent}
            onChangeSubscriptionEvent={props.onChangeSubscriptionEvent}
            onChangedataintegrationEvent={props.onChangedataintegrationEvent}
            productsOptions={props.productsOptions}
            onFormChange={props.onFormChange}
            companyReg={props.companyReg}
            subscriptionOption={props.subscriptionOption}
            dataIntegrationOption={props.dataIntegrationOption}
            onClickSave={props?.onClickSave}
            error={props?.error}
            editMode={props?.editMode}
          />
        </>
        : null
    } 
    {
      !props.toggle ? 
        <div>
          <Table
            dataSource={props.dataSource}
            columns={props.columns}
            scroll={{ x: 'max-content' }}
            showSorterTooltip={false}
            loading={props?.loading}
            pagination={props.activePagination}
            onChangeEvent={props?.onPaginationChange}
            rowKey='partner_id'
          />
        </div>: null
    }
  </div>;
};

export default PartnersTable;