import React, {useEffect, useState} from 'react';
import YAML from 'json-to-pretty-yaml';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { useDispatch, useSelector } from 'react-redux';

import { Spinner } from '../../CommonComponents';

import {
  operations as appOps,
  selectors as appSelector
} from '../../../state/features/app';

import './YAML-Reader.scss';

const YAMLReader = ({fileURL}) => {

  const dispatch = useDispatch();
  const dispatchGetFileJsonData = appOps.dispatchGetFileJsonData(dispatch);

  const {
    fileJsonContent
  } = useSelector(
    state => ({
      fileJsonContent: appSelector.fileJsonContent(state),
    })
  );

  const [yamlDataString, setYamlDataString] = useState('');

  useEffect(() => {
    if(fileURL) {
      dispatchGetFileJsonData(fileURL);
    }
  }, []);

  // console.log('fileJsonContent', fileJsonContent);

  useEffect(() => {
    if(fileURL && fileJsonContent !== '' && Object.prototype.hasOwnProperty.call(fileJsonContent, fileURL)) {
      const jsonData = fileJsonContent[fileURL] ?? {};
      const translatedData = YAML.stringify(jsonData);
      setYamlDataString(translatedData);
    }
  }, [fileJsonContent]);

  return (
    yamlDataString !== '' ?
      <div className='yamlFile'>
        <SyntaxHighlighter language="yaml" style={docco}>
          {yamlDataString}
        </SyntaxHighlighter>
      </div> :
      <Spinner spinning={true} tip='Loading Code...' />
  );
};

export default YAMLReader;