import React from 'react';
import HistoryTab from './HistoryTab';

const HistoryTabContainer = (props) => {
  const {
    historycolumns,
    history,
    hideHistorypayloadModal,
    showHistoryPayloadModal,
    historyPayload,
    historyPayloadValue,
    loading
  } = props;


  return (
    <HistoryTab 
      historycolumns={historycolumns} 
      history={history} 
      hideHistorypayloadModal={hideHistorypayloadModal} 
      showHistoryPayloadModal={showHistoryPayloadModal} 
      historyPayload={historyPayload} 
      historyPayloadValue={historyPayloadValue} 
      loading={loading}
    />
  );
};

export default HistoryTabContainer;
